import { purple } from "@material-ui/core/colors";
import { createMuiTheme } from "@material-ui/core/styles";

const defaultColors = {
  background: {
    default: "white",
    dark: "#29132e", //'rgba(66, 26, 64, 1)',
    light: "#5c465d" //'rgba(87, 69, 92, 0.6)'
  },
  text: {
    default: "#545964",
    contrast: "white",
    themedLight: "#29132e",
    themedDark: "#4b1c46"
  },
  primary: { main: "rgba(66, 26, 64, 1)", light: "rgba(66, 26, 64, .85)" },
  secondary: {
    main: "rgba(240, 83, 71, 1)",
    light: "rgba(240, 83, 71, 0.85)"
  },
  default: {
    text: "#545964",
    main: "#ffffff",
    light: "#ffffff"
  }
};

const theme = createMuiTheme({
  palette: {
    background: { paper: "#fff", default: "#efeff2" },
    primary: {
      main: defaultColors.primary.main,
      // dark: '#006db3',
      // light: '#63ccff',
      dark: defaultColors.background.dark,
      light: defaultColors.background.light
      // contrastText: '#fff'
    },
    secondary: {
      main: defaultColors.secondary.main
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2
  },
  overrides: {
    // ICON BUTTON
    MuiIconButton: {
      root: {
        padding: "6px"
      }
    },
    // BUTTON
    MuiButton: {
      root: {},
      outlined: {
        border: "solid 1px #d5d2d5",
        boxShadow: "none",
        color: defaultColors.default.text,
        backgroundColor: defaultColors.default.main,
        "&:hover": {
          backgroundColor: defaultColors.default.light
        }
      },
      contained: {
        border: "solid 1px #d5d2d5",
        boxShadow: "none",
        color: defaultColors.default.text,
        backgroundColor: defaultColors.default.main,
        "&:hover": {
          backgroundColor: defaultColors.default.light
        }
      },
      outlinedPrimary: {
        color: defaultColors.text.themedDark,
        backgroundColor: defaultColors.background.default,
        "&:hover": {
          backgroundColor: defaultColors.background.dark
        }
      },
      containedPrimary: {
        color: defaultColors.text.contrast,
        backgroundColor: defaultColors.primary.main,
        "&:hover": {
          backgroundColor: defaultColors.primary.light
        }
      },
      // outlinedSecondary: {
      //   color: defaultColors.text.themedDark,
      //   backgroundColor: defaultColors.background.default,
      //   '&:hover': {
      //     backgroundColor: defaultColors.background.dark
      //   }
      // },
      containedSecondary: {
        color: defaultColors.text.contrast,
        backgroundColor: defaultColors.secondary.main,
        "&:hover": {
          backgroundColor: defaultColors.secondary.light
        }
      }
    },

    // CHECKBOX STYLES
    MuiCheckbox: {
      root: {
        color: "#d5d2d5",
        backgroundColor: "transparent",
        "&$checked": {
          color: "#736973"
        },
        // Remove MUI's onHover gray circle shadow
        "&:hover": {
          backgroundColor: "transparent"
        }
      },
      // checked: {
      //   '&:hover': {
      //     backgroundColor: 'transparent'
      //   }
      // },
      colorPrimary: {
        color: "#d5d2d5",
        backgroundColor: "transparent",
        "&$checked": {
          color: "#5c465d"
        },
        // Remove MUI's onHover gray circle shadow
        "&:hover": {
          backgroundColor: "transparent"
        }
      }
      // colorSecondary: {
      //   '&:hover': {
      //     backgroundColor: 'transparent'
      //   }
      // }
    }
  },

  // props: {
  //   // CHECKBOX PROPS
  //   MuiCheckbox: {
  //     icon: {
  //       width: 18,
  //       height: 18,
  //       borderRadius: 3,
  //       border: 'solid 1px #d5d2d5',
  //       backgroundColor: '#d5d2d5',
  //       // boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  //       // backgroundColor: '#ffffff',
  //       // backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  //       // "$root.Mui-focusVisible &": {
  //       //   outline: "2px auto rgba(19,124,189,.6)",
  //       //   outlineOffset: 20
  //       // },
  //       'input:hover ~ &': {
  //         backgroundColor: '#736973'
  //       },
  //       'input:disabled ~ &': {
  //         boxShadow: 'none',
  //         background: 'rgba(213,210,213,.5)'
  //       }
  //     },
  //     checkedIcon: {
  //       backgroundColor: '#736973',
  //       // backgroundImage:
  //       //   "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  //       '&:before': {
  //         display: 'block',
  //         width: 18,
  //         height: 18,
  //         backgroundImage:
  //           "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
  //           " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
  //           "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
  //         content: '""'
  //       },
  //       'input:hover ~ &': {
  //         backgroundColor: '#433943'
  //       }
  //     }
  //   }
  // },
  typography: {
    subtitle1: {
      fontWeight: 300
    }
  }
});

export default theme;
