import {
  Button,
  Icon,
  Paper,
  Typography,
  Grid,
  List,
  ListItemText,
  Checkbox,
  ListItemSecondaryAction,
  CircularProgress
} from "@material-ui/core";
import AcUnit from "@material-ui/icons/AcUnit";
import _ from "lodash";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import CoolTable, {
  IHeadCell,
  ICoolTablePage
} from "../../components/CoolTable/CoolTable";
import Loading from "../../components/Loading/Loading";
import ServiceNavigationBar from "../../widgets/Menu/ServiceNavigationBar";
import { IAuditMap, IAudit } from "../../models/Audits";
import {
  useStoreActions,
  useStoreState,
  IServiceTypes
} from "../../models/RootStore";
import { ISite } from "../../models/SitesModel";
import useStyle from "./AuditsList.style";
import AuditsDataPopUp from "./AuditsDataPopUp";
import {
  showAuditAction,
  showAuditData,
  auditActionMap
} from "./AuditsActionMap";
import { AuditsFilter, IAuditsFilter } from "./AuditsFilter";
import SelectionsMenu from "../../components/Header/SelectionsMenu";

export interface IAuditRow {
  icon: any;
  date: string;
  actorId: string;
  actionId: string;
  // actionOnSubject: { actionId: string; action: string; subject: string };
  data: any;
  // optional fields
  unitId?: string;
  deviceId?: string;
  siteId?: string;
  customerId?: string;
  unitName?: string;
  deviceName?: string;
  siteName?: string;
  customerName?: string;
  user?: string;
}
type IAuditRowField = keyof IAuditRow;

type Order = "asc" | "desc";

const AuditsList: React.FC = (props: any) => {
  const classes = useStyle();

  const getMyAudits = useStoreActions(s => s.audits.getMyAudits);

  const isInitialized = useStoreState(s => s.isInitialized);
  const allUsers = useStoreState(s => s.users.otherUsers);
  const me = useStoreState(s => s.users.me);
  const allSites = useStoreState(s => s.sites.allSites);
  const allUnits = useStoreState(s => s.units.allUnits);
  const getCustomerName = useStoreState(s => s.customers.getCustomerName);
  const getDeviceName = useStoreState(s => s.devices.getDeviceName);
  const getSiteName = useStoreState(s => s.sites.getSiteName);
  const getFullName = useStoreState(s => s.users.getFullName);
  const getUsername = useStoreState(s => s.users.getUsername);
  const types = useStoreState(s => s.types);
  const [audits, setAudits] = useState<IAuditRow[]>([]);
  const [actorsFilter, setActorsFilter] = useState<IAuditsFilter>({});
  const [actionsFilter, setActionsFilter] = useState<IAuditsFilter>({});
  const selections = useStoreState(s => s.selections.selections);
  const setSelections = useStoreActions(s => s.selections.setSelections);
  const [loading, setLoading] = useState(false);
  const setUnitUpdateStatus = useStoreActions(
    action => action.setUnitUpdateStatus
  );
  setUnitUpdateStatus({ status: "" });
  useEffect(() => {
    if (!audits.length) {
      (async () => {
        // Also reset selections ?
        setSelections({
          customerId: null,
          siteId: null,
          unitId: null,
          dateRange: {
            startDate: new Date(
              new Date().setHours(0, 0, 0) - 7 * 24 * 60 * 60 * 1000
            ),
            endDate: new Date()
          }
        });
        await fetchAudits();
      })();
    } else {
    }
    return () => {
      // cleanup
    };
  }, [audits]);

  const toPastTense = (verb: string | undefined) =>
    verb === "set" ? verb : verb === "accept" ? verb + "ed" : verb + "d";

  const prepareSubject = (name: string) => name.replace("Trigger", t`Anomaly`);

  const fetchAudits = async (payload?: ICoolTablePage<IAuditRow>) => {
    setLoading(true);

    // Get and parse audits from API into our current state
    const auditMap: IAuditMap = await getMyAudits({ params: payload });
    const auditsArr: any = parseAudits(auditMap);
    setAudits(auditsArr);

    // Create filters from users and actions
    let actorsFilterMap = parseFilter(allUsers, "id");
    actorsFilterMap[me.id] = true;
    setActorsFilter(actorsFilterMap);
    const actionsFilterMap = parseFilter(auditMap, "action");
    setActionsFilter(actionsFilterMap);

    setLoading(false);
  };

  // Parse IAuditMap received form API into our internal structure: IAuditRow
  const parseAudits = (auditMap: IAuditMap) => {
    return _.map(Object.values(auditMap), (auditAPI: any) => {
      // Split camelcase action description into separate words
      // const words = audit.action.replace(/([A-Z])/g, ',$1').split(',');
      // let actorId = getFullName(auditAPI.actor);
      // Action name can be one word or two words if it starts with 'root'
      // let actionId = words.shift()?.toLowerCase();
      // let secondWord: string | undefined = '';
      // if (actionId === 'root') {
      //   actorId = actionId;
      //   secondWord = words.shift();
      // }
      // if (secondWord) actionId = secondWord.toLowerCase();
      // Subject name is the rest of the action description
      // const subjectName = words.join(' ');
      // console.log('PARSING :', auditAPI.action, words, actionId, subjectName);
      // Construct table row from parsed data

      const customerId = auditAPI.customer ? auditAPI.customer : auditAPI.site;
      // ? allSites[auditAPI.site].customer
      // : undefined;

      const row: IAuditRow = auditAPI && {
        icon: <AcUnit />,
        date: auditAPI.timestamp,
        actorId: auditAPI.actor,
        actionId: auditAPI.action,
        data: auditAPI.value ? { value: auditAPI.value } : auditAPI.data,
        unitId: auditAPI.unit,
        unitName:
          auditAPI.unit && allUnits[auditAPI.unit]
            ? allUnits[auditAPI.unit].name
            : "-",
        siteId: auditAPI.site,
        siteName: getSiteName(auditAPI.site),
        deviceId: auditAPI.device,
        deviceName: getDeviceName(auditAPI.device),
        customerId: customerId,
        customerName: getCustomerName(customerId),
        user: auditAPI?.user ? getFullName(auditAPI.user) : "-"
      };

      return row;
    });
  };

  const parseFilter = (valueMap: { [key: string]: any }, field: string) => {
    const filterMap: IAuditsFilter = {};
    _.forEach(Object.keys(valueMap), key => {
      const fieldValue: string = valueMap[key][field];
      if (_.isUndefined(fieldValue)) {
        // console.log(`no "${field}" in audit:`, audit);
        // return;
        filterMap["undefined"] = true;
      } else {
        filterMap[fieldValue] = true;
      }
    });
    return filterMap;
  };

  // Apply filters and selections to audits
  const getFilteredAudits = (audits: IAuditRow[]) => {
    function applyFilters(audits: IAuditRow[]) {
      return _(audits)
        .filter(audit => actorsFilter[audit.actorId])
        .filter(audit => actionsFilter[audit.actionId])
        .value();
    }
    function applySelections(audits: IAuditRow[]) {
      return _(audits)
        .filter(audit =>
          selections.unitId ? audit.unitId === selections.unitId : true
        )
        .filter(audit =>
          selections.siteId ? audit.siteId === selections.siteId : true
        )
        .filter(audit =>
          selections.customerId
            ? audit.customerId === selections.customerId
            : true
        )
        .filter(audit =>
          selections.dateRange
            ? moment(new Date(audit.date)).isBetween(
              selections.dateRange.startDate,
              selections.dateRange.endDate
            )
            : true
        )
        .value();
    }
    return applySelections(applyFilters(audits));
  };

  // Get one page out of audits array
  const getAuditsPage = ({
    page,
    rowsPerPage,
    order,
    orderBy
  }: ICoolTablePage<IAuditRow>) => {
    function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
      const stabilizedThis = array.map(
        (el, index) => [el, index] as [T, number]
      );
      stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
      });
      return stabilizedThis.map(el => el[0]);
    }
    function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
      if (b[orderBy] < a[orderBy]) {
        return -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return 1;
      }
      return 0;
    }
    function getComparator<T>(
      order: Order,
      orderBy: keyof T
    ): (a: T, b: T) => number {
      return order === "desc"
        ? (a, b) => descendingComparator<T>(a, b, orderBy)
        : (a, b) => -descendingComparator<T>(a, b, orderBy);
    }
    // console.log('page, rowsPerPage :', page, rowsPerPage);
    return stableSort<IAuditRow>(
      getFilteredAudits(audits),
      getComparator(order, orderBy)
    ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  };

  // Define columns (table header)
  interface IAuditTableColumn extends IHeadCell<IAuditRow> {
    id: IAuditRowField;
    title: string | undefined;
    tableCellClassName?: string | undefined;
  }

  const tableColumns: IAuditTableColumn[] = [
    {
      id: "icon",
      title: "",
      width: 30,
      disableSort: true,
      tableHeadCellClassName: classes.tableHeadCell,
      tableCellClassName: classes.tableCell__icon
    },
    {
      id: "unitId",
      title: "Unit",
      width: "15ch",
      disableSort: true,
      tableHeadCellClassName: classes.tableHeadCell
      // tableCellClassName: classes.tableCell__actionOnSubject
    },
    {
      id: "siteId",
      title: "Site",
      width: "15ch",
      disableSort: true,
      tableHeadCellClassName: classes.tableHeadCell
      // tableCellClassName: classes.tableCell__actionOnSubject
    },
    {
      id: "customerId",
      title: "Customer",
      width: "15ch",
      disableSort: true,
      tableHeadCellClassName: classes.tableHeadCell
      // tableCellClassName: classes.tableCell__actionOnSubject
    },
    {
      id: "actionId",
      title: "Action",
      width: "20ch",
      disableSort: true,
      tableHeadCellClassName: classes.tableHeadCell,
      tableCellClassName: classes.tableCell__actionOnSubject
    },
    {
      id: "actorId",
      title: "User",
      width: "20ch",
      disableSort: true,
      tableHeadCellClassName: classes.tableHeadCell,
      tableCellClassName: classes.tableCell__actor
    },
    {
      id: "date",
      title: "Date/Time",
      width: "30ch",
      disablePadding: true,
      disableSort: true,
      tableHeadCellClassName: classes.tableHeadCell,
      tableCellClassName: classes.tableCell__date
    },
    // {
    //   id: 'subjectName',
    //   title: 'Subject',
    //   tableCellClassName: classes.tableCell__subject
    // },
    {
      id: "data",
      title: "Data",
      width: "30ch",
      disableSort: true,
      tableHeadCellClassName: classes.tableHeadCell,
      tableCellClassName: classes.tableCell__data
    }
  ];

  const ActionColors: { [key: string]: string } = {
    created: "green",
    set: "darkgreen",
    updated: "#b76603",
    accepted: "#fafafa",
    deleted: "red"
  };

  // Returns JSX formatted cell value for MUI Table
  const formatAuditRowField = (audit: IAuditRow, id: IAuditRowField) => {
    const value = audit[id];
    switch (id) {
      case "icon":
        return null;
      case "date":
        return moment(value).format("llll");
      case "actorId":
        // case 'subjectName':
        return getUsername(value);
      case "actionId":
        return showAuditAction(audit);
      case "unitId":
        return audit.unitName;
      case "customerId":
        return audit.customerName;
      case "siteId":
        return audit.siteName;
      // return (
      //   <div>
      //     <span style={{ color: ActionColors[audit.actionOnSubject.action] || 'default' }}>
      //       {audit.actionOnSubject.action}{' '}
      //     </span>
      //     <span>{audit.actionOnSubject.subject}</span>
      //   </div>
      // );
      case "data":
        // return auditFormatData(audit, types);
        return (
          <div className={classes.itemButton}>
            <AuditsDataPopUp audit={audit}></AuditsDataPopUp>
          </div>
        );
      default:
        return value;
    }
  };

  // Formats audit data to display in table cell
  const auditFormatData = (audit: IAuditRow, types: IServiceTypes) => {
    const actionDescription = showAuditData(audit, types);
    if (actionDescription) return actionDescription;
    // if (!audit.data) return <span>-</span>;
    return (
      <pre style={{ fontSize: 10 }}>
        {false ? "-" : JSON.stringify(audit, null, 3)}
      </pre>
    );
  };

  return (
    <ServiceNavigationBar
      {...props}
      filters={<SelectionsMenu hideSystemSelection showDateRangePicker />}
    >
      <div className={classes.view}>
        <div className={classes.contentArea}>
          <div className={classes.headerContainer}>
            <Typography variant="h5">{t`Audit Reports`}</Typography>
            {/* <Button
            variant="contained"
            color="primary"
            endIcon={<Icon style={{ transform: 'rotateY(180deg)' }}>reply</Icon>}
            // onClick={handleAuditShare}
          >
            {t`Share Report`}
          </Button> */}
          </div>
          {loading ? (
            <div className={classes.loadingContainer}>
              <CircularProgress />
              <Typography variant="h5">{t`Loading Audit Reports...`}</Typography>
            </div>
          ) : (
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="stretch"
                className={classes.wrapper}
              >
                <Grid
                  container
                  direction="column"
                  justify="flex-start"
                  alignItems="stretch"
                  className={classes.filterContainer}
                >
                  <Grid item className={classes.filterGridItem}>
                    <AuditsFilter
                      title={t`Users`}
                      className={classes.filter}
                      filters={actorsFilter}
                      setFilter={setActorsFilter}
                      getFilterName={actorId => getUsername(actorId)}
                    />
                  </Grid>
                  <Grid item className={classes.filterGridItem}>
                    <AuditsFilter
                      title={t`Actions`}
                      className={classes.filter}
                      filters={actionsFilter}
                      setFilter={setActionsFilter}
                      getFilterName={action =>
                        auditActionMap[action]?.name || action
                      }
                    />
                  </Grid>
                </Grid>
                <Grid item className={classes.tableWrapper}>
                  <CoolTable
                    headCells={tableColumns}
                    rows={getFilteredAudits(audits)}
                    getPageRows={(page, rowsPerPage, order, orderBy) =>
                      getAuditsPage({ page, rowsPerPage, order, orderBy })
                    }
                    renderCell={formatAuditRowField}
                    defaultOrderBy={"date"}
                    options={{
                      // withDatePicker: true,
                      classNameForTableContainer: classes.tableContainer,
                      rowsPerPage: 10,
                      rowsPerPageOptions: [10, 30, 50, 100]
                    }}
                  />
                </Grid>
              </Grid>
            )}
        </div>
      </div>
    </ServiceNavigationBar>
  );
};

export default AuditsList;
