import {
    Button,
    CircularProgress,
    Grid,
    IconButton,
    InputLabel,
    Switch,
    Typography
} from "@material-ui/core";
import {Check, Close, Edit} from "@material-ui/icons";
import {Formik} from "formik";
import _ from "lodash";
import React, {useEffect, useState} from "react";
import {t} from "ttag";
import * as Yup from "yup";
import {EditIcon} from "../../iconImages";
import {useStoreActions, useStoreState} from "../../models/RootStore";
import AdvancedInputField from "../../widgets/AdvancedInputField/AdvancedInputField";
import LocalButton from "../../widgets/Button/Button";
import ServiceNavigationBar from "../../widgets/Menu/ServiceNavigationBar";
import Site from "../SiteManagement/Site";
import AddUser from "../UserManagement/OneUser";
import style from "./EditCustomer.module.css";

const EditCustomer = (props: any) => {
    const [customer, setCustomer] = useState<any>({});
    const [isOpen, setOpen] = useState<boolean>(false);
    const [customerBeforeEdit, setCustomerBeforeEdit] = useState<any>({});
    const [editableAttribute, setEditableAttribute] = useState<any>({
        name: false,
        description: false
    });
    const {
        history,
        match: {
            params: {customerId}
        }
    } = props;
    const [showAddUserDialog, setShowAddUserDialog] = useState<boolean>(false);
    const [enableLanguageSelection, setEnableLanguageSelection] = useState<boolean>(customer ? customer.enableLanguageSelection : false);
    const [enablePowerDistribution, setEnablePowerDistribution] = useState<boolean>(customer ? customer.enablePowerDistribution : false);
    const [enableMgmtAppAccess, setEnableMgmtAppAccess] = useState<boolean>(customer ? customer.enableMgmtAppAccess : false);
    const [enableServiceAppAccess, setEnableServiceAppAccess] = useState<boolean>(customer ? customer.enableMgmtAppAccess : false);

    const [errorOnCreate, setErrorOnCreate] = useState<string>("");
    const [editable, setEditable] = useState<boolean>(!customerId ? true : false);
    const types = useStoreState((s) => s.types);
    const getCustomerConnectedUnits = useStoreState((state) => state.customers.getCustomerConnectedUnits);
    const createUser = useStoreActions((actions) => actions.users.createUser);
    const createCustomer = useStoreActions(
        (actions) => actions.customers.createCustomer
    );
    const getCustomer = useStoreActions(
        (actions) => actions.customers.getOneCustomer
    );
    const customers = useStoreState((state) => state.customers.customerList);
    const updateCustomer = useStoreActions(
        (actions) => actions.customers.updateCustomer
    );
    const getCustomerDevices = useStoreState((state) => state.customers.getCustomerDevices);
    const {addMessage} = useStoreActions((action) => action.errorMessage);

    const validationSchema = Yup.object({
        name: Yup.string().required(t`required field`),
        discription: Yup.string(),
        billingModel: Yup.number(),
        prePaidSessionLengthMinutes: Yup.number().typeError("must be a number"),
        prePaidAllocatedSessions: Yup.number().typeError("must be a number"),
        email: Yup.string()
            .email("invalid email"),
        licensedUnits: Yup.number()
        // prePaidUsedSessions: Yup.number()
    });

    useEffect(() => {
        // maybe get customer by id is better???
        if (_.isEmpty(customers)) {
            getCustomer(customerId)
                .then((customer: any) => {
                    setCustomer(customer);
                    setCustomerBeforeEdit(customer);
                })
                .catch((error: any) => {
                });

        } else {
            const customer = customers[customerId];
            if (customer) {
                setEnableLanguageSelection(customer.enableLanguageSelection || false);
                setEnablePowerDistribution(customer.enablePowerDistribution || false);
                setEnableMgmtAppAccess(customer.enableMgmtAppAccess || false);
                setEnableServiceAppAccess(customer.enableServiceAppAccess || false);
                setCustomer(customer);
                setCustomerBeforeEdit(customer);
            }
        }
    }, [customerId, customers, getCustomer]);

    const onCreateUser = async (id: string, data: any) => {
        setErrorOnCreate("");
        const res = await createUser({id, data});

        if (res.err) {
            setErrorOnCreate(res.err);
            return;
        }

        setShowAddUserDialog(false);
    };

    // const handleInputChange = (event: any) => {
    //   const {
    //     target: { value, name }
    //   } = event;
    //   setCustomer({ ...customer, [name]: value });
    // };
    const handleClickOpenDialog = () => {
        setOpen(true);
    };
    const handleCloseDialog = () => {
        setOpen(false);
    };
    const goToCustomerSites = () => {
        history.push(`/customers/${customerId}/sites`);
    };
    const goToCustomerUsers = () => {
        history.push(`/customers/${customerId}/users`);
    };
    const goToCustomerDevices = () => {
        history.push(`/customers/${customerId}/devices`);
    };

    const saveCustomer = (values: any, {setSubmitting}: any) => {
        // const { name, description, billingModel, prePaidSessionLengthMinutes,
        //   prePaidAllocatedSessions,
        //   prePaidUsedSessions } = values;
        // TODO finish save..
        delete values.subscriptions;
        delete values.createdAt;
        delete values.smsCounter;
        values.enableLanguageSelection = enableLanguageSelection;
        values.enablePowerDistribution = enablePowerDistribution;
        values.enableMgmtAppAccess = enableMgmtAppAccess;
        values.enableServiceAppAccess = enableServiceAppAccess;
        updateCustomer({id: customerId, data: values})
            .then(() => {
                setEditable(false);
            })
            .finally(() => setSubmitting(false));
    };
    const onCreateCustomer = async (values: any, {setSubmitting}: any) => {
        delete values.subscriptions;
        delete values.createdAt;
        delete values.smsCounter;
        values.enableLanguageSelection = enableLanguageSelection;
        values.enablePowerDistribution = enablePowerDistribution;
        values.enableMgmtAppAccess = enableMgmtAppAccess;
        values.enableServiceAppAccess = enableServiceAppAccess;

        const createdClient = await createCustomer({data: values});

        if (createdClient.err) {
            addMessage({message: createdClient.err.message});
            return;
        } else {
            history.push(`/customers`);

        }
    };
    const statusOptions = _.map(types.customerStatuses, (status) => {
        return {
            value: status.value,
            label: status.text
        };
    });
    const billingOptions = _.map(types.billingModels, (billing) => {
        return {
            value: billing.value,
            label: billing.text
        };
    });
    const users = useStoreState((state) => state.users.otherUsers);

    const customerUsers = customerId ? _.filter(_.values(users), (user) => user.customer === customerId) : [];

    return (
        ((!_.isEmpty(customer) && customerId) || !customerId) && (
            <ServiceNavigationBar title={t`Customers  ${customer ? `> ${customer.name}` : ""}`} {...props}>
                <div className={style.editCustomerPageContainer}>
                    <Grid className={style.addButtonsContainer}>
                        {customerId ?
                            <>
                                < LocalButton
                                    title={t`Add User`}
                                    className={style.saveButtonStyle}
                                    onClick={() => setShowAddUserDialog(true)}
                                />
                                <LocalButton
                                    title={t`Add site`}
                                    className={style.saveButtonStyle}
                                    onClick={handleClickOpenDialog}
                                />
                            </>
                            : ""
                        }
                    </Grid>
                    <Grid className={style.buttonsContainer}>
                        {customerId ?
                            <>

                                <LocalButton
                                    title={t`Sites` + " (" + customer.sites.length + ")"}
                                    className={style.saveButtonStyle}
                                    onClick={goToCustomerSites}
                                />
                                <LocalButton
                                    title={t`Devices` + " (" + getCustomerDevices(customer.id).length + ")"}
                                    className={style.saveButtonStyle}
                                    onClick={goToCustomerDevices}
                                />
                                <LocalButton
                                    title={t`Users` + " (" + customerUsers.length + ")"}
                                    className={style.saveButtonStyle}
                                    onClick={goToCustomerUsers}
                                /></>
                            : ""
                        }
                    </Grid>
                    <div className={style.editCustomerPaper}>
                        <Formik
                            initialValues={{
                                description: customer ? customer.description : "",
                                name: customer ? customer.name : "",
                                billingModel: customer ? customer.billingModel : "",
                                prePaidSessionLengthMinutes: customer ? customer.prePaidSessionLengthMinutes : 0,
                                prePaidAllocatedSessions: customer ? customer.prePaidAllocatedSessions : 0,
                                prePaidUsedSessions: customer ? customer.prePaidUsedSessions : 0,
                                status: customer ? customer.status : "",
                                smsCounter: customer ? customer.smsCounter : 0,
                                subscriptions: getCustomerConnectedUnits(customerId).length || 0,
                                email: customer ? customer.email : "",
                                contactPerson: customer ? customer.contactPerson : "",
                                licensedUnits: customer ? customer.licensedUnits : 0,
                                createdAt: customer ? customer.createdAt : "Unknown"
                                // enableLanguageSelection: : customer ? customer.createdAt : "Unknown",
                                //ADD the rest
                            }}
                            enableReinitialize={true}
                            onSubmit={!customerId ? onCreateCustomer : saveCustomer}
                            validationSchema={validationSchema}
                        >
                            {(formProps) => {
                                const {
                                    values,
                                    touched,
                                    errors,
                                    handleChange,
                                    handleSubmit,
                                    handleReset,
                                    dirty,
                                    isSubmitting
                                } = formProps;
                                return (
                                    <form onSubmit={handleSubmit} style={{backgroundColor: "white"}}>
                                        <div className={style.customerDetailsHeader}>
                                            <Typography className={style.editCustomerTitle}>
                                                {t`Customer Details`}
                                            </Typography>
                                            {isSubmitting && (
                                                <CircularProgress className={style.loader}/>
                                            )}
                                            {customerId && !editable && (
                                                <IconButton onClick={() => setEditable(true)}>
                                                    <img src={EditIcon} alt="edit"/>
                                                </IconButton>
                                            )}
                                            {customerId && editable && (
                                                <div style={{display: "flex"}}>
                                                    <IconButton type="submit" disabled={isSubmitting}>
                                                        <Check className={style.customerDetailsIcons}/>
                                                    </IconButton>
                                                    <IconButton
                                                        disabled={isSubmitting}
                                                        onClick={() => {
                                                            handleReset();
                                                            setEditable(false);
                                                        }}
                                                    >
                                                        <Close className={style.customerDetailsIcons}/>
                                                    </IconButton>
                                                </div>
                                            )}
                                        </div>
                                        <div className={style.customerDetailsFieldsContainer}>
                                            <AdvancedInputField
                                                name="name"
                                                label={t`Name`}
                                                editable={editable}
                                                {...formProps}
                                            />
                                            <AdvancedInputField
                                                name="description"
                                                label={t`Description`}
                                                editable={editable}
                                                {...formProps}
                                            />
                                            <AdvancedInputField
                                                name="contactPerson"
                                                label={t`CA Sales Contact person`}
                                                editable={editable}
                                                {...formProps}
                                            />
                                            <AdvancedInputField
                                                name="email"
                                                label={t`Email`}
                                                editable={editable}
                                                {...formProps}
                                            />
                                            {/* <AdvancedInputField
                        name="Actual subscriptions"
                        label={t`Actual subscriptions`}
                        editable={false}
                        {...formProps}
                      /> */}
                                            <InputLabel
                                                // className={clsx(styles.fieldLabel, styles.tempField)}
                                                style={{width: "100%"}}
                                            >
                                                {t`Language selection`}
                                                <div>
                                                    disable
                                                    <Switch
                                                        disabled={!editable}

                                                        color="default"
                                                        onChange={() => {
                                                            setEnableLanguageSelection(!enableLanguageSelection);
                                                        }}
                                                        checked={enableLanguageSelection}
                                                        name="enableLanguageSelection"
                                                    />
                                                    enable

                                                </div>
                                            </InputLabel>
                                            <InputLabel
                                                // className={clsx(styles.fieldLabel, styles.tempField)}
                                                style={{width: "100%"}}
                                            >
                                                {t`Mgmt app access`}
                                                <div>
                                                    disable
                                                    <Switch
                                                        disabled={!editable}

                                                        color="default"
                                                        onChange={() => {
                                                            setEnableMgmtAppAccess(!enableMgmtAppAccess);
                                                        }}
                                                        checked={enableMgmtAppAccess}
                                                        name="enableMgmtAppAccess"
                                                    />
                                                    enable

                                                </div>
                                            </InputLabel>
                                            <InputLabel
                                                // className={clsx(styles.fieldLabel, styles.tempField)}
                                                style={{width: "100%"}}
                                            >
                                                {t`Service app access`}
                                                <div>
                                                    disable
                                                    <Switch
                                                        disabled={!editable}

                                                        color="default"
                                                        onChange={() => {
                                                            setEnableServiceAppAccess(!enableServiceAppAccess);
                                                        }}
                                                        checked={enableServiceAppAccess}
                                                        name="enableServiceAppAccess"
                                                    />
                                                    enable

                                                </div>
                                            </InputLabel>
                                            <InputLabel
                                                // className={clsx(styles.fieldLabel, styles.tempField)}
                                                style={{width: "100%"}}
                                            >
                                                {t`Power menu`}
                                                <div>
                                                    disable
                                                    <Switch
                                                        disabled={!editable}

                                                        color="default"
                                                        onChange={() => {
                                                            setEnablePowerDistribution(!enablePowerDistribution);
                                                        }}
                                                        checked={enablePowerDistribution}
                                                        name="enablePowerDistribution"
                                                    />
                                                    enable

                                                </div>
                                            </InputLabel>
                                            {
                                                !customer ? "" :
                                                    <AdvancedInputField
                                                        name="createdAt"
                                                        label={t`Creation date`}
                                                        editable={false}
                                                        {...formProps}
                                                    />
                                            }

                                            <AdvancedInputField
                                                select
                                                name="status"
                                                options={statusOptions}
                                                label={t`Status`}
                                                editable={editable}
                                                {...formProps}
                                            />
                                            <AdvancedInputField
                                                select
                                                name="billingModel"
                                                options={billingOptions}
                                                label={t`Billing Model`}
                                                editable={editable}
                                                {...formProps}
                                            />
                                            {customerId ?
                                                <AdvancedInputField
                                                    name="Actual units"
                                                    label={t`Subscriptions`}
                                                    editable={false}
                                                    {...formProps}
                                                />
                                                : ""

                                            }
                                            {customerId ?
                                                <AdvancedInputField
                                                    name="licensedUnits"
                                                    label={t`Subscriptions Units`}
                                                    editable={editable}
                                                    {...formProps}
                                                />
                                                : ""

                                            }

                                            {customerId ?
                                                <AdvancedInputField
                                                    name="smsCounter"
                                                    label={t`SMS usage`}
                                                    editable={false}
                                                    {...formProps}
                                                />
                                                : ""

                                            }

                                            {
                                                (types.billingModels && values.billingModel === types.billingModels.prePaid.value) && (
                                                    <>
                                                        <AdvancedInputField
                                                            type="number"
                                                            name="prePaidSessionLengthMinutes"
                                                            label={t`pre-paid Session Length Minutes`}
                                                            editable={editable}
                                                            {...formProps}
                                                        />
                                                        <AdvancedInputField
                                                            type="number"
                                                            name="prePaidAllocatedSessions"
                                                            label={t`pre-paid Allocated Sessions`}
                                                            editable={editable}
                                                            {...formProps}
                                                        />
                                                        {customerId ?
                                                            <AdvancedInputField
                                                                type="number"
                                                                name="prePaidUsedSessions"
                                                                label={t`pre-paid Used Sessions`}
                                                                editable={false}
                                                                {...formProps}
                                                            />
                                                            : ""
                                                        }
                                                    </>
                                                )
                                            }
                                            {customerId ? "" :
                                                <div className={style.saveBtn}>
                                                    <Button
                                                        title="save site"
                                                        variant="contained"
                                                        color="primary"
                                                        type="submit"
                                                        style={{width: "7rem"}}
                                                    >
                                                        {t`Save`}
                                                    </Button>
                                                </div>
                                            }
                                        </div>
                                    </form>
                                );
                            }}
                        </Formik>
                    </div>
                    <Site
                        isOpen={isOpen}
                        handleCloseDialog={handleCloseDialog}
                        {...props}
                    />
                    {showAddUserDialog && (
                        <AddUser
                            withPasswordField
                            onSave={onCreateUser}
                            onClose={() => setShowAddUserDialog(false)}
                            title="Create User"
                            mainButtonLabel="Create"
                            customers={customers}
                            errorText={errorOnCreate}
                            customerIdLockedto={customerId}
                        />
                    )}
                </div>
            </ServiceNavigationBar>
        )
    );
};

export default EditCustomer;
