import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  row: {
    margin: "1rem",
    borderBottom: "1px solid lightgrey",
    display: "flex",
    alignItems: "center",
  },
  rowInfo: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "calc(100% - 116px)",
  },
  rowTitle: {
    fontWeight: "bold",
    fontSize: "0.875rem",
    color: "rgb(84, 89, 100)",
    width: "5.8rem",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  infoContainer: {
    height: "100%",
    padding: "1rem",
    width: "auto",
    overflow: "auto",
    flexFlow: "column nowrap",
  },
  detailsContainer: {
    marginTop: "2rem",
    marginBottom: "1rem",
  },
  description: {
    backgroundColor: "#f4f4f4",
    marginLeft: "1rem",
    marginTop: "2rem",
    height: "75px",
    color: "#545964",
    fontFamily: "Roboto",
    overflow: "auto",
  },
  descriptionTitle: {
    fontWeight: 500,
  },
  resolveDescription: {
    marginLeft: "1rem",
    marginTop: "1rem",
    fontFamily: "RobotoMedium",
    fontSize: "0.875rem",
    color: "rgb(84, 89, 100)",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  resolvedInfo: {
    border: "1px solid grey",
    borderRadius: "3px",
    marginBottom: "5px",
    width: "100%",
    padding: "5px",
  },
  saveBtn: { alignSelf: "flex-end", width: "40%", marginTop: "5px" },
  resolveTitle: {
    fontWeight: 500,
    alignSelf: "flex-start",
  },
  btn: {
    marginLeft: "1rem",
    marginTop: "2rem",
  },
  dialogTitle: {
    color: "#29132e",
    backgroundColor: "#f6f6f7",
    borderBottom: "1px solid #d5d2d5",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  clearButton: {
    width: "40px",
    height: "40px",
  },
  dialogContent: {
    margin: "2rem",
  },
  alertHeader: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "space-between",
    alignItems: "center",
  },
  emailDialogStyle: {
    padding: 0,
    minWidth: "400px",
    width: "400px",
    minHeight: "300px",
    height: "auto",
    display: "flex",
    flexFlow: "column nowrap",
    maxHeight: "400px",
  },
  emailDialogActions: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "flex-end",
    alignItems: "center",
    marginTop: "30px",
  },
  emailDialogContent: {
    display: "flex",
    flexFlow: "column nowrap",
    padding: "30px 26px 27px 26px",
    maxHeight: "300px",
    overflow: "auto",
    minHeight: "235px",
  },
  cancelBtn: {
    color: "#4b1c46",
    backgroundColor: "#ffffff",
    width: "100px",
    marginRight: "20px",
  },
  sendBtn: {
    width: "100px",
  },
  emailPart: {
    display: "flex",
    width: "100%",
    flexFlow: "row nowrap",
    justifyContent: "space-between !important",
    alignItems: "center",
  },
  deleteTitleContent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "1px solid #d5d2d5",
    padding: "16px 9px 16px 24px",
    color: "#29132e",
  },
  deleteDialog: {
    height: "15.625rem",
    borderRadius: "0.5rem",
    boxShadow: "0 2px 11px 0 rgba(0, 0, 0, 0.15)",
    border: " 1px solid #d5d2d5",
  },

  deleteDialogContent: {
    margin: "1rem",
    padding: "1.25rem",
    paddingBottom: 0,
  },
  deleteContentText: {
    fontFamily: "Roboto",
    color: "#545964",
  },
  deleteNoBtn: {
    width: "9rem",
    height: "2.625rem",
    border: "none",
  },
  deleteYesBtn: {
    color: "#4b1c46",
    width: "9rem",
    height: "2.625rem",
    border: "1px solid #421a40",
  },
  deleteDialogActions: {
    display: "flex",
    justifyContent: "space-evenly",
    marginBottom: "1rem",
  },
  title: {
    backgroundColor: "#f6f6f7",
    color: "#4b1c46",
    borderBottom: "1px solid #d5d2d5",
    padding: "4px 24px",
  },
  titleContent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  alertInfoDialogPaper: {
    width: "540px",
  },
  cancelFilter: { marginRight: "22px" },
  ackButtonContainer: {
    marginTop: "20px",
    marginBottom: "40px",
  },
  ackButton: {
    height: "40px",
    borderRadius: "6px !important",
    border: "solid 1px #413044 !important",
  },
  ackButtonLable: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
  },
  ackButtonText: {
    fontSize: "16px",
    color: "#545964",
    textTransform: "capitalize",
  },
  ackButtonIcon: {
    fontSize: "19px",
    marginRight: "7px",
    color: "#00b259",
  },
  email: {
    "&>span": {
      fontSize: "25px",
    },
  },
});
export default useStyles;
