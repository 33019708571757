import React from "react";
import styles from "./advancedInputField.module.css";

import {
  InputBase,
  InputLabel,
  MenuItem,
  TextField,
  Typography
} from "@material-ui/core";
import _ from "lodash";
import moment from "moment";

const AdvancedInputField = ({
  values,
  handleChange,
  errors,
  touched,
  name,
  label,
  select,
  options = [],
  editable,
  ...props
}: any) => (
  <div className={styles.dataRow}>
    {errors[name] && touched[name] && (
      <Typography className={styles.siteDetailsError}>
        {errors[name] || "required field"}
      </Typography>
    )}
    <div className={styles.flexBox}>
      <InputLabel className={styles.siteDetailsLabel}>{label}</InputLabel>
      {!select ? (
        <InputBase
          disabled={!editable}
          name={name}
          onChange={handleChange}
          value={name === "createdAt" ? moment(values[name]).format("DD/MM/YY") : values[name] || ""}
          className={styles.siteDetailsFields}
          placeholder={label}
        />
      ) : (
          <TextField
            select
            disabled={!editable}
            name={name}
            label={!_.isNil(values[name]) ? "" : `Select ${label}`}
            value={values[name]}
            onChange={handleChange}
            className={styles.selectField}
            InputProps={{
              disableUnderline: true,
              classes: {
                formControl: styles.inputRoot,
                root: styles.inputRoot
              }
            }}
            InputLabelProps={{ shrink: false }}
            margin="none"
          >
            {options.map((option: any, index: any) => (
              <MenuItem key={index} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        )}
    </div>
  </div>
);

export default AdvancedInputField;
