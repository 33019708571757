import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  view: {
    display: "flex",
    height: "100%",
    "& >div": {
      overflow: "unset",
      backgound: "red"
    }
  },
  contentArea: {
    width: "100%",
    height: "100vh"
  },

  loadingContainer: {
    height: "100%",
    background: theme.palette.background.default,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },

  headerContainer: {
    height: "60px",
    display: "flex",
    // padding: "0 1rem",
    background: "#efeff2",
    alignItems: "center",
    justifyContent: "space-between"
  },

  wrapper: {
    /* 130px = headerMenu(70px) + headerContainer(60px) */
    height: "calc(100vh - 160px)",
    // padding: "0 1rem 1rem",
    background: "rgb(236, 235, 239)",
    display: "flex",
    flexWrap: "nowrap"
    // display: 'grid'
    // width: 'auto'
  },
  // buttonContainer: {
  //   justifySelf: 'flex-end',
  //   padding: '0 0 1rem'
  // },
  // titleContainer: {
  //   display: 'flex',
  //   justifySelf: 'flex-start',
  //   padding: '0 0 1rem'
  // },

  // ---- Audits filters
  filterContainer: {
    height: "100%",
    width: "20rem",
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    flexShrink: 0
  },

  filterGridItem: {
    height: "50%",
    background: theme.palette.background.paper,
    borderRadius: 4,
    display: "flex",
    flexDirection: "column",
    padding: "1rem",
    "&:first-child": {
      borderBottom: "1rem solid #efeff2"
    }
  },

  filter: {
    height: "100%",
    overflow: "auto",
    marginBottom: "1rem",
    display: "flex",
    flexDirection: "column",
    boxShadow: "none"
  },

  // ---- Audits table
  tableWrapper: {
    // width: '100%',
    height: "100%",
    marginLeft: "1rem",
    flexGrow: 1
  },

  tableContainer: {
    height: "100%",
    width: "100%"
    // padding: '0 1rem'
  },
  table: {
    // padding: '0 1rem',
    background: theme.palette.background.paper
  },
  tableHeadRow: {
    height: "3rem"
  },
  overWritePadding: {
    // fontSize: '0.8rem',
    // padding: '0 0.8rem'
  },
  tableHeadCell: {
    background: "#efeff2",
    color: "#79797d",
    textTransform: "uppercase"
  },
  tableCell__icon: {
    width: 30
  },
  tableCell__date: {
    width: "24ch",
    paddingLeft: 0
  },
  tableCell__actor: {
    width: "20ch"
  },
  tableCell__unit: {
    width: "15ch"
  },
  tableCell__actionOnSubject: {
    width: "25ch"
  },
  tableCell__subject: {
    width: "15ch"
  },
  tableCell__data: {
    width: "auto",
    padding: " 5px 2px"
  },
  editIcon: {
    fontSize: "1rem",
    cursor: "pointer",
    color: "initial",
    "&:hover": {
      color: "green"
    }
  },
  deleteIcon: {
    fontSize: "1rem",
    cursor: "pointer",
    color: "initial",
    "&:hover": {
      color: "red"
    }
  },

  title: {
    borderBottom: "1px gray solid",
    marginBottom: "2rem",
    width: "100%",
    lineHeight: "50px"
  },
  addNewUser: {
    borderRadius: "4px",
    width: "15%",
    alignSelf: "flex-end",
    color: "#fff",
    background:
      "linear-gradient(-60deg, rgb(66, 26, 64) 50%, rgb(41, 19, 46) 50%)",
    fontFamily: "RobotoMedium",
    fontSize: "0.875rem",
    textAlign: "center",
    marginRight: "1rem"
  },

  mainTitle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  iconButton: {
    minWidth: "fit-content",
    padding: "0"
  },

  headMenuTitle: {
    fontSize: "1.5rem",
    fontWeight: "bold",
    color: "white",
    marginBottom: "1.5rem",
    width: "100%",
    lineHeight: "6rem",
    borderBottom: "1px rgb(87, 69, 92) solid",
    padding: "0 10px 0 10px",
    boxSizing: "border-box"
  },
  '[class^="NavigationBar"]': {
    padding: 0
  },
  body: {
    background: "#fafafa"
  },
  emailColor: {
    color: "#0079ff"
  },
  button: {
    backgroundColor: "white",
    color: "rgba(41, 19, 46, 0.95)",
    marginTop: 12
  },
  itemButton: {
    display: "flex",
    alignItems: "center"
  },
  dialogTitle: {
    backgroundColor: "#f6f6f7",
    // color: "#29132e",
    color: "#4b1c46",
    borderBottom: "1px solid #d5d2d5",
    padding: "4px 24px"
  },
  titleContent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  container: {
    border: "1px solid #d5d2d5",
    borderRadius: "4px",
    marginBottom: "1.5rem",
    marginTop: "1rem"
  },
  auditContent: {
    padding: ".5rem"
  }
}));

export default useStyles;
