import { Card, Typography } from "@material-ui/core";
import {
  AcUnit,
  CancelPresentation,
  Devices,
  PeopleAlt,
  Room,
  SettingsApplications,
  SettingsSystemDaydream,
  SupervisedUserCircle
} from "@material-ui/icons";
import { Admin as sdkAdmin } from "coolremote-sdk";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { t } from "ttag";
import SelectionsMenu from "../../components/Header/SelectionsMenu";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import ServiceNavigationBar from "../../widgets/Menu/ServiceNavigationBar";
import styles from "./Dashboard.module.css";

const cards = [
  {
    title: t`Customers`,
    path: `/customers`,
    info: "customerCount",
    icon: <PeopleAlt className={styles.icon} />
  },
  {
    title: t`Sites`,
    path: `/sites`,
    info: "siteCount",
    icon: <Room className={styles.icon} />
  },
  {
    title: t`Registered Devices`,
    path: `/devices`,
    info: "devicesCount",
    icon: <Devices className={styles.icon} />
  },
  {
    title: t`Disconnected Devices`,
    path: `/devices/disconnected`,
    info: "disconnectedDevicesCount",
    icon: <CancelPresentation className={styles.icon} />
  },
  {
    title: t`Connected Devices`,
    path: `/devices/connected`,
    info: "connectedDevicesCount",
    icon: <Devices className={styles.icon} />
  },
  {
    title: t`Users`,
    path: `/users`,
    info: "userCount",
    icon: <SupervisedUserCircle className={styles.icon} />
  },
  {
    title: t`All Units`,
    path: `/`,
    info: "unitCount",
    icon: <AcUnit className={styles.icon} />
  },
  {
    title: t`Control Units`,
    path: `/`,
    info: "controlUnitCount",
    icon: <AcUnit className={styles.icon} />
  },
  {
    title: t`Outdoor Units`,
    path: `/`,
    info: "outdoorUnitCount",
    icon: <Devices className={styles.icon} />
  },
  {
    title: t`Service Units`,
    path: `/`,
    info: "serviceUnitCount",
    icon: <SettingsApplications className={styles.icon} />
  },
  {
    title: t`Systems`,
    path: `/`,
    info: "systemCount",
    icon: <SettingsSystemDaydream className={styles.icon} />
  }
];

export default function Dashboard(props: any) {
  const history = useHistory();
  const [stats, setStats] = useState<any>({});
  const selections = useStoreState((s) => s.selections.selections);
  const [selectedCustomer, setSelectedCustomer] = useState<string>(selections?.customerId || "");

  useEffect(() => {
    if (selectedCustomer){
      sdkAdmin.getGeneralStats(selectedCustomer)
      .then((resp: any) => {
        setStats(resp);
      });
      return;
    }

    sdkAdmin.getGeneralStats()
    .then((resp: any) => {
      setStats(resp);
    });
  }, [selectedCustomer]);

  const goTo = (path: string) => {
    history.push(path);
  };

  return (
    <ServiceNavigationBar
      filters={<SelectionsMenu hideSiteSelection hideUnitSelection hideSystemSelection onCustomerSelect={(id: any) => setSelectedCustomer(id)} />}
      {...props}
    >
      <div className={styles.dashboardContainer}>

        {!_.isEmpty(stats) ? cards?.map((card: any, index: number) => (
          <Card
            key={`card-${index}-${card.info}`}
            className={styles.cardContainer}
            onClick={() => goTo(card.path)}
          >
            <div className={styles.cardTitle}>
              <Typography
                className={styles.cardText}
              >{card.title}</Typography>
              {card.icon}
            </div>
            <Typography className={styles.cardInfo}>{stats[card.info]}</Typography>
          </Card>
        ))
          :
          <span />

        }

      </div>
    </ServiceNavigationBar >
  );
}
