import React, { useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import {
  Button,
  Icon,
  Paper,
  Typography,
  Grid,
  List,
  ListItemText,
  Checkbox,
  ListItemSecondaryAction,
  ListItem,
  MuiMediaQueryList,
  ListProps,
  PaperProps
} from '@material-ui/core';
import _ from 'lodash';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
export interface IAuditsFilter {
  [key: string]: boolean;
}

export interface IAuditsFilterProps {
  title: string;
  filters: IAuditsFilter;
  setFilter: (newFilter: IAuditsFilter) => void;
  getFilterName?: (filter: string) => string;
}

const useStyles = makeStyles((theme: Theme) => ({
  filterHeaderContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: '0.5rem',
    borderBottom: '2px solid rgba(60, 60, 60, 0.2)'
  },
  filterHeader: {
    paddingLeft: '0.5rem',
    color: '#4b1c46'
  },
  filterList: {
    height: '100%',
    overflow: 'auto'
  }
}));

export const AuditsFilter: React.FC<IAuditsFilterProps & Partial<PaperProps>> = ({
  title,
  filters,
  setFilter,
  getFilterName,
  ...rest
}) => {
  const styles = useStyles();
  const [allToggled, setAllToggled] = useState(true);

  const handleToggle = (filterId: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFilter: IAuditsFilter = { ...filters, [filterId]: !!event.target.checked };
    setFilter(newFilter);
  };

  const handleToggleAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFilter: IAuditsFilter = {};
    for (let key of Object.keys(filters)) {
      if (key !== 'all') {
        newFilter[key] = !allToggled;
      }
    }
    setFilter(newFilter);
    setAllToggled(!allToggled);
  };

  const filterKeys = Object.keys(filters);
  const sortedFilterKeys = filterKeys.sort((a: string, b: string) => {
    if (getFilterName) {
      a = getFilterName(a);
      b = getFilterName(b);
    }
    return a.toLowerCase().localeCompare(b.toLowerCase());
  });

  let isIndeterminate = false;
  let sameKey: boolean | undefined = undefined;
  for (let key of filterKeys) {
    if (_.isUndefined(sameKey)) {
      sameKey = filters[key];
    } else if (sameKey !== filters[key]) {
      isIndeterminate = true;
      break;
    }
  }

  return (
    <Paper {...rest}>
      <div className={styles.filterHeaderContainer}>
        <Typography variant="h6" className={styles.filterHeader}>
          {title}
        </Typography>
      </div>
      <List disablePadding={true} className={styles.filterList}>
        <ListItem dense autoFocus divider key={'all'}>
          <ListItemText id={'all'} primary={` All ${title}`} />
          <ListItemSecondaryAction>
            <Checkbox
              color="default"
              edge="end"
              onChange={handleToggleAll}
              checked={allToggled}
              indeterminate={isIndeterminate}
              inputProps={{ 'aria-labelledby': `toggle all ${title}` }}
            />
          </ListItemSecondaryAction>
        </ListItem>
        {_.map(sortedFilterKeys, (key, id) => (
          <ListItem dense autoFocus divider key={id}>
            <ListItemText id={`${id}`} primary={getFilterName ? getFilterName(key) : key} />
            <ListItemSecondaryAction>
              <Checkbox
                color="default"
                edge="end"
                onChange={handleToggle(key)}
                checked={filters[key]}
                inputProps={{ 'aria-labelledby': key }}
              />
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </Paper>
  );
};
