
import _ from 'lodash';
import React from 'react';
import '../../icons.css';
import style from './Button.module.css';

class Button extends React.Component<{title: string; onClick?: Function; className?: string; disabled?: boolean}, any> {

    public render() {
        return (
        <button className={`${style.bigButton} ${this.props.className}`} type="button" onClick={() => this.onClick()} disabled={this.props.disabled}>
            <span className={style.title}> {this.props.title} </span>
        </button>
        );
    }

    private onClick() {
        if (_.isFunction(this.props.onClick)) {
            this.props.onClick();
          }
      }
}

export default Button;
