import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  menuHeader: {
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    position: "relative",
    justifyContent: "space-between",
    backgroundColor: "transparent",
    color: "#fff",
    fontSize: "0.9375rem"
  },
  icon: {
    color: "#7f7692",
    paddingTop: "5px",
    fontSize: "1.5em"
  },
  menuTitle: {
    cursor: "pointer",
    display: "inline",
    lineHeight: "50px",
    verticalAlign: "middle",
    padding: "0 0.5rem",
    overflow: "hidden",
    whiteSpace: "nowrap",
    width: "calc(100% - 20px)",
    textOverflow: "ellipsis"
  },
  selectedItem: {
    backgroundColor: "#523b53",
    "&>span": {
      fontWeight: "bold"
    }
  }
});

export default useStyles;
