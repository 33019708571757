import { Customer } from "coolremote-sdk";
import { Services as ServicesSdk, SubscriptionPlan as SubscriptionSdk } from "coolremote-sdk";
import {
  Action,
  action,
  actionOn,
  ActionOn,
  Computed,
  computed,
  debug,
  memo,
  Thunk,
  thunk,
} from "easy-peasy";
import _ from "lodash";
import { IRootStoreModel } from "./RootStore";

export interface ISubscription {
  id: string;
  isPerpetual: boolean;
  endDate?: string;
  startDate?: string;
  description?: string;
  purchaseOrder?: string;
  contactEmail?: string;
  managementUnits?: number;
  serviceUnits?: number;
  site: string;
  customer: string;
}

export interface ISubscriptionMap {
  [key: string]: ISubscription;
}

export interface ISubscriptionsModel {
  allSubscriptions: ISubscriptionMap;
  initialize: Action<ISubscriptionsModel, any>;
  onInitialized: ActionOn<ISubscriptionsModel, IRootStoreModel>;
  updateSubscription: Thunk<
    ISubscriptionsModel,
    { subscriptionId: string; updatedData: any }
  >;
  getSubscriptions: Thunk<ISubscriptionsModel, { customerId: any }>;
  createSubscriptionPlan: Thunk<
    ISubscriptionsModel,
    { customerId: any; data: any }
  >;
  associateSubscriptionToSite: Thunk<
    ISubscriptionsModel,
    { id: any; siteId: any }
  >;
  dissociateSubscriptionFromSite: Thunk<ISubscriptionsModel, { id: any }>;

  _storeUpdateSubscription: Action<
    ISubscriptionsModel,
    { subscriptionId: string; updatedSubscriptionData: any }
  >;
  getSubscription: Computed<
    ISubscriptionsModel,
    (id?: string | null) => ISubscription | undefined
  >;
  getSubscriptionById: Thunk<ISubscriptionsModel, { id: any }>;
  deleteSubscription: Thunk<ISubscriptionsModel, { id: any }>;
  getSubscriptionTemplates: Thunk<any>;
}

export const subscriptionsModel: ISubscriptionsModel = {
  allSubscriptions: {},

  initialize: action((state, payload) => {
    const newSubscriptions: ISubscriptionMap = _(Object.values(payload))
      .map((subscription: any) => {
        const newSubscriptions: ISubscription = { ...subscription };
        return newSubscriptions;
      })
      .keyBy("id")
      .value();
    state.allSubscriptions = newSubscriptions;
  }),

  onInitialized: actionOn(
    (actions, storeActions) => [actions.initialize],
    (state, target) => {
      console.log(target.resolvedTargets);
    }
  ),
  getSubscription: computed(
    [(state) => state.allSubscriptions],
    (allSubscriptions) =>
      memo((id) => {
        if (_.isNil(id)) {
          return undefined;
        }
        return allSubscriptions[id];
      }, 100)
  ),
  updateSubscription: thunk(async (actions, payload) => {
    const updatedSubscriptionData = await SubscriptionSdk.update(
      payload.subscriptionId,
      payload.updatedData
    );

    actions._storeUpdateSubscription({
      subscriptionId: payload.subscriptionId,
      updatedSubscriptionData
    });
  }),
  getSubscriptions: thunk(async (actions, payload) => {
    return Customer.getSubscriptionPlans(payload.customerId);
  }),

  createSubscriptionPlan: thunk(async (actions, payload) => {
    return Customer.createSubscriptionPlan(
      payload.customerId,
      payload.data
    );
  }),
  associateSubscriptionToSite: thunk(async (actions, payload) => {
    const updatedSubscriptionData = await SubscriptionSdk.associateSubscriptionToSite(
      payload.id,
      payload.siteId
    );
    actions._storeUpdateSubscription({
      subscriptionId: payload.id,
      updatedSubscriptionData
    });
  }),
  dissociateSubscriptionFromSite: thunk(async (actions, payload) => {
    const updatedSubscriptionData = await SubscriptionSdk.dissociateSubscriptionFromSite(
      payload.id
    );
    actions._storeUpdateSubscription({
      subscriptionId: payload.id,
      updatedSubscriptionData
    });
  }),
  getSubscriptionTemplates: thunk((actions, payload) => {
    return ServicesSdk.getSubscriptionTemplates();
  }),
  _storeUpdateSubscription: action((state, payload) => {
    if (state.allSubscriptions[payload.subscriptionId]) {
      state.allSubscriptions[payload.subscriptionId] =
        payload.updatedSubscriptionData;
    }
  }),
  getSubscriptionById: thunk(async (actions, payload) => {
    return SubscriptionSdk.getSubscriptionPlan(payload);
  }),
  deleteSubscription: thunk(async (actions, payload) => {
    console.log(payload);
    return SubscriptionSdk.delete(payload.id);
  })
};
