import React from 'react';
import _ from 'lodash';
import { t } from 'ttag';
import {
    Button,
    DialogTitle,
    DialogActions,
    DialogContent,
    Dialog,
    Typography
} from '@material-ui/core';
import styles from './SimpleModal.module.css';



interface IProps {
    showModal: boolean,
    title: string,
    contentText?: string,
    actionName?: string,
    onAction?: ()=>void,
    closeModal?: ()=>void,
    children?: any | any[],
    hideBtns?: boolean
}

export default function SimpleModal(props: IProps) {
    if(!props.showModal){
        return <div/>;
    }

    return (
        <Dialog onClose={_.noop} aria-labelledby="simple-dialog-title" open={true}>
            <DialogTitle id="simple-dialog-title">{props.title}</DialogTitle>
            <DialogContent className={styles.content}>
                {props.contentText && <Typography>{props.contentText}</Typography>}
                {props.children}
            </DialogContent>
            { !props.hideBtns &&
            <DialogActions>
                <Button onClick={props.closeModal} >
                    {t`Cancel`}
                </Button>
                <Button onClick={props.onAction} >
                    {props.actionName}
                </Button>
            </DialogActions>
            }
        </Dialog>
    );
}
