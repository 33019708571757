import _ from "lodash";
import React from "react";
import { Menu, Header } from "../../components";
import styles from "./ServiceNavigationBar.module.css";

const serviceNavigationBar: React.FC<any> = ({
  history,
  match,
  title,
  children,
  filters
}) => {
  return (
    <div className={styles.serviceNavigationContainer}>
      <Menu history={history} />
      <div className={styles.rightContent}>
        <Header
          history={history}
          match={match}
          title={title}
          filters={filters}
        />
        <div className={styles.pageContent}>{children}</div>
      </div>
    </div>
  );
};

export default serviceNavigationBar;
