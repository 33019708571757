import { Card, IconButton, Typography } from "@material-ui/core";
import clsx from "clsx";
import _ from "lodash";
import React from "react";
import { useHistory  } from "react-router";
import { t } from "ttag";
import Button from "../../components/cool_widgets/Button";
import { useStoreState } from "../../models/RootStore";
import styles from "./UnitInfo.module.css";
import useStyles from "./UnitInfo.style";

interface IProps {
  unit: any;
  site: any;
  device: any;
  system: any;
  showSysBTn?: boolean;
}

export default function UnitInfo(props: IProps) {
  const classes = useStyles();
  const history = useHistory();

  const {
    id,
    name,
    serialNumber,
    privateId,
    capacity,
    capacityMeasurementUnits,
    proId,
    airnet,
    model,
    type
  } = props.unit;
  const siteName = props.site.name;
  const hvacBrands = useStoreState((state) => state.types.hvacBrands);
  const brandName =
    props.system && !_.isNil(_.find(hvacBrands, { value: props.system.brandNum }))
      ? _.find(hvacBrands, { value: props.system.brandNum }).name
      : null;
  const brand = brandName ? brandName : "--";
  const capacityMeasurementUnitTypes = useStoreState(
    (state) => state.types.capacityMeasurementUnitTypes
  );
  const unitTypesMirrror = useStoreState((state) => state.unitTypesMirrror);

  return (
    <Card className={classes.card}>
      <div className={classes.unitInfoHeader}>
        <Typography className={classes.unitInfoTitle}>{`${unitTypesMirrror[type]?.toUpperCase() === "SERVICE" ? "Indoor" : (unitTypesMirrror[type]?.toUpperCase() === "BSBOX" ? "Bs Box" : unitTypesMirrror[type])}` + t` Unit Information`}</Typography>

        {(false && !props.device.isConnected) &&
          <Typography className={classes.unitInfoDisconnectedText}>{t`- Unit device is disconnected`}</Typography>
        }
        {(props.showSysBTn && props?.system?.id) && <Button className={classes.sysBtn} onClick={() => history.push(`/system-diagnostics/${props.system.id}`)}>Go To System Diagnostics</Button>}
      </div>
      <div className={classes.itemsWrapper}>
        <div className={clsx(classes.item, classes.firstItem)} style={{ width: "13%" }}>
          <Typography className={classes.infoTitle}>{t`Unit Name`}</Typography>
          <Typography className={clsx(classes.info, classes.limited20)}>{name}</Typography>
        </div>
        <div className={clsx(classes.item, classes.oneLineNoEllipse)} style={{ width: "13%" }}>
          <Typography className={classes.infoTitle}>{t`Unit SN`}</Typography>
          <Typography className={clsx(classes.info, classes.oneLineNoEllipse)} style={{ maxWidth: "12ch" }}>{serialNumber}</Typography>
        </div>
        <div className={clsx(classes.item, classes.oneLineNoEllipse)} style={{ width: "13%" }}>
          <Typography className={classes.infoTitle}>{t`Brand`}</Typography>
          <Typography className={clsx(classes.info, classes.oneLineNoEllipse)} style={{ maxWidth: "17ch" }}>{brand}</Typography>
        </div>
        <div className={classes.item} style={{ width: "13%" }}>
          <Typography className={classes.infoTitle}>{t`Site Name`}</Typography>
          <Typography className={clsx(classes.info, classes.limited20)}>{siteName}</Typography>
        </div>
        <div className={clsx(classes.item, classes.oneLineNoEllipse)} style={{ width: "13%" }}>
          <Typography className={classes.infoTitle}>{t`ID`}</Typography>
          <Typography className={clsx(classes.info, classes.oneLineNoEllipse)} style={{ maxWidth: "7ch" }}> {privateId}</Typography>
        </div>
        <div className={clsx(classes.item, classes.oneLineNoEllipse)} style={{ width: "13%" }}>
          <Typography className={classes.infoTitle}>{t`Model`}</Typography>
          <Typography className={clsx(classes.info, classes.oneLineNoEllipse)} style={{ maxWidth: "12ch" }}> {model}</Typography>
        </div>
        <div className={clsx(classes.item, classes.oneLineNoEllipse)} style={{ width: "11%" }}>
          <Typography className={classes.infoTitle}>{t`Capacity`}</Typography>
          {capacity !== 0 && (
            <div>
              <Typography className={clsx(classes.info, classes.oneLineNoEllipse)} style={{ maxWidth: "12ch" }}>
                {capacity && capacity}
                {capacity &&
                  capacityMeasurementUnits &&
                  _.invert(capacityMeasurementUnitTypes)[capacityMeasurementUnits]}
              </Typography>
            </div>
          )}
        </div>
        {airnet && airnet !== 0 ? (
          <div className={clsx(classes.item, classes.oneLineNoEllipse)} style={{ width: "11%" }}>
            <Typography className={classes.infoTitle}>{t`Airnet: `}</Typography>
            <Typography className={clsx(classes.info, classes.oneLineNoEllipse)} style={{ maxWidth: "12ch" }}>{airnet}</Typography>
          </div>
        ) : (
            <></>
          )}
      </div>
    </Card>
  );
}
