import {
  Button,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";
import { FilterList } from "@material-ui/icons";
import clsx from "clsx";
import { Admin as SdkAdmin } from "coolremote-sdk";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { t } from "ttag";
import SelectionsMenu from "../../components/Header/SelectionsMenu";
import { Lookup } from "../../components/Lookup";
import { DeleteIcon, EditIcon } from "../../iconImages";
import ArrowIcon from "../../icons/ArrowLong";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import { IUserModel } from "../../models/UsersModel";
import ErrorBox from "../../widgets/ErrorBox/ErrorBox";
import ServiceNavigationBar from "../../widgets/Menu/ServiceNavigationBar";
import SimpleModal from "../../widgets/SimpleModal/SimpleModal";
import OneUser from "./OneUser";
import styles from "./UserManagement.module.css";

export default function UserManagement(props: any) {
  const history = useHistory();
  const getCustomerUsers = useStoreActions((actions) => actions.users.getCustomerUsers);
  const selections = useStoreState((state) => state.selections.selections);
  const allCustomers = useStoreState((state) => state.customers.customerList);
  const allLanguages = useStoreState((state) => state.types.languages);

  const onUpdate = useStoreActions((actions) => actions.users.updateUser);
  const delUser = useStoreActions((actions) => actions.users.deleteUser);
  const createUser = useStoreActions((actions) => actions.users.createUser);

  const [chosenUser, setChoseUser] = useState<IUserModel | null>(null);
  const [delModal, setDelModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [showErr, setShowErr] = useState<string>("");
  const [showModal, toggleModal] = useState(false);
  const [errorOnCreate, setErrorOnCreate] = useState<string>("");
  const [currentUsers, setCurrentUsers] = useState<any>([]);
  const [users, setUsers] = useState<any>({});
  const [filters, setFilters] = useState<any>({
    username: [], customer: []
  });
  const [appliedFilters, setAppliedFilters] = useState<any>({
    username: [],
    customer: []
  });
  const [clickedColumnHead, setClickedColumnHead] = useState<string>("");
  const [lookupAnchor, setAnchor] = useState(null);
  const [searchValue, setSearchValue] = useState<any>("");
  const [filteredUsers, setFilteredUsers] = useState<any>([]);

  useEffect(() => {
    const { customerId } = props.match.params;
    if (!customerId && !selections.customerId) {
      return;
    }

    if (!customerId && !!selections.customerId) {
      history.push(`/customers/${selections.customerId}/users`);
      return;
    }
    getCustomerUsers(customerId).then((data: any) => {
      setUsers(data);
    });
  }, [getCustomerUsers, props.match.params]);

  useEffect(() => {
    const { customerId } = props.match.params;

    const customerUsers = customerId
      ? Object.values(users).filter((user: any) => user.customer === customerId)
      : Object.values(users);
    setCurrentUsers(Object.values(users));
    setFilteredUsers(customerUsers);
    if (customerId) {
      setAppliedFilters({
        ...appliedFilters,
        customer: [customerId]
      });
    }

  }, [props.match.params, users]);

  useEffect(() => {
    if (_.isEmpty(filteredUsers)) {
      setFilters({ username: [], customer: [] });
    }
    const usernames: any = [];
    const customers: any = [];
    filteredUsers.forEach((user: any) => {
      const { username, customer } = user;
      !usernames.includes(username) && usernames.push(username);
      customer && !customers.includes(customer) && customers.push(customer);
    });
    const { customerId } = props.match.params;

    if (customerId) {
      setFilters({ username: usernames, customer: Object.keys(allCustomers) });

    } else {
      setFilters({ username: usernames, customer: customers });

    }
  }, [filteredUsers]);

  const filterUsers = useCallback(
    (filterValues: any) => {
      const filteredItems = currentUsers.filter((item: any) => {
        if (!item) {
          return null;
        }

        const { username, customer } = item;
        if (
          (_.isEmpty(filterValues.username) ||
            filterValues.username.includes(username)) &&
          (_.isEmpty(filterValues.customer) ||
            filterValues.customer.includes(customer))
        ) {
          return item;
        }
        return null;
      });
      setFilteredUsers(filteredItems);
    },
    [currentUsers]
  );

  useEffect(() => {
    if (_.isEmpty(currentUsers)) {
      return;
    }
    if (
      appliedFilters.username.length === 0 &&
      appliedFilters.customer.length === 0
    ) {
      setFilteredUsers(currentUsers);
      return;
    }

    filterUsers(appliedFilters);
  }, [appliedFilters, currentUsers, filterUsers]);

  const onUpdateUser = async (id: string, data: any) => {
    const res = await onUpdate({ id, data });

    if (res.err && res.err === "username already exists") {
      setErrorOnCreate(res.err);
      return;
    }

    if (res.err) {
      setShowErr(res.err);
    }

    setChoseUser(null);
    setEditModal(false);
  };

  const onCreateUser = async (id: string, data: any) => {
    setErrorOnCreate("");

    const res = await createUser({ id, data });

    if (res.err && res.err === "username already exists") {
      setErrorOnCreate(res.err);
      return;
    }

    if (res.message) {
      setShowErr(res.err);
    }

    toggleModal(false);
    if (!selections?.customerId) {
      return;
    }
    getCustomerUsers(selections?.customerId)
      .then((users: any) => {
        setUsers(users);
      });

  };

  const onDelete = async () => {
    if (!chosenUser) {
      return onCloseDelModal();
    }

    const success = await delUser(chosenUser.id);
    if (!success) {
      setShowErr("something went wrong while deleting User");
    }

    onCloseDelModal();
  };

  const onCloseDelModal = () => {
    setDelModal(false);
    setChoseUser(null);
  };

  const doImpersonate = (username: any, application: string) => {
    let env = "";
    if (window.location.origin.indexOf("-dev") !== -1 || window.location.origin.indexOf("localhost") !== -1) {
      env = "-dev";
    } else if (window.location.origin.indexOf("-stg") !== -1) {
      env = "-stg";
    }
    let appId;
    if (application === "service") {
      appId = "CoolService"
    }
    if (application === "management") {
      appId = "CoolManagement"
    }
    if (application === "control") {
      appId = "coolAutomationControl"
    }

    SdkAdmin.getUserToken(username, appId)
      .then(({ token }) => {
        const url = `https://${application}${env}.daichicloud.ru/impersonate/${token}`;
        const win = window.open(url, "_blank");
        win?.focus();
      });
  };

  const onDelModal = () => {
    const name = chosenUser ? chosenUser.username : "";
    const bodyText = `are you sure you want to delete this user ${name}`;
    return (
      <SimpleModal
        showModal={delModal}
        title="Delete User"
        contentText={bodyText}
        actionName="Delete"
        onAction={onDelete}
        closeModal={onCloseDelModal}
      ></SimpleModal>
    );
  };

  const onErrorModal = () => {
    if (!showErr.length) {
      return <div />;
    }

    return (
      <ErrorBox
        error={showErr}
        onClose={() => {
          onCloseDelModal();
          setShowErr("");
        }}
      />
    );
  };

  const clearSearchValue = () => {
    setSearchValue("");
    setFilteredUsers(currentUsers);
  };

  const handleSearchValueChange = (event: any) => {
    const {
      target: { value }
    } = event;
    setSearchValue(value);
  };

  const onApply = (selectedFilters: any) => {
    setAppliedFilters({
      ...appliedFilters,
      [clickedColumnHead]: selectedFilters
    });
    setClickedColumnHead("");
  };
  const handleFilterPopup = (event: any, clickedcolumn: string) => {
    setClickedColumnHead(clickedcolumn);
    setAnchor(event.currentTarget);
  };

  const hasFilters = !!Object.values(appliedFilters).flat().length;
  const sortedUsers = _.orderBy(filteredUsers, [(user) => user.username.toLowerCase()], ["asc"]);

  return (
    <>
      <ServiceNavigationBar
        title={t`Users Management`}
        filters={<SelectionsMenu hideSiteSelection hideUnitSelection hideSystemSelection onCustomerSelect={(id: any) => id ? history.push(`/customers/${id}/users`) : history.push(`/users`)} />}
        {...props}
      >
        {onErrorModal()}
        {onDelModal()}
        <div className={styles.addButtonContainer}>
          <Button
            className={styles.buttonStyle}
            onClick={() => toggleModal(true)}
          >{t`ADD NEW User`}</Button>
        </div>
        {!selections.customerId ?
          (<Grid container direction={"column"} className={styles.noContentContainer}>
            <div className={styles.grayOval}>
              <ArrowIcon className={styles.arrowIcon} />
            </div>
            <Typography >
              {t`Please select a customer using the above filters.`}
            </Typography>
          </Grid>) :
          <>
            <Paper elevation={0} className={styles.paperPadding}>
              <TableContainer>
                <Table stickyHeader className="" aria-label="customized table">
                  <TableHead className={styles.tableHead}>
                    <TableRow>
                      <TableCell
                        className={styles.headCells}
                        onClick={(e: any) => handleFilterPopup(e, "username")}
                      >
                        <div className={styles.headContainer}>
                          {t`USERNAME`}{" "}
                          <FilterList
                            className={clsx(styles.filterStyle, {
                              [styles.blueFilter]: !_.isEmpty(
                                appliedFilters.username
                              )
                            })}
                          />
                        </div>
                      </TableCell>
                      <TableCell className={styles.headCells}>{t`EMAIL`}</TableCell>
                      <TableCell
                        className={styles.headCells}
                      >
                        <div className={styles.headContainer}>
                          {t`Impersonate Service`}
                        </div>
                      </TableCell>
                      <TableCell
                        className={styles.headCells}
                      >
                        <div className={styles.headContainer}>
                          {t`Impersonate Management`}
                        </div>
                      </TableCell>
                      <TableCell
                        className={styles.headCells}
                      >
                        <div className={styles.headContainer}>
                          {t`Impersonate Control`}
                        </div>
                      </TableCell>
                      <TableCell className={styles.headCells}>{t`CREATION DATE`}</TableCell>
                      <TableCell className={styles.headCells}>{t`CREATOR USER`}</TableCell>
                      {/* <TableCell
                    className={styles.headCells}
                    onClick={(e: any) => handleFilterPopup(e, "permissions")}
                  >
                    <div className={styles.headContainer}>
                      {t`USER PERMISSIONS`}{" "}
                      <FilterList
                        className={clsx(styles.filterStyle, {
                          [styles.blueFilter]: !_.isEmpty(
                            appliedFilters.permissions
                          )
                        })}
                      />
                    </div>
                  </TableCell> */}
                      {/* <TableCell
                    align="center"
                    className={styles.headCells}
                  >{t`EDIT`}</TableCell> */}
                      <TableCell
                        align="center"
                        className={styles.headCells}
                      >{t`DELETE`}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sortedUsers.map((user: any) => {
                      return (
                        <TableRow hover tabIndex={-1} key={user.id} onDoubleClick={() => {
                          setEditModal(true);
                          setChoseUser(user);
                        }}>
                          <TableCell
                            component="th"
                            scope="row"
                            align="left"
                            className={styles.rowCell}
                          >
                            {user.username}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            align="left"
                            className={styles.rowCell}
                          >
                            {user.email || ""}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            align="left"
                            className={styles.rowCell}
                          >
                            <Button
                              className={clsx([styles.buttonStyle, styles.impersonateButton])}
                              onClick={() => doImpersonate(user.username, "service")}
                            >{t`service`}</Button>

                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            align="left"
                            className={styles.rowCell}
                          >
                            <Button
                              className={clsx([styles.buttonStyle, styles.impersonateButton])}
                              onClick={() => doImpersonate(user.username, "management")}
                            >{t`management`}</Button>

                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            align="left"
                            className={styles.rowCell}
                          >
                            <Button
                              className={clsx([styles.buttonStyle, styles.impersonateButton])}
                              onClick={() => doImpersonate(user.username, "control")}
                            >{t`control`}</Button>

                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            align="left"
                            className={styles.rowCell}
                          >
                            {user.creationDate || ""}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            align="left"
                            className={styles.rowCell}
                          >
                            {user.creatorUser || ""}
                          </TableCell>
                          {/* <TableCell
                        component="th"
                        scope="row"
                        align="left"
                        className={styles.rowCell}
                      > */}
                          {/* TODO add permissions */}
                          {/* {user.permissions || ""} */}
                          {/* </TableCell> */}
                          {/* <TableCell
                        component="th"
                        scope="row"
                        align="center"
                        className={styles.rowCell}
                      >
                        <IconButton
                          onClick={() => {
                            setEditModal(true);
                            setChoseUser(user);
                          }}
                        >
                          <img src={EditIcon} alt="edit user" />
                        </IconButton>
                      </TableCell> */}
                          <TableCell
                            component="th"
                            scope="row"
                            align="center"
                            className={styles.rowCell}
                          >
                            <IconButton
                              onClick={() => {
                                setDelModal(true);
                                setChoseUser(user);
                              }}
                            >
                              <img src={DeleteIcon} alt="delete user" />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </>
        }
      </ServiceNavigationBar>
      {showModal && (
        <OneUser
          withPasswordField
          onSave={onCreateUser}
          onClose={() => toggleModal(false)}
          title="Create User"
          mainButtonLabel="Create"
          customers={allCustomers}
          errorText={errorOnCreate}
          languages={allLanguages}
          selectedCustomer={selections?.customerId || ""}
        />
      )}
      {editModal && chosenUser && (
        <OneUser
          user={chosenUser}
          onSave={onUpdateUser}
          onClose={() => setEditModal(false)}
          title="Update User info"
          mainButtonLabel="Update"
          customers={allCustomers}
          errorText={errorOnCreate}
          customerIdLockedto={allCustomers[chosenUser?.customer || ""] ? { value: chosenUser.customer, label: allCustomers[chosenUser?.customer || ""].name } : ""}
          languages={allLanguages}
        />
      )}
      {clickedColumnHead && (
        <Lookup
          filtersList={filters[clickedColumnHead]}
          appliedFilters={appliedFilters[clickedColumnHead]}
          onApply={onApply}
          lookupAnchor={lookupAnchor}
          onClose={() => setClickedColumnHead("")}
          replacementContent={
            clickedColumnHead === "customer"
              ? { data: allCustomers, property: "name" }
              : ""
          }
          clearAllFilters={() => setAppliedFilters({
            username: [],
            customer: []
          })}
          hasFilters={hasFilters}
        />
      )}
    </>
  );
}
