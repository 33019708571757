import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from "@material-ui/core/";
import { FilterList } from "@material-ui/icons";
import clsx from "clsx";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { t } from "ttag";
import { Lookup } from "../../components/Lookup";
import LightTooltip from "../../components/Tooltip/LightTooltip";
import { useStoreState } from "../../models/RootStore";
import useStyles from "./Alerts.style";

const filterValues: any = {
  codes: ["3C ", "E3 ", "FH ", "60 "],
  statuses: ["closed", "open"],
  types: ["System Error", "Indoor Error", "Maintenance", "Not Connected", "Anomalies"]
};

const AlertsTable: React.FC<any> = (props: any) => {
  const history = useHistory();
  const classes = useStyles(),
    { appliedFilters, page, rowsPerPage, rows, setSelectedAlert, clearAllFilters, preSelectedAlert, setPreSelectedAlert } = props,
    [clickedHead, setClickedHead] = useState(""),
    [lookupAnchor, setAnchor] = useState(null),
    [alertsPerPage, setAlertsPerPage] = useState([]),
    tableHasFilters = !!appliedFilters["customerName"].length || !!appliedFilters["statuses"].length ||
     !!appliedFilters["description"].length || !!appliedFilters["systemName"].length ||
     !!appliedFilters["siteName"].length || !!appliedFilters["unitName"].length ,
    handleChangePage = (event: unknown, newPage: number) => {
      props.setPage(newPage);
    },
    handleChangeRowsPerPage = (event: any) => {
      props.setPage(0);
      props.setRowsPerPage(event.target.value);
    },
    updateClickedHead = (event: any, columnHead: string) => {
      setClickedHead(columnHead);
      setAnchor(event.currentTarget);
    },
    onApply = (selectedFilters: any) => {
      const selectedFiltersObject = { [clickedHead]: selectedFilters };
      props.getSelectedFilters(selectedFiltersObject);
      setClickedHead("");
    }, // Get one page out of alerts array
    _getAlertsPerPage = ({ page, rowsPerPage, rows }: any) => {
      return rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    },
    errorTypes = useStoreState((state) => state.serviceErrorTypes),
    getSystem = useStoreState(
      (state) => state.systems.getSystem
    ),

    getTitle = (systemId: string, errorCode: string) => {
      const system = getSystem(systemId);
      return (system && system.brandNum && errorTypes.errorCodeTypes && errorTypes.errorCodeTypes[system.brandNum]) ? errorTypes.errorCodeTypes[system.brandNum][errorCode] : "";
    };

  useEffect(() => {
    const alertsPerPage = _getAlertsPerPage({ page, rowsPerPage, rows });
    setAlertsPerPage(alertsPerPage);
    preSelectedAlert && page !== 0
      ? setPreSelectedAlert(false)
      : setSelectedAlert(alertsPerPage[0]);
  }, [page, rowsPerPage, rows]);

  const getMoreInfo = (alert: any) => {
    if (!alert?.unitIds?.length) {
     return;
   }

    const unitId = alert.unitIds[0];
    const data: any = { time: alert.timestamp, alertCheck: true, unitId };
    history.push({pathname: `/unit-diag/${unitId}`, state: data});

   };

  return (
    <div style={{ height: "100%" }}>
      {props.isDisabled ? (
        <div className={classes.progress}>
          <div className={classes.progressContainer}>
            <div className={clsx(classes.processingAnimationContainer)}>
              <CircularProgress />
            </div>
            <Typography variant="h5">{t`Loading Alerts`}</Typography>
          </div>
        </div>
      ) : (
          <div className={classes.tablePaper}>
            <div className={classes.tablePagesHeader}>
              <TablePagination
                colSpan={7}
                count={rows.length}
                rowsPerPage={props.rowsPerPage}
                page={props.page}
                SelectProps={{
                  inputProps: { "aria-label": "rows per page" },
                  native: false
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                className={classes.pagingStyle}
              />
              {props.isDisabled && (
                <TablePagination
                  colSpan={7}
                  count={0}
                  rowsPerPage={props.rowsPerPage}
                  page={props.page}
                  SelectProps={{
                    inputProps: { "aria-label": "rows per page" },
                    native: false
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  className={classes.pagingStyle}
                />
              )}
            </div>
            <TableContainer className={classes.tableContainer}>
              <Table stickyHeader aria-label="customized table">
                <TableHead>
                  <TableRow>
                  <TableCell
                  className={clsx(classes.columnsTitles, classes.dateColumn)}
                  align="left"
                  style={{ minWidth: "132px", maxWidth: "132px", width: "132px" }}
                  >{t`DATE/TIME`}</TableCell>
                  <TableCell
                    className={classes.columnsTitles}
                    align="left"
                    onClick={(e: any) => updateClickedHead(e, "systemName")}
                  >
                  <div className={classes.headContainer}>
                  {t`System`}
                  <FilterList
                  className={clsx(classes.filterStyle, {
                    [classes.blueFilter]: !_.isEmpty(appliedFilters.systemName)
                  })}
                  />
                  </div>
                  </TableCell>
                    <TableCell
                      className={classes.columnsTitles}
                      align="left"
                      onClick={(e: any) => updateClickedHead(e, "unitName")}
                    >
                    <div className={classes.headContainer}>
                    {t`UNIT`}
                    <FilterList
                    className={clsx(classes.filterStyle, {
                      [classes.blueFilter]: !_.isEmpty(appliedFilters.unitName)
                    })}
                    />
                    </div>
                    </TableCell>
                    <TableCell
                    className={classes.columnsTitles}
                    align="left"
                    onClick={(e: any) => updateClickedHead(e, "statuses")}
                    style={{ minWidth: "110px", maxWidth: "110px", width: "110px" }}
                    >
                    <div className={classes.headContainer}>
                    {t`STATUS`}
                    <FilterList
                    className={clsx(classes.filterStyle, {
                      [classes.blueFilter]: !_.isEmpty(appliedFilters.statuses)
                    })}
                    />
                    </div>
                    </TableCell>
                    <TableCell
                      className={classes.columnsTitles}
                      align="left"
                      onClick={(e: any) => updateClickedHead(e, "description")}
                    ><div className={classes.headContainer}>
                    {t`DESCRIPTION`}
                    <FilterList
                    className={clsx(classes.filterStyle, {
                      [classes.blueFilter]: !_.isEmpty(appliedFilters.description)
                    })}
                    />
                    </div></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {alertsPerPage.map((alert: any, index: number) => (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={index}
                      onClick={() => props.onRowSelect(alert)}
                      onDoubleClick={() => getMoreInfo(alert)}
                      className={alert.id === props.selectedAlert ? classes.selected : ""}
                    >
                    <TableCell className={clsx(classes.cellStyle, classes.showWhiteSpace)} align="left" >
                    {alert.time}
                    </TableCell>
                    <TableCell className={classes.cellStyle} align="left"
                    style={{ minWidth: "36ch", maxWidth: "36ch", width: "36ch" }}>
                    {alert.systemName || "-"}
                    </TableCell>
                      <TableCell
                        className={classes.cellStyle}
                        component="th"
                        scope="row"
                        align="left"
                        style={{ minWidth: "36ch", maxWidth: "36ch", width: "36ch" }}
                      >
                        {alert.unitName || "-"}
                      </TableCell>
                      <TableCell className={classes.cellStyle} align="left">
                      {alert.status}
                      </TableCell>
                      <TableCell className={classes.cellStyle} align="left"
                        style={{ minWidth: "25ch", maxWidth: "25ch", width: "100%" }}>
                        {/* {alert.alertItemContainerIds ? getTitle(alert.alertItemContainerIds.systemId, alert.errorCode) : alert.description} */}
                        {alert.description}
                      </TableCell>

                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}
      {clickedHead && (
        <Lookup
          filtersList={props.filterValues[clickedHead]}
          appliedFilters={props.appliedFilters[clickedHead]}
          onApply={onApply}
          lookupAnchor={lookupAnchor}
          onClose={() => setClickedHead("")}
          tableHasFilters={tableHasFilters}
          clearAllFilters={clearAllFilters}
        />
      )}
    </div>
  );
};
export default AlertsTable;
