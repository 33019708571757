import { css } from "@emotion/core";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import Alerts from "../Alerts/Alerts";
import CustomerManagement from "../CustomerManagment/CustomerManagement";
import Dashboard from "../Dashboard/Dashboard";
import DeviceManagement from "../DeviceManagment/DeviceManagement";
import Login from "../Login/Login";
import NoAccessScreen from "../NoAccess/NoAccessScreen";
import SiteDetails from "../SiteManagement/SiteDetails";
import SiteManagement from "../SiteManagement/SiteManagement";
import TrapsList from "../Traps/TrapsList";
import TriggerManagment from "../TriggerManagement/TriggerManagment";
import UnitDiag from "../UnitDiag/DashboardUnit";
import UserList from "../UserManagement/UserManagement";
import Authenticated from "./Authenticated";

// Non 'JS Styles' CSS imports.
import "rc-slider/assets/index.css";
import "react-flags-select/css/react-flags-select.css";
import "react-table/react-table.css";
import Blocked from "../../components/Blocked/Loading";
import { ErrorStoreMessage } from "../../components/ErrorStoreMessage";
import Loadiang from "../../components/Loading/Loading";
import AuditsList from "../Audits/AuditsList";
import DeviceManager from "../DeviceManagment/EditDevice";
import EditCustomer from "../EditCustomer/EditCustomer";
import Subscriptions from "../Subscriptions/Subscriptions";
import SupportView from "../Support/SupportView";
import SystemDiagnostics from "../SystemDiagnostics/SystemDiagnostics";

const getAllowedRoute = (displayFlags: any) => {
  if (displayFlags.enableCustomers){
    return "/customers";
  }
  if (displayFlags.enableSites){
    return "/sites";
  }
  if (displayFlags.enableDevices){
    return "/devices";
  }
  if (displayFlags.enableUsers){
    return "/users";
  }
  if (displayFlags.enableAnomalies){
    return "/anomalies-settings";
  }
  if (displayFlags.enableAlerts){
    return "/alerts";
  }
  if (displayFlags.enableSupport){
    return "/SupportView";
  }
  if (displayFlags.enableUnitDiag && displayFlags.enableUnitDiagTopbar){
    return "/unit-diag/";
  }
  if (displayFlags.enableSysDiag && displayFlags.enableSysDiagTopbar){
    return "/system-diagnostics/";
  }
  if (displayFlags.enableSubscriptions){
    return "/subscriptions";
  }

  return "/blocked";
};

export default function App() {
  const setCanLoadApp = useStoreActions((actions) => actions.setIsLoaded);
  const isConnected = useStoreState((state) => state.users.dataState);
  const displayFlags = useStoreState((state) => state.users.displayFlags);
  const logout = useStoreActions((actions) => actions.users.logout);
  const checkLoggedin = useStoreActions(
    (actions) => actions.users.checkLoggedin
  );
  const getUserTree = useStoreActions((action) => action.getUserTree);
  const setLoggedIn = useStoreActions((action) => action.setLoggedIn);
  const isLoggedIn = useStoreState((s) => s.isLoggedIn);
  const getserviceTypes = useStoreActions((action) => action.getServiceTypes);
  const messageObj = useStoreState((s) => s.errorMessage.message);

  const initialize = async () => {
    if (isLoggedIn) {
      try {
        await getUserTree();
      } catch (error) {
        setLoggedIn(false);
        logout();
      }
    }
  };

  useEffect(() => {
    initialize();
  }, [isLoggedIn]);

  useEffect(() => {
    getserviceTypes();
  }, [getserviceTypes]);

  useEffect(() => {
    checkLoggedin();
  }, [checkLoggedin]);

  if (isConnected !== "done"){
    return <Loadiang />;
  }

  return (
    <div className="App">
      <Router>
      <Switch>
        <Route exact={true} path="/" component={Login} />
        <Route exact={true} path="/no-access" component={NoAccessScreen} />
        <Route exact={true} path="/blocked" component={Blocked} />

        {displayFlags.enableDashboard && <Authenticated exact path="/dashboard" component={Dashboard} />}

        {displayFlags.enableSites && <Authenticated exact path="/sites" component={SiteManagement} />}

      {displayFlags.enableAnomalies &&   <Authenticated
          exact
          path="/trigger-management"
          component={TriggerManagment}
        /> }
      {displayFlags.enableDevices &&   <Authenticated
          exact
          path="/device-management"
          component={DeviceManagement}
        /> }
      {displayFlags.enableUsers &&   <Authenticated exact path="/users" component={UserList} />}
      {displayFlags.enableCustomers &&   <Authenticated
          exact
          path="/customers/new"
          component={EditCustomer}
        />}
        {displayFlags.enableCustomers && <Authenticated
          exact
          path="/customers/:customerId"
          component={EditCustomer}
        />}
        {displayFlags.enableSites && <Authenticated
          exact
          path="/customers/:customerId/sites"
          component={SiteManagement}
        />}
      {displayFlags.enableUsers &&   <Authenticated
          exact
          path="/customers/:customerId/users"
          component={UserList}
        />}
        {displayFlags.enableDevices && <Authenticated
          exact
          path="/customers/:customerId/devices"
          component={DeviceManagement}
        />}

      {(displayFlags.enableUnitDiag  && displayFlags.isUnitDiagAllowedOnFirstLoad) &&   <Authenticated
          exact
          path="/unit-diag/:unitId?"
          component={UnitDiag}
        />}

      {displayFlags.enableDevices &&   <Authenticated exact path="/customers/:customerId/devices/disconnected" component={DeviceManagement} disconnected={true} />}

      {displayFlags.enableCustomers &&   <Authenticated exact path="/customers" component={CustomerManagement} />}
        {displayFlags.enableDevices && <Authenticated exact path="/devices" component={DeviceManagement} />}
        {displayFlags.enableDevices && <Authenticated exact path="/devices/disconnected" component={DeviceManagement} disconnected={true} />}
        {displayFlags.enableDevices && <Authenticated exact path="/devices/connected" component={DeviceManagement} connected={true} />}

      {displayFlags.enableDevices &&   <Authenticated
          exact
          path="/devices/:deviceId"
          component={DeviceManager}
        />}
      {displayFlags.enableDevices &&   <Authenticated
          exact
          path="/site/:siteId/devices"
          component={DeviceManagement}
        /> }

        {displayFlags.enableSites && <Authenticated exact path="/site/:siteId" component={SiteDetails} />}
       <Authenticated exact path="/audit" component={AuditsList} />
        {displayFlags.enableSupport && <Authenticated exact path="/SupportView" component={SupportView} />}
      {displayFlags.enableAnomalies &&   <Authenticated exact path="/anomalies-settings" component={TrapsList} />}
      {displayFlags.enableAlerts &&   <Authenticated exact path="/alerts" component={Alerts} />}
      {displayFlags.enableSubscriptions &&   <Authenticated exact path="/subscriptions" component={Subscriptions} />}
      {(displayFlags.enableSysDiag && displayFlags.isSysDiagAllowedOnFirstLoad) && <Authenticated exact path="/system-diagnostics/:systemId?" component={SystemDiagnostics} />}
        <Redirect to={displayFlags.enableDashboard ? "/" : getAllowedRoute(displayFlags)} />
        </Switch>
      </Router>
      {messageObj.message && (<ErrorStoreMessage message={messageObj} />)}
    </div>
  );
}
