import { Button, Grid, Paper, TextField, Typography } from "@material-ui/core";
import _ from "lodash";
import React, { useState } from "react";
import { t } from "ttag";
import styles from "./SupportView.module.css";

import { useStoreActions } from "../../models/RootStore";
import ServiceNavigationBar from "../../widgets/Menu/ServiceNavigationBar";
import SimpleModal from "../../widgets/SimpleModal/SimpleModal";
import style from "./SupportView.module.css";

const SupportView: React.FC = (props: any) => {
    const deviceBySerial = useStoreActions((action) => action.deviceBySerial);
    const usersByDevice = useStoreActions((action) => action.usersByDevice);
    const deviceByUser = useStoreActions((action) => action.deviceByUser);
    const [data, setData] = useState<any>({ 1: "", 2: "", 3: "" });
    const [showModal, setShowModal] = useState<boolean>(false);
    const [resData, setResData] = useState<any>({ failed: false, res: {} });
    const [actionType, setActionType] = useState<any>(0);

    const getData = async (fn: any, type: number) => {
        const res = await fn(data[type]);
        if (_.isString(res)) {
            setResData({ failed: true, res });
        } else {
            setResData({ failed: false, res });
        }

        setActionType(type);
        setShowModal(true);
    };

    const getAction = (title: string, onAction: any, type: number) => {

        return (
            <Grid container className={styles.supportItem} key={type}>
                <Grid item xs={12} className={style.titleWrapper}>
                    <Typography className={styles.title}> {t`${title}`}</Typography>
                </Grid>

                <Grid item xs={6} className={style.inputStyle} >
                    <TextField
                        id="outlined-basic"
                        label="Outlined"
                        variant="outlined"
                        onChange={(event: any) => {
                            setData({ ...data, ...{ [type]: event.target.value } });
                        }}
                        value={data[type]}
                    />
                </Grid>
                <Grid item xs={6} className={style.btnWrpper}>
                    <Button
                        variant="contained"
                        className={style.findBtn}
                        onClick={() => {
                            getData(onAction, type);

                        }}>
                        Find
                    </Button>
                </Grid>

            </Grid>
        );
    };

    const responseModal = () => {

        let bodyText = "";
        if (resData.failed) {
            bodyText = resData.res;
        } else {

            switch (actionType) {

                case 1:
                    bodyText = `device pin : ${resData.res.pin}`;
                    break;
                case 2:
                    if (resData.res.length) {
                        const users = _.map(resData.res, (user) => `userEmail: ${user.email}. username: ${user.username}`);
                        bodyText = users.join("\n");
                    } else {
                        bodyText = `the device doesn't belong to any user`;
                    }

                    break;
                case 3:
                    if (resData.res.length) {
                        bodyText = `users devices: ${resData.res.join(", ")}`;
                    } else {
                        bodyText = `the user has no devices`;
                    }
                    break;
                default:
                    bodyText = `something went wrong with the request ${JSON.stringify(resData.res)}`;

            }
        }

        const onAction = () => {
            setShowModal(false);
            setData({ 1: "", 2: "", 3: "" });
        };

        return (
            <SimpleModal
                showModal={showModal}
                title="The data"
                contentText={bodyText}
                actionName="OK"
                onAction={onAction}

                closeModal={onAction}
            />
        );
    };

    return (
        <ServiceNavigationBar title={t`Support`} {...props}>
            <Paper elevation={0} className={styles.paper}>
                {responseModal()}
                <Grid container spacing={3} className={styles.supportContanier}>
                    {getAction("Get device pin by device serial", deviceBySerial, 1)}
                    {getAction("Get users by device serial", usersByDevice, 2)}
                    {getAction("Get device serials by username", deviceByUser, 3)}

                </Grid>

            </Paper>

        </ServiceNavigationBar>
    );

};

export default SupportView;
