import { Admin, Customer } from "coolremote-sdk";
import { action, Action, Computed, computed, thunk, Thunk } from "easy-peasy";
import _ from "lodash";
import { IDeviceModel } from "./DeviceModel";
import { IRootStoreModel } from "./RootStore";
import { IUnit, IUnitsModel } from "./UnitModel";

export interface ICustomer {
  id: string;
  name?: string;
  description?: string;
  enableTriggers?: boolean;
  sites: string[];
  triggers: string[];
  role: any;
  type?: any;
  smsCounter?: any;
  contactPerson?: any;
  email?: string;
  status?: number;
  billingModel?: number;
  prePaidSessionLengthMinutes?: number;
  prePaidAllocatedSessions?: number;
  prePaidUsedSessions?: number;
  createdAt?: any;
  licensedUnits?: any;
}

export interface ICustomerModel {
  id: string;
  name: string;
  description: string;
  admin: string;
  enableTriggers: boolean;
  sites: string[];
  users: string[];
  groups: string[];
  alertGroups: string[];
  triggers: string[];
  invites: string[];
  type?: any;
  smsCounter?: any;
  contactPerson?: any;
  email?: string;
  status?: number;
  billingModel?: number;
  prePaidSessionLengthMinutes?: number;
  prePaidAllocatedSessions?: number;
  prePaidUsedSessions?: number;
  createdAt?: any;
  enableLanguageSelection?: boolean;
  enablePowerDistribution?: boolean;
  enableMgmtAppAccess?: boolean;
  enableServiceAppAccess?: boolean;
}

export interface ICustomerMap {
  [key: string]: ICustomerModel;
}

export interface ICustomersModel {
  customerList: ICustomerMap;
  setCustomers: Action<ICustomersModel, { customers: any }>;
  getCustomers: Thunk<ICustomersModel>;
  createCustomer: Thunk<ICustomersModel, { data: any }>;
  addCustomer: Action<ICustomersModel, ICustomerModel>;
  removeCustomer: Action<ICustomersModel, string>;
  deleteCustomer: Thunk<ICustomersModel, string>;
  updateCustomer: Thunk<ICustomersModel, { data: any; id: string }>;
  _updateCustomer: Action<ICustomersModel, ICustomerModel>;
  getOneCustomer: Thunk<ICustomerModel, any>;
  getCustomerName: Computed<
    ICustomersModel,
    (id: string | undefined) => string | undefined
  >;
  getCustomerDevices: Computed<
    ICustomersModel,
    (id: string) => IDeviceModel[] | [],
    IRootStoreModel
  >;
  getCustomerConnectedUnits: Computed<
    ICustomersModel,
    (id: string) => IUnit[] | [],
    IRootStoreModel
  >;
}

export const CustomersModel: ICustomersModel = {
  customerList: {},
  setCustomers: action((state, payload) => {
    const fetchedCustomers: ICustomerMap = _(Object.values(payload.customers))
      .map((customer: any) => {
        const addedClustomer: ICustomerModel = { ...customer };

        return addedClustomer;
      })
      .keyBy("id")
      .value();

    state.customerList = fetchedCustomers;
  }),

  addCustomer: action((state, payload) => {
    state.customerList[payload.id] = payload;
  }),

  removeCustomer: action((state, payload) => {
    delete state.customerList[payload];
  }),

  _updateCustomer: action((state, payload) => {
    state.customerList[payload.id] = payload;
  }),

  getCustomers: thunk(async (actions) => {
    return Customer.getCustomers()
      .then((data) => {
        actions.setCustomers({ customers: data });
        return true;
      })
      .catch(() => false);
  }),
  createCustomer: thunk(async (actions, payload) => {
    return Admin.createCustomer(payload.data)
      .then((data) => {
        actions.addCustomer(data);
        return true;
      })
      .catch((e) => {
        return { err: e };
      });
  }),
  deleteCustomer: thunk(async (actions, payload) => {
    return Customer.delete(payload)
      .then(() => {
        actions.removeCustomer(payload);
        return true;
      })
      .catch(() => false);
  }),

  updateCustomer: thunk(async (actions, payload) => {
    return Customer.update(payload.id, payload.data)
      .then((data) => {
        actions._updateCustomer(data);
        return true;
      })
      .catch(() => false);
  }),
  getOneCustomer: thunk(async (actions, payload) => {
    return Customer.getCustomerById(payload);
  }),
  getCustomerName: computed((state) => (id) => {
    const noName = "-";

    if (!id || !state.customerList[id] || !state.customerList[id].name) {
      return noName;
    }

    return state.customerList[id].name;
  }),
  getCustomerDevices: computed(
    [
      (state) => state.customerList,
      (state, storeState) => storeState.sites.allSites,
      (state, storeState) => storeState.devices.allDevices
    ],
    (allCustomers, allSites, allDevices) => (id) => {
      if (!allCustomers[id]) return [];
      const sites = _.map(allCustomers[id].sites, (siteId) => allSites[siteId]);
      if (!sites || _.includes(sites, undefined)) {
        return [];
      }
      let devices = [];
      for (let site of sites) {
        if (!site) {
          continue;
        }
        for (let deviceId of site.devices) {
          devices.push(allDevices[deviceId]);
        }
      }
      return devices;
    }
  ),
  getCustomerConnectedUnits: computed(
    [
      (state) => state.customerList,
      (state, storeState) => storeState.sites.allSites,
      (state, storeState) => storeState.devices.allDevices,
      (state, storeState) => storeState.units.allUnits
    ],
    (allCustomers, allSites, allDevices, allUnits) => (id) => {
      if (!allCustomers[id]) return [];
      const sites = _.map(allCustomers[id].sites, (siteId) => allSites[siteId]);
      if (!sites || _.includes(sites, undefined)) {
        return [];
      }

      let units = [];
      for (let site of sites) {
        if (!site) {
          continue;
        }
        for (let deviceId of site.devices) {
          for (let unit of allDevices[deviceId].units) {
            units.push(allUnits[unit]);
          }
        }
      }
      return units;
    }
  )
};
