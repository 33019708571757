import { Typography } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import { Clear } from "@material-ui/icons";
import clsx from "clsx";
import React, { useState } from "react";
import { t } from "ttag";
import Button from "../../components/cool_widgets/Button";
import { DeleteIcon } from "../../logos";
import ErrorBox from "../../components/WarnningBox/ErrorBox";
import LightTooltip from "../Tooltip/LightTooltip";
import useStyles from "./Delete.style";

export default function Delete(props: any) {
  const [open, setOpen] = React.useState(false);
  const [error, setError] = useState(null);

  const { buttonClass, iconStyle, object: objectToDelete, type, detach, getActionResult, disabled = false } = props;

  const classes = useStyles();
  const fullName = !objectToDelete.name ? objectToDelete.purchaseOrder ? objectToDelete.purchaseOrder : `${objectToDelete.firstName} ${objectToDelete.lastName}` : "";
  const handleClickOpen = async () => {
    if (type === `System`) {
      const mapping = await props.isMapping(objectToDelete);
      if (!mapping) {
        setOpen(true);

      }
    }
    else { setOpen(true); }
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleAgree = async () => {
    const id = objectToDelete.id;
    detach && detach({ id })
      .then((res: any) => {
        setOpen(false);
        getActionResult && getActionResult(true);
      })
      .catch(({ message }: any) => {
        getActionResult && getActionResult(false);
        setError(message);
      });
  };

  if (error) {
    return <ErrorBox
      error={error}
      onClose={() => {
        setError(null);
        setOpen(false);
      }}
    />;
  }
  return (
    <>

      <LightTooltip title={!disabled ? t`Delete ${type}` : t`You don't have permssion to delete this ${type}`}>
        <IconButton disabled={false} onClick={disabled ? () => { } : handleClickOpen} classes={{ root: classes.overWriteIcon }} className={disabled ? classes.disabledIcon : ""}>
          <DeleteIcon className={iconStyle} />
        </IconButton>
      </LightTooltip>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ paper: classes.dialog }}
      >
        <DialogTitle
          id="alert-dialog-title"
          className={classes.titleContent}
          disableTypography={true}
        >
          <Typography variant="h6">{t`Delete ` + type}</Typography>
          <IconButton onClick={() => setOpen(false)}>
            <Clear />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <DialogContentText className={classes.DialogContentText} id="alert-dialog-description">
            {t`Are you sure you want to delete ` + `${objectToDelete.name || fullName}  ?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button onClick={handleAgree}
            className={classes.yesBtn}
            white width={130} uppercase
          >
            {t`Yes`}
          </Button>

          <Button
            autoFocus={true}
            onClick={handleClose}
            width={130} uppercase
          >
            {t`No`}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
