import {
  Button,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";
import { Close, Search } from "@material-ui/icons";
import { FilterList } from "@material-ui/icons";
import clsx from "clsx";
import _ from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { t } from "ttag";
import { Lookup } from "../../components/Lookup";
import { DeleteIcon, EditIcon } from "../../iconImages";
import { ICustomerModel } from "../../models/CustomerModel";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import ErrorBox from "../../widgets/ErrorBox/ErrorBox";
import ServiceNavigationBar from "../../widgets/Menu/ServiceNavigationBar";
import SimpleModal from "../../widgets/SimpleModal/SimpleModal";
import styles from "./CustomerManagment.module.css";
import OneCustomer from "./OneCustomer";

export default function CustomerManagement(props: any) {
  const customers = useStoreState((state) => state.customers.customerList);
  const [chosenCustomer, setChoseCustomer] = useState<ICustomerModel | null>(
    null
  );
  const [delModal, setDelModal] = useState(false);
  const delCustomer = useStoreActions(
    (actions) => actions.customers.deleteCustomer
  );
  const [showErr, setShowErr] = useState<string>("");
  const [showModal, toggleModal] = useState(false);
  const [customerName, setCustomerName] = useState("");
  const [custoemrDescription, setCustomerDescription] = useState("");
  const [showFilterPopup, setFilterPopup] = useState<boolean>(false);
  const [lookupAnchor, setAnchor] = useState(null);
  const createCustomer = useStoreActions(
    (actions) => actions.customers.createCustomer
  );
  const [searchValue, setSearchValue] = useState<any>("");
  const [filteredCustomers, setFilteredCustomers] = useState<any>([]);
  const [appliedFilters, setAppliedFilters] = useState<any>([]);
  const [customersNames, setCustomersNames] = useState<any>([]);
  const getCustomerDevices = useStoreState((state) => state.customers.getCustomerDevices);
  const getCustomerConnectedUnits = useStoreState((state) => state.customers.getCustomerConnectedUnits);
  const types = useStoreState((s) => s.types);

  const onCreateCustomer = async (data: any) => {
    const createdClient = await createCustomer({ data });

    if (createdClient.err) {
      setShowErr(createdClient.err.message);
      return;
    } else {
      onCloseModal();
    }
  };

  const onCloseModal = () => {
    toggleModal(false);
    setCustomerName("");
    setCustomerDescription("");
  };

  useEffect(() => {
    if (_.isEmpty(customers)) {
      setCustomersNames([]);
      return;
    }

    const names = Object.values(customers).map(
      (customer: any) => customer.name || ""
    );
    setCustomersNames(names);
  }, [filteredCustomers]);

  const onDelete = async () => {
    if (!chosenCustomer) {
      return onCloseDelModal();
    }

    const success = await delCustomer(chosenCustomer.id);
    if (!success) {
      setShowErr("something went wrong while deleting customer");
    }

    onCloseDelModal();
  };

  const onCloseDelModal = () => {
    setDelModal(false);
    setChoseCustomer(null);
  };

  const onDelModal = () => {
    const name = chosenCustomer ? chosenCustomer.name : "";
    const bodyText = `are you sure you want to delete this customer ${name}`;
    return (
      <SimpleModal
        showModal={delModal}
        title="Delete Customer"
        contentText={bodyText}
        actionName="Delete"
        onAction={onDelete}
        closeModal={onCloseDelModal}
      ></SimpleModal>
    );
  };

  const onErrorModal = () => {
    if (!showErr.length) {
      return <div />;
    }

    return (
      <ErrorBox
        error={showErr}
        onClose={() => {
          onCloseDelModal();
          setShowErr("");
        }}
      />
    );
  };

  const filterCustomers = useCallback(
    (valuesArray: string[]) => {
      const filteredItems = Object.values(customers).filter((item: any) => {
        if (!item) {
          return null;
        }
        const { name } = item;
        if (valuesArray.includes(name)) {
          return item;
        }
        return null;
      });
      setFilteredCustomers(filteredItems);
    },
    [customers]
  );
  const handleFilterPopup = (event: any) => {
    setFilterPopup(true);
    setAnchor(event.currentTarget);
  };

  useEffect(() => {
    if (_.isEmpty(customers)) {
      return;
    }
    if (
      _.isEmpty(appliedFilters) ||
      appliedFilters.length === Object.values(customers).length
    ) {
      setFilteredCustomers(Object.values(customers));
      return;
    }

    filterCustomers(appliedFilters);
  }, [customers, filterCustomers, appliedFilters]);

  const clearSearchValue = () => {
    setSearchValue("");
    setFilteredCustomers(Object.values(customers));
  };

  const handleSearchValueChange = (event: any) => {
    const {
      target: { value }
    } = event;
    setSearchValue(value);
  };

  const onApply = (selectedFilters: any) => {
    setAppliedFilters(selectedFilters);
    setFilterPopup(false);
  };

  const hasFilters = !!appliedFilters.length;
  const sortedCUstomers = _.orderBy(filteredCustomers, [(customer) => customer.name.toLowerCase()], ["asc"]);
  const users = useStoreState((state) => state.users.otherUsers);
  const getCustomerUsers = (customerId: any) => {
    return _.filter(_.values(users), (user) => user.customer === customerId).length;
  };

  return (
    <>
      <ServiceNavigationBar title={t`Customers Management`} {...props}>
        {onErrorModal()}
        {onDelModal()}
        <div className={styles.addButtonContainer}>
          <Button
            className={styles.buttonStyle}
            onClick={() => props.history.push(`/customers/new`)}
          >{t`ADD NEW Customer`}</Button>
        </div>
        <Paper elevation={0} className={styles.paperPadding}>
          <TableContainer>
            <Table stickyHeader className="" aria-label="customized table">
              <TableHead className={styles.tableHead}>
                <TableRow>
                  <TableCell
                    className={styles.headCells}
                    onClick={handleFilterPopup}
                  >
                    <div className={styles.headContainer}>
                      {t` NAME`}
                      <FilterList
                        className={clsx(styles.filterStyle, {
                          [styles.blueFilter]: !_.isEmpty(appliedFilters)
                        })}
                      />
                    </div>
                  </TableCell>
                  {/* <TableCell
                    className={styles.headCells}
                  >{t`PARENT ACCOUNT`}</TableCell> */}
                  <TableCell
                    align="center"
                    className={styles.headCells}
                  >{t`CREATION DATE`}</TableCell>
                  {/* <TableCell
                    align="center"
                    className={styles.headCells}
                  >{t`LAST ACTIVITY DATE`}</TableCell> */}
                  <TableCell
                    align="center"
                    className={styles.headCells}
                  >{t`# OF SITES`}</TableCell>
                  <TableCell
                    align="center"
                    className={styles.headCells}
                  >{t`# OF DEVICES`}</TableCell>
                  <TableCell
                    align="center"
                    className={styles.headCells}
                  >{t`# OF USERS`}</TableCell>
                  <TableCell
                    align="center"
                    className={styles.headCells}
                  >{t`Subscriptions Units`}</TableCell>
                  <TableCell
                    align="center"
                    className={styles.headCells}
                  >{t`Actual units`}</TableCell>
                  <TableCell
                    align="center"
                    className={styles.headCells}
                  >{t`CA Sales Contact`}</TableCell>

                  <TableCell
                    align="center"
                    className={styles.headCells}
                  >{t`Description`}</TableCell>
                  <TableCell
                    align="center"
                    className={styles.headCells}
                  >{t`Email`}</TableCell>
                  <TableCell
                    align="center"
                    className={styles.headCells}
                  >{t`Type`}</TableCell>

                  {<TableCell
                    align="center"
                    className={styles.headCells}
                  >{t`SMS usage`}</TableCell>}
                  {/* <TableCell
                    align="center"
                    className={styles.headCells}
                  >{t`DETAILS`}</TableCell> */}
                  <TableCell
                    align="center"
                    className={styles.headCells}
                  >{t`DELETE`}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedCUstomers.map((customer: any) => {
                  return (
                    <TableRow hover tabIndex={-1} key={customer.id} onDoubleClick={() => props.history.push(`/customers/${customer.id}`)} >
                      <TableCell
                        component="th"
                        scope="row"
                        align="left"
                        className={styles.rowCell}
                      >
                        {customer.name}
                      </TableCell>
                      {/* <TableCell
                        component="th"
                        scope="row"
                        align="left"
                        className={styles.rowCell}
                      >
                        {customer.parentAcount || ""}
                      </TableCell> */}
                      <TableCell
                        component="th"
                        scope="row"
                        align="left"
                        className={styles.rowCell}
                      >
                        {customer.createdAt ? moment(customer.createdAt).format("DD/MM/YY") : "-"}
                      </TableCell>
                      {/* <TableCell
                        component="th"
                        scope="row"
                        align="left"
                        className={styles.rowCell}
                      >
                        {customer.lastActivityDate || ""}
                      </TableCell> */}
                      <TableCell
                        component="th"
                        scope="row"
                        align="center"
                        className={styles.rowCell}
                      >
                        {customer.sites && customer.sites.length}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        align="center"
                        className={styles.rowCell}
                        onClick={() =>
                          props.history.push(`/customers/${customer.id}/devices`)
                        }
                      >
                        {getCustomerDevices(customer.id).length}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        align="center"
                        className={styles.rowCell}
                        onClick={() =>
                          props.history.push(`/customers/${customer.id}/users`)
                        }
                      >
                        {getCustomerUsers(customer.id)}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        align="center"
                        className={styles.rowCell}
                      >{customer.licensedUnits}</TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        align="center"
                        className={styles.rowCell}
                      >{getCustomerConnectedUnits(customer.id).length || 0}</TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        align="center"
                        className={styles.rowCell}
                      >{customer.contactPerson || ""}</TableCell>

                      <TableCell
                        component="th"
                        scope="row"
                        align="center"
                        className={styles.rowCell}
                      >{customer.description || ""}</TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        align="center"
                        className={styles.rowCell}
                      >{customer.email || ""}</TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        align="center"
                        className={styles.rowCell}
                      >{types.creationMethods && _.isNumber(customer.method) ?
                        _.find(types.creationMethods, (method) => method.value === customer.method).text : ""}</TableCell>

                      {<TableCell
                        component="th"
                        scope="row"
                        align="center"
                        className={styles.rowCell}
                      >{customer.smsCounter ? customer.smsCounter : "0"}</TableCell>}
                      {/* <TableCell
                        component="th"
                        scope="row"
                        align="center"
                        className={styles.rowCell}
                      >
                        <IconButton
                          onClick={() =>
                            props.history.push(`/customers/${customer.id}`)
                          }
                        >
                          <img src={EditIcon} alt="edit user" />
                        </IconButton>
                      </TableCell> */}
                      <TableCell
                        component="th"
                        scope="row"
                        align="center"
                        className={styles.rowCell}
                      >
                        <IconButton
                          onClick={() => {
                            setDelModal(true);
                            setChoseCustomer(customer);
                          }}
                        >
                          <img src={DeleteIcon} alt="delete user" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </ServiceNavigationBar>
      {showModal && (
        <OneCustomer
          username={customerName}
          description={custoemrDescription}
          onSave={onCreateCustomer}
          onClose={onCloseModal}
          title="Create Customer"
          contentText="please type in new customer name"
          btnName="Create"
        />
      )}

      {showFilterPopup && (
        <Lookup
          filtersList={customersNames}
          appliedFilters={appliedFilters}
          onApply={onApply}
          lookupAnchor={lookupAnchor}
          onClose={() => setFilterPopup(false)}
          clearAllFilters={() => setAppliedFilters([])}
          hasFilters={hasFilters}
        />
      )}
    </>
  );
}
