import { Button, Icon, Popover, TextField } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import DateRangeIcon from "@material-ui/icons/DateRange";
import { Autocomplete } from "@material-ui/lab";
import { DatePicker } from "@material-ui/pickers";
import _ from "lodash";
import moment from "moment-timezone";
import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Calendar } from "../../icons";
import SvgArrow from "../../icons/Arrow";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import { IDateRange } from "../../models/Selections";
import CoolDateRangePicker from "../CoolDateRangePicker/CoolDateRangePicker";
import UnitsMenu from "../Menu/UnitsMenu";
import MenuDropDown from "../MenuDropDown/MenuDropDown";
import useStyles from "./SelectionsMenu.style";

export interface ISelectionsMenuProps {
  onCustomerSelect?: (id: any) => void;
  onSiteSelect?: (id: string) => void;
  onSystemSelect?: (id: string) => void;
  onUnitSelect?: (id: string) => void;
  hideCustomerSelection?: boolean;
  hideSiteSelection?: boolean;
  hideSystemSelection?: boolean;
  hideUnitSelection?: boolean;
  showDateRangePicker?: boolean;
  showOneDatePicker?: boolean;
  onDateSelect?: (clearFilter: boolean) => void;
}

const SelectionsMenu = ({
  onCustomerSelect,
  onSiteSelect,
  onSystemSelect,
  onUnitSelect,
  hideCustomerSelection = false,
  hideSiteSelection = false,
  hideSystemSelection = false,
  hideUnitSelection = false,
  showDateRangePicker = false,
  showOneDatePicker = false,
  onDateSelect
}: ISelectionsMenuProps) => {
  const history = useHistory();
  const classes = useStyles();
  const selections = useStoreState((s) => s.selections.selections);
  const setSelections = useStoreActions((a) => a.selections.setSelections);
  const updateSelections = useStoreActions((a) => a.selections.liteUpdateSelections);
  const customers = useStoreState((s) => s.selections.getCustomersBySelection);
  const customerList = useStoreState((s) => s.customers.customerList);
  const sortedCustomers = _.orderBy(customers, [(customer: any) => customer.name.toLowerCase()], ["asc"]);
  const {sites, systems, units} = useStoreState((s) => s.selections.getFiltersBySelections);
  const filtersTree = useStoreState((s) => s.selections.filtersTree);
  const requestStatsRefresh = useStoreActions((a) => a.units.requestStatsRefresh);
  // Popover state management
  const [anchorEl, setAnchorEl] = React.useState({
    unitsMenu: null,
    dateRangePicker: null
  });
  const unitsMenuOpen = Boolean(anchorEl.unitsMenu);
  const dateRangePickerOpen = Boolean(anchorEl.dateRangePicker);

  const setUnit = (id: string) => {
    const {sites, units} = filtersTree;
    const {site, system} = units[id];
    const {customer} = sites[site];

    setSelections({ customerId: customer, siteId: site, systemId: system, unitId: id });
    onUnitSelect && onUnitSelect(id);
    handleClose();
  };

  // Selections state management
  const setCustomer = (id: string | null) => {
    setSelections({
      customerId: id && id.length ? id : null,
      siteId: null,
      systemId: null,
      unitId: null
    });

    onCustomerSelect && onCustomerSelect(id);
  };

  const setSite = (id: string) => {
    if (!id){
      setSelections({customerId: selections.customerId, siteId: null, systemId: null, unitId: null});
      onSiteSelect && onSiteSelect(id);
      return;
    }
    const {sites} = filtersTree;
    const {customer} = sites[id];
    setSelections({
      customerId: customer,
      siteId: id,
      systemId: null,
      unitId: null
    });
    onSiteSelect && onSiteSelect(id);
  };

  const setSystem = (id: string) => {
    if (!id){
      setSelections({customerId: selections.customerId,
                     siteId: selections.siteId, systemId: null, unitId: null});
      onSiteSelect && onSiteSelect(id);
      return;
    }

    const {sites, systems} = filtersTree;
    const {site} = systems[id];
    const {customer} = sites[site];

    setSelections({ customerId: customer, siteId: site, systemId: id, unitId: null });
    onSystemSelect && onSystemSelect(id);
  };
  const handleNewDateRange = (range: IDateRange | null) => {
    // If null - user chose Cancel, so we don't change current selection
    if (range) {
      setSelections({ dateRange: range });
    }
    setAnchorEl({ unitsMenu: null, dateRangePicker: null });
  };

  // Builds options for selection dropdown
  const getOptions = useCallback(
    (itemType: "customer" | "site" | "system", items: any[]) => {
      if (itemType === "customer" && items.length === 1) {
        return [
          {
            name: `${items[0].name}`,
            value: items[0].id,
            key: items[0].id,
            type: items[0].type
          }
        ];
      } else {
        const options = items.map((item: any) => ({
          name: `${item.name}`,
          value: item.id,
          key: item.id,
          type: item.type
        }));
        return [
          {
            name: options.length ? `All ${itemType}s` : `No ${itemType}s`,
            value: "",
            key: itemType,
            type: 0
          },
          ...options
        ];
      }
    },
    [customers]
  );

  const printDateRange = (range: IDateRange) => {
    return `${moment(range.startDate).format("ll")} - ${moment(
      range.endDate
    ).format("ll")}`;
  };

  // Click handler for dropdown button which assigns correct anchor and opens Popover
  type IAnchor = "unitsMenu" | "dateRangePicker";
  const handleClick = useCallback(
    (anchorName: IAnchor) => (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
      setAnchorEl({ ...anchorEl, [anchorName]: event.currentTarget });
    },
    []
  );
  const handleClose = () => {
    setAnchorEl({ unitsMenu: null, dateRangePicker: null });
  };

  const selectedCustomer: any = !hideCustomerSelection && selections?.customerId ? customerList[selections.customerId] : null;

  const handleDateChange = (date: any | null) => {
      // If null - user chose Cancel, so we don't change current selection
      onDateSelect && onDateSelect(false);
      if (date) {
        const now = new Date();
        let endDate;
        if (new Date(new Date(date?.getTime()).setHours(23, 59, 59)) > now) {
          endDate = now;
        } else {
          endDate = new Date(new Date(date?.getTime()).setHours(23, 59, 59));
        }
        const range = { startDate: new Date(new Date(date.getTime()).setHours(0, 0, 0)), endDate };
        updateSelections({ type: "time", data: range });
      }
      setAnchorEl({ unitsMenu: null, dateRangePicker: null });
    };

  return (
    <div className={classes.selectionsContainer}>
      {!hideCustomerSelection && (
        <Autocomplete
          options={getOptions("customer", sortedCustomers)}
          getOptionLabel={(option) => option.name}
          value={selectedCustomer}
          style={{ width: 300 }}
          onChange={(event: any, newValue: any) => {
            setCustomer(newValue?.value || null);
          }}
          renderInput={(params) => <TextField {...params} placeholder={`Search...`} InputProps={{ disableUnderline: true, classes: { root: classes.inputRoot }, ...params.InputProps }} />}
          classes={{
            clearIndicator: classes.searchIcon,
            popupIndicator: classes.searchIcon,
            popper: classes.autoCompletePoper,
            paper: classes.autoCompletePaper,
            listbox: classes.autoCompleteItem,
            noOptions: classes.autoCompleteItem,
            groupLabel: classes.autoCompleteGroup,
            groupUl: classes.autoCompleteGroupUl

          }}
        />
      )}
      {!hideSiteSelection && (
        <MenuDropDown
          onChange={setSite}
          value={selections.siteId || ""}
          options={sites}
          placeholder={"No Sites"}
        />
      )}
      {!hideSystemSelection && (
        <MenuDropDown
          onChange={setSystem}
          value={selections.systemId || ""}
          options={systems}
          placeholder={"No Systems"}
        />
      )}
      {!hideUnitSelection && (
        <React.Fragment>
          <Button
            className={classes.selectUnitButton}
            classes={{
              label: classes.selectUnitButton__text
            }}
            size="large"
            onClick={handleClick("unitsMenu")}
            endIcon={<ArrowDropDownIcon />}
          >
            {!selections.unitId ? "Units" : filtersTree.units[selections.unitId].name}
            <SvgArrow className={classes.selectUnitButton__icon} />
          </Button>
          <Popover
            open={unitsMenuOpen}
            anchorEl={anchorEl.unitsMenu}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left"
            }}
          >
            <UnitsMenu setUnit={setUnit} />
          </Popover>
        </React.Fragment>
      )}
      {showDateRangePicker && (
        <React.Fragment>
          <Button
            className={classes.selectDateRangeButton}
            classes={{
              label: classes.selectDateRangeButton__text,
              iconSizeLarge: classes.selectDateRangeButton__icon
            }}
            size="large"
            onClick={handleClick("dateRangePicker")}
            endIcon={<ArrowDropDownIcon />}
          >
            <DateRangeIcon />
            {selections.dateRange
              ? printDateRange(selections.dateRange)
              : "no selection"}
          </Button>
          <Popover
            open={dateRangePickerOpen}
            anchorEl={anchorEl.dateRangePicker}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left"
            }}
          >
            <CoolDateRangePicker
              handleSubmit={handleNewDateRange}
              initialRange={selections.dateRange}
            />
          </Popover>
        </React.Fragment>
      )}
      {showOneDatePicker && (
        <React.Fragment>
          <Button
            disableRipple
            // disabled={disableOneDatePicker}
            className={classes.selectDateRangeButton}
            classes={{
              label: classes.selectDateRangeButton__text,
              iconSizeLarge: classes.selectDateRangeButton__icon
            }}
            size="large"
            onMouseUp={handleClick("dateRangePicker")}
            endIcon={<ArrowDropDownIcon />}
            startIcon={<Calendar style={{ marginLeft: "4px" }} />}
          >
            {`${moment(selections.dateRange?.endDate).format("ll")}`}
          </Button>

          <Popover
            open={dateRangePickerOpen}
            anchorEl={anchorEl.dateRangePicker}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left"
            }}
            transformOrigin={{
              vertical: -10,
              horizontal: "left"
            }}
          >
            <DatePicker
              disableToolbar
              variant="static"
              value={selections.dateRange?.endDate}
              onChange={handleDateChange}
              className={classes.datePicker}
              autoOk
              maxDate={new Date()}
              // ref={(r) => setDatePickerDialogReference(r)}
            />
          </Popover>
        </React.Fragment>
      )}
    </div>
  );
};

export default SelectionsMenu;
