import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  view: {
    display: "flex",
    height: "100vh",
    overflow: "hidden",
  },
  contentArea: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    background: "rgb(236, 235, 239)",
    maxHeight: "-webkit-fill-available",
  },
  tableContainer: {
    display: "flex",
    height: "100%",
    // paddingRight: '5px'
  },

  paperTableContainer: {
    boxSizing: "border-box",
    margin: "1rem",
    marginTop: 0,
    overflow: "auto",
    height: "100%",
    padding: "1rem",
    maxHeight: "calc(100% - 130px)",
    display: "flex",
    flexFlow: "column nowrap",
    // minHeight: "250px",
    flexDirection: "row",
  },

  table: {
    background: theme.palette.background.paper,
  },
  overWritePadding: {
    fontSize: "13px",
    padding: "0 15px",
    height: "35px",
  },
  tableHeadCell: {
    borderBottom: "none",
    padding: "0 15px",
    color: "#79797d",
    fontWeight: "bold",
    lineHeight: "normal",
    letterSpacing: "normal",
    backgroundColor: "#efeff2",
    textTransform: "uppercase",
    height: 45,
    fontSize: 13,
  },
  editIcon: {
    marginLeft: "-6px",
  },
  overWriteIcon: {
    "&:hover": {
      borderRadius: "0",
    },
  },
  deleteIcon: {
    fontSize: "1rem",
    cursor: "pointer",
    // color: 'initial'
  },

  anomaliesContainer: {
    height: "82%",
    display: "flex",
    paddingBottom: "1rem",
  },

  titleBar: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "0 16px",
    minHeight: "70px",
  },
  title: {
    height: "18px",
    width: "72px",
    color: "#312e34",
    fontFamily: "Roboto",
    fontSize: "15px",
    fontWeight: "bold",
    lineHeight: "20px",
  },
  '[class^="NavigationBar"]': {
    padding: "0",
  },
  smallWidth: { width: "20%", wordBreak: "break-word" },
  mediumWidth: { width: "60%", wordBreak: "break-word" },
  headerButton: {
    minHeight: "40px",
    maxHeight: "40px",
    minWidth: "169px",
    fontSize: "16px",
    fontWeight: "normal",
    lineHeight: "normal",
    color: "#fff",
    textTransform: "capitalize",
  },
  ImportExportContainer: {
    padding: "15px",
    marginRight: "15px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  dialogPaper: {
    width: "fit-content",
    maxWidth: "95% !important",
    minWidth: "850px",
    height: "fit-content",
    maxHeight: "calc(100vh - 140px)",
    borderRadius: "8px",
    boxShadow: "0 2px 11px 0 rgba(0, 0, 0, 0.15)",
    border: "solid 1px #d5d2d5",
    backgroundColor: "#ffffff",
    justifyContent: "space-between",
    overflow: "hidden",
  },
  dialogHeader: {
    borderBottom: "solid 1px #d5d2d5",
    width: "1005",
    display: "flex",
    justifyContent: "space-between",
    padding: "20px",
    height: "60px",
    backgroundColor: "#f6f6f7",
    alignItems: "center",
  },
  headerTitle: {
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#29132e",
  },
  selectedTrap: {
    backgroundColor: "#efeff2",
  },
  actionsContainer: {
    backgroundColor: "#fff",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    paddingBottom: "24px",
    paddingTop: "5px",
    paddingRight: "10px",
    width: "100%",
    bottom: 0,
    left: 0,
    position: "sticky",
    zIndex: 3,
  },
  uploadContainer: {
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "center",
  },
  processingAnimationContainer: {
    display: "flex",
    minHeight: "300px",
    justifyContent: "center",
    alignItems: "center",
  },
}));
export default useStyles;
