import {
    Checkbox,
    CircularProgress,
    Dialog,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@material-ui/core";
import clsx from "clsx";
import React, { useState } from "react";
import { t } from "ttag";
import Button from "../../components/cool_widgets/Button";
import Loading from "../../components/Loading/Loading";
import { Close } from "../../icons";
import useStyles from "./TrapsList.style";

const ExportTraps = (props: any) => {
    const classes = useStyles();
    const { traps, close, getBrandName, passTraps, isLoading } = props;
    const [selected, setSelected] = useState<any>({});

    const onSelectAllClick = () => {
        if (Object.values(selected).length === traps.length) {
            setSelected({});
        }
        else {
            const selectedTraps: any = {};
            traps.map((trap: any, index: number) => selectedTraps[index] = trap);
            setSelected({ ...selectedTraps });
        }

    };

    const onSelectTrapClick = (trap: any, index: number) => {
        if (selected[index]) {
            delete selected[index];
            setSelected({ ...selected });
        } else {
            selected[index] = trap;
            setSelected({ ...selected });
        }
    };

    const passSelectedTraps = () => {
        passTraps(Object.values(selected));
        // close();
    };

    return (
        <div>
            <Dialog
                disableEnforceFocus
                fullScreen={true}
                classes={{ paper: classes.dialogPaper }}
                aria-labelledby="simple-dialog-title"
                open={true}
            >
                <div className={classes.dialogHeader}>
                    <Typography className={classes.headerTitle}>{t`Import Anomaly Rules`}</Typography>
                    <IconButton style={{ padding: "6px", marginRight: "-6px" }} onClick={close}><Close color="#7f7692" /></IconButton>
                </div>
                {isLoading ? <div className={clsx(classes.processingAnimationContainer)}>
                    <CircularProgress />
                </div> : <>
                        <Paper elevation={0} className={classes.paperTableContainer}>
                            <TableContainer className={classes.tableContainer}>
                                <Table stickyHeader className={classes.table} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell padding="checkbox" hidden>
                                            </TableCell>
                                            <TableCell
                                                classes={{ root: classes.tableHeadCell }}
                                                align="left"
                                            >
                                                {t`Rule Name`}
                                            </TableCell>
                                            <TableCell
                                                classes={{ root: classes.tableHeadCell }}
                                                align="left"
                                            >{t`BRAND`}
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {traps.length && <TableRow
                                            hover
                                            tabIndex={-1}
                                            role="checkbox"
                                        >
                                            <TableCell padding="checkbox" hidden>
                                                <Checkbox
                                                    color="default"
                                                    checked={Object.values(selected).length === traps.length}
                                                    onChange={onSelectAllClick}
                                                    inputProps={{ "aria-labelledby": `toggle all` }}
                                                />
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                                classes={{ root: clsx(classes.overWritePadding, classes.mediumWidth) }}
                                                align="left"
                                            >
                                                {t`All`}
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                                classes={{ root: clsx(classes.overWritePadding, classes.mediumWidth) }}
                                                align="left"
                                            >
                                                {""}
                                            </TableCell>
                                        </TableRow>}
                                        {traps.map((trap: any, index: number) => {
                                            const { name = "", userSelections: { brand = -99 } = {} } = trap;
                                            const isSelected = !!selected[index];
                                            const brandName = getBrandName(brand);
                                            return (
                                                <TableRow
                                                    hover
                                                    tabIndex={-1}
                                                    key={`imported-trap-${index}`}
                                                    role="checkbox"
                                                >
                                                    <TableCell padding="checkbox" hidden>
                                                        <Checkbox
                                                            color="default"
                                                            onChange={() => onSelectTrapClick(trap, index)}
                                                            checked={isSelected}
                                                            inputProps={{ "aria-labelledby": name }}
                                                        />
                                                    </TableCell>
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                        classes={{ root: clsx(classes.overWritePadding, classes.mediumWidth) }}
                                                        align="left"
                                                    >
                                                        {name}
                                                    </TableCell>
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                        classes={{ root: clsx(classes.overWritePadding, classes.mediumWidth) }}
                                                        align="left"
                                                    >
                                                        {brandName}
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                        <div className={classes.actionsContainer}>
                            <Button onClick={close} onMouseDown={(event: any) => event.preventDefault()} marginRight white uppercase width={150}> {t`cancel`}</Button>
                            <Button onClick={passSelectedTraps} uppercase width={150} disabled={!Object.values(selected)?.length}> {t`import`}</Button>
                        </div>
                    </>}

            </Dialog>
        </div>
    );
};

export default ExportTraps;
