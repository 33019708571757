import _ from "lodash";
import React, { useState } from "react";
import useStyles from "./CoolDateRangePicker.style";
import { Paper, Button } from "@material-ui/core";
import { t } from "ttag";
import moment from "moment-timezone";
import { IDateRange } from "../../models/Selections";
import { DateRangePicker, DateRange } from "./DateRangePicker";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

type IHumanDateRange =
  | "last hour"
  | "last 24 hours"
  | "last 7 days"
  | "last 30 days";

export interface ICoolDateRangePickerProps {
  initialRange: IDateRange | IHumanDateRange | null;
  handleSubmit: (range: IDateRange | null) => void;
  options?: ICoolDateRangePickerOptions;
  setMaxDate?: boolean;
}

export interface ICoolDateRangePickerOptions {
  classNameFor?: string;
}
const defaultCoolDateRangePickerOptions = {};

const getRangeFromHDR = (hdr: IHumanDateRange) => {
  const range = { begin: new Date(), end: new Date() };
  switch (hdr) {
    case "last hour":
      range.begin = new Date(new Date().getTime() - 1000 * 60 * 60);
      range.end = new Date();
      break;
    case "last 24 hours":
      range.begin = new Date(
        new Date().setHours(0, 0, 0) - 24 * 60 * 60 * 1000
      );
      range.end = new Date();
      break;
    case "last 7 days":
      range.begin = new Date(
        new Date().setHours(0, 0, 0) - 7 * 24 * 60 * 60 * 1000
      );
      range.end = new Date();
      break;
    case "last 30 days":
      range.begin = new Date(
        new Date().setHours(0, 0, 0) - 30 * 24 * 60 * 60 * 1000
      );
      range.end = new Date();
      break;
  }

  return range;
};

export default function CoolDateRangePicker({
  initialRange,
  handleSubmit,
  options,
  setMaxDate
}: ICoolDateRangePickerProps) {
  const classes = useStyles();
  const [range, setRange] = useState<DateRange>(
    initialRange === null
      ? getRangeFromHDR("last 7 days")
      : typeof initialRange === "string"
        ? getRangeFromHDR(initialRange)
        : { begin: initialRange.startDate, end: initialRange.endDate }
  );

  options = { ...defaultCoolDateRangePickerOptions, ...options };

  const handleSelect = (range: DateRange) => {
    setRange(range);
  };

  const handleUserSubmit = () => {
    // Make sure the range conver whole days
    handleSubmit({
      // startDate: new Date(range.begin as Date),
      // endDate: new Date(range.end as Date)
      startDate:
        range && range.begin
          ? new Date(new Date(range.begin.getTime()).setHours(0, 0, 0))
          : new Date(range.begin as Date),
      endDate:
        range && range.end
          ? new Date(new Date(range.end.getTime()).setHours(23, 59, 59))
          : new Date(range.end as Date)
    });
  };

  return (
    <Paper className={classes.drpContainer}>
      <DateRangePicker
        disableFuture
        orientation="portrait"
        variant="static"
        value={range}
        open={true}
        // placeholder="Select a date range"
        onChange={handleSelect}
        setMaxDate={setMaxDate}
      />
      <div className={classes.drpButtons}>
        <Button
          variant="contained"
          onClick={() => handleSubmit(null)}
        >{t`CANCEL`}</Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleUserSubmit}
        >{t`OK`}</Button>
      </div>
    </Paper>
  );
}

// On production:
//
// admin
// Abcd@1234
