import {
  Action,
  action,
  actionOn,
  ActionOn,
  computed,
  Computed,
  debug,
  Thunk,
  thunk,
} from "easy-peasy";
import _ from "lodash";
import { ICustomer } from "./CustomerModel";
import { IRootStoreModel } from "./RootStore";
import { ISite } from "./SitesModel";
import { ISystem } from "./Systems";
import { IUnit } from "./UnitModel";

export interface ISelections {
  customerId: string | null;
  siteId: string | null;
  systemId: string | null;
  unitId: string | null;
  lastSelectedUnitId: string | null;
  dateRange: IDateRange | null; // null - no date range selected
  isFromMap?: boolean;
}
interface ISelection {
  type: "customer" | "site" | "system" | "unit" | "time" | "alertClickType";
  data: any; //string | IDateRange | null;
}
export interface IDateRange {
  startDate: Date;
  endDate: Date;
}

export interface ISelectionsModel {
  selections: ISelections;
  setSelections: Action<ISelectionsModel, Partial<ISelections>>;
  getCurrentSelection: Computed<ISelectionsModel>;
  getCustomersBySelection: Computed<ISelectionsModel, any, IRootStoreModel>;
  getSitesBySelection: Computed<ISelectionsModel, ISite[], IRootStoreModel>;
  getSystemsBySelection: Computed<ISelectionsModel, ISystem[], IRootStoreModel>;
  getUnitsBySelection: Computed<
    ISelectionsModel,
    (type: "indoor" | "outdoor", searchText?: string) => IUnit[],
    IRootStoreModel
  >;
  updateSelections: Thunk<ISelectionsModel, ISelection, any>;
  filtersTree: any;
  setFiltersTree: Action<ISelectionsModel, any>;
  getFiltersBySelections: Computed<ISelectionsModel>;
  liteUpdateSelections: Action<ISelectionsModel, ISelection>;

  // getIndoorUnits: Computed<
  //   ISelectionsModel,
  //   (searchText: string) => IUnit[],
  //   IRootStoreModel
  // >;
  // getOutdoorUnits: Computed<
  //   ISelectionsModel,
  //   (searchText: string) => IUnit[],
  //   IRootStoreModel
  // >;
  // initialize: Action<ISelectionsModel, any>;
  // onInitialized: ActionOn<ISelectionsModel, IRootStoreModel>;
}

export const selectionsModel: ISelectionsModel = {
  selections: {
    customerId: null,
    siteId: null,
    systemId: null,
    unitId: null,
    lastSelectedUnitId: null,
    dateRange: null
  },
  updateSelections: thunk((actions, payload, { getStoreState }) => {
    if (payload.type === "time") {
      actions.setSelections({ dateRange: payload.data as IDateRange });
      return;
    }

    let newSelection: any = {
      customerId: null,
      siteId: null,
      systemId: null,
      unitId: null
    };

    const rootState: any = getStoreState();
    const oldSelections = rootState.selections.selections;

    switch (payload.type) {
      case "unit":
        const unit = rootState.units.allUnits[payload.data];
        if (!_.isEmpty(unit)) {
          newSelection.customerId = unit.customer
            ? unit.customer
            : oldSelections.customerId;
          newSelection.siteId = unit.site ? unit.site : oldSelections.siteId;
          newSelection.systemId = unit.system
            ? unit.system
            : oldSelections.systemId;
          newSelection.unitId = payload.data;
        } else {
          newSelection = { ...oldSelections, unitId: null };
        }
        break;
      case "system":
        const sys = rootState.systems.allSystems[payload.data];
        //rootState.getObject("system", payload.data);
        if (!_.isEmpty(sys)) {
          newSelection.customerId = sys.customer
            ? sys.customer
            : oldSelections.customerId;
          newSelection.siteId = sys.site ? sys.site : oldSelections.siteId;
          newSelection.systemId = payload.data;
        } else {
          const unassignedUnits: any = Object.values(
            rootState.units.allUnits
          ).filter((unit: any) => _.isNil(unit.system));
          let systems: any = {};
          for (let unit of unassignedUnits) {
            if (!systems[unit.line]) {
              systems[unit.line] = [unit];
            } else {
              systems[unit.line].push(unit);
            }
          }
          if (systems[payload.data]) {
            newSelection.siteId = systems[payload.data][0].site
              ? systems[payload.data][0].site
              : oldSelections.siteId;
            const site = rootState.sites.allSites[newSelection.siteId];
            newSelection.customerId = site
              ? site.customer
              : oldSelections.customerId;
            newSelection.systemId = payload.data;
          } else {
            newSelection = { ...oldSelections, unitId: null, systemId: null };
          }
        }
        break;

      case "site":
        const site = rootState.sites.allSites[payload.data]; //rootState.getObject("site", payload.data);
        if (!_.isEmpty(site)) {
          newSelection.customerId = site.customer
            ? site.customer
            : oldSelections.siteId;
          newSelection.siteId = payload.data;
        } else {
          newSelection = {
            ...oldSelections,
            unitId: null,
            systemId: null,
            siteId: null
          };
        }
        break;
      case "customer":
        newSelection.customerId = payload.data;
        break;

      default:
        break;
    }

    actions.setSelections(newSelection);
  }),
  setSelections: action((state, payload) => {
    // const lastUnitId = state.selections.unitId;
    _.assign(state.selections, payload);
    // if (lastUnitId !== payload.unitId)
    //   state.selections.lastSelectedUnitId = lastUnitId;
  }),

  getCurrentSelection: computed([(state) => state.selections], (selections) => {
    if (selections.unitId) {
      return { type: "unitId", id: selections.unitId };
    }
    if (selections.systemId) {
      return { type: "systemId", id: selections.systemId };
    }
    if (selections.siteId) {
      return { type: "siteId", id: selections.siteId };
    }
    if (selections.customerId) {
      return { type: "customerId", id: selections.customerId };
    } else {
      return { type: "customerId", id: "all" };
    }
  }),

  getCustomersBySelection: computed(
    [
      (state) => state.selections,
      (state, storeState) => storeState.customers.customerList
    ],
    (selections, allCustomers) => {
      return Object.values(allCustomers);
      // // .filter(customer =>
      // //   selections.customer ? customer.id === selections.customer : true
      // );
    }
  ),

  getSitesBySelection: computed(
    [
      (state) => state.selections,
      (state, storeState) => storeState.sites.allSites,
      (state, storeState) => storeState.customers.customerList
    ],
    (selections, allSites, allCustomers) => {
      return Object.values(allSites).filter(
        (site) =>
          selections.customerId
            ? _.includes(allCustomers[selections.customerId].sites, site.id)
            : true
      );
    }
  ),
  getSystemsBySelection: computed(
    [
      (state) => state.selections,
      (state, storeState) => storeState.sites.allSites,
      (state, storeState) => storeState.systems.allSystems,
      (state, storeState) => storeState.devices.allDevices,
      (state, storeState) => storeState.customers.customerList
    ],
    (selections, allSites, allSystems, allDevices, allCustomers) => {
      return Object.values(allSystems).filter((system) => {
        const systemDevice = allDevices[system.device];
        if (_.isUndefined(systemDevice)) return false;
        const systemSite = allSites[systemDevice.site];
        if (_.isUndefined(systemSite)) return false;
        const systemCustomerId = systemSite.customer;

        return (
          (selections.customerId
            ? systemCustomerId === selections.customerId
            : true) &&
          (selections.siteId ? systemSite.id === selections.siteId : true)
        );

        // _.includes(allDevices[selections.customerId].sites, site.id);
      });
    }
  ),
  getUnitsBySelection: computed(
    [
      (state) => state.selections,
      (state, storeState) => storeState.units.allUnits,
      (state, storeState) => storeState.devices.allDevices,
      (state, storeState) => storeState.sites.allSites
    ],
    (selections, allUnits, allDevices, allSites) => (type, searchText = "") => {
      const filterType = type === "indoor" ? 1 : 2;

      const result: any = Object.values(allUnits).filter((unit: any) => {
        const unitSystemId = unit.system;
        const unitDevice = allDevices[unit.device];
        if (_.isUndefined(unitDevice)) return false;
        const unitSite = allSites[unitDevice.site];
        if (_.isUndefined(unitSite)) return false;
        const unitCustomerId = unitSite.customer;
        if (_.isUndefined(unitCustomerId)) return false;

        return (
          unit.type === filterType &&
          (selections.customerId
            ? unitCustomerId === selections.customerId
            : true) &&
          (selections.siteId ? unitSite.id === selections.siteId : true) &&
          (selections.systemId ? unitSystemId === selections.systemId : true) &&
          (searchText && searchText.length
            ? unit.name.toLowerCase().includes(searchText)
            : true)
        );
      });
      return result;
    }
  ),
  filtersTree: {},
  setFiltersTree: action((state, payload) => {
    state.filtersTree = payload;
  }),
  getFiltersBySelections: computed([(state) => state.selections, (state) => state.filtersTree],
    (selections, filtersTree) => {

      const {customerId, siteId, systemId, unitId} = selections;
      const {sites = {}, systems = {}, units = {}, customers = {}} = filtersTree;

      if (unitId){
        const {system, site} = units[unitId];
        const {customer} = sites[site];

        return {
          sites: [{id: "", name: "All Sites"}, ...Object.values(customers[customer]?.sites || {})],
          systems: [{id: "", name: "All Systems"}, ...Object.values(sites[site]?.systems || {})],
          units: system ? Object.values(systems[system]?.units || {}) : Object.values(sites[site]?.units || {})
        };
      }

      if (systemId){
        const {site} = systems[systemId];
        const {customer} = sites[site];

        return {
          sites: [{id: "", name: "All Sites"}, ...Object.values(customers[customer]?.sites || {})],
          systems: [{id: "", name: "All Systems"}, ...Object.values(sites[site]?.systems || {})],
          units: Object.values(systems[systemId]?.units || {})
        };
      }

      if (siteId){
        const {customer} = sites[siteId];

        return {
          sites: [{id: "", name: "All Sites"}, ...Object.values(customers[customer]?.sites || {})],
          systems: [{id: "", name: "All Systems"}, ...Object.values(sites[siteId]?.systems || {})],
          units: Object.values(sites[siteId]?.units || {})
        };
      }

      if (customerId){
        return {
          sites: [{id: "", name: "All Sites"}, ...Object.values(customers[customerId]?.sites || {})],
          systems: [{id: "", name: "All Systems"}, ...Object.values(customers[customerId]?.systems || {})],
          units: Object.values(customers[customerId]?.units || {})
        };
      }

      return {
        sites: [{id: "", name: "All Sites"}, ...Object.values(sites)],
        systems: [{id: "", name: "All Systems"}, ...Object.values(systems)],
        units: Object.values(units)
      };
    }
  ),
  liteUpdateSelections: action((state, payload) => {
    const {filtersTree} = state;
    const {customers, sites, systems, units} = filtersTree;
    const {type, data} = payload;

    if (payload.type === "time") {
      _.assign(state.selections, { dateRange: payload.data as IDateRange });
      return;
    }

    if (payload.type === "unit") {
      const {system, site, type} = units[data] || {};
      if (!site || (type !== 2 && type !== 3)){
        return;
      }

      const {customer} = sites[site];
      _.assign(state.selections, { customerId: customer, siteId: site,  systemId: system, unitId: data});
      return;
    }

    if (payload.type === "system") {
      const {site} = systems[data] || {};
      if (!site){
        return;
      }
      const {customer} = sites[site];
      _.assign(state.selections, { customerId: customer, siteId: site,  systemId: data, unitId: null});
      return;
    }

    if (payload.type === "site") {
      const {customer} = sites[data] || {};
      if (!customer){
        return;
      }
      _.assign(state.selections, { customerId: customer, siteId: data,  systemId: null, unitId: null});
      return;
    }

    if (payload.type === "customer") {
      const customer = customers[data];
      if (!customer){
        return;
      }
      _.assign(state.selections, { customerId: data, siteId: null,  systemId: null, unitId: null});
      return;
    }
  })
  // getIndoorUnits: computed(
  //   [
  //     state => state.selections,
  //     (state, storeState) => storeState.units.allUnits,
  //     (state, storeState) => storeState.devices.allDevices,
  //     (state, storeState) => storeState.sites.allSites
  //   ],
  //   (selections, allUnits, allDevices, allSites) => searchText => {
  //     return Object.values(allUnits).filter(unit => {
  //       const unitSystem = unit.system;
  //       const unitDevice = allDevices[unit.device];
  //       const unitSite = allSites[unitDevice.site];
  //       const unitCustomer = unitSite.customer;
  //       return (
  //         unit.type === 1 &&
  //         (selections.customer ? unitCustomer === selections.customer : true) &&
  //         (selections.site ? unitSite.id === selections.site : true) &&
  //         (selections.system ? unitSystem === selections.system : true) &&
  //         (searchText && searchText.length
  //           ? unit.name.toLowerCase().includes(searchText)
  //           : true)
  //       );
  //     });
  //   }
  // ),

  // // return units.map(unit =>
  // //   values(unit)
  // //     .filter(
  // //       unit =>
  // //         unit.type === 1 &&
  // //         unit.name.toLowerCase().includes(this.state.indoorSearch)
  // //     )
  // //     .map((unit: any) => this.getUnitItem(unit))
  // // );
  // // }
  // // return units.map((u: any) =>
  // //   values(u)
  // //     .filter((unit: IUnit) => unit.type === 1)
  // //     .map((unit: any) => this.getUnitItem(unit))

  // getOutdoorUnits: computed(
  //   [
  //     state => state.selections,
  //     (state, storeState) => storeState.units.allUnits,
  //     (state, storeState) => storeState.devices.allDevices,
  //     (state, storeState) => storeState.sites.allSites
  //   ],
  //   (selections, allUnits, allDevices, allSites) => searchText => {
  //     return Object.values(allUnits).filter(unit => {
  //       const unitSystem = unit.system;
  //       const unitDevice = allDevices[unit.device];
  //       const unitSite = allSites[unitDevice.site];
  //       const unitCustomer = unitSite.customer;
  //       return (
  //         unit.type === 2 &&
  //         (selections.customer ? unitCustomer === selections.customer : true) &&
  //         (selections.site ? unitSite.id === selections.site : true) &&
  //         (selections.system ? unitSystem === selections.system : true) &&
  //         (searchText && searchText.length
  //           ? unit.name.toLowerCase().includes(searchText)
  //           : true)
  //       );
  //     });
  //   }
  // ),

  // get getOutdoor() {
  //   const allUnits = this.getUnits();

  //   return allUnits.map((u: any) =>
  //     values(u)
  //       .filter((unit: IUnit) => {
  //         if (unit.type !== 2) {
  //           return false;
  //         }
  //         if (!this.state.outdoorSearch) {
  //           return true;
  //         }
  //         return unit.name.toLowerCase().includes(this.state.outdoorSearch);
  //       })
  //       .map((unit: any) => {
  //         let unitClass = false;

  //         const systemUnitMapArray: any = this.getSystems().filter(
  //           (system: any) =>
  //             values(system).filter((s: any) => unit.system === s.id).length
  //         );
  //         const systemUnit =
  //           systemUnitMapArray.length && systemUnitMapArray[0].size
  //             ? values(systemUnitMapArray[0])[0]
  //             : null;

  //         if (!systemUnit) {
  //           return this.getUnitItem(unit, false);
  //         }

  //         if (systemUnit.brand.toUpperCase().startsWith('DAIKIN')) {
  //           const unitInternalId = unit.internalId;
  //           let temp = _.split(unitInternalId, ':')[1];
  //           temp = temp.slice(2, 6);
  //           if (temp != '3030') {
  //             unitClass = true;
  //           }
  //         }

  //         return this.getUnitItem(unit, unitClass);
  //       })
};
