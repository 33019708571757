/* global google */
/// <reference types="googlemaps" />
import { Card, Typography } from "@material-ui/core";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import useStyles from "./TemperatureInfo.style";

interface IProps {
  unit: any;
  site: any;
  externalOnly?: boolean;
  externalTemp: number | null;
  outdoorTemp?: string;
  roomTemp: any;
  brandName?: string;
  outsideAirTemp: any;

}

export default function TemperatureInfo(props: IProps) {
  const {
  roomTemp,
  brandName,
  outsideAirTemp,
  externalTemp,
  outdoorTemp
} = props;
  const classes = useStyles();
  const temperatureScale = useStoreState((s) => s.users.me.temperatureScale);

  const isAltherma = brandName === "Daikin Altherma";
  return (
  <Card className={classes.card}>
    <div className={classes.content}>
      {!props.externalOnly && (
        <div className={classes.infoContainer}>
          <div>
            <Typography display={"inline"} className={classes.minorTempStyle}>
              {!roomTemp ? "--" : roomTemp}
            </Typography>
            <Typography display={"inline"} className={classes.minorTempIconStyle}>
              {temperatureScale === 1 ? "°C" : "°F"}
            </Typography>
          </div>
          <Typography display={"inline"} className={classes.minorTempTitleStyle}>
            {t`Room Temp`}
          </Typography>{" "}
        </div>
      )}
      <div
        className={classes.infoContainer}
      >
        <div>
          <Typography display={"inline"} className={classes.minorTempStyle}>
            {isAltherma ?
              outsideAirTemp !== null ? outsideAirTemp : "--"
              : externalTemp !== null ? externalTemp : "--"}
          </Typography>
          <Typography display={"inline"} className={classes.minorTempIconStyle}>
            {temperatureScale === 1 ? "°C" : "°F"}
          </Typography>
        </div>
        <Typography display={"inline"} className={classes.minorTempTitleStyle}>
          {isAltherma ? t`Outside air temp` : t`Site Temp`}
        </Typography>
      </div>
      {props.externalOnly && (
        <div className={classes.infoContainer}
          style={{ marginLeft: "5px" }}
        >
          <div>
            <Typography display={"inline"} className={classes.minorTempStyle}>
              {outdoorTemp || "--"}
            </Typography>
            <Typography display={"inline"} className={classes.minorTempIconStyle}>
              {temperatureScale === 1 ? "°C" : "°F"}
            </Typography>
          </div>
          <Typography display={"inline"} className={classes.minorTempTitleStyle}>
            {t`Outdoor Temp`}
          </Typography>{" "}
        </div>
      )}
    </div>
  </Card>
);
}
