import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  item: {
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    minHeight: "50px",
    position: "relative",
    // fontSize: '20px',
    borderTop: "1px rgb(87, 69, 92) solid",
    // backgroundColor: "white",
    color: "white",
    paddingRight: "0.5rem",
    paddingLeft: "0.5rem",
    fontSize: "1em",
    background: "rgba(41, 19, 46, 0)"
  },

  onClick: {
    flexGrow: 9,
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    alignItems: "center"
  },

  locationTitle: {
    cursor: "pointer",
    display: "inline",
    lineHeight: "50px",
    verticalAlign: "middle",
    marginRight: "1rem",
    fontSize: "0.9375rem"
  },

  collapsibleIcon: {
    fontSize: "0.875rem"
  },
  lengthTitle: {
    background: "rgba(236, 235, 239, 0.15)",
    borderRadius: "9px",
    padding: "2px 8px",
    width: "fit-content",
    justifySelf: "center",
    fontSize: "0.875rem"
  },
  unitsList: {
    backgroundColor: "#331e38",
    maxHeight: "40vh",
    overflowY: "auto"
  }
});

export default useStyles;
