import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  optionStyle: {
    color: '#000',
    width: '180px',
    fontSize: '0.8125rem',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  optionText: {
    fontSize: '0.8125rem',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  selectContainer: {
    // padding: '0 9px',
    height: '36px'
    // display: 'flex',
    // alignItems: 'center'
  },
  selectStyle: {
    width: '10rem',
    color: '#fff',
    backgroundColor: 'rgba(255, 255, 255, 0.15)',
    borderRadius: '4px',
    margin: '1rem .5rem',
    padding: '0 9px',
    height: '36px',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: theme.palette.primary.light
    }
  },
  inputStyle: {},
  iconStyle: {
    right: '0'
  },
  focusStyle: {
    '&:focus': {
      backgroundColor: 'rgba(255, 255, 255, 0.15)',
      borderRadius: '4px'
    }
  },
  arrowIcon: {
    color: '#fff',
    fontSize: '2.5rem !important',
    top: '0',
    height: '36px'
  }
}));

export default useStyles;
