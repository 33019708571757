import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  view: {
    display: "flex",
    height: "100%"
  },
  content: {
    padding: "1rem"
  },
  contentArea: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    background: "rgb(236, 235, 239)",
    maxHeight: "-webkit-fill-available",
    maxWidth: "calc(100% - 240px)"
  },
  container: {
    padding: "3rem"
  },
  tableContainer: {
    height: "calc(100% - 53px)",
    padding: "1rem",
    width: "auto",
    overflowY: "auto"
  },
  processingAnimationContainer: {
    display: "flex",
    flexDirection: "column"
  },
  progressContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  progress: {
    height: "calc(100vh - 125px)",
    padding: "1rem",
    width: "auto",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    left: "50%",
    position: "absolute"
  },
  hidden: {
    visibility: "hidden"
  },
  tableHeadCell: {
    borderBottom: "none"
  },

  alertInfoContainer: {},
  selected: {
    backgroundColor: "#f4f4f4"
  },
  headContainer: { display: "flex", alignItems: "center" },
  blueFilter: { fill: "#1976d2" },
  filterStyle: { marginLeft: "10px" },
  dateColumn: { minWidth: "135px" },
  tablePaper: {
    background: "#fefefe",
    padding: "20px",
    paddingTop: 0,
    borderRadius: "4px",
    boxShadow: "0 2px 5px -5px rgba(180, 175, 203, 0.63)",
    border: "solid 1px #ebebeb",
    height: "calc(100vh - 125px)"
  },
  tablePagesHeader: { display: "flex", justifyContent: "flex-end" },
  columnsTitles: {
    border: "none",
    padding: "9px 15px",
    fontSize: "13px",
    color: "#79797d",
    fontWeight: "bold",
    lineHeight: "normal",
    letterSpacing: "normal",
    backgroundColor: "#efeff2"
  },
  pagingStyle: { border: "none" },
  wrapper: {
    height: "calc(100vh - 180px)",
    padding: "0 1rem 1rem",
    background: "rgb(236, 235, 239)",
    flexFlow: "row nowrap"
  },

  titleContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "20%",
    paddingLeft: "0.5rem",
    color: "#4b1c46"
  },
  buttonContainer: {
    justifyContent: "flex-end",
    paddingRight: "1rem",
    display: "flex",
    alignItems: "center",
    width: "20%",
    color: "#4b1c46"
  },

  filterContainer: {
    height: "100%",
    paddingRight: "1rem",
    minWidth: "270px"
  },
  tableWrapper: {
    height: "100%",
    display: "flex",
    flexFlow: "column nowrap",
    width: "83.333333%",
    maxWidth: "calc(100% - 270px)"
  },
  filterGridItem: {
    height: "39%",
    width: "100%",
    background: theme.palette.background.paper,
    borderRadius: 4,
    display: "flex",
    flexDirection: "column",
    padding: "1rem",
    "&:first-child": {
      borderBottom: "1rem solid #efeff2"
    },
    overflow: "auto",

    marginRight: "1rem"
  },
  filterOutdoorGridItem: {
    height: "30.5%",
    width: "100%",
    background: theme.palette.background.paper,
    borderRadius: 4,
    display: "flex",
    flexDirection: "column",
    padding: "1rem",
    "&:first-child": {
      borderBottom: "1rem solid #efeff2"
    },
    overflow: "auto",
    marginRight: "1rem"
  },
  filterContainerBs: {
    height: "35%"
  },
  filterBsGridItem: {
    height: "28%",
    width: "100%",
    background: theme.palette.background.paper,
    borderRadius: 4,
    display: "flex",
    flexDirection: "column",
    padding: "1rem",
    "&:first-child": {
      borderBottom: "1rem solid #efeff2"
    },
    overflow: "auto",
    marginRight: "1rem",
    marginBottom: "1rem"
  },

  filter: {
    height: "100%",
    overflow: "auto",
    marginBottom: "1rem",
    display: "flex",
    flexDirection: "column"
  },

  // ---- Audits table
  tablesContainer: {
    height: "100%",
    // width: "20rem",
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap"
  },
  tableGridItem: {
    height: "39%",
    display: "flex",
    padding: "1rem",
    // overflow: "auto",
    background: "#fff",
    borderRadius: "4px",
    flexDirection: "column"
  },
  tableBsGridItem: {
    height: "28%",
    display: "flex",
    padding: "1rem",
    overflow: "auto",
    background: "#fff",
    borderRadius: "4px",
    flexDirection: "column",
    marginBottom: "1rem"
  },
  tableOutdoorGridItem: {
    height: "35%",
    display: "flex",
    padding: "1rem",
    overflow: "auto",
    background: "#fff",
    borderRadius: "4px",
    flexDirection: "column",
    borderBottom: "1rem solid #efeff2"
  },
  tableOutdoorGridItemBs: {
    height: "30.5%"
  },
  filterGridItemBs: {
    height: "65%"
  },
  table: {
    background: theme.palette.background.paper
  },
  tableHeadRow: {
    height: "3rem"
  },
  overWritePadding: {},
  tableCell__icon: {
    width: 30
  },
  tableCell__date: {
    width: "24ch",
    paddingLeft: 0
  },
  tableCell__actor: {
    width: "20ch"
  },
  tableCell__unit: {
    width: "15ch"
  },
  tableCell__actionOnSubject: {
    width: "25ch"
  },
  tableCell__subject: {
    width: "15ch"
  },
  tableCell__data: {
    width: "auto"
  },
  slider: {
    flex: 1,
    margin: "1rem",
    color: "grey"
    // backgroundColor: "grey",
  },
  sliderContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    flex: 1
    // paddingLeft: "1rem",
  },
  headerContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    padding: "0 16px",
    flexFlow: "row nowrap",
    minHeight: "70px"
  },
  valueLabel: {
    // span: {
    "&:.PrivateValueLabel-circle": {
      borderRadius: "50% 0 50% 50% !important",
      color: "red"
      //   },
    },
    top: "30px"
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 12,
    paddingLeft: 12,
    paddingRight: 12,
    fontWeight: 600
  },
  tooltipArrow: {
    color: theme.palette.common.white,
    filter: "drop-shadow( 0px 6px 7px #000 )"
  },
  headerButton: {
    minHeight: "40px",
    maxHeight: "40px",
    minWidth: "169px",
    fontSize: "16px",
    fontWeight: "normal",
    lineHeight: "normal",
    color: "#fff",
    textTransform: "capitalize"
  },
  noSysText: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    fontSize: "24px"
  },
  contentContainer: {
    width: "100%",
    height: "100%",
    padding: "0.5rem",
    boxSizing: "border-box",
    overflowX: "hidden"
  },
  noContentContainer: {
    boxShadow: "0 2px 5px -5px rgba(180, 175, 203, 0.63)",
    backgroundColor: "#fefefe",
    justifyContent: "center",
    alignItems: "center",
    padding: 0,
    width: "calc(100% - 60px)",
    height: "calc(100vh - 190px)",
    alignSelf: "center",
    marginTop: "30px"
  },
  grayOval: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "136px",
    height: "136px",
    backgroundColor: "rgba(170, 162, 170, 0.1)",
    borderRadius: "50%"
  },
  arrowIcon: { fontSize: "91px", transform: "rotate(90deg)" },
  noUnitText: {
    fontSize: "26px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#545964",
    marginTop: "43px"
  },
  iconBtnStyle: {
    width: 30,
    height: 30,
    borderRadius: 6,
    padding: 0
  },
  btnStyle: {
    marginLeft: 20
  }
}));

export default useStyles;
