import coolremoteSDK, { xhr as SdkXhr } from "coolremote-sdk";
import {
  Action,
  action,
  Computed,
  computed,
  createTypedHooks,
  Thunk,
  thunk,
} from "easy-peasy";
import _ from "lodash";
import { localXhr } from "../services/localXhr";
import { alertsModel, IAlert, IAlertsModel } from "./Alerts";
import {
  anomalyTemplatesModel,
  IAnomalyTemplatesModel,
} from "./AnomalyTemplates";
import { auditsModel, IAuditsModel } from "./Audits";
import { CustomersModel, ICustomersModel } from "./CustomerModel";
import { DevicesModel, IDevicesModel } from "./DeviceModel";
import { IMessagesModel, messageModel } from "./ErrorMessage";
import { ISelectionsModel, selectionsModel } from "./Selections";
import { ISitesModel, SitesModel } from "./SitesModel";
import { ISubscriptionsModel, subscriptionsModel } from "./Subscriptions";
import { ISystemsModel, systemsModel } from "./Systems";
import { ITrapsModel, trapsModel } from "./traps";
import { ITriggersModel, TriggersModel } from "./TriggerModel";
import { IUnitsModel, unitsModel } from "./UnitModel";
import { IUsersModel, usersModel } from "./UsersModel";

export interface IServiceTypes {
  temperatureScale?: any;
  operationStatuses?: any;
  operationModes?: any;
  operationModesWithTemperatures?: any;
  fanModes?: any;
  swingModes?: any;
  weekDays?: any;
  roles?: any;
  permissions?: any;
  resources?: any;
  hvacBrands?: any[];
  outdoorUnitTasks?: any;
  systemTypes?: string[];
  alertClasses?: any;
  alertClearTypes?: any;
  proParsingCodes?: any;
  capacityMeasurementUnitTypes?: any;
  unitTypes?: any;
  customerStatuses?: any;
  creationMethods?: any;
  billingModels?: any;
  languages?: any;
  procedureConditions?: any;
  procedureRunningStates?: any;
  procedureStateCommands?: any;
  procedureDeviceCommands?: any;
  procedureStepTypes?: any;
  measurementUnitTypes?: any;
  pressureScale?: any;
  eventClearTypes?: any;
  eventTypes?: any;
  eventStatusTypes?: any;
  applications?: any;
  trapTypes?: any;
  siteStatuses?: any;
  subscriptionTemplatesTypes?: any;
  calculatedMode?: any;
}

export interface IRootStoreModel {
  isLoggedIn: boolean;
  setLoggedIn: Action<IRootStoreModel, boolean>;
  users: IUsersModel;
  customers: ICustomersModel;
  devices: IDevicesModel;
  units: IUnitsModel;
  alerts: IAlertsModel;
  types: IServiceTypes;
  unitTypesMirrror: Computed<IRootStoreModel>;
  traps: ITrapsModel;
  anomalyTemplates: IAnomalyTemplatesModel;
  logout: Thunk<IRootStoreModel>;
  triggers: ITriggersModel;
  isLoaded: boolean;
  setIsLoaded: Action<IRootStoreModel, boolean>;
  sites: ISitesModel;
  isInitialized: boolean;
  getUserTree: Thunk<IRootStoreModel>;
  fetchedUserTree: Action<IRootStoreModel>;
  customersTree: any;
  setCustomers: Action<IRootStoreModel, any>;
  audits: IAuditsModel;
  selections: ISelectionsModel;
  systems: ISystemsModel;
  subscriptions: ISubscriptionsModel;
  setUnitUpdateStatus: Action<IRootStoreModel, { status: string }>;
  unitUpdateStatus: string;
  setTypes: Action<IRootStoreModel, IServiceTypes>;
  setServiceParamTypes: Action<IRootStoreModel, any>;
  getServiceTypes: Thunk<IRootStoreModel, any>;
  getServiceParamTypes: Thunk<IRootStoreModel>;
  deviceBySerial: Thunk<IRootStoreModel, any>;
  usersByDevice: Thunk<IRootStoreModel, any>;
  deviceByUser: Thunk<IRootStoreModel, any>;
  errorMessage: IMessagesModel;
  temperatureScaleMirror: Computed<IRootStoreModel>;
  serviceParamTypes: any;
  unitTypesOptions: Computed<IRootStoreModel>;
  trapOperatorsOptions: any;
  eventClearTypesMirror: Computed<IRootStoreModel>;
  eventTypesMirror: Computed<IRootStoreModel>;
  eventStatusTypesMirror: Computed<IRootStoreModel>;
  selectedAlert: any;
  setSelectedAlert: Action<IRootStoreModel, any>;
  serviceErrorTypes: any;
  setServiceErrorTypes: Action<IRootStoreModel, any>;
}

const storeModel: IRootStoreModel = {
  users: usersModel,
  customersTree: {},
  customers: CustomersModel,
  triggers: TriggersModel,
  devices: DevicesModel,
  sites: SitesModel,
  units: unitsModel,
  alerts: alertsModel,
  traps: trapsModel,
  anomalyTemplates: anomalyTemplatesModel,
  types: {},
  serviceErrorTypes: {},
  serviceParamTypes: {},
  unitTypesMirrror: computed((state) => {
    const { types } = state;
    const { unitTypes } = types;

    if (!unitTypes) {
      return null;
    }

    const keys = Object.keys(unitTypes);
    const object: { [index: string]: any } = {};

    const unitType = keys.reduce((allTypes, key) => {
      const name = unitTypes[key];
      allTypes[name as string] = key;

      return allTypes;
    }, object);

    return unitType;
  }),
  audits: auditsModel,
  selections: selectionsModel,
  systems: systemsModel,
  subscriptions: subscriptionsModel,
  isLoaded: false,
  isLoggedIn: false,
  isInitialized: false,
  unitUpdateStatus: "",
  setIsLoaded: action((state) => {
    state.isLoaded = true;
  }),

  logout: thunk((actions, payload: any) => {
    localXhr.removeToken();
    actions.setIsLoaded(true);
  }),
  setLoggedIn: action((state, payload) => {
    state.isLoggedIn = payload;
  }),
  getUserTree: thunk(async (actions) => {
    actions.customers.getCustomers();
    // const response: any = await coolremoteSDK.User.getMyTree();

    // const allSites: any = {};
    // const allDevices: any = {};
    // const allUnits: any = {};
    // // const allCompressors: any = {};
    // const allSystems: any = {};
    // const allAlerts: any = {};
    // // const allTriggers: any = {};
    // // const allZones: any = {};
    // const allUsers: any = {};
    // // const allAlertGroups: any = {};

    // _(response.customers).forEach((customer: any) => {
    //   _.merge(allSites, customer.sites);
    //   _.merge(allUsers, customer.users);
    //   // _.merge(allAlertGroups, customer.alertGroups);
    //   // _.merge(allTriggers, customer.triggers);
    // });
    // _(allSites).forEach((site: any) => {
    //   _.merge(allDevices, site.devices);
    //   // _.merge(allAlerts, site.alerts);
    //   // _.merge(allTriggers, site.triggers);
    //   // _.merge(allZones, site.zones);
    //   _.merge(allUsers, site.users);
    // });
    // _(allDevices).forEach((device: any) => {
    //   _.merge(allUnits, device.units);
    //   _.merge(allSystems, device.systems);
    // });
    // // _(allUnits).forEach((unit: any) => {
    // //   _.merge(allCompressors, unit.compressors);
    // // });

    // // actions.customers.initialize(response.customers);
    // actions.sites.initialize(allSites);
    // // actions.devices.initialize(allDevices);
    // // actions.alerts.initialize(allAlerts);
    // // actions.triggers.initialize(allTriggers);
    // // actions.zones.initialize(allZones);
    // actions.units.initialize(allUnits);
    // // actions.compressors.initialize(allCompressors);
    // actions.systems.initialize(allSystems);
    // // actions.alertGroups.initialize(allAlertGroups);
    // actions.setCustomers(response.customers);
    // actions.fetchedUserTree();
  }),
  fetchedUserTree: action((state) => {
    state.isInitialized = true;
  }),
  setCustomers: action((state, payload) => {
    state.customersTree = { ...payload };
  }),
  setUnitUpdateStatus: action((state, payload) => {
    state.unitUpdateStatus = payload.status;
  }),
  setTypes: action((state, payload) => {
    state.types = payload;
  }),
  setServiceParamTypes: action((state, payload) => {
    state.serviceParamTypes = payload;
  }),
  setServiceErrorTypes: action((state, payload) => {
    state.serviceErrorTypes = payload;
  }),
  getServiceTypes: thunk(async (actions, payload) => {
    // Service types contain textual description of various params
    const serviceTypes = await coolremoteSDK.Services.getTypes();
    actions.setTypes(serviceTypes);
  }),
  getServiceParamTypes: thunk(async (actions, payload) => {
    const serviceParamTypes = await coolremoteSDK.Services.getServiceParamTypes();
    actions.setServiceParamTypes(serviceParamTypes);
  }),
  deviceBySerial: thunk(async (actions, payload) => {
    // Service types contain textual description of various params
    const serviceTypes = await coolremoteSDK.Admin.deviceBySerial(payload);
    return serviceTypes;
  }),
  selectedAlert: null,
  setSelectedAlert: action((state, payload) => {
    state.selectedAlert = payload;
  }),
  eventClearTypesMirror: computed((state) => {
    const { types } = state;
    const { eventClearTypes } = types;

    if (!eventClearTypes) {
      return null;
    }

    const keys = Object.keys(eventClearTypes);
    const object: { [index: string]: any } = {};

    const eventClearType = keys.reduce((allTypes, key) => {
      const name = eventClearTypes[key];
      allTypes[name as string] = key;

      return allTypes;
    }, object);

    return eventClearType;
  }),
  eventTypesMirror: computed((state) => {
    const { types } = state;
    const { eventTypes } = types;

    if (!eventTypes) {
      return {};
    }

    const keys = Object.keys(eventTypes);
    const object: { [index: string]: any } = {};

    const eventType = keys.reduce((allTypes, key) => {
      const name = eventTypes[key];
      allTypes[name as string] = key;

      return allTypes;
    }, object);

    return eventType;
  }),
  eventStatusTypesMirror: computed((state) => {
    const { types } = state;
    const { eventStatusTypes } = types;

    if (!eventStatusTypes) {
      return {};
    }

    const keys = Object.keys(eventStatusTypes);
    const object: { [index: string]: any } = {};

    const eventType = keys.reduce((allTypes, key) => {
      const name = eventStatusTypes[key];
      allTypes[name as string] = key;

      return allTypes;
    }, object);

    return eventType;
  }),
  temperatureScaleMirror: computed((state) => {
    const { types } = state;
    const { temperatureScale } = types;

    if (!temperatureScale) {
      return null;
    }

    const keys = Object.keys(temperatureScale);
    const object: { [index: string]: any } = {};

    const temperatureScaleType = keys.reduce((types, key) => {
      const name = temperatureScale[key];
      types[name as string] = key;

      return types;
    }, object);

    return temperatureScaleType;
  }),
  usersByDevice: thunk(async (actions, payload) => {
    // Service types contain textual description of various params
    const serviceTypes = await coolremoteSDK.Admin.usersByDevice(payload);
    return serviceTypes;
  }),

  deviceByUser: thunk(async (actions, payload) => {
    // Service types contain textual description of various params
    const serviceTypes = await coolremoteSDK.Admin.deviceByUser(payload);
    return serviceTypes;
  }),
  unitTypesOptions: computed((state) => {
    const { types } = state;
    const { unitTypes } = types;

    if (!unitTypes) {
      return null;
    }

    return [
      { value: unitTypes.service, name: "Indoor" },
      { value: unitTypes.outdoor, name: "Outdoor" },
      { value: "mixed", name: "Mixed" }
    ];
  }),
  trapOperatorsOptions: [
    {
      value: ">",
      label: ">"
    },
    {
      value: "<",
      label: "<"
    },
    { value: "=", label: "=" },
    {
      value: "!=",
      label: "≠"
    },
    {
      value: "threshold",
      label: "Difference"
    }
  ],
  errorMessage: messageModel
};

const typedHooks = createTypedHooks<IRootStoreModel>();
export const useStoreActions = typedHooks.useStoreActions;
export const useStoreDispatch = typedHooks.useStoreDispatch;
export const useStoreState = typedHooks.useStoreState;

export default storeModel;
