import {
  Card,
  Grid,
  Typography
} from "@material-ui/core";
import { useLoadScript } from "@react-google-maps/api";
import _ from "lodash";
import moment from "moment";
import "moment-timezone";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router";
import { t } from "ttag";
import SelectionsMenu from "../../components/Header/SelectionsMenu";
import TemperatureInfo from "../../components/TemperatureInfo/TemperatureInfo";
import UnitControl from "../../components/UnitControl/UnitControl";
import UnitStats from "../../components/UnitDiagComps/UnitStats";
import ErrorLogs from "../../components/UnitErrorLogs/ErrorLogs";
import UnitInfo from "../../components/UnitInfo/UnitInfo";
import alertSeverities from "../../constants/alertSeverities";
import ArrowIcon from "../../icons/ArrowLong";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import ServiceNavigationBar from "../../widgets/Menu/ServiceNavigationBar";
import useStyles from "./DashboardUnit.style";
const severties: any = alertSeverities;

const eventTypesToSeverityMapping: any = {
  cleanFilter: "MAINTENANCE",
  customTelemetry: "ANOMALIES",
  adminTelemetry: "ANOMALIES",
  deviceDisconnected: "NOT_CONNECTED",
  indoorUnitError: "SYSTEM_ERROR",
  outdoorUnitError: "INDOOR_ERROR",
  unitDisconnected: "NOT_CONNECTED",
  unitsDisconnected: "NOT_CONNECTED",
  unspecified: "ALL_IS_WELL",
  operationalTelemetry: "ANOMALIES" };

const DashboardUnit: React.FC = (props: any) => {
  const location: any = useLocation();
  const { state } = location;

  // Get history from hook
  const history = useHistory(),
    classes = useStyles(),
    // Get unit id from path
    match = useRouteMatch<{ unitId: string }>();
  const { unitId } = match.params;

  const getUnit = useStoreActions((a) => a.units.getUnitById);

  // const getLineQuality = useStoreActions((action) => action.devices.getLineQuality);
  const refreshUnit = useStoreActions((a) => a.units.refreshUnit);
  const updateSelections = useStoreActions((a) => a.selections.liteUpdateSelections);
  // const unitCustomer = useStoreState((s) => (customerId ? s.customers.customerList[customerId] : null));
  const allSites = useStoreState((s) => s.sites.allSites);
  const allDevices = useStoreState((s) => s.devices.allDevices); //.getDevice(deviceId));
  const {sites, systems} = useStoreState((s) => s.selections.filtersTree);
  const currentUser = useStoreState((s) => s.users.me);
  const displayFlags = useStoreState((s) => s.users.displayFlags);

  const {unitId: selectedUnitId} = useStoreState((s) => s.selections.selections);
  const types = useStoreState((state) => state.types);
  const eventTypesMirror = useStoreState((state) => state.eventTypesMirror);
  const getAlertsByFilters = useStoreActions((action) => action.alerts.getAlertsByFilters);
  const eventStatusTypesMirror = useStoreState((state) => state.eventStatusTypesMirror);
  const [outdoorTemp, setOutdoorTemp] = useState("--");
  const [reFectUnit, setReFetchUnit] = useState<boolean>(false);
  const [externalTemp, setExternalTemp] = useState<any>(null);
  const [externalTempC] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [externalTempF] = useState<any>(null);
  const [unitAlerts, setUnitAlerts] = useState<any>([]);
  const [unit, setUnit] = useState<any>(null);
  const [controlUnit, setControlUnit] = useState<any>(null);
  const [selectedTime, setSelectedTime] = useState<any>({}); //time picker on graph
  const [outsideAirTemp, setOutsideAirTemp] = useState<any>(null);
  const [remoconRoomTemp, setRemoconRoomTemp] = useState<any>(null);
  const [unitOpenAlerts, setOpenAlerts] = useState<any>([]);
  const [brandName, setBrandName] = useState<any>(null);

  const { device : deviceId = "", site: siteId = "", system : systemId = "" } = unit || {};
  const unitDevice = allDevices[deviceId] || {id: deviceId};
  const unitSite = sites[siteId] || {id: siteId};
  const unitSystem = systems[systemId] || {id: systemId};
  const { temperatureScale } = currentUser;
  const { unitTypes } = types;

  useEffect(() => {
  if (state?.unitId && state?.time) {
      const { time } = state;
      const diff = moment.now() - time;
      setSelectedTime({ selectedStartTime: time - 43200000, selectedEndTime: diff < 43200000 ? moment.now() : (time + 43200000) });
      // history.replace({
      //   pathname: `/unit-diag/${unitId}`,
      //   state: {}
      // });
    } else {
      const defaultDateNow = new Date().getTime();
      const defaultDatePast = new Date().getTime() - 1 * 24 * 60 * 60 * 1000;
      setSelectedTime({ selectedStartTime: defaultDatePast, selectedEndTime: defaultDateNow });
    }
   }, []);

  useEffect(() => {
    if (!unit || !unit.controlUnit) {
      return;
    }
    const contUnit = getUnit(unit.controlUnit);
    if (!!contUnit) {
      setControlUnit(contUnit);
      return;
    }
    setControlUnit(null);
  }, [unit]);

  useEffect(() => {
    if (!unitId && selectedUnitId){
      history.push(`/unit-diag/${selectedUnitId}`);
      return;
    }
    if (unitId !== selectedUnitId) {
      updateSelections({ type: "unit", data: unitId });
    }
  }, []);

  useEffect(() => {
    if (selectedUnitId === unitId) {
      getUnit(unitId)
      .then((unitData: any) => setUnit(unitData))
      .catch(() => setUnit(null));
      return;
    }
    setUnit(null);
  }, [selectedUnitId, unitId]);

  useEffect(() => {
    if (!reFectUnit || !selectedUnitId){
      return;
    }
    setReFetchUnit(false);
    getUnit(selectedUnitId)
    .then((unitData: any) => setUnit(unitData));
  }, [reFectUnit]);

  useEffect(() => {
    if (!unit) {
      return;
    }
    if (!!unit.controlUnit) {
      refreshUnit(unit.controlUnit)
        .then((data: any) => {
          setControlUnit(data);
        });
    }
  }, [unit]);

  useEffect(() => {
      if (!selectedUnitId || !selectedTime.selectedStartTime || !selectedTime.selectedEndTime) {
       return;
     }

      getAlertsByFilters({
       startTime: selectedTime.selectedStartTime,
       endTime: selectedTime.selectedEndTime,
       type: types.applications.console
     })
       .then((alertsByTime: any) => {
         const alerts: any = [];
         const openAlerts: any = [];

         Object.values(alertsByTime).forEach((alert: any) => {
           const {status, eventTime, trapDescription, clearTime, data, systemName, resources = [{id: "none"}]} = alert;

           if (resources[0]?.id !== selectedUnitId){
             return;
           }

           const severity = severties[eventTypesToSeverityMapping[eventTypesMirror[alert.type]]];
           if (!severity){
             return;
           }

           const statusText = eventStatusTypesMirror[status];
           const item: any = {
             name: "error",
             timestamp: eventTime,
             description: trapDescription,
             alerttype: severity.name,
             color: severity.color,
             errorCode: data,
             status: statusText,
             clearTime: moment(clearTime).format("DD/MM/YY   HH:mm"),
             time: moment(eventTime).format("DD/MM/YY   HH:mm"),
             systemName,
             y: 18,
             z: 100
           };

           alerts.push(item);
           if (statusText === "open"){
             openAlerts.push(item);
           }
         });
         setUnitAlerts(alerts);
         setOpenAlerts(openAlerts);
       });
    }, [selectedUnitId, selectedTime]);

  const onUnitSelect = (unitId: string | null) => {

    if (unitId) {
      history.push(`/unit-diag/${unitId}`);
    } else {
      history.push("/unit-diag");
    }
  };

  const isBsUnit = unit?.type === unitTypes["bsBox"];
  const isControlUnit = unit?.type === unitTypes["indoor"];
  const notOutdoor = unit?.type !== unitTypes["outdoor"];
  const isAltherma = brandName === "Daikin Altherma";

  //onunitselect //

  return (
    <ServiceNavigationBar {...props} filters={<SelectionsMenu onUnitSelect={onUnitSelect} hideCustomerSelection={!displayFlags.enableUnitDiagTopbar} hideSiteSelection={!displayFlags.enableUnitDiagTopbar} />}>

      <div className={classes.view}>
        <div className={classes.rightSide}>
          {unit
            // !_.isNil(unitCustomer) &&
            // !_.isNil(unitSite) &&
            // !_.isNil(unitDevice)
            ? (
              <Grid container direction={"row"} className={classes.contentContainer}>
                {/* --- start of unitInfoContainer  */}
                <Grid container direction={"row"} className={classes.unitInfoContainer}>
                  <Grid item xs={7} className={classes.halfPadding} style={{ maxHeight: "121px" }}>
                    <UnitInfo
                      unit={unit}
                      site={unitSite}
                      device={unitDevice}
                      system={unitSystem}
                      showSysBTn={displayFlags.enableSysDiag}
                    // setNotesAnch={setNotesAnch}
                    />
                  </Grid>
                  <Grid item xs={2} className={classes.halfPadding}>
                    {unit.type !== 2 && !isBsUnit ? (
                      <TemperatureInfo
                        unit={unit}
                        site={unitSite}
                        externalTemp={externalTemp}
                        roomTemp={isAltherma && notOutdoor ? remoconRoomTemp : controlUnit ? controlUnit.ambientTemperature : unit.ambientTemperature}
                        outsideAirTemp={outsideAirTemp}
                        brandName={brandName}
                      />
                    ) : (
                        <TemperatureInfo
                          unit={unit}
                          site={unitSite}
                          externalTemp={externalTemp}
                          externalOnly
                          outdoorTemp={outdoorTemp}
                          roomTemp={isAltherma && notOutdoor ? remoconRoomTemp : controlUnit ? controlUnit.ambientTemperature : unit.ambientTemperature}
                          outsideAirTemp={outsideAirTemp}
                          brandName={brandName}
                        />
                      )}
                  </Grid>
                  <Grid item xs={3} className={classes.halfPadding}>
                    {!isBsUnit && isControlUnit ? (
                      <UnitControl unit={unit} temperatureScale={temperatureScale} device={unitDevice} />
                    ) : null}
                  </Grid>
                </Grid>
                {/* --- end of unitInfoContainer */}
                {/* --- start of paramsContainer  */}
                <Grid container direction={"row"} className={classes.halfPadding}>
                  <Grid item xs={12} className={classes.paramsContainer}>
                    <Card className={classes.statsRapper}>
                      {unit.type === 2 ? (
                        <UnitStats
                          site={unitSite}
                          unit={unit}
                          alerts={unitAlerts}
                          setOutdoorTemp={setOutdoorTemp}
                          getLineQuality={() => { }}
                          setSelectedTime={setSelectedTime}
                          setReFetchUnit={setReFetchUnit}
                          isLoading={isLoading}
                          setIsLoading={setIsLoading}
                          selectedTime={selectedTime}
                          setExternalTemp={setExternalTemp}
                          setOutsideAirTemp={setOutsideAirTemp}
                        setRemoconRoomTemp={setRemoconRoomTemp}
                        />
                      ) : (
                          <UnitStats
                            site={unitSite}
                            unit={unit}
                            alerts={unitAlerts}
                            getLineQuality={() => { }}
                            setSelectedTime={setSelectedTime}
                            setReFetchUnit={setReFetchUnit}
                            isLoading={isLoading}
                            setIsLoading={setIsLoading}
                            selectedTime={selectedTime}
                            setExternalTemp={setExternalTemp}
                            setOutsideAirTemp={setOutsideAirTemp}
                            setRemoconRoomTemp={setRemoconRoomTemp}
                            setOutdoorTemp={setOutdoorTemp}
                          />
                        )}
                    </Card>
                  </Grid>
                  {/* --- end of paramsContainer */}
                  {/* --- start of errorLogs and systemOverview */}
                </Grid>

                <Grid container direction={"row"} spacing={3} className={classes.bottomContainer}>
                  <Grid item xs={5}>
                    <Card
                      classes={{
                        root: classes.errorLogsCard
                      }}
                    >
                       <ErrorLogs alerts={unitOpenAlerts}/>
                    </Card>
                  </Grid>

                  <Grid item xs={7}>
                    {/* {unitSystem && (
                      <Card className={classes.systemOverviewCard}>
                        <SystemOverview system={unitSystem} />
                      </Card>
                    )} */}
                  </Grid>

                  {/* --- end of errorLogs and systemOverview */}
                </Grid>
              </Grid>
            ) : (
              <Grid container direction={"column"} className={classes.noContentContainer}>
                <div className={classes.grayOval}>
                  <ArrowIcon className={classes.arrowIcon} />
                </div>
                <Typography className={classes.noUnitText}>
                  {t`Please select a unit using the above filters.`}
                </Typography>
              </Grid>
            )}
        </div>

      </div>
    </ServiceNavigationBar>
  );
};

export default DashboardUnit;
