import { System as SystemSdk } from "coolremote-sdk";
import {
  Action,
  action,
  actionOn,
  ActionOn,
  Computed,
  computed,
  debug,
  memo,
  Thunk,
  thunk,
} from "easy-peasy";
import _ from "lodash";
import { IAlert } from "./Alerts";
import { IRootStoreModel } from "./RootStore";
import { IUnit } from "./UnitModel";

export interface ISystem {
  id: string;
  name: string;
  brand?: string;
  description?: string;
  type?: string;
  series?: string;
  capacity?: number;
  capacityMeasurementUnits?: number;
  line: number;
  device: string;
  units: string[];
  role: any;
  types: any;
  capacityMeasurement: any;
  brandNum?: number;
}

export interface ISystemMap {
  [key: string]: ISystem;
}

export interface ISystemsModel {
  allSystems: ISystemMap;
  initialize: Action<ISystemsModel, any>;
  onInitialized: ActionOn<ISystemsModel, IRootStoreModel>;
  updateSystem: Thunk<ISystemsModel, { systemId: string; updatedData: any }>;
  systemTogglePower: Thunk<ISystemsModel, { id: string; data: any }>;
  getSystems: Thunk<ISystemsModel>;
  changeSystemOperationMode: Thunk<ISystemsModel, { id: string; data: any }>;
  _storeUpdateSystem: Action<
    ISystemsModel,
    { systemId: string; updatedSystemData: any }
  >;
  getSystem: Computed<
    ISystemsModel,
    (id?: string | null) => ISystem | undefined
  >;
  // getSystemAlerts: Computed<
  //   ISystemsModel,
  //   (id: string, type: "all" | "unresolved") => IAlert[] | [],
  //   IRootStoreModel
  // >;
  // hasUnitsOn: Computed<ISystemsModel, (id: string) => boolean, IRootStoreModel>;
  // getSystemMode: Computed<
  //   ISystemsModel,
  //   (id: string) => number,
  //   IRootStoreModel
  // >;
  // hasDisconnectedUnits: Computed<
  //   ISystemsModel,
  //   (id: string) => boolean,
  //   IRootStoreModel
  // >;
  getSystemFirstUnitId: Computed<
    ISystemsModel,
    (id: string, unitType: number | undefined) => string | null,
    IRootStoreModel
  >;
  getSystemById: Thunk<ISystemsModel, any>;
  deleteSystem: Thunk<ISystemsModel, any>;
}

export const systemsModel: ISystemsModel = {
  allSystems: {},

  initialize: action((state, payload) => {
    const newSystems: ISystemMap = _(Object.values(payload))
      .map((system: any) => {
        const newSystem: ISystem = { ...system };
        return newSystem;
      })
      .keyBy("id")
      .value();
    state.allSystems = newSystems;
  }),

  onInitialized: actionOn(
    (actions, storeActions) => [actions.initialize],
    (state, target) => {
      // console.log("Got systems: ", debug(state.allSystems));
    }
  ),
  getSystem: computed([(state) => state.allSystems], (allSystems) =>
    memo((id) => {
      if (_.isNil(id)) {
        return undefined;
      }
      return allSystems[id];
    }, 100)
  ),
  updateSystem: thunk(async (actions, payload) => {
    const updatedSystemData = await SystemSdk.update(
      payload.systemId,
      payload.updatedData
    );

    actions._storeUpdateSystem({
      systemId: payload.systemId,
      updatedSystemData
    });
  }),

  systemTogglePower: thunk(async (actions, payload) => {
    await SystemSdk.changeSystemPower(payload.id, payload.data);
  }),
  getSystems: thunk(async (actions) => {
    return SystemSdk.getSystems();
  }),

  changeSystemOperationMode: thunk(async (actions, payload) => {
    await SystemSdk.changeSystemOperationMode(payload.id, payload.data);
  }),

  _storeUpdateSystem: action((state, payload) => {
    if (state.allSystems[payload.systemId]) {
      state.allSystems[payload.systemId] = payload.updatedSystemData;
    }
  }),

  // getSystemAlerts: computed(
  //   [
  //     state => state.allSystems,
  //     (state, storeState) => storeState.units.allUnits,
  //     (state, storeState) => storeState.devices.allDevices,
  //     (state, storeState) => storeState.sites.allSites,
  //     (state, storeState) => storeState.alerts.allAlerts,
  //     (state, storeState) => storeState.units.isItMe
  //   ],
  //   (systems, allUnits, allDevices, allSites, allAlerts, unitIsItMe) =>
  //     memo((id, type = "all") => {
  //       const system = systems[id];
  //       const systemDevice = allDevices[system.device];
  //       const systemSite = allSites[systemDevice.site];
  //       let systemAlerts: IAlert[] = [];
  //       Object.values(allAlerts).forEach((alert: IAlert) => {
  //         if (type === "unresolved" && !_.isUndefined(alert.clearTime)) return;
  //         if (systemSite.id === alert.site) {
  //           if (_.has(alert, "data.eventData.unitId")) {
  //             Object.values(allUnits).forEach((unit: IUnit) => {
  //               if (
  //                 alert.data.eventData.unitId &&
  //                 unitIsItMe(unit, alert.data.eventData.unitId) &&
  //                 unit.system === system.id
  //               ) {
  //                 // TODO: remove console.log
  //                 // console.log('alert1', systemSite.id === alert.site, alert);
  //                 systemAlerts.push(alert);
  //               }
  //             });
  //           } else if (
  //             alert.data.trigger.template.includes("DEVICE_DISCONNECT") &&
  //             systemDevice.serial === alert.data.eventData.deviceId
  //           ) {
  //             // TODO: remove console.log
  //             // console.log('alert2', systemSite.id === alert.site, alert);
  //             systemAlerts.push(alert);
  //           }
  //         }
  //       });
  //       return systemAlerts;
  //     }, 100)
  // ),

  // hasUnitsOn: computed(
  //   [(state, storeState) => storeState.units.allUnits],
  //   allUnits => id => {
  //     let hasUnitsOn = false;

  //     const units = Object.values(allUnits).filter(unit => {
  //       if (unit.system === id && unit.activeOperationStatus === 1) {
  //         return true;
  //       }
  //       return false;
  //     });
  //     if (units.length) {
  //       hasUnitsOn = true;
  //     }

  //     return hasUnitsOn;
  //   }
  // ),

  // getSystemMode: computed(
  //   [(state, storeState) => storeState.units.allUnits],
  //   allUnits => id => {
  //     let systemMode = -1; // not specified
  //     const systemIndoorUnits = Object.values(allUnits).filter(unit => {
  //       if (unit.system === id && unit.type === 1) {
  //         return true;
  //       }
  //       return false;
  //     });
  //     if (systemIndoorUnits.length > 0) {
  //       systemMode = systemIndoorUnits[0].activeOperationMode;
  //     } else return -1;

  //     for (let unit of systemIndoorUnits) {
  //       if (unit.activeOperationMode !== systemMode) {
  //         return -1;
  //       }
  //     }
  //     return systemMode;
  //   }
  // ),

  // hasDisconnectedUnits: computed(
  //   [(state, storeState) => storeState.units.allUnits],
  //   allUnits => id => {
  //     for (let unit of Object.values(allUnits)) {
  //       if (
  //         unit.system === id &&
  //         _.has(unit, "isConnected") &&
  //         !unit.isConnected
  //       ) {
  //         // console.log('Found disconnected unit:', unit);
  //         return true;
  //       }
  //     }

  //     return false;
  //   }
  // ),

  getSystemFirstUnitId: computed(
    [(state, storeState) => storeState.units.allUnits],
    (allUnits) => (id, unitType) => {
      let systemFilteredUnits = Object.values(allUnits).filter((unit: any) => {
        // Filter out wrong typed units only if unit-type is specified.
        if (!_.isNil(unitType) && unitType !== unit.type) {
          return false;
        }

        // Filter in units with system assigned and it is the system requested.
        if (!_.isNil(unit.system) && unit.system === id) {
          return true;
        }

        // No match.
        return false;
      });

      // Return the first filtered unit's ID.
      //rand
      // if (systemFilteredUnits.length) {
      //   // Outdoor units are sorted by line ID.
      //   if (unitType === 2) {
      //     systemFilteredUnits = _.sortBy(
      //       systemFilteredUnits,
      //       unit => unit.line
      //     );
      //   }

      //   return systemFilteredUnits[0].id;
      // }
      //rand
      // No unit found.
      return null;
    }
  ),
  getSystemById: thunk(async (actions, payload) => {
    return SystemSdk.getSystemById(payload);
  }),
  deleteSystem: thunk(async (actions, payload) => {
    return SystemSdk.delete(payload);
  })
};
