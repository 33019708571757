import {
  Card,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Input,
  Typography
} from "@material-ui/core/";
import { Check, Clear } from "@material-ui/icons";
import { ToggleButton } from "@material-ui/lab";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { isEmail, ReactMultiEmail } from "react-multi-email";
import "react-multi-email/style.css";
import { t } from "ttag";
import Button from "../../components/cool_widgets/Button";
import LightTooltip from "../../components/Tooltip/LightTooltip";
import { Delete as DeleteIcon, Email as EmailIcon } from "../../icons/";
import { alertsModel } from "../../models/Alerts";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import ErrorBox from "../../widgets/ErrorBox/ErrorBox";
import useStyles from "./AlertInfo.style";

const AlertInfo: React.FC<any> = (props: any) => {
  const { alert, open, selectUnit, itemGotDeleted, addResolvedDescription, setAcknowledged } = props;
  const classes = useStyles();
  const cleanFilterUnit = useStoreActions((action) => action.units.cleanFilterUnit);
  const [isOpen, setIsOpen] = useState(false);
  const [isEmailDialogOpen, setEmailDialogOpen] = useState(false);
  const [isDeleteDialogOpen, handleDeleteDialog] = useState(false);
  const [emails, setEmails] = useState<string[]>([]);
  const [btnIsActive, activateBtn] = useState<boolean>(false);
  const [error, setError] = useState(null);
  const [resolvedAlertDescription, setResolvedAlertDescription] = useState<any>("");
  const [selectedUnit, setSelectedUnit] = useState<string | null>("");
  const [selectedSystem, setSelectedSystem] = useState<string | null>("");
  const [toAck, setToAck] = useState<boolean>(false);
  const sendAlertEmail = useStoreActions((actions) => actions.alerts.sendAlertEmail);
  const allUnits = useStoreState((state) => state.units.allUnits);
  const types = useStoreState((state) => state.types);
  const { eventTypes } = types;
  const deleteAlert = useStoreActions((actions) => actions.alerts.deleteAlert);
  const updateAlert = useStoreActions((actions) => actions.alerts.updateAlert);
  const displayFlags = useStoreState((state) => state.users.displayFlags);
  const getSystem = useStoreState((state) => state.systems.getSystem);
  const errorTypes = useStoreState((state) => state.serviceErrorTypes);
  const setAcknowledgedEvent = useStoreActions((actions) => actions.alerts.setAcknowledgedEvent);

  const getTitle = (systemId: string, errorCode: any) => {
    const system = getSystem(systemId);
    const brand = system?.brandNum || "";
    const error = _.isNaN(+errorCode) ? errorCode : +errorCode;

    return errorTypes.errorCodeTypes[brand] ? errorTypes.errorCodeTypes[brand][error] : "";
  };
  const handleClose = () => {
    setIsOpen(false);
    setSelectedUnit("");
    setSelectedSystem("");
  };
  const handleOpen = (id: string, type: string) => {
    if (type === "system") {
      const systemId = allUnits[id]?.system;
      setSelectedSystem(systemId);
    } else {
      const controlUnitId = allUnits[id]?.controlUnit || id;
      setSelectedUnit(controlUnitId);
    }
    setIsOpen(true);
  };
  const handleAgree = () => {
    if (selectedSystem) {
      const system = getSystem(selectedSystem) || { units: [] };
      const systemUnits = system?.units;
      systemUnits.forEach((unit: any) => {
        const controlUnitId = allUnits[unit]?.controlUnit;
        if (controlUnitId) {
          cleanFilterUnit({ id: controlUnitId });
        }
      });
    }
    else if (selectedUnit) {
      cleanFilterUnit({ id: selectedUnit });
    }
    handleClose();
  };
  const confrimDeleteAlert = () => {
    deleteAlert(alert.id)
      .then(() => {
        handleDeleteDialog(false);
        itemGotDeleted();
      })
      .catch((error: any) => console.log("error", error));
  };
  const handleEmailDialog = () => {
    setEmails([]);
    setEmailDialogOpen(!isEmailDialogOpen);
  };
  const handleEmails = (emails: string[]) => {
    setEmails(emails);
  };
  const sendAlertByEmail = () => {
    sendAlertEmail({ emails, id: alert.id })
      .then(() => setEmailDialogOpen(false))
      .catch((error: any) => console.log("error", error));
  };
  const validateEmail = (email: any) => {
    return isEmail(email);
  };
  const addLabeledEmail = (email: string, index: number, removeEmail: (index: number) => void) => {
    return (
      <div data-tag className={classes.emailPart} key={index}>
        {email}
        <span data-tag-handle onClick={() => removeEmail(index)}>
          ×
          </span>
      </div>
    );
  };
  const handleInputsChange = (event: any) => {
    const {
      target: { value }
    } = event;
    setResolvedAlertDescription(value);
  };
  const save = () => {
    updateAlert({ id: alert.id, data: { userText: resolvedAlertDescription } })
      .then(() => {
        activateBtn(false);
        addResolvedDescription(resolvedAlertDescription, alert.id);
      })
      .catch((err: any) => {
        setError(err.message);
      });
  };
  useEffect(() => {
    setResolvedAlertDescription("");
    activateBtn(false);
    if (!alert || alert.userText === undefined) { return; }
    setResolvedAlertDescription(alert.userText);
  }, [alert]);

  useEffect(() => {
    if (
      !alert ||
      (alert && alert.status !== "Closed") ||
      (alert && alert.userText === resolvedAlertDescription) ||
      !resolvedAlertDescription
    ) {
      return;
    }

    activateBtn(true);
  }, [resolvedAlertDescription]);
  const errorBox = error ? <ErrorBox error={error || ""} onClose={() => setError(null)} /> : null;
  const disableDelete = false;
  const isAckAlert = alert?.status === "Acknowledged";
  const isOpenAlert = alert?.status === "Open";

  return (
    <>
      {errorBox}
      {open && (
        <Grid container className={classes.infoContainer}>
          <div className={classes.alertHeader}>
            <Typography variant="h5" color="primary">
              {t`Alert`}
            </Typography>
            <div>
              <LightTooltip title={t`Share Alert`}>
                <IconButton onClick={handleEmailDialog} className={classes.email}>
                  <EmailIcon />
                </IconButton>
              </LightTooltip>
              {(alert && alert.status === "Closed") &&
                <LightTooltip title={t`Delete Alert`}>
                  <IconButton disabled={disableDelete} onClick={() => handleDeleteDialog(true)}>
                    <DeleteIcon color={disableDelete ? "#AAA2AA" : "#4B1C46"} />
                  </IconButton>
                </LightTooltip>
              }
            </div>
          </div>
          <Grid item>
            <Card className={classes.detailsContainer}>
              <Typography variant="h6">{t`General Details`}</Typography>
              <Grid container direction="row" className={classes.row}>
                <Typography className={classes.rowTitle}> {t`Customer`}</Typography>
                <Typography variant="body2" className={classes.rowInfo}>
                  {" "}
                  {alert.customerName}
                </Typography>
              </Grid>
              <Grid container direction="row" className={classes.row}>
                <Typography className={classes.rowTitle}> {t`Site`}</Typography>
                <Typography variant="body2" className={classes.rowInfo}>
                  {" "}
                  {alert.siteName}
                </Typography>
              </Grid>
              <Grid container direction="row" className={classes.row}>
                <Typography className={classes.rowTitle}> {t`System`}</Typography>
                <Typography variant="body2" className={classes.rowInfo}>
                  {" "}
                  {alert.systemName}
                </Typography>
              </Grid>
              <Grid container direction="row" className={classes.row}>
                <Typography className={classes.rowTitle}> {t`Device`}</Typography>
                <Typography variant="body2" className={classes.rowInfo}>
                  {" "}
                  {alert.deviceName}
                </Typography>
              </Grid>
              <Grid container direction="row" className={classes.row}>
                <Typography className={classes.rowTitle}> {t`Unit`}</Typography>
                <Typography variant="body2" className={classes.rowInfo}>
                  {" "}
                  {eventTypes.deviceDisconnected === alert.type ? "" : alert.unitName}
                </Typography>
              </Grid>
            </Card>

            {
              (isOpenAlert || isAckAlert) && (
                <div className={classes.ackButtonContainer}>
                  <ToggleButton
                    value="ack"
                    selected={isAckAlert}
                    classes={{ root: classes.ackButton, label: classes.ackButtonLable }}
                    onChange={() => {
                      const toAck = alert.status === "Acknowledged" ? false : true;
                      if (toAck) {
                        setToAck(true);
                        return;
                      }
                      setAcknowledgedEvent({ id: alert.id, data: false })
                        .then(() => {
                          setAcknowledged({ id: alert.id, data: false });
                        });
                    }}
                  >
                    <Check className={classes.ackButtonIcon} />
                    <Typography className={classes.ackButtonText}>{t`Acknowledge`}</Typography>
                  </ToggleButton>
                </div>
              )
            }

            <Card>
              <Typography variant="h6">{t`Alert Details`}</Typography>
              <Grid container direction="row" className={classes.row}>
                <Typography className={classes.rowTitle}> {t`Id`}</Typography>
                <Typography variant="body2" className={classes.rowInfo}>
                  {" "}
                  {alert.shortId}
                </Typography>
              </Grid>
              <Grid container direction="row" className={classes.row}>
                <Typography className={classes.rowTitle}> {t`Open Date`}</Typography>
                <Typography variant="body2" className={classes.rowInfo}>
                  {" "}
                  {alert.time}
                </Typography>
              </Grid>
              {alert.acknowledgedTime && (<Grid container direction="row" className={classes.row}>
                <Typography className={classes.rowTitle}> {t`Ack Date`}</Typography>
                <Typography variant="body2" className={classes.rowInfo}>
                  {" "}
                  {alert.acknowledgedTime}
                </Typography>
              </Grid>)}
              <Grid container direction="row" className={classes.row}>
                <Typography className={classes.rowTitle}> {t`Clear Date`}</Typography>
                <Typography variant="body2" className={classes.rowInfo}>
                  {alert.status !== "Open" ? alert.clearTime || "" : ""}
                </Typography>
              </Grid>
              <Grid container direction="row" className={classes.row}>
                <Typography className={classes.rowTitle}> {t`Clear Reason`}</Typography>
                <Typography variant="body2" className={classes.rowInfo}>
                  {`${alert.clearReason || ""}`}
                </Typography>
              </Grid>
              <Grid container direction="row" className={classes.row}>
                <Typography className={classes.rowTitle}> {t`Error Code`}</Typography>
                <Typography variant="body2" className={classes.rowInfo}>
                  {alert.errorCode}

                </Typography>
              </Grid>
              {(eventTypes.unitsDisconnected === alert.type && alert.unitIds) && (
                <Card>
                  <Typography className={classes.descriptionTitle}>{t`Current Status:`}</Typography>
                  {alert.unitIds.map((unitId: string, index: number) => {
                    const { name, isConnected } = allUnits[unitId];

                    return <Grid container key={`alert-unit-${index}`} direction="row" className={classes.row}>
                      <Typography className={classes.rowTitle} style={{
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        maxWidth: "96px",
                        paddingRight: "16px"
                      }}> {name}</Typography>
                      <Typography variant="body2" className={classes.rowInfo}>
                        {isConnected ? "connected" : "disconnected"}
                      </Typography>
                    </Grid>;
                  })}
                </Card>
              )}
              {(eventTypes.customTelemetry === alert.type && alert.unitIds) && (
                <Card>
                  <Typography className={classes.descriptionTitle}>{t`Units:`}</Typography>
                  {alert.unitIds.map((unitId: string, index: number) => {
                    const { name } = allUnits[unitId];

                    return <Grid container key={`alert-unit-${index}`} direction="row" className={classes.row}>
                      <Typography className={classes.rowTitle} style={{
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        maxWidth: "90%",
                        width: "100%",
                        paddingRight: "16px"
                      }}> {name}</Typography>
                    </Grid>;
                  })}
                </Card>
              )}
              <Card className={classes.description}>
                <Typography className={classes.descriptionTitle}>{t`Description`}</Typography>
                <Typography>{(alert.errorCode && alert.alertItemContainerIds) ? getTitle(alert.alertItemContainerIds.systemId, alert.errorCode) : alert.description}
                </Typography>
              </Card>
              {alert.alertType === "Clean Filter" && alert.status === "Open" && (
                <>
                  <Button
                    onClick={() => handleOpen(alert.alertItemContainerIds.unitId, "unit")}
                    white
                    uppercase
                    width={150}
                    className={classes.btn}
                  >{t`Reset unit filter`}</Button>
                  <Button
                    onClick={() => handleOpen(alert.alertItemContainerIds.unitId, "system")}
                    white
                    uppercase
                    width={150}
                    className={classes.btn}
                  >{t`Reset system filters`}</Button>
                </>
              )}
              {(false && alert.alertItemContainerIds.unitId &&
                <Button
                  onClick={() => selectUnit(alert.alertItemContainerIds.unitId)}
                  white
                  uppercase
                  width={200}
                  className={classes.btn}
                >{t`Unit alert History`}</Button>
              )}

              {alert.status !== "Open" && (
                <Card className={classes.resolveDescription}>
                  <Typography className={classes.resolveTitle}>{t`Resolve Description`}</Typography>
                  <Input
                    multiline
                    rows="4"
                    name="resolved alert"
                    disableUnderline={true}
                    type="text"
                    classes={{ multiline: classes.resolvedInfo }}
                    value={resolvedAlertDescription}
                    onChange={handleInputsChange}
                  />
                  <Button
                    type="submit"
                    width={120}
                    uppercase
                    className={classes.sendBtn + "" + classes.saveBtn}
                    disabled={!btnIsActive}
                    onClick={save}
                  >{t`save`}</Button>
                </Card>
              )}
            </Card>
          </Grid>
          <Grid item xs={6}>
            {/* <AlertUsers/> */}
          </Grid>
        </Grid>
      )
      }
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
      >
        <div className={classes.dialogTitle}>
          <DialogTitle id="simple-dialog-title">{t`Reset filter`}</DialogTitle>
          <IconButton onClick={handleClose} className={classes.clearButton}>
            <Clear />
          </IconButton>
        </div>
        <DialogContent className={classes.dialogContent}>{t`Are you sure ?`}</DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            white
            width={100}
            uppercase
            className={classes.cancelFilter}
            style={{ marginRight: "22px" }}
          >{t`Cancel`}</Button>
          <Button
            onClick={handleAgree}
            autoFocus={true}
            width={100}
            uppercase
          >
            {t`Ok`}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={isEmailDialogOpen}
        onClose={handleEmailDialog}
        aria-labelledby="email-alert-dialog-title"
        aria-describedby="email-alert-dialog-description"
        maxWidth="sm"
        classes={{ paper: classes.emailDialogStyle }}
      >
        <div className={classes.dialogTitle}>
          <DialogTitle id="simple-dialog-title">{t`Share alert`}</DialogTitle>
        </div>
        <div className={classes.emailDialogContent}>
          <Typography>Recipients</Typography>
          <ReactMultiEmail
            placeholder="Email Address/es"
            emails={emails}
            onChange={handleEmails}
            validateEmail={validateEmail}
            getLabel={addLabeledEmail}
          />
          <div className={classes.emailDialogActions}>
            <Button
              white
              width={110}
              uppercase
              marginRight
              onClick={handleEmailDialog}
            >{t`Cancel`}</Button>
            <Button
              type="submit"
              width={110}
              uppercase
              disabled={_.isEmpty(emails)}
              onClick={sendAlertByEmail}
            >{t`Share`}</Button>
          </div>
        </div>
      </Dialog>
      <Dialog
        open={isDeleteDialogOpen}
        onClose={() => handleDeleteDialog(false)}
        aria-labelledby="delete-alert-dialog-title"
        aria-describedby="delete-alert-dialog-description"
        classes={{ paper: classes.deleteDialog }}
      >
        <DialogTitle
          id="delete-alert-dialog-title"
          className={classes.deleteTitleContent}
          disableTypography
        >
          <Typography variant="h6">{t`Delete Alert`}</Typography>
          <IconButton onClick={() => handleDeleteDialog(false)}>
            <Clear />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.deleteDialogContent}>
          <DialogContentText className={classes.deleteContentText} id="alert-dialog-description">
            {t`Are you sure you want to delete this alert?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.deleteDialogActions}>
          <Button onClick={confrimDeleteAlert} white width={140} uppercase>
            {t`Yes`}
          </Button>

          <Button
            autoFocus
            onClick={() => handleDeleteDialog(false)}
            width={140}
            uppercase
          >
            {t`No`}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={toAck}
        onClose={() => setToAck(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t`Acknowledge Event?`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t`Acknowledge will move this alert to Acknowledge status and will not show it in the dashboards of open alerts`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            width={110}
            uppercase
            onClick={() => {
              const toAck = alert.status === "Acknowledged" ? false : true;
              setToAck(false);
              setAcknowledgedEvent({ id: alert.id, data: toAck })
                .then(() => {
                  setAcknowledged({ id: alert.id, data: toAck });
                });
            }}
          >{t`Ok`}</Button>

          <Button
            white
            width={110}
            uppercase
            marginRight
            onClick={() => setToAck(false)}
          >{t`Cancel`}</Button>
        </DialogActions>
      </Dialog>

    </>
  );
};
export default AlertInfo;
