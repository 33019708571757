import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import { Clear } from "@material-ui/icons";
import { Field, Formik } from "formik";
import _ from "lodash";
import { Select } from "material-ui-formik-components/Select";
import { tz } from "moment-timezone";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import * as Yup from "yup";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import ErrorBox from "../../widgets/ErrorBox/ErrorBox";
import InputField from "../../widgets/InputField/InputField";
import { countries } from "./countryList";
import useStyles from "./Site.style";
import { stateList } from "./stateList";

interface ISiteState {
  name: string;
  customer: string;
  city: string;
  address: string;
  postalCode: string;
  country: string;
  state: string;
  timezone: string;
}
interface ICountry {
  value: string;
  label: string;
}

export default function Site(props: any) {
  const [error, setError] = useState("");

  const createSite = useStoreActions(actions => actions.sites.createSite);
  const allCustomers = useStoreState(state => state.customers.customerList);
  const getCustomers = useStoreActions(
    actions => actions.customers.getCustomers
  );
  const {
    site,
    isOpen,
    handleCloseDialog,
    match: {
      params: { customerId }
    }
  } = props;

  const classes = useStyles();

  const SiteSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    customer: Yup.string().required("Required"),
    city: Yup.string().required("Required"),
    address: Yup.string().required("Required"),
    postalCode: Yup.string().required("Required"),
    country: Yup.string().required("Required"),
    state: Yup.string(),
    timezone: Yup.string().required("Required")
  });

  const initialValues: ISiteState = {
    name: site ? site.name : "",
    customer: customerId ? customerId : "",
    city: site ? site.city : "",
    address: site ? site.address : "",
    postalCode: site ? site.postalCode : "",
    country: site ? site.country : "",
    state: site ? site.state : "",
    timezone: site ? site.timezone : ""
  };

  useEffect(() => {
    if (_.isEmpty(allCustomers)) {
      getCustomers();
    }
  }, [allCustomers]);

  const handleAgree = (values: ISiteState, actions: any) => {
    save(values);
    handleCloseDialog();
  };

  const save = (site: any) => {
    const data = {
      name: site.name,
      country: site.country,
      city: site.city,
      state: site.state,
      address: site.address,
      postalCode: site.postalCode,
      timezone: site.timezone
    };
    createSite({
      customerId,
      data
    }).catch((err: any) => { });
  };

  const errorBox = error ? (
    <ErrorBox error={error} onClose={() => setError("")} />
  ) : null;

  const countryOptions = countries.map((country: any) => ({
    value: country.code,
    label: country.name
  }));
  const timeZones = tz.names().map((timezone: any) => ({
    value: timezone,
    label: `${timezone}  ${tz(timezone).format("Z")}`
  }));
  const stateOptions = stateList.map((state: any) => ({
    value: state.value,
    label: state.label
  }));
  const customers = Object.values(allCustomers).map((customer: any) => ({
    value: customer.id,
    label: customer.name
  }));

  return (
    <Dialog
      open={isOpen}
      onClose={handleCloseDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
    >
      <DialogTitle id="alert-dialog-title" className={classes.title}>
        <div className={classes.titleContent}>
          <Typography variant="h6">{t`Add site`}</Typography>
          <IconButton onClick={handleCloseDialog}>
            <Clear />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {errorBox}

        <Formik
          initialValues={initialValues}
          onSubmit={handleAgree}
          enableReinitialize={true}
          validationSchema={SiteSchema}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {({ handleSubmit, errors, touched, values, ...restFormikProps }) => (
            <form onSubmit={handleSubmit}>
              <div className={classes.inputFieldWithLabel}>
                <Typography>{t`Customer`}</Typography>
                <Field
                  disabled={true}
                  name="customer"
                  value={customerId}
                  options={customers}
                  component={Select}
                  variant="outlined"
                  error={errors.customer && touched.customer ? true : false}
                  helperText={
                    errors.customer && touched.customer ? errors.customer : ""
                  }
                />
              </div>
              <div className={classes.inputFieldWithLabel}>
                <Typography>{t`Name`}</Typography>
                <Field
                  value={values.name}
                  name="name"
                  component={InputField}
                  error={errors.name && touched.name ? true : false}
                  helperText={errors.name && touched.name ? errors.name : ""}
                />
              </div>
              <div className={classes.inputFieldWithLabel}>
                <Typography>{t`City`}</Typography>
                <Field
                  value={values.city}
                  name="city"
                  component={InputField}
                  error={errors.city && touched.city ? true : false}
                  helperText={errors.city && touched.city ? errors.city : ""}
                />
              </div>
              <div className={classes.inputFieldWithLabel}>
                <Typography>{t`Address`}</Typography>
                <Field
                  value={values.address}
                  name="address"
                  component={InputField}
                  error={errors.address && touched.address ? true : false}
                  helperText={
                    errors.address && touched.address ? errors.address : ""
                  }
                />
              </div>
              <div className={classes.inputFieldWithLabel}>
                <Typography>{t`Postal Code`}</Typography>
                <Field
                  value={values.postalCode}
                  name="postalCode"
                  component={InputField}
                  error={errors.postalCode && touched.postalCode ? true : false}
                  helperText={
                    errors.postalCode && touched.postalCode
                      ? errors.postalCode
                      : ""
                  }
                />
              </div>
              <div className={classes.inputFieldWithLabel}>
                <Typography>{t`Country`}</Typography>
                <Field
                  options={countryOptions}
                  name="country"
                  component={Select}
                  variant="outlined"
                  error={errors.country && touched.country ? true : false}
                  helperText={
                    errors.country && touched.country ? errors.country : ""
                  }
                />
              </div>
              <div className={classes.inputFieldWithLabel}>
                <Typography>{t`State`}</Typography>
                <Field
                  options={stateOptions}
                  name="state"
                  component={Select}
                  variant="outlined"
                  error={errors.state && touched.state ? true : false}
                  helperText={errors.state && touched.state ? errors.state : ""}
                />
              </div>
              <div className={classes.inputFieldWithLabel}>
                <Typography>{t`Time zone`}</Typography>
                <Field
                  options={timeZones}
                  name="timezone"
                  component={Select}
                  variant="outlined"
                  placeholder=""
                  error={errors.timezone && touched.timezone ? true : false}
                  helperText={
                    errors.timezone && touched.timezone ? errors.timezone : ""
                  }
                  className={classes.select}
                />
              </div>
              <div className={classes.modalActions}>
                <Button
                  title="close edit"
                  variant="contained"
                  onClick={handleCloseDialog}
                  style={{
                    color: "#4b1c46",
                    backgroundColor: "#ffffff",
                    width: "7rem",
                    marginRight: "1rem"
                  }}
                >
                  {t`Cancel`}
                </Button>
                <Button
                  title="save site"
                  variant="contained"
                  color="primary"
                  type="submit"
                  style={{ width: "7rem" }}
                >
                  {t`Save`}
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}
