import { createStyles, makeStyles, Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "100%"
    },
    nav: {
      color: "#fff",
      backgroundColor: "#29132e",
      boxSizing: "border-box",
      width: "240px",
      minHeight: "100vh",
      maxHeight: "100vh",
      overflowY: "auto",
      display: "flex",
      flexFlow: "column nowrap"
    },
    listITem: {
      opacity: 0.7,
      display: "inline-flex",
      justifyItems: "center",
      "&:hover": {
        backgroundColor: "#5c465d",
        opacity: 0.9,
        cursor: "pointer"
      }
    },

    listItemText: {
      paddingLeft: "10px",
      paddingTop: "0px",
      fontSize: "16px",
      cursor: "pointer"
    },
    iconContainer: {
      padding: "0px"
    },
    iconSize: {
      fontSize: "24px",
      marginRight: "9px",
      "& path": { stroke: "#fff" }
    },
    logoStyle: { width: "180px" },
    logoButton: {
      marginBottom: "20px",
      paddingRight: "52px"
    },
    versionText: {
      alignSelf: "center",
      margin: "auto 0 1rem",
      opacity: "0.5"
    }
  }));
