import { IRootStoreModel } from "./RootStore";
import _ from "lodash";
import {
  Action,
  action,
  actionOn,
  ActionOn,
  debug,
  Computed,
  computed,
  Thunk,
  thunk,
  memo,
} from "easy-peasy";
import { t } from "ttag";
import coolremoteSDK from "coolremote-sdk";
// import alertSeverities from '../constants/alertSeverities';

export interface IAlert {
  id: string;
  type: string;
  eventTime: number;
  site: string;
  trigger: string | null;
  isRead: boolean;
  data: any;
  initiator: string | null;
  clearTime?: number;
  clearReason?: number;
  shortId: string;
}

export interface IAlertMap {
  [key: string]: IAlert;
}

export interface IAlertsModel {
  allAlerts: IAlertMap;
  allOpenAlerts: IAlertMap;

  initialize: Action<IAlertsModel, any>;
  onInitialized: ActionOn<IAlertsModel, IRootStoreModel>;
  fetchUpdatedAlert: Thunk<IAlertsModel, { id: string }>;
  getAlertsPaginated: Thunk<
    IAlertsModel,
    {
      filter: string;
      id: string;
      page: number;
      rowsPerPage: number;
      startDate: any;
      endDate: any;
    }
  >;
  _storeFetchUpdatedAlert: Action<
    IAlertsModel,
    { alertId: string; alert: IAlert }
  >;
  isResolved: Computed<IAlertsModel, (id: string) => boolean>;
  timeResolved: Computed<IAlertsModel, (id: string) => number | undefined>;
  deleteAlert: Thunk<IAlertsModel, string>;

  // getAlertParsedData: Computed<
  //   IAlertsModel,
  //   (id: string) => any,
  //   IRootStoreModel
  // >;
  getAlertSeverity: Computed<
    IAlertsModel,
    (id: string) => any,
    IRootStoreModel
  >;
  getAlertFeildsNamesAndIds: Computed<IAlertsModel, any>;

  // getAlertsByFilter: Computed<
  //   IAlertsModel,
  //   (
  //     filter: {
  //       customerId: string | null | undefined;
  //       siteId: string | null | undefined;
  //       systemId: string | null | undefined;
  //       unitId: string | null | undefined;
  //     },
  //     isResolved: boolean
  //   ) => IAlert[],
  //   IRootStoreModel
  // >;
  // getAlertItemContainerIds: Computed<
  //   IAlertsModel,
  //   (
  //     id: string
  //   ) => {
  //     customerId: string | null;
  //     siteId: string | null;
  //     systemId: string | null;
  //     unitId: string | null;
  //     deviceId: string | null;
  //   },
  //   IRootStoreModel
  // >;
  _storeDeleteAlert: Action<IAlertsModel, string>;
  sendAlertEmail: Thunk<IAlertsModel, { id: string; emails: string[] }>;
  getAlertsByFilters: Thunk<IAlertsModel, { startTime?: any; endTime?: any, status?: any, type?:any }>;
  updateAlert: Thunk<IAlertsModel, { id: string; data: any }>;
  setAcknowledgedEvent: Thunk<IAlertsModel, { id: string; data: any }>;

  
}

export const alertsModel: IAlertsModel = {
  allAlerts: {},
  allOpenAlerts: {},

  initialize: action((state, payload) => {
    const newAlerts: IAlertMap = _(Object.values(payload))
      .map((alert: any) => {
        const newAlert: IAlert = { ...alert };

        return newAlert;
      })
      .keyBy("id")
      .value();
    state.allAlerts = newAlerts;
  }),

  onInitialized: actionOn(
    (actions, storeActions) => [actions.initialize],
    (state, target) => {}
  ),
  sendAlertEmail: thunk((actions, payload) => {
    return coolremoteSDK.Services.sendEventEmail(
      { mailList: payload.emails },
      payload.id
    );
  }),
  setAcknowledgedEvent: thunk(async (actions, payload) => {
    const { id, data } = payload;
    return coolremoteSDK.Event.setAcknowledgedEvent(id, { value: data });
  }),
  _storeDeleteAlert: action((state, payload) => {
    if (!state.allOpenAlerts[payload]) {
      return;
    }
    const { [payload]: _, ...otherAlerts } = state.allOpenAlerts;

    state.allOpenAlerts = otherAlerts;
  }),
  getAlertsByFilters: thunk(async (actions, payload: any = "") => {
    const alerts = coolremoteSDK.Event.getEvents(payload);
    return alerts;
  }),
  updateAlert: thunk(async (actions, payload) => {
    const { id, data } = payload;
    return coolremoteSDK.Event.update(id, data);
  }),
  deleteAlert: thunk(async (actions, payload) => {
    await coolremoteSDK.Event.delete(payload);

    actions._storeDeleteAlert(payload);
  }),
  getAlertsPaginated: thunk(async (actions, payload) => {
    // let res = await coolremoteSDK.Alert.getAlertsPaginated(payload.id
    //   //   ,
    //   //   {
    //   //   filter: payload.filter,
    //   //   page: payload.page,
    //   //   rowsPerPage: payload.rowsPerPage,
    //   //   startDate: payload.startDate,
    //   //   endDate: payload.endDate
    //   // }
    // );
    // const alerts = res.retAlertObj;
    // const length = res.length;
    // const data = { alerts, length };
    // return data;
    return {};
  }),
  getAlertFeildsNamesAndIds: computed(
    [(state, storeState: any) => storeState.sites.allSites,
    (state, storeState: any) => storeState.units.allUnits,
    (state, storeState: any) => storeState.devices.allDevices,
    (state, storeState: any) => storeState.systems.allSystems,
    (state, storeState: any) => storeState.types.eventTypes],
    (allSites, allUnits, allDevices, allSystems, eventTypes) => (alert: any) => {
      return mapAlertFields(allSites, allDevices, allSystems, allUnits, eventTypes, alert);

    }),
  fetchUpdatedAlert: thunk(async (actions, payload) => {
    // const alert = await coolremoteSDK.Alert.fetch(payload.id);
    //
    // actions._storeFetchUpdatedAlert({ alertId: payload.id, alert });
  }),

  _storeFetchUpdatedAlert: action((state, payload) => {
    state.allAlerts[payload.alertId] = payload.alert;
  }),

  isResolved: computed([state => state.allAlerts], allAlerts => id => {
    if (_.isUndefined(allAlerts[id]) || !_.isUndefined(allAlerts[id].clearTime))
      return true;
    else return false;
  }),

  timeResolved: computed([state => state.allAlerts], allAlerts => id => {
    if (_.isUndefined(allAlerts[id])) return undefined;
    else return allAlerts[id].clearTime;
  }),

  getAlertSeverity: computed(
    [
      state => state.allAlerts,
      (state, storeState) => storeState.sites.allSites,
      (state, storeState) => storeState.devices.allDevices,
      (state, storeState) => storeState.units.allUnits,
    ]
    // (allAlerts, allSites, allDevices, allUnits) =>
    //   memo(id => {
    //     const alert = allAlerts[id];
    //     const siteDevices = Object.values(allDevices).filter(device => device.site === alert.site);
    //     const siteUnits = Object.values(allUnits).filter(unit =>
    //       _.includes(siteDevices, allDevices[unit.device])
    //     );
    //     // console.log(alert.data.trigger.template);
    //     if (alert.data.trigger.template.indexOf('UNIT_ERROR') !== -1) {
    //       if (!_.has(alert, 'data.eventData.unitId')) {
    //         return null;
    //       }
    //       // Not efficient. Sorry, no time :)
    //       const erroredUnit = siteUnits.filter(
    //         unit => unit.internalId === alert.data.eventData.unitId
    //       )[0];
    //       if (erroredUnit && erroredUnit.type === 2) {
    //         return alertSeverities.SYSTEM_ERROR;
    //       }
    //       if (erroredUnit && erroredUnit.type === 1) {
    //         return alertSeverities.INDOOR_ERROR;
    //     }
    //   } else if (alert.data.trigger.template.indexOf('UNIT_LIMITS') !== -1) {
    //     return alertSeverities.ANOMALIES;
    //   } else if (alert.data.trigger.template.indexOf('UNIT_EXCEEDED') !== -1) {
    //     return alertSeverities.ANOMALIES;
    //   } else if (alert.data.type === 'ISTAT_BREACH' || alert.data.type === 'OSTAT_BREACH') {
    //     return alertSeverities.ANOMALIES;
    //   } else if (alert.data.type === 'ISTAT_EQUALS' || alert.data.type === 'OSTAT_EQUALS') {
    //     return alertSeverities.ANOMALIES;
    //   } else if (
    //     alert.data.type === 'ISTAT_THRESHOLD_EXCEEDED' ||
    //     alert.data.type === 'OSTAT_THRESHOLD_EXCEEDED'
    //   ) {
    //     return alertSeverities.ANOMALIES;
    //   } else if (alert.data.trigger.template.indexOf('UNIT_CLEAN_FILTER') !== -1) {
    //     return alertSeverities.MAINTENANCE;
    //   } else if (alert.data.trigger.template.indexOf('SCHEDULED_EVENT') !== -1) {
    //     return alertSeverities.MAINTENANCE;
    //   } else if (alert.data.trigger.template.indexOf('DEVICE_DISCONNECT') !== -1) {
    //     return alertSeverities.NOT_CONNECTED;
    //   }

    //   return alertSeverities.ALL_IS_WELL;
    // }, 100) // 10000
  ),

  // getAlertParsedData: computed(
  //   [
  //     state => state.allAlerts,
  //     (state, storeState) => storeState.sites.allSites,
  //     (state, storeState) => storeState.devices.getDeviceNameBySerial,
  //     (state, storeState) =>
  //       storeState.users.conditionalConvertValueToFahrenheit,
  //     (state, storeState) =>
  //       storeState.users.conditionalConvertThresholdToFahrenheit,
  //     (state, storeState) => storeState.users.getTemperatureScaleDisplay,
  //     (state, storeState) => storeState.serviceParams,
  //     (state, storeState) => storeState.triggerTemplates.allTriggerTemplates,
  //     (state, storeState) => storeState.triggerTemplates.myTriggerTemplates
  //   ],
  //   (
  //     allAlerts,
  //     allSites,
  //     getDeviceNameBySerial,
  //     conditionalConvertValueToFahrenheit,
  //     conditionalConvertThresholdToFahrenheit,
  //     getTemperatureScaleDisplay,
  //     serviceParams,
  //     allTriggerTemplates,
  //     myTriggerTemplates
  //   ) => id => {
  //     const alert = allAlerts[id];
  //     let alertParsedData = {
  //       isError: false,
  //       typeText: "",
  //       description: ""
  //     };
  //     if (alert) {
  //       // const site = allSites[alert.site];
  //       // Special handling for trigger-template originated alerts.
  //       if (alert.data.trigger.triggerTemplateId) {
  //         let foundTriggerTemplate = null;

  //         if (myTriggerTemplates[alert.data.trigger.triggerTemplateId]) {
  //           foundTriggerTemplate =
  //             myTriggerTemplates[alert.data.trigger.triggerTemplateId];
  //         } else if (
  //           allTriggerTemplates[alert.data.trigger.triggerTemplateId]
  //         ) {
  //           foundTriggerTemplate =
  //             allTriggerTemplates[alert.data.trigger.triggerTemplateId];
  //         }

  //         if (foundTriggerTemplate) {
  //           alertParsedData = {
  //             isError: false,
  //             typeText: foundTriggerTemplate.name,
  //             description: foundTriggerTemplate.description
  //               ? foundTriggerTemplate.description
  //               : ""
  //           };
  //         }

  //         return alertParsedData;
  //       }

  //       const durationAppendix =
  //         alert.data.trigger.templateData &&
  //         alert.data.trigger.templateData.duration
  //           ? alert.data.trigger.templateData.duration === 1
  //             ? ` for ${alert.data.trigger.templateData.duration} minute`
  //             : ` for ${alert.data.trigger.templateData.duration} minutes`
  //           : "";

  //       if (alert.data.trigger.template.indexOf("UNIT_ERROR") !== -1) {
  //         alertParsedData = {
  //           isError: true,
  //           typeText: t`Unit error`,
  //           description: t`${alert.data.eventData.error.trim()} Error status`
  //         };
  //       } else if (alert.data.trigger.template.indexOf("UNIT_LIMITS") !== -1) {
  //         const ambientTemperature = alert.data.metaData.ambientTemperature;
  //         const temperatureScale = alert.data.metaData.temperatureScale;
  //         const setpoint = alert.data.metaData.setpoint;
  //         alertParsedData = {
  //           isError: false,
  //           typeText: t`Safety limits exceeded`,
  //           description: t`Current Temp: ${conditionalConvertValueToFahrenheit(
  //             ambientTemperature,
  //             temperatureScale
  //           )}${getTemperatureScaleDisplay()}, Setpoint: ${conditionalConvertValueToFahrenheit(
  //             setpoint,
  //             temperatureScale
  //           )}${getTemperatureScaleDisplay()}`
  //         };
  //       } else if (
  //         alert.data.trigger.template.indexOf("UNIT_EXCEEDED") !== -1
  //       ) {
  //         const ambientTemperature = alert.data.metaData.ambientTemperature;
  //         const temperatureScale = alert.data.metaData.temperatureScale;
  //         const setpoint = alert.data.metaData.setpoint;
  //         const setThreshold = alert.data.metaData.setThreshold;

  //         alertParsedData = {
  //           isError: false,
  //           typeText: t`Threshold exceeded`,
  //           description: t`Current Temp: ${conditionalConvertValueToFahrenheit(
  //             ambientTemperature,
  //             temperatureScale
  //           )}${getTemperatureScaleDisplay()}, Setpoint: ${conditionalConvertValueToFahrenheit(
  //             setpoint,
  //             temperatureScale
  //           )}${getTemperatureScaleDisplay()}, Alert threshold: +-${conditionalConvertThresholdToFahrenheit(
  //             setThreshold,
  //             temperatureScale
  //           )}${getTemperatureScaleDisplay()}`
  //         };
  //       } else if (
  //         alert.data.trigger.template.indexOf("UNIT_CLEAN_FILTER") !== -1
  //       ) {
  //         alertParsedData = {
  //           isError: false,
  //           typeText: t`Clean filter`,
  //           description: t`Clean filter`
  //         };
  //       } else if (
  //         alert.data.trigger.template.indexOf("SCHEDULED_EVENT") !== -1
  //       ) {
  //         alertParsedData = {
  //           isError: false,
  //           typeText: t`Scheduled maintenance`,
  //           description: t`Your scheduled maintenance arrived`
  //         };
  //       } else if (
  //         alert.data.trigger.template.indexOf("DEVICE_DISCONNECT") !== -1
  //       ) {
  //         alertParsedData = {
  //           isError: false,
  //           typeText: t`Device disconnected`,
  //           description: t`Can't connect with device: ${getDeviceNameBySerial(
  //             alert.data.eventData.deviceId
  //           )}`
  //         };
  //       } else if (
  //         alert.data.type === "ISTAT_BREACH" ||
  //         alert.data.type === "OSTAT_BREACH"
  //       ) {
  //         const alertingCode = alert.data.trigger.templateData.code;
  //         const isAlertingCodeTemperature =
  //           serviceParams[alertingCode] &&
  //           serviceParams[alertingCode].data_unit_of_measurement === "°C"
  //             ? true
  //             : false;
  //         //const alertingCodeValue = alert.data.eventData.metrics[alertingCode];
  //         const alertingCodeValueBiggerThan =
  //           alert.data.trigger.templateData.biggerThan;
  //         const alertingCodeValueSmallerThan =
  //           alert.data.trigger.templateData.SmallerThan;

  //         const alertingCodeText = serviceParams[alertingCode]
  //           ? serviceParams[alertingCode].title
  //           : "";

  //         const alertingCodeValueDisplayBiggerThan = _.isNil(
  //           alertingCodeValueBiggerThan
  //         )
  //           ? ""
  //           : isAlertingCodeTemperature
  //           ? `${conditionalConvertValueToFahrenheit(
  //               alertingCodeValueBiggerThan,
  //               "°C",
  //               0
  //             )} ${getTemperatureScaleDisplay()}`
  //           : alertingCodeValueBiggerThan;
  //         const alertingCodeValueDisplaySmallerThan = _.isNil(
  //           alertingCodeValueSmallerThan
  //         )
  //           ? ""
  //           : isAlertingCodeTemperature
  //           ? `${conditionalConvertValueToFahrenheit(
  //               alertingCodeValueSmallerThan,
  //               "°C",
  //               0
  //             )} ${getTemperatureScaleDisplay()}`
  //           : alertingCodeValueSmallerThan;

  //         const [valueText, valueDescription] =
  //           !_.isNil(alert.data.trigger.templateData.biggerThan) &&
  //           !_.isNil(alert.data.trigger.templateData.smallerThan)
  //             ? [
  //                 t`System Parameter < Value1 OR system Parameter > Value2`,
  //                 t`${alertingCodeText} is outside predefined range ( ${alertingCodeValueDisplaySmallerThan}-${alertingCodeValueDisplayBiggerThan})` +
  //                   durationAppendix
  //               ]
  //             : !_.isNil(alert.data.trigger.templateData.biggerThan)
  //             ? [
  //                 t`System Parameter > Value`,
  //                 t`${alertingCodeText} is above ${alertingCodeValueDisplayBiggerThan}` +
  //                   durationAppendix
  //               ]
  //             : !_.isNil(alert.data.trigger.templateData.smallerThan)
  //             ? [
  //                 t`System Parameter < Value`,
  //                 t`${alertingCodeText} is below ${alertingCodeValueDisplaySmallerThan}` +
  //                   durationAppendix
  //               ]
  //             : ["", ""];

  //         alertParsedData = {
  //           isError: false,
  //           typeText: valueText,
  //           description: valueDescription
  //         };
  //       } else if (
  //         alert.data.type === "ISTAT_EQUALS" ||
  //         alert.data.type === "OSTAT_EQUALS"
  //       ) {
  //         // Yes! I know it's a copy paste (almost) of the above code but it will probably change.
  //         const alertingCode = alert.data.trigger.templateData.code;
  //         const isAlertingCodeTemperature =
  //           serviceParams[alertingCode] &&
  //           serviceParams[alertingCode].data_unit_of_measurement === "°C"
  //             ? true
  //             : false;
  //         //const alertingCodeValue = alert.data.eventData.metrics[alertingCode];
  //         const alertingCodeValue = alert.data.trigger.templateData.value;

  //         const alertingCodeText = serviceParams[alertingCode]
  //           ? serviceParams[alertingCode].title
  //           : "";
  //         const alertingCodeValueDisplay = _.isNil(alertingCodeValue)
  //           ? ""
  //           : isAlertingCodeTemperature
  //           ? `${conditionalConvertValueToFahrenheit(
  //               alertingCodeValue,
  //               "°C",
  //               0
  //             )} ${getTemperatureScaleDisplay()}`
  //           : alertingCodeValue;
  //         const [valueText, valueDescription] = [
  //           t`System Parameter = Value`,
  //           t`${alertingCodeText} equals ${alertingCodeValueDisplay}` +
  //             durationAppendix
  //         ];

  //         alertParsedData = {
  //           isError: false,
  //           typeText: valueText,
  //           description: valueDescription
  //         };
  //       } else if (
  //         alert.data.type === "ISTAT_THRESHOLD_EXCEEDED" ||
  //         alert.data.type === "OSTAT_THRESHOLD_EXCEEDED"
  //       ) {
  //         const alertingCode1 = alert.data.trigger.templateData.value.code1;
  //         const alertingCode2 = alert.data.trigger.templateData.value.code2;
  //         const isAlertingCode1Temperature =
  //           serviceParams[alertingCode1] &&
  //           serviceParams[alertingCode1].data_unit_of_measurement === "°C"
  //             ? true
  //             : false;
  //         const isAlertingCode2Temperature =
  //           serviceParams[alertingCode2] &&
  //           serviceParams[alertingCode2].data_unit_of_measurement === "°C"
  //             ? true
  //             : false;
  //         //const alertingCode1Value = alert.data.eventData.metrics[alertingCode1];
  //         //const alertingCode2Value = alert.data.eventData.metrics[alertingCode2];
  //         const alertingCodeValue =
  //           alert.data.trigger.templateData.value.threshold;
  //         const alertingCodeValueDisplay = _.isNil(alertingCodeValue)
  //           ? ""
  //           : isAlertingCode1Temperature && isAlertingCode2Temperature
  //           ? `${conditionalConvertThresholdToFahrenheit(
  //               alertingCodeValue,
  //               "°C",
  //               0
  //             )} ${getTemperatureScaleDisplay()}`
  //           : alertingCodeValue;

  //         const alertingCode1Text = serviceParams[alertingCode1]
  //           ? serviceParams[alertingCode1].title
  //           : "";
  //         const alertingCode2Text = serviceParams[alertingCode2]
  //           ? serviceParams[alertingCode2].title
  //           : "";
  //         // Why Math.round the diff?
  //         // Run node in your favorite shell. Write 76.8 - 77.7. Enjoy :)
  //         // const diffDisplay =
  //         //   _.isNil(alertingCode1Value) || _.isNil(alertingCode2Value)
  //         //     ? ''
  //         //     : isAlertingCode1Temperature && isAlertingCode2Temperature
  //         //     ? `${Math.round(Math.abs(conditionalConvertValueToFahrenheit(alertingCode1Value, '°C', 0) - conditionalConvertValueToFahrenheit(alertingCode2Value, '°C', 0)) * 10) / 10} ${getTemperatureScaleDisplay()}`
  //         //     : Math.round(
  //         //         Math.abs(alertingCode1Value - alertingCode2Value) * 10
  //         //       ) / 10;
  //         const [valueText, valueDescription] = [
  //           t`Difference between system-Parameter-1 and system-Parameter-2 > Value`,
  //           t`Delta between ${alertingCode1Text} and ${alertingCode2Text} is above ${alertingCodeValueDisplay}` +
  //             durationAppendix
  //         ];

  //         alertParsedData = {
  //           isError: false,
  //           typeText: valueText,
  //           description: valueDescription
  //         };
  //       }
  //       return alertParsedData;
  //     } else {
  //       return null;
  //     }
  //   }
  // ),

  // getAlertsByFilter: computed(
  //   [
  //     state => state.allAlerts,
  //     (state, storeState) => storeState.sites.allSites,
  //     (state, storeState) => storeState.units.allUnits,
  //     (state, storeState) => storeState.units.getUnitByInternalOrProId,
  //     (state, storeState) => storeState.devices.getDeviceBySerial
  //   ],
  //   (
  //     allAlerts,
  //     allSites,
  //     allUnits,
  //     getUnitByInternalOrProId,
  //     getDeviceBySerial
  //   ) => (filter, isResolved) => {
  //     return _.filter(_.values(allAlerts), (alert: IAlert) => {
  //       // First of all, filter out resolved alerts if we don't want them.
  //       if (!isResolved) {
  //         if (!_.isUndefined(alert.clearTime)) {
  //           return false;
  //         }
  //       }

  //       // Different flows for device alerts and unit alerts.

  //       // If this is a device alert.
  //       if (
  //         _.has(alert, "data.type") &&
  //         alert.data.type === "DEVICE_DISCONNECTED"
  //       ) {
  //         // No device ID? Filter out.
  //         if (!_.has(alert, "data.eventData.deviceId")) {
  //           return false;
  //         }

  //         const alertDevice = getDeviceBySerial(alert.data.eventData.deviceId);

  //         // Device doesn't exist? Filter out.
  //         if (!alertDevice) {
  //           return false;
  //         }

  //         // Check if the alert's device falls under the unit filter.
  //         if (filter.unitId) {
  //           const filterUnit = allUnits[filter.unitId];

  //           if (filterUnit && filterUnit.device === alertDevice.id) {
  //             return true;
  //           }

  //           return false;
  //         }

  //         // Check if the alert's device falls under the system filter.
  //         if (filter.systemId) {
  //           return _.includes(alertDevice.systems, filter.systemId);
  //         }

  //         // Check if the alert's device falls under the site filter.
  //         if (filter.siteId) {
  //           return alertDevice.site === filter.siteId;
  //         }

  //         // Check if the alert's device falls under the customer filter.
  //         if (filter.customerId) {
  //           const deviceSite = allSites[alertDevice.site];

  //           return deviceSite && deviceSite.customer === filter.customerId;
  //         }

  //         // No filter - pass.
  //         return true;
  //       }
  //       // If this is a unit alert.
  //       else {
  //         const alertInternalUnitId = alert.data.eventData?.unitId;
  //         let alertUnit: any = null;

  //         // Resolve unit id from the alert (if there is one).
  //         if (alertInternalUnitId) {
  //           alertUnit = getUnitByInternalOrProId(alertInternalUnitId);
  //         }

  //         // No unit? Filter out.
  //         if (!alertUnit) {
  //           return false;
  //         }

  //         // Check if the alert's unit falls under the unit filter.
  //         if (filter.unitId) {
  //           return alertUnit.id === filter.unitId;
  //         }

  //         // Check if the alert's unit falls under the system filter.
  //         if (filter.systemId) {
  //           return alertUnit.system && alertUnit.system === filter.systemId;
  //         }

  //         // Check if the alert's unit falls under the site filter.
  //         if (filter.siteId) {
  //           const filterSite = allSites[filter.siteId];

  //           if (
  //             filterSite &&
  //             _.includes(filterSite.devices, alertUnit.device)
  //           ) {
  //             return true;
  //           }

  //           return false;
  //         }

  //         // Check if the alert's unit falls under the customer filter.
  //         if (filter.customerId) {
  //           // Get unit's site.
  //           const unitSites = _.filter(_.values(allSites), site =>
  //             _.includes(site.devices, alertUnit.device)
  //           );

  //           // If the alert unit's site is somehow not found.
  //           if (_.isEmpty(unitSites)) {
  //             console.log(`Site not found for alert unit: [${alertUnit.id}]`);
  //             return false;
  //           }

  //           const unitSite = unitSites[0];

  //           return unitSite.customer === filter.customerId;
  //         }

  //         // No filter - pass.
  //         return true;
  //       }
  //     });
  //   }
  // ),

  // getAlertItemContainerIds: computed(
  //   [
  //     state => state.allAlerts,
  //     (state, storeState) => storeState.sites.allSites,
  //     (state, storeState) => storeState.units.getUnitByInternalOrProId,
  //     (state, storeState) => storeState.devices.getDeviceBySerial
  //   ],
  //   (
  //     allAlerts,
  //     allSites,
  //     getUnitByInternalOrProId,
  //     getDeviceBySerial
  //   ) => id => {
  //     const alert: IAlert | undefined = allAlerts[id];
  //     const returnValue: any = {
  //       customerId: null,
  //       siteId: null,
  //       systemId: null,
  //       unitId: null,
  //       deviceId: null
  //     };

  //     if (!alert) {
  //       return returnValue;
  //     }

  //     // Different flows for device alerts and unit alerts.

  //     // If this is a device alert.
  //     if (
  //       _.has(alert, "data.type") &&
  //       alert.data.type === "DEVICE_DISCONNECTED"
  //     ) {
  //       if (!_.has(alert, "data.eventData.deviceId")) {
  //         return returnValue;
  //       }

  //       const alertDevice = getDeviceBySerial(alert.data.eventData.deviceId);

  //       if (!alertDevice) {
  //         return returnValue;
  //       }

  //       const deviceSite = allSites[alertDevice.site];

  //       if (!deviceSite) {
  //         return returnValue;
  //       }
  //       returnValue.deviceId = alertDevice.id;
  //       returnValue.siteId = deviceSite.id;
  //       returnValue.customerId = deviceSite.customer;

  //       return returnValue;
  //     }
  //     // If this is a unit alert.
  //     else {
  //       const alertInternalUnitId = alert.data.eventData?.unitId;
  //       let alertUnit: any = null;

  //       // Resolve unit id from the alert (if there is one).
  //       if (alertInternalUnitId) {
  //         alertUnit = getUnitByInternalOrProId(alertInternalUnitId);
  //       }

  //       // No unit? Filter out.
  //       if (!alertUnit) {
  //         return returnValue;
  //       }

  //       returnValue.systemId = alertUnit.system;
  //       returnValue.unitId = alertUnit.id;

  //       // Get unit's site.
  //       const unitSites = _.filter(_.values(allSites), site =>
  //         _.includes(site.devices, alertUnit.device)
  //       );

  //       // If the alert unit's site is somehow not found.
  //       if (_.isEmpty(unitSites)) {
  //         console.log(`Site not found for alert unit: [${alertUnit.id}]`);
  //         return returnValue;
  //       }

  //       const unitSite = unitSites[0];

  //       returnValue.siteId = unitSite.id;
  //       returnValue.customerId = unitSite.customer;

  //       return returnValue;
  //     }
  //   }
  // )
};
const mapAlertFields = (sites: any, devices: any, systems: any, units: any, eventTypes: any, alert: any) => {
  const { type, resources = [], status } = alert;
  const fieldsObj: any = { eventNames: { siteName: "", deviceName: "", systemName: "", unitName: "" }, eventIds: { siteId: "", systemId: "", unitId: "", unitIds: [], systemIds: [] } };
  if (resources.length === 0) { return fieldsObj; }

  const { eventNames, eventIds } = fieldsObj;
  //device disconnected
  if (eventTypes.deviceDisconnected === type) {
    const device = devices[resources[0].id];
    eventNames.deviceName = device ? device.serial : (resources[0].name || "");
    eventNames.siteName = (device && device.site && sites[device.site].name) || "";
    eventIds.siteId = device ? device.site : "";
    if (status === 1)
    {eventNames.unitName = eventNames.deviceName; }
    return fieldsObj;
  }

  let siteId = "";
  let deviceName = "";
  for (let i in resources) {
    const { id = "" } = resources[i];
    const storeUnit: any = units[id];

    if (storeUnit) {
      let { site = "", system = "", device = "" } = storeUnit;
      if (storeUnit.type === 1 && storeUnit.serviceUnits.length){
      const serviceUnit = units[storeUnit.serviceUnits[0]];
      system = serviceUnit ? serviceUnit.system : "";
     }

      if (!siteId) {
        siteId = site;
      }

      if (!deviceName) {
        deviceName = devices[device]?.serial || "";
      }

      (system && !eventIds.systemIds.includes(system)) && eventIds.systemIds.push(system);
      eventIds.unitIds.push(id);
    }
  }

  if (_.isEmpty(eventIds.unitIds)) {
    return fieldsObj;
  }

  const unitId = eventIds.unitIds.length === 1 ? eventIds.unitIds[0] : "";
  const systemId = eventIds.systemIds.length === 1 ? eventIds.systemIds[0] : "";
  eventNames.unitName = unitId ? units[unitId]?.name : "multiple";
  eventNames.systemName = systemId ? systems[systemId]?.name : _.isEmpty(eventIds.systemIds) ? "" : "multiple";
  eventIds.unitId = unitId;
  eventIds.systemId = systemId;
  eventNames.siteName = sites[siteId]?.name || "";
  eventNames.deviceName = deviceName;
  eventIds.siteId = siteId;

  return fieldsObj;

};