import {
  Button,
  InputBase,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Typography
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import clsx from "clsx";
import { Formik } from "formik";
import _ from "lodash";
// @ts-ignore
import MuiPhoneNumber from "material-ui-phone-number";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import { ICustomerMap } from "../../models/CustomerModel";
import { UserSchema, UserSchemaNew } from "../../models/UsersModel";
import SimpleModal from "../../widgets/SimpleModal/SimpleModal";
import styles from "./OneUser.module.css";

interface IProps {
  onSave: (data: any, id: string) => void;
  onClose: () => void;
  title: string;
  mainButtonLabel: string;
  withPasswordField?: boolean;
  customers: ICustomerMap;
  user?: any;
  customerIdLockedto?: any;
  errorText?: string;
  languages?: any;
  selectedCustomer?: string;
}

const timeFormatI = [
    {
        name: "iso8601_24Hours",
        text: "24 hours",
        value: 0
    },
    {
        name: "standard12Hours",
        text: "12 hours",
        value: 1
    }

];
const dateFormatI = [
    {
        name: "startWithDay",
        text: "DD/MM/YY",
        value: 0
    },
    {
        name: "startWithMonth",
        text: "MM/DD/YY",
        value: 1
    }
];

export default function OneUser(props: IProps) {
  const {
    user,
    customers,
    mainButtonLabel,
    withPasswordField,
    title,
    onClose,
    customerIdLockedto,
    onSave,
    errorText,
    languages,
    selectedCustomer = ""
  } = props;

  const {
    firstName = "",
    lastName = "",
    username = "",
    email = "",
    phone = "",
    customer = "",
    timeFormat = 0,
    dateFormat = 0,
    // language = "en",
    temperatureScale: tempScale = 1
  } = user || {};

  const [temperatureScale, setTemperatureScale] = useState(tempScale);
  const [customersOptions, setCustomersOptions] = useState<any>([]);
  const selectedCustomerOption = {value: selectedCustomer, label: customers[selectedCustomer]?.name};

  const getCustomers = () => {
      const menuOptions: any[] = [];

      _.map(customers, (customer) => {
       menuOptions.push({value: customer.id, label: customer.name});
      });

      return _.sortBy(menuOptions, [(option) => option.label?.toUpperCase()], ["asc"]);
    };

  useEffect(() => {
     setCustomersOptions(getCustomers());
   }, [customers]);

  const getLanguages = () => {
    const menuOptions: any[] = [];

    _.map(_.keys(languages), (lan) => {
      menuOptions.push(
        <MenuItem
          value={lan}
          key={lan}
          className={styles.SelectorItem}
        >
          {languages[lan]}
        </MenuItem>
      );
    });

    return menuOptions;
  };

  const CustomizedTextField = ({
    label,
    labelClass,
    handleChange,
    errors,
    touched,
    name,
    helperText,
    inputProps,
    values
  }: any) => (
      <InputLabel className={clsx(styles.fieldLabel, labelClass)}>
        {label}
        <InputBase
          className={styles.fieldStyle}
          onChange={handleChange}
          inputProps={inputProps}
          value={values[name]}
          name={name}
        />
        <Typography
          className={errors[name] && touched[name] ? styles.error : styles.helper}
        >
          {errors[name] && touched[name] ? errors[name] : helperText}
        </Typography>
      </InputLabel>
    );

  const getPhoneNumber = (value: string) => {
    let newValue = value.replace(/[^a-zA-Z0-9 ]/g, "");
    newValue = newValue.replace(/\s/g, "");
    return newValue;
  };

  const onSubmit = (values: any) => {
    const permissions = {
      customers: {
        [values.customer?.value]: user
          ? Object.values(user.permissions.customers)[0]
          : "customerAdmin"
      }
    };

    const data = {
      ...values,
      temperatureScale,
      permissions,
      phone: values.phone ?  getPhoneNumber(values.phone) : undefined,
      customer: undefined,
      password: withPasswordField ? values.password : undefined
    };

    user ? onSave(user.id, data) : onSave(values.customer?.value, data);
  };

  return (
    <SimpleModal showModal={true} title={title} hideBtns>
      <Formik
        initialValues={{
          firstName,
          lastName,
          email,
          username,
          phone,
          customer:  selectedCustomer ? selectedCustomerOption : customerIdLockedto ? customerIdLockedto : customer,
          password: "",
          timeFormat,
          dateFormat
          // language: language

        }}
        enableReinitialize={true}
        onSubmit={onSubmit}
        validationSchema={user ? UserSchema : UserSchemaNew}
      >
        {(props) => {
          const { values, touched, errors, handleChange, handleSubmit, setFieldValue } = props;
          return (
            <form className={styles.fieldsContainer} onSubmit={handleSubmit}>
              <div className={styles.twoFieldsRow}>
                <CustomizedTextField
                  label="First name"
                  name="firstName"
                  helperText="up to 20 characters"
                  inputProps={{ maxLength: 20 }}
                  labelClass={styles.smallField}
                  {...props}
                />
                <CustomizedTextField
                  labelClass={styles.smallField}
                  label="Last name"
                  name="lastName"
                  helperText="up to 20 characters"
                  inputProps={{ maxLength: 20 }}
                  {...props}
                />
              </div>
              <CustomizedTextField
                label="Email Address"
                name="email"
                helperText="valid email address"
                {...props}
              />
              <CustomizedTextField
                label="Username"
                name="username"
                helperText="6-20 characters, must be unique"
                {...props}
              />
              {withPasswordField && (
                <CustomizedTextField
                  label="Password"
                  name="password"
                  helperText="8+ characters, 1 capital, 1 number, 1 special characters"
                  {...props}
                />
              )}

              <InputLabel className={styles.fieldLabel}>
                {t`Phone number`}
                <MuiPhoneNumber
                  value={values.phone}
                  name="phone"
                  inputClass={clsx(styles.fieldStyle, styles.phoneStyle)}
                  defaultCountry={"us"}
                  onChange={(e: any) => setFieldValue("phone", e)}
                  InputProps={{
                    disableUnderline: true,
                    classes: { underline: styles.error }
                  }}
                />
                <Typography
                  className={
                    errors.phone && touched.phone ? styles.error : styles.helper
                  }
                >
                  {errors.phone && touched.phone
                    ? errors.phone
                    : "International format telephone number"}
                </Typography>
              </InputLabel>
              <div className={styles.twoFieldsRow}>
                <InputLabel
                  className={clsx(styles.fieldLabel, styles.smallField)}
                >
                  {t`Connect to customer`}
                  <Autocomplete
                    options={!selectedCustomer ? customersOptions : selectedCustomerOption}
                    id="customer-list"
                    value={ !!selectedCustomer ? selectedCustomerOption :
                      !!customerIdLockedto
                      ? customerIdLockedto
                      : values.customer
                    }
                    defaultValue={""}
                    onChange={(e: any, value: any) => setFieldValue("customer", value)}
                    disabled={!!customerIdLockedto || !!selectedCustomer}
                    getOptionLabel={(option: any) => option.label || ""}
                    renderOption={(option) => (<>{option.label}</>)}
                    renderInput={(params) => (
                      <TextField
                      name="customer"
                      variant="filled"
                      {...params}
                      inputProps={{...params.inputProps, autoComplete: "new-password"}}
                      InputProps={{...params.InputProps, disableUnderline: true}}
                      className={styles.autoCompleteStyle}
                      />
                    )}
                    />

                  <Typography
                    className={
                      errors.customer && touched.customer
                        ? styles.error
                        : styles.helper
                    }
                  >
                    {errors.customer && touched.customer
                      ? errors.customer
                      : "Select to which customer to connect"}
                  </Typography>
                </InputLabel>
                <InputLabel
                  className={clsx(styles.fieldLabel, styles.tempField)}
                >
                  {t`Temperature scale`}
                  <div>
                    ℃
                    <Switch
                      color="default"
                      onChange={() => {
                        setTemperatureScale(temperatureScale !== 2 ? 2 : 1);
                      }}
                      checked={temperatureScale === 2}
                      name="temperatureScale"
                    />
                    ℉
                  </div>
                </InputLabel>
              </div>

                <div className={styles.formatContainer}>
                    <InputLabel
                        className={clsx(styles.fieldLabel, styles.smallField)}
                    >
                        {t`Time Format`}
                        <Select
                            displayEmpty
                            disabled={false}
                            value={values.timeFormat ? values.timeFormat : 0}

                            onChange={(event: any) => {
                                setFieldValue("timeFormat", event.target.value);
                            }}
                            // error={error}
                            variant="outlined"
                            disableUnderline
                            className={styles.formats}
                        >
                            {<MenuItem value={""} disabled style={{ display: "none" }}>{t`12 hours`}</MenuItem>}
                            {timeFormatI.map((formatObj: any) => {
                                    return <MenuItem value={formatObj.value} >{formatObj.text}</MenuItem>;
                                }
                            )}
                        </Select >
                    </InputLabel>
                </div>

                <div className={styles.formatContainer}>
                    <InputLabel
                        className={clsx(styles.fieldLabel, styles.smallField)}
                    >
                        {t`Date Format`}
                        <Select
                            displayEmpty
                            disabled={false}
                            value={values.dateFormat ? values.dateFormat : 0}
                            onChange={(event: any) => {
                                setFieldValue("dateFormat", event.target.value);
                            }}
                            // error={error}
                            variant="outlined"
                            disableUnderline
                            className={styles.formats}
                        >
                            {<MenuItem value={""} disabled style={{ display: "none" }}>DD/MM/YY</MenuItem>}
                            {dateFormatI.map((formatObj: any) => {
                                    return <MenuItem value={formatObj.value} >{formatObj.text}</MenuItem>;
                                }
                            )}

                        </Select >
                    </InputLabel>
                </div>

              {!!errorText && (
                <Typography className={styles.error}>{errorText}</Typography>
              )}
              <div className={styles.actionsContainer}>
                <Button
                  type="submit"
                  className={clsx(
                    styles.sharedButtonStyle,
                    styles.submitButton
                  )}
                >
                  {mainButtonLabel}
                </Button>
                <Button onClick={onClose} className={styles.sharedButtonStyle}>
                  {t`Cancel`}
                </Button>
              </div>
            </form>
          );
        }}
      </Formik>
    </SimpleModal>
  );
}
