import _ from "lodash";
import React, { useCallback, useEffect } from "react";
import { t } from "ttag";
import {
  Button,
  Menu,
  Typography,
  ListItem,
  List,
  ListItemText,
  ListItemSecondaryAction,
  TextField
} from "@material-ui/core";
import { Clear } from "@material-ui/icons";
import Checkbox from "../cool_widgets/CoolCheckbox";
import useStyles, { paperStyle } from "./lookup.style";

export default function Lookup(props: any) {
  const classes = useStyles();
  const {
    onApply,
    lookupAnchor,
    filtersList = [],
    onClose,
    appliedFilters = [],
    replacementContent = false,
    clearAllFilters,
    hasFilters
  } = props;

  const { data, property } = replacementContent;
  const [selectedFilters, setSelectedFilters] = React.useState<string[]>(
    appliedFilters
  );
  const [filteredFilters, setFilteredFilters] = React.useState<string[]>(
    filtersList
  );
  const [searchValue, handleSearchValue] = React.useState<string>("")
  const [selectAll, setSelectAll] = React.useState<boolean>(selectedFilters.length === 0);


  // reset values on kill component
  useEffect(() => {
    return () => {
      setFilteredFilters([]);
      setSelectedFilters([]);
      handleSearchValue("")
    }
  }, [])


  const handleFilters = (value: string) => {
    if (selectedFilters.includes(value)) {
       const index = selectedFilters.indexOf(value);
       selectedFilters.splice(index, 1);
       setSelectedFilters([...selectedFilters]);
     } else {
       setSelectedFilters([...selectedFilters, value]);
     }
     if (filtersList.length === selectedFilters.length) {
       setSelectAll(true);
       setSelectedFilters([]);
     } else {
       setSelectAll(false);
     }
  }

  const apply = () => {
    onApply &&
        onApply(selectAll || filtersList.length === selectedFilters.length ? [] : selectedFilters);
  };
  const filterBySearchValue = (value: string) => {
    const filteredItems = filtersList.filter((item: any) => {
      if (!item) {
        return null;
      }
      const stringSearchingValue = value.toLowerCase();

      if (item.toLowerCase().indexOf(stringSearchingValue) >= 0) {
        return item;
      }
      return null;
    });
    setFilteredFilters(filteredItems);
  };

  const handleSearchValueChange = (event: any) => {
    const {
      target: { value }
    } = event;
    handleSearchValue(value);
  };

  useEffect(() => {
    if (!searchValue) {
      setFilteredFilters(filtersList);
      return;
    }

    filterBySearchValue(searchValue);
  }, [filtersList, searchValue]);

  const handleAllFilters = () => {
      setSelectAll(!selectAll);
      if (selectAll) {
        setSelectedFilters([]);
      }
    };

  const clearFilters = () => {
    clearAllFilters();
    onClose();
  };
  const stopPropagation = (e: any) => {
    switch (e.key) {
      case "ArrowDown":
      case "ArrowUp":
      case "Home":
      case "End":
        break;
      default:
        e.stopPropagation();
    }
  };

  return (
    <Menu
      id="filters--menu"
      anchorEl={lookupAnchor}
      keepMounted
      open={true}
      onClose={onClose}
      className={classes.menuStyle}
      PaperProps={{
        style: paperStyle
      }}
    >
      <div className={classes.menuHeader}>
        <Typography>{t`Filters`}</Typography>
        <Clear onClick={onClose} />
      </div>
      <TextField
        placeholder="Search"
        variant="outlined"
        value={searchValue}
        onChange={handleSearchValueChange}
        onKeyDown={stopPropagation}
        className={classes.textField}
      />
      <List disablePadding={true} className={classes.optionsContainer}>
        {_.isEmpty(filteredFilters) && (
          <ListItem dense autoFocus divider key={"no data"}>
            <ListItemText id={"no-data"} primary={`No data to display`} />
          </ListItem>
        )}
        {!_.isEmpty(filteredFilters) && filteredFilters.length > 1 && (
          <ListItem dense autoFocus divider key={"all"}>
            <ListItemText id={"all"} primary={` All`} />
            <ListItemSecondaryAction>
              <Checkbox
                color="default"
                edge="end"
                onChange={handleAllFilters}
                checked={selectAll || filtersList.length === selectedFilters.length}
                indeterminate={!selectAll && filtersList.length !== selectedFilters.length}
                inputProps={{ "aria-labelledby": `toggle all` }}
              />
            </ListItemSecondaryAction>
          </ListItem>
        )}
        {filteredFilters.map((option: string, index: number) => (
          <ListItem
            dense
            autoFocus
            divider
            key={index}
            className={classes.optionStyle}
          >
            <ListItemText
              id={`${index}`}
              primary={
                replacementContent
                  ? (data[option] && data[option][property]) || ""
                  : option
              }
            />
            <ListItemSecondaryAction>
              <Checkbox
                color="default"
                edge="end"
                onClick={() => handleFilters(option)}
                checked={selectedFilters.includes(option) || selectAll}
                inputProps={{ "aria-labelledby": option }}
              />
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
      <Button
        className={classes.applyBtn}
        variant="contained"
        color="primary"
        onClick={apply}
        disabled={!filtersList.length || filtersList.length === 1}
      >{t`Apply`}</Button>

      {/* <Button
        variant="text"
        onClick={clearFilters}
        disabled={!hasFilters}
      >{t`Reset All Filters`}</Button> */}
    </Menu>
  );
}
