import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  selectionsContainer: {
    whiteSpace: "nowrap"
    // padding: '1rem',
    // alignItems: 'center'
  },
  selectUnitButton: {
    width: "10.4rem",
    height: 36,
    justifyContent: "space-between",
    margin: "1rem .5rem",
    padding: "0 .4rem 0 .5rem",
    textTransform: "none",
    backgroundColor: "rgba(255, 255, 255, 0.15)",
    color: theme.palette.common.white,
    background: theme.palette.primary.dark,
    "&:hover": {
      backgroundColor: theme.palette.primary.light
    }
  },
  selectUnitButton__text: {
    fontSize: "0.8125rem",
    fontWeight: "normal"
  },
  selectUnitButton__icon: {
    "&>svg": {
      fontSize: "1.5rem"
    }
  },
  selectDateRangeButton: {
    width: "30ch",
    height: 36,
    justifyContent: "space-between",
    margin: "1rem .5rem",
    padding: "0 .4rem 0 .5rem",
    textTransform: "none",
    backgroundColor: "rgba(255, 255, 255, 0.15)",
    color: theme.palette.common.white,
    background: theme.palette.primary.dark,
    "&:hover": {
      backgroundColor: theme.palette.primary.light
    }
  },
  selectDateRangeButton__text: {
    fontSize: "0.8125rem",
    fontWeight: "normal"
  },
  selectDateRangeButton__icon: {
    "&>svg": {
      fontSize: "1.5rem"
    }
  },
  searchIcon: {
    color: "#fff"
  },
  autoCompletePoper: {
    backgroundColor: theme.palette.primary.dark
  },
  autoCompleteItem: {
    backgroundColor: "rgba(0,0,0,0)",
    color: "#fff",
    "& li": {
      margin: "5px 10px"
    }
  },
  autoCompletePaper: {
    backgroundColor: "rgba(0,0,0,0)"
  },
  autoCompleteGroup: {
    backgroundColor: theme.palette.primary.dark, //"rgba(0,0,0,0)",
    color: "#fff",
    fontWeight: "bold",
    fontSize: "17px"
  },
  autoCompleteGroupUl: {
    backgroundColor: "#331e38"//theme.palette.primary.light
  },
  inputRoot: {
    color: "white",
    fontFamily: "Roboto",
    fontSize: "13px",
    width: "300px",
    display: "flex",
    flexDirection: "row",
    height: "36px",
    borderRadius: "4px",
    padding: "0 15px",
    backgroundColor: "rgba(170, 162, 170, 0.3)",
    maxWidth: "70%",
    marginRight: "40px"
  },
  datePicker: {
    input: {
      color: "white"
    }
  }

}));
export default useStyles;
