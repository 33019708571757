import { Admin as AdminSdk, Trap as TrapSdk } from "coolremote-sdk";
import { Thunk, thunk } from "easy-peasy";

export interface IAnomalyTemplatesModel {
  getTrapTemplates: Thunk<IAnomalyTemplatesModel>;
  addTrapTemplate: Thunk<IAnomalyTemplatesModel, any>;
  updateTrapTemplate: Thunk<
    IAnomalyTemplatesModel,
    { data: any; trapId: string }
  >;
  deleteTrapTemplate: Thunk<IAnomalyTemplatesModel, { id: string }>;
}

export const anomalyTemplatesModel: IAnomalyTemplatesModel = {
  getTrapTemplates: thunk(() => {
    return TrapSdk.getTrapTemplates();
  }),
  addTrapTemplate: thunk((actions, payload) => {
    return AdminSdk.createTrapTemplate(payload);
  }),
  updateTrapTemplate: thunk((actions, payload) => {
    return TrapSdk.updateTrapTemplate(payload.data, payload.trapId);
  }),
  deleteTrapTemplate: thunk((actions, payload) => {
    return TrapSdk.deleteTrapTemplate(payload.id);
  }),
};
