import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  actionIcon: {
    "&hover": {
      backgroundColor: "white"
    }
  },
  // title: {
  //   backgroundColor: "#f6f6f7",
  //   color: "#29132e",
  //   borderBottom: "1px solid rgba(0, 0, 0, 0.54)",
  //   padding: "4px 24px"
  // },
  titleContent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "1px solid #d5d2d5",
    padding: "16px 9px 16px 24px",
    color: "#29132e"
  },
  dialog: {
    // border: '1px solid  rgba(0, 0, 0, 0.54)',
    height: "15.625rem",
    borderRadius: "0.5rem",

    boxShadow: "0 2px 11px 0 rgba(0, 0, 0, 0.15)",
    border: " 1px solid #d5d2d5"
  },
  closeIcon: {
    width: "24px",
    height: "24px"
  },
  dialogContent: {
    margin: "1rem",
    padding: "1.25rem",
    paddingBottom: 0
  },
  DialogContentText: {
    fontFamily: "Roboto",
    color: "#545964"
  },
  noBtn: {
    width: "9rem",
    height: "2.625rem",
    border: "none"
    // marginRight: '1rem'
  },
  yesBtn: {
    marginRight: "17px"
  },
  dialogActions: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "1rem"
  },
  // btn: {
  //   padding: '1rem'
  // },
  hover: {},
  "MuiIconButton-root": {
    "&:hover": {
      backgroundColor: "inherit"
    }
  },
  "MuiDialogTitle-root": {
    padding: "4px 24px"
  },
  overWriteIcon: {
    paddingRight: "2px",
    paddingLeft: "3px",
    "&:hover": {
      borderRadius: "0"
    }
  },
  disabledIcon: {
    backgroundColor: "transparent",
    opacity: 0.5
  }
});

export default useStyles;
