import { types } from "@babel/core";
import { Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import clsx from "clsx";
import _ from "lodash";
import moment from "moment";
import React from "react";
import { useEffect, useState } from "react";
import { t } from "ttag";
import Button from "../../components/cool_widgets/Button";
import Delete from "../../components/Delete/Delete";
import SelectionsMenu from "../../components/Header/SelectionsMenu";
import LightTooltip from "../../components/Tooltip/LightTooltip";
import ErrorBox from "../../components/WarnningBox/ErrorBox";
import ArrowIcon from "../../icons/ArrowLong";
import { EditIcon } from "../../logos";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import sort from "../../utils/sortUtil";
import ServiceNavigationBar from "../../widgets/Menu/ServiceNavigationBar";
import AddEditSubscription from "./AddEditSubscription";
import useStyles from "./subscriptions.style";

export default function Subscriptions(props: any) {
    const classes = useStyles();
    const [error, setError] = useState<any>("");
    const [openAddSubscription, setOpenAddSubscription] = useState<boolean>(false);
    const [subscriptions, setSubscriptions] = useState<any>({});
    const [editSubscription, setEditSubscription] = useState<any>({});
    const getSitesByCustomer = useStoreActions((action) => action.sites.getSitesByCustomer);
    const sortedSubscriptions = !_.isEmpty(subscriptions) ? sort(Object.values(subscriptions), "name", "asc") : [];
    const getSubscriptions = useStoreActions((action) => action.subscriptions.getSubscriptions);
    const updateSubscription = useStoreActions((action) => action.subscriptions.updateSubscription);
    const createSubscription = useStoreActions((action) => action.subscriptions.createSubscriptionPlan);
    const dissociateSubscriptionFromSite = useStoreActions((action) => action.subscriptions.dissociateSubscriptionFromSite);
    const associateSubscriptionToSite = useStoreActions((action) => action.subscriptions.associateSubscriptionToSite);
    const updateSite = useStoreActions((actions) => actions.sites.updateSite);
    const currentDay = new Date();
    let aYearFromNow = new Date();
    aYearFromNow.setFullYear(currentDay.getFullYear() + 1);
    const deleteSubscription = useStoreActions((a) => a.subscriptions.deleteSubscription);
    const { dateFormat, timeFormat } = useStoreState((state) => state.users);
    const defaultDateFormat = dateFormat || "DD/MM/YYYY";
    const [refresh, setRefresh] = useState<any>(true);
    const selections = useStoreState((state) => state.selections.selections);
    const [allSites, setAllSites] = useState<any>({});
    const subscriptionTemplatesTypes = useStoreState((s) => s.types.subscriptionTemplatesTypes);

    useEffect(() => {
        if (!selections?.customerId) {
            return;
        }
        (async function() {
            if (selections.customerId || refresh) {
                const subscriptionsRes = await getSubscriptions({ customerId: selections.customerId });
                setSubscriptions(subscriptionsRes);
                setRefresh(false);
                if (selections.customerId){
                    const sitesRes = await getSitesByCustomer(selections.customerId);
                    setAllSites(sitesRes);
                }
            }
        })();
    }, [selections.customerId, refresh]);

    const closeDialog = () => {
        if (openAddSubscription) {
            setOpenAddSubscription(false);
        }
        setEditSubscription({});
    };
    const handleSave = async (subscriptionToSave: any) => {
        let allErrors: any = [];

        delete subscriptionToSave.serviceStatus;
        delete subscriptionToSave.managementStatus;
   
        if (_.isEmpty(editSubscription) && openAddSubscription) {
            await createSubscription({ customerId: selections.customerId, data: subscriptionToSave }).then(async (res: any) => {
            }).catch((err: any) => {
                console.log(err);
                setError(err);

            });
        }

        if (!_.isEmpty(editSubscription)) {
            await updateSubscription({ subscriptionId: editSubscription.id, updatedData: subscriptionToSave }).then(async (res: any) => {
            }).catch((err: any) => {
                console.log(err);
                setError(err);
            });
        }
        setRefresh(true);
        closeDialog();
    };

    const errorBox = error ? <ErrorBox error={error || ""} onClose={() => setError("")} onAccept={() => setError("")} /> : null;

    return (
        <ServiceNavigationBar
            {...props}
            filters={<SelectionsMenu hideSiteSelection hideUnitSelection hideSystemSelection />}
        >
            {!selections.customerId ?
                (<Grid container direction={"column"} className={classes.noContentContainer}>
                    <div className={classes.grayOval}>
                        <ArrowIcon className={classes.arrowIcon} />
                    </div>
                    <Typography >
                        {t`Please select a customer using the above filters.`}
                    </Typography>
                </Grid>) :

                <div className={classes.contentArea}>
                    <div className={classes.titleBar}>
                        <Button onClick={() => setOpenAddSubscription(true)}>
                            {t`Add Subscription`}
                        </Button>
                    </div>
                    <Paper elevation={0} className={classes.paperTableContainer}>

                        <TableContainer className={classes.tableContainer}>
                            <Table stickyHeader className={classes.table} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell
                                            classes={{ root: classes.tableHeadCell }}
                                            align="left"
                                        >{t`purchase Order`}</TableCell>
                                        <TableCell
                                            classes={{ root: classes.tableHeadCell }}
                                            align="left"
                                        >{t`start Date`}</TableCell>
                                        <TableCell
                                            classes={{ root: classes.tableHeadCell }}
                                            align="left"
                                        >{t`end Date`}</TableCell>
                                        <TableCell
                                            classes={{ root: classes.tableHeadCell }}
                                            align="left"
                                        >{t`Description`}</TableCell>
                                        <TableCell
                                            classes={{ root: classes.tableHeadCell }}
                                            align="left"
                                        >{t`is Perpetual`}</TableCell>
                                        <TableCell
                                            classes={{ root: classes.tableHeadCell }}
                                            align="left"
                                        >{t`contact Email`}</TableCell>
                                        <TableCell
                                            classes={{ root: classes.tableHeadCell }}
                                            align="left"
                                        >{t`Units`}</TableCell>
                                        <TableCell
                                            classes={{ root: classes.tableHeadCell }}
                                            align="left"
                                        >{t`site`}</TableCell>
                                        <TableCell
                                            classes={{ root: classes.tableHeadCell }}
                                            align="left"
                                        >{t`EDIT`}</TableCell>
                                        <TableCell
                                            classes={{ root: classes.tableHeadCell }}
                                            align="left"
                                        >{t`REMOVE`}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {sortedSubscriptions.map((subscription: any) => {
                                        const { id, isPerpetual, purchaseOrder, description, contactEmail, units, site
                                        } = subscription;
                                        const startDate = new Date(subscription.startDate);
                                        const endDate = new Date(subscription.endDate);

                                        return (
                                            <TableRow
                                                hover
                                                tabIndex={-1}
                                            // key={id}
                                            >
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                    classes={{ root: clsx(classes.overWritePadding, classes.smallWidth) }}
                                                    align="left"
                                                >
                                                    {purchaseOrder}
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                    classes={{ root: clsx(classes.overWritePadding, classes.smallWidth) }}
                                                    align="left"
                                                >
                                                    {moment(startDate).format(defaultDateFormat)}
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                    classes={{ root: clsx(classes.overWritePadding, classes.smallWidth) }}
                                                    align="left"
                                                >
                                                    {isPerpetual ? "" : moment(endDate).format(defaultDateFormat)}
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                    classes={{ root: clsx(classes.overWritePadding, classes.mediumWidth) }}
                                                    align="left"
                                                >
                                                    {description}
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                    classes={{ root: clsx(classes.overWritePadding, classes.smallWidth) }}
                                                    align="left"
                                                >
                                                    {isPerpetual ? t`true` : t`false`}
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                    classes={{ root: clsx(classes.overWritePadding, classes.mediumWidth) }}
                                                    align="left"
                                                >
                                                    {contactEmail}
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                    classes={{ root: clsx(classes.overWritePadding, classes.smallWidth) }}
                                                    align="left"
                                                >
                                                    {units}
                                                </TableCell>

                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                    classes={{ root: clsx(classes.overWritePadding, classes.mediumWidth) }}
                                                    align="left"
                                                >
                                                    {allSites[site]?.name}
                                                </TableCell>
                                                <TableCell classes={{ root: classes.overWritePadding }} align="left">
                                                    <LightTooltip title="Edit Subscription">
                                                        <IconButton
                                                            className={classes.editIcon}
                                                            onClick={() => setEditSubscription(subscription)}
                                                        >
                                                            <EditIcon />
                                                        </IconButton>
                                                    </LightTooltip>
                                                </TableCell>
                                                <TableCell classes={{ root: classes.overWritePadding }} align="left">
                                                    <Delete
                                                        // disabled={!canDelete}
                                                        type={t`Subscription`}
                                                        object={subscription}
                                                        detach={deleteSubscription}
                                                        getActionResult={(result: any) => {
                                                            if (result) {
                                                                setRefresh(true);
                                                            }
                                                        }}
                                                        buttonClass={classes.deleteIcon}
                                                    ></Delete>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>

                    {errorBox}

                    <AddEditSubscription
                        close={closeDialog}
                        open={openAddSubscription || !_.isEmpty(editSubscription)}
                        handleSave={handleSave}
                        subscription={editSubscription}
                        currentDay={currentDay}
                        aYearFromNow={aYearFromNow}
                        setRefresh={setRefresh}
                        refresh={refresh}
                        allSites={allSites}
                        title={openAddSubscription ? t`Add new subscription` : t`Edit Subscription `}
                    />
                </div>
            }
        </ServiceNavigationBar>
    );
}
