import {
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@material-ui/core";
import clsx from "clsx";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import AddRule from "../../components/AddRule";
import Delete from "../../components/Delete/Delete";
import ServiceNavigationBar from "../../widgets/Menu/ServiceNavigationBar";
import LightTooltip from "../../components/Tooltip/LightTooltip";
import Button from "../../components/cool_widgets/Button";
import { EditIcon } from "../../logos";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import sort from "../../utils/sortUtil";
import Export from "../../icons/Export";
import Download from "../../icons/Download";
import ExportTraps from "./ExportTraps";

import useStyles from "./TrapsList.style";
import moment from "moment";
import ErrorBox from "../../widgets/ErrorBox/ErrorBox";
import ImportTraps from "./ImportTraps";

const TrapsList: React.FC = (props: any) => {
    const classes = useStyles();
    const types = useStoreState((s) => s.types);
    const getTraps = useStoreActions((action) => action.traps.getAdminTraps);
    const addTrap = useStoreActions((action) => action.traps.addAdminTrap);
    const updateTrap = useStoreActions((action) => action.traps.updateTrap);
    const deleteTrap = useStoreActions((action) => action.traps.deleteTrap);
    const getTrapTemplates = useStoreActions((action) => action.anomalyTemplates.getTrapTemplates);
    const [openExportTraps, setOpenExportTraps] = useState<boolean>(false);
    const [fileData, setFileData] = useState<any>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errors, setErrors] = useState<any>({ file: false, error: "" });
    const [openAddRule, setOpenAddRule] = useState<boolean>(false);
    const [traps, setTraps] = useState<any>({});
    const [editTrap, setEditTrap] = useState<any>(null);
    const [trapTemplatesToBrands, setTrapTemplatesTobrands] = useState<any>({});
    const [trapTemplatesMap, setTrapTemplatesMap] = useState<any>({});
    const [refresh, setRefresh] = useState<number>(0);
    const [multiTrapsToImport, setMultiTrapsToImport] = useState<any>(null);
    const { customerId: selectedCustomer } = useStoreState((s) => s.selections.selections);
    const setSelections = useStoreActions(a => a.selections.setSelections);
    const sortedTraps = !_.isEmpty(traps) ? sort(Object.values(traps), "name", "asc") : [];
    const { hvacBrands, eventTypes } = types;
    useEffect(() => {
        Promise.all([getTraps(), getTrapTemplates()]).then((response: any) => {
            const templatesToBrands: any = {};
            Object.values(response[1] || {}).forEach((template: any) => {
                const brandNum = template?.userSelections?.brand || null;
                if (!brandNum) {
                    return;
                }
                if (templatesToBrands[brandNum]) {
                    templatesToBrands[brandNum].push(template);
                    return;
                }

                templatesToBrands[brandNum] = [template];
            });
            if (!_.isEmpty(response[0])) {
                const traps: any = {};
                for (let trap in response[0]) {
                    if (response[0][trap]?.type === eventTypes.adminTelemetry) {
                        traps[trap] = response[0][trap];
                    }
                }
                setTraps(traps);
            }
            setTrapTemplatesMap(response[1]);
            setTrapTemplatesTobrands(templatesToBrands);
        });
    }, [refresh]);
    useEffect(() => {
        if (!fileData) {
            return;
        }
        setIsLoading(true);
        const currNames = Object.values(traps).map((t: any) => t.name);
        const today = moment().format("YYYY/MM/DD");

        const importedTraps = fileData?.length && fileData.map((impTrap: any) => {
            const { customer, ...restTrap } = impTrap;
            if (restTrap.type == types.trapTypes.operationalTelemetry) {
                setErrors({ file: false, error: "Anomaly type not supported " });
                return;
            }
            restTrap.type = types.trapTypes.adminTelemetry;
            const name = currNames.indexOf(impTrap?.name) > -1 ? `${impTrap.name}-imported at ${today}` : impTrap.name;

            return addTrap({ data: { ...restTrap, name } });
        });
        Promise.all(importedTraps)
            .then(() => {
                closeImport();
                setRefresh(refresh + 1);
            });

    }, [fileData]);
    useEffect(() => {
        Promise.all([getTraps(), getTrapTemplates()]).then((response: any) => {
            const templatesToBrands: any = {};
            Object.values(response[1] || {}).forEach((template: any) => {
                const brandNum = template?.userSelections?.brand || null;
                if (!brandNum) {
                    return;
                }
                if (templatesToBrands[brandNum]) {
                    templatesToBrands[brandNum].push(template);
                    return;
                }

                templatesToBrands[brandNum] = [template];
            });
            if (!_.isEmpty(response[1])) {
                setTrapTemplatesMap(response[1]);
            }
            if (!_.isEmpty(response[0])) {
                const traps: any = {};
                for (let trap in response[0]) {
                    if (response[0][trap]?.type === eventTypes.adminTelemetry) {
                        traps[trap] = response[0][trap];
                    }
                }
                setTraps(traps);
            }
            setTrapTemplatesTobrands(templatesToBrands);

        });
    }, []);
    const errorBox = errors.error ? <ErrorBox error={errors.error || ""} onClose={() => setErrors({ file: false, error: "" })} /> : null;
    const getBrandName = (value: any) => {
        return hvacBrands?.filter((item) => item.value === value)[0]?.name;
    };
    const addNewTrap = (data: any) => {
        return addTrap({ data })
            .then((resp: any) => {
                setTraps({ ...traps, [resp.id]: { ...resp } });
            })
            .catch((error: any) => {

            });
    };


    const updateExistingTrap = (data: any, trapId: string, selectedSystems: any, selectedUnits: any, removeFromList: boolean) => {
        const apiCall = updateTrap({ data, trapId })

        return apiCall
            .then((resp: any) => {
                setTraps({ ...traps, [trapId]: { ...traps[trapId], ...data } });
            })
            .catch((error: any) => {

            });
    };

    const closeDialog = () => {
        setOpenAddRule(false);
        setEditTrap(null);
    };
    const closeExport = () => {
        setOpenExportTraps(false);
    };
    const closeImport = () => {
        setIsLoading(false);
        setMultiTrapsToImport(null);
        setSelections({ customerId: null });
    };
    const handleUploadFile = (event: any) => {

        setErrors({ file: false, error: "" });
        const noFileChoosed = event.target.files.length < 1;
        if (!noFileChoosed) {

            const ext: any = _.last(event.target.files[0].name.split(".")) || "";
            if (ext !== "json") {
                setErrors({ file: false, error: t`file type is not allowed, use Json files`, });
                return;
            }

            const fileReader = new FileReader();
            fileReader.readAsText(event.target.files[0], "UTF-8");
            fileReader.onload = (event) => {
                const x: any = event.target?.result;
                const data = JSON.parse(x);

                if (!Array.isArray(data)) {
                    return;
                }

                if (data.length < 2) {
                    setFileData(data);
                    return;
                }

                setMultiTrapsToImport(data);
            };

        }

        if (noFileChoosed) {
            setErrors({ file: noFileChoosed, error: "" });
            return;
        }
    };


    return (
        <ServiceNavigationBar title={t`Anomalies`}{...props} >
            <div className={classes.contentArea}>
                <div className={classes.titleBar}>
                    <div className={classes.ImportExportContainer}>
                        <div>
                            <LightTooltip title={"Export Anomaly"}>
                                <IconButton onClick={() => setOpenExportTraps(true)}>
                                    <Export />
                                </IconButton>
                            </LightTooltip>
                            <LightTooltip title={"Import Anomaly"}>
                                <IconButton>
                                    <label htmlFor="file"><Download /></label>
                                    <input disabled={false} type="file" hidden name="file" id="file" accept=".json" onChange={handleUploadFile} />
                                </IconButton>
                            </LightTooltip>
                        </div>
                    </div>
                    <Button onClick={() => setOpenAddRule(true)}>
                        {t`Add Rule`}
                    </Button>
                </div>
                <Paper elevation={0} className={classes.paperTableContainer}>
                    <TableContainer className={classes.tableContainer}>
                        <Table stickyHeader className={classes.table} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        classes={{ root: classes.tableHeadCell }}
                                        align="left"
                                    >{t`Rule Name`}</TableCell>
                                    <TableCell
                                        classes={{ root: classes.tableHeadCell }}
                                        align="left"
                                    >{t`BRAND`}</TableCell>
                                    <TableCell
                                        classes={{ root: classes.tableHeadCell }}
                                        align="left"
                                    >{t`Description`}</TableCell>
                                    <TableCell
                                        classes={{ root: classes.tableHeadCell }}
                                        align="left"
                                    >{t`EDIT`}</TableCell>
                                    <TableCell
                                        classes={{ root: classes.tableHeadCell }}
                                        align="left"
                                    >{t`REMOVE`}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {sortedTraps.map((trap: any) => {
                                    const { id, name, customer, userSelections: { brand = -99 } = {}, description } = trap;
                                    return (
                                        <TableRow
                                            hover
                                            tabIndex={-1}
                                            key={id}
                                        >
                                            <TableCell
                                                component="th"
                                                scope="row"
                                                classes={{ root: clsx(classes.overWritePadding, classes.smallWidth) }}
                                                align="left"
                                            >
                                                {name}
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                                classes={{ root: clsx(classes.overWritePadding, classes.smallWidth) }}
                                                align="left"
                                            >
                                                {getBrandName(brand)}
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                                classes={{ root: clsx(classes.overWritePadding, classes.mediumWidth) }}
                                                align="left"
                                            >
                                                {description}
                                            </TableCell>
                                            <TableCell classes={{ root: classes.overWritePadding }} align="left">
                                                <LightTooltip title="Edit anomaly">
                                                    <IconButton
                                                        className={classes.editIcon}
                                                        onClick={() => setEditTrap(trap)}
                                                    >
                                                        <EditIcon />
                                                    </IconButton>
                                                </LightTooltip>
                                            </TableCell>
                                            <TableCell classes={{ root: classes.overWritePadding }} align="left">
                                                <Delete
                                                    disabled={false}
                                                    type={t`anomaly`}
                                                    object={trap}
                                                    detach={deleteTrap}
                                                    getActionResult={(result: any) => {
                                                        if (result) {
                                                            delete traps[id];
                                                            setTraps({ ...traps });
                                                        }
                                                    }}
                                                    buttonClass={classes.deleteIcon}
                                                ></Delete>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
                {openExportTraps && (
                    <ExportTraps traps={sortedTraps}
                        close={closeExport}
                        getBrandName={getBrandName}
                    />
                )}
                {multiTrapsToImport && (
                    <ImportTraps
                        isLoading={isLoading}
                        traps={multiTrapsToImport}
                        close={() => setMultiTrapsToImport(null)}
                        getBrandName={getBrandName}
                        passTraps={(traps: any) => setFileData(traps)}
                    />
                )}
                {errorBox}
                {/* {openUploadPopUp && (
                    <ImportTraps
                        isLoading={isLoading}
                        traps={multiTrapsToImport}
                        close={() => setMultiTrapsToImport(null)}
                        getBrandName={getBrandName}
                        passTraps={(traps: any) => setFileData(traps)}
                    />
                )*/}
                {errorBox}

                {(openAddRule || editTrap) &&
                    <AddRule
                        close={closeDialog}
                        createRule={addNewTrap}
                        editTrap={editTrap}
                        updateRule={updateExistingTrap}
                        trapTemplatesToBrands={trapTemplatesToBrands}
                        trapTemplatesMap={trapTemplatesMap}
                    />
                }
            </div>
        </ServiceNavigationBar >
    );
};

export default TrapsList;
