import { Button } from "@material-ui/core";
import classNames from "classnames";
import clsx from "clsx";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { Fragment, useEffect } from "react";
import { t } from "ttag";
import * as Yup from "yup";
import "../../icons.css";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import assetsService from "../../services/assetsService";
import LocalButton from "../../widgets/Button/Button";
import style from "./Login.module.css";

export default function Login(props: any) {
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [errorMsg, setErrorMsg] = React.useState("");

  const login = useStoreActions((actions) => actions.users.login);
  const user = useStoreState((state) => state.users.me);
  const { isLoggedIn } = user;
  const { history } = props;

  useEffect(() => {
    if (isLoggedIn) {
      history.push(`/dashboard`);
    }
  }, [isLoggedIn, history]);

  const validationSchema = Yup.object({
    username: Yup.string().required(t`required field`),
    password: Yup.string().required(t`required field`)
  });

  const handleSubmit = (values: any) => {
    const { username, password } = values;

    login({ username, password }).catch((err: any) => {
      alert("Wrong Email/password!");
    });
  };

  return (
    <>
      <div className={style.loginContainer}>
        <div className={style.logoSection}>
          <img alt="logo" src={assetsService.getResourcePath("fullLogo.png")} />
        </div>

        <Formik
          initialValues={{ username: "", password: "" }}
          onSubmit={handleSubmit}
          enableReinitialize={true}
          validationSchema={validationSchema}
          render={({ values, setFieldValue, ...formikProps }) => {
            return (
              <Form translate="yes" className={style.contentSection}>
                <h1 className={style.welcomeMessage}>
                  {t`Welcome! Please login.`}
                </h1>
                <Field
                  name="username"
                  placeholder={t`Username`}
                  formikprops={formikProps}
                  className={clsx(style.usernameStyle, style.inputClass)}
                />
                <ErrorMessage
                  name="username"
                  component="div"
                  className={style.errorStyle}
                />

                <Field
                  name="password"
                  placeholder={t`Password`}
                  formikprops={formikProps}
                  className={clsx(style.passwordStyle, style.inputClass)}
                  type="password"
                />
                <ErrorMessage
                  name="password"
                  component="div"
                  className={style.errorStyle}
                />
                <Button
                  type="submit"
                  className={clsx(style.LoginButtonsStyle, style.submitButton)}
                >{t`Login`}</Button>
              </Form>
            );
          }}
        />
      </div>
    </>
  );
}
