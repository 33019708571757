import React from "react";
import assetsService from "../../services/assetsService";
import useStyles from "./Loading.style";

export default function Loading() {
  const classes = useStyles();
  return (
    <div className={classes.loading}>
      <div className={classes.main}>
        <div className={classes.logos}>
          <img
            alt="logo"
            src={assetsService.getResourcePath("fullLogo.png")}
            // className={classes.logo}
          />
        {  /*<img
            alt="logo title"
            src={assetsService.getResourcePath("logo_title.png")}
            className={classes.logoTitle}
          />*/   }
        </div>
      </div>
    </div>
  );
}
