import _ from "lodash";
import React, { PropsWithChildren, useEffect, useState } from "react";
import { t } from "ttag";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import { IUnit } from "../../models/UnitModel";
import GroupSearchMenuItem from "./GroupSearchMenuItem";
import MenuItemIcon from "./MenuItemIcon";
import { useStyles } from "./UnitsMenu.style";

interface IUnitsMenuProps {
  onCustomerSelect?: (customerId: string | null) => void;
  onSiteSelect?: (siteId: string | null) => void;
  onSystemSelect?: (systemId: string | null) => void;
  onUnitSelect?: (unitId: string | null) => void;
  isSystemHidden?: boolean;
  setUnit?: any;
}

const UnitsMenu: React.FC<PropsWithChildren<IUnitsMenuProps>> = (
  props: PropsWithChildren<IUnitsMenuProps>
) => {
  const c = useStyles();
  const {setUnit} = props;
  const [indoorsUnits, setIndoors] = useState<any>([]);
  const [outdoorsUnits, setOutdoors] = useState<any>([]);

  const setSelections = useStoreActions((a) => a.selections.setSelections);
  const selections = useStoreState((s) => s.selections.selections);
  const {units} = useStoreState((s) => s.selections.getFiltersBySelections);
  const {units: allUnits} = useStoreState((s) => s.selections.filtersTree);

  useEffect(() => {
    const indoors: any = [];
    const outdoors: any = [];
    Object.values(units).forEach((unit: any) => {
      if (unit.type === 3){
        indoors.push(unit);
      }
      if (unit.type === 2){
        outdoors.push(unit);
      }
    });

    setIndoors(_.sortBy(indoors, (unit) => [unit.name]));
    setOutdoors(_.sortBy(outdoors, (unit) => [unit.name]));
  }, [units]);

  return (
    <div className={c.view}>
      <div className={c.main}>
        <div className={props.isSystemHidden ? c.hidden : ""}>
          <GroupSearchMenuItem
            title={t`Outdoor`}
            onClick={_.noop}
            numItems={outdoorsUnits.length}
            className={c.outdoor}
            isOpen={
              selections.unitId != null &&
              allUnits[selections.unitId].type === 2
            }
          >
          {outdoorsUnits.map((unit: any) => (
            <MenuItemIcon
              key={unit.id}
              title={unit.name}
              onClick={setUnit}
              unit={unit.id}
              isSelected={selections.unitId === unit.id}
            />))}
          </GroupSearchMenuItem>
          <GroupSearchMenuItem
            title={t`Indoor`}
            onClick={_.noop}
            numItems={indoorsUnits.length}
            className={c.indoor}
            isOpen={
              selections.unitId != null &&
              allUnits[selections.unitId].type === 3
            }
          >
            {indoorsUnits.map((unit: any) => (
              <MenuItemIcon
                key={unit.id}
                title={unit.name}
                onClick={setUnit}
                unit={unit.id}
                isSelected={selections.unitId === unit.id}
              />))}
          </GroupSearchMenuItem>
        </div>
      </div>
    </div>
  );
};

export default UnitsMenu;
