import { Button, Typography } from "@material-ui/core";
import React from "react";
import logo from "../../images/logo.png";
import logo1 from "../../images/logo1.png";
import { useStoreState } from "../../models/RootStore";
import { UserMenu } from "../../widgets/";
import styles from "./Header.module.css";

const Header: React.FC<any> = ({ history, title, filters }) => {
  const userName = useStoreState((state) => state.users.me.firstName);

  return (
    <div className={styles.headerContainer}>
      <div className={styles.leftSide}>
        {title && <Typography className={styles.headerTitle}>{title}</Typography>}
        {filters && filters}
      </div>
      <UserMenu
        userName={userName}
        history={history}
        className={styles.userName}
      />
    </div>
  );
};

export default Header;
