import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Typography,
  InputLabel,
  InputBase,
  MenuItem,
  IconButton,
  CircularProgress,
  Grid,
  Switch,
} from "@material-ui/core/";
import { Check, Close } from "@material-ui/icons";
import _ from "lodash";
import { t } from "ttag";
import { Formik } from "formik";
import * as Yup from "yup";
import { tz } from "moment-timezone";
import clsx from "clsx";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import { EditIcon } from "../../iconImages";
import ServiceNavigationBar from "../../widgets/Menu/ServiceNavigationBar";
import { stateList } from "./stateList";
import { countries } from "./countryList";
import styles from "./SiteManagement.module.css";
import AdvancedInputField from "../../widgets/AdvancedInputField/AdvancedInputField";
import { CustomSelect } from "../../components/AddRule/CustomedComponents";

const timeZones = tz.names().map((timezone) => ({
  value: timezone,
  label: `${timezone}  ${tz(timezone).format("Z")}`,
}));
const stateOptions = stateList.map((state: any) => ({
  value: state.value,
  label: state.label,
}));
const countryOptions = countries.map((country: any) => ({
  value: country.code,
  label: country.name,
}));

const SiteDetails = (props: any) => {
  const [site, setSite] = useState<any>({});
  const [editable, setEditable] = useState<boolean>(false);
  const [devicesCount, setDevicesCount] = useState<any>(0);
  const [ignoreStatus, setIgnoreStatus] = useState(!_.isNil(site.ignoreStatus)? site.ignoreStatus : false);
  const getSite = useStoreActions((actions) => actions.sites.getSite);
  const updateSite = useStoreActions((actions) => actions.sites.updateSite);
  const getDevice = useStoreActions((actions) => actions.devices.getDeviceById);
  const types = useStoreState((state) => state.types);
  
  useEffect(() => {
   if(!_.isNil(site.ignoreStatus)){
    setIgnoreStatus(site.ignoreStatus);
   }
}, [site]);

  const { match, history } = props;
  const { params } = match;
  const { siteId } = params;
  const {
    name,
    description,
    country,
    city,
    address,
    postalCode,
    state,
    timezone,
    serviceStatus,
    managementStatus
    } = site;
  const statusesOptions = Object.keys(types.siteStatuses).map((status: any) => ({
    value: types.siteStatuses[status],
    label: status,
  }));

  console.log(ignoreStatus)
  console.log(site)

  const validationSchema = Yup.object({
    name: Yup.string().required(t`required field`),
    country: Yup.string().required(t`required field`),
    city: Yup.string().required(t`required field`),
    address: Yup.string().required(t`required field`),
    timezone: Yup.string().required(t`required field`),
    managementStatus: Yup.number(),
    serviceStatus: Yup.number(),
    ignoreStatus: Yup.boolean()
  });

  useEffect(() => {
    (async () => {
      if (!_.isEmpty(site) || !siteId) {
        return;
      }
      let connected = 0;
      getSite(siteId).then(async (data: any) => {
        for (let deviceId of data.devices) {
          const device = await getDevice(deviceId);
          if (device.isConnected) {
            connected++;
          }
        }
        setDevicesCount(connected);
        setSite(data);
      });
    })();



  }, [getSite, site, siteId]);

  const handleSubmit = (values: any, { setSubmitting }: any) => {
    const data = {
      ...values,
      zip: undefined,
      postalCode: values.zip,
      ignoreStatus
    };
    updateSite({
      id: siteId,
      data,
    })
      .then(() => {
        setSite({ ...site, ...data });
        setEditable(false);
      })
      .catch((err: any) => { })
      .finally(() => setSubmitting(false));
  };
  return (
    <ServiceNavigationBar title={t`${name}`} {...props}>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="stretch"
      // className={classes.wrapper}
      >
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="stretch"
        // className={classes.filterContainer}
        >



          <div className={styles.siteDetailsContainer}>
            <Formik
              initialValues={{
                description: description,
                city: city,
                country: country,
                address: address,
                zip: postalCode,
                state: state,
                timezone: timezone,
                name: name,
                managementStatus: managementStatus,
                serviceStatus: serviceStatus,
                ignoreStatus

              }}
              enableReinitialize={true}
              onSubmit={handleSubmit}
              validationSchema={validationSchema}
            >
              {(props) => {
                const {
                  values,
                  touched,
                  errors,
                  handleChange,
                  handleSubmit,
                  handleReset,
                  dirty,
                  isSubmitting,
                } = props;
                return (
                  <form onSubmit={handleSubmit} className={styles.siteDetailsForm}>
                    <div className={styles.siteDetailsHeader}>
                      <Typography className={styles.editSiteTitle}>
                        {t`Site Details`}
                      </Typography>
                      {isSubmitting && (
                        <CircularProgress className={styles.loader} />
                      )}
                      {!editable && (
                        <IconButton onClick={() => setEditable(true)}>
                          <img src={EditIcon} alt="edit" />
                        </IconButton>
                      )}
                      {editable && (
                        <div style={{ display: "flex" }}>
                          <IconButton type="submit" disabled={isSubmitting}>
                            <Check className={styles.siteDetailsIcons} />
                          </IconButton>
                          <IconButton
                            disabled={isSubmitting}
                            onClick={() => {
                              handleReset();
                              setEditable(false);
                            }}
                          >
                            <Close className={styles.siteDetailsIcons} />
                          </IconButton>
                        </div>
                      )}
                    </div>
                    <div className={styles.siteDetailsFieldsContainer}>
                      <AdvancedInputField
                        name="name"
                        label={t`Name`}
                        editable={editable}
                        {...props}
                      />
                      <AdvancedInputField
                        name="description"
                        label={t`Description`}
                        editable={editable}
                        {...props}
                      />
                      <AdvancedInputField
                        name="address"
                        label={t`Address`}
                        editable={editable}
                        {...props}
                      />
                      <AdvancedInputField
                        name="city"
                        label={t`City`}
                        editable={editable}
                        {...props}
                      />
                      <AdvancedInputField
                        select
                        value={values.state || ""}
                        name="state"
                        options={stateOptions}
                        label={t`State`}
                        editable={editable}
                        {...props}
                      />
                      <AdvancedInputField
                        name="zip"
                        label={t`Zip`}
                        editable={editable}
                        {...props}
                      />
                      <AdvancedInputField
                        select
                        value={values.country || ""}
                        name="country"
                        label={t`Country`}
                        options={countryOptions}
                        editable={editable}
                        {...props}
                      />

                      <AdvancedInputField
                        select
                        value={values.timezone}
                        name="timezone"
                        label={t`Timezone`}
                        options={timeZones}
                        editable={editable}
                        {...props}
                      />
                      <InputLabel
                        style={{ width: '100%' }}
                        >
                      {t`ignore Status`}
                      <div>
                          disable
                          <Switch
                              disabled={!editable}
                              color="default"
                              onChange={() => {
                                setIgnoreStatus(!ignoreStatus)
                            }}
                              checked={ignoreStatus}
                              name="ignoreStatus"
                          />
                          enable
                      </div>
                    </InputLabel>
                    </div>
                        
                    <div className={styles.siteBox}>
                      <CustomSelect
                        disabled={!editable}
                        placeholder="Management Status"
                        name={`managementStatus`}
                        onChange={handleChange}
                        value={!_.isNil(values.managementStatus) ? values.managementStatus : ""}
                        options={_.map(Object.keys(types.siteStatuses), (status: any) => (
                          {
                            key: types.siteStatuses[status],
                            name: status,
                            value: types.siteStatuses[status]
                          }
                        ))}
                      />
                      <CustomSelect
                        disabled={!editable}
                        placeholder="Service Status"
                        name={`serviceStatus`}
                        onChange={handleChange}
                        value={!_.isNil(values.serviceStatus) ? values.serviceStatus : ""}
                        options={_.map(Object.keys(types.siteStatuses), (status: any) => (
                          {
                            key: types.siteStatuses[status],
                            name: status,
                            value: types.siteStatuses[status]
                          }
                        ))}
                      />
                    </div>
                    {/* <AdvancedInputField
                      select
                      value={!_.isNil(values.managementStatus) ? values.managementStatus : ""}
                      name="managementStatus"
                      options={statusesOptions}
                      label={t`Management Status`}
                      editable={editable}
                      {...props}
                    />
                    <AdvancedInputField
                      select
                      value={!_.isNil(values.serviceStatus) ? values.serviceStatus : ""}
                      name="serviceStatus"
                      options={statusesOptions}
                      label={t`Service Status`}
                      editable={editable}
                      {...props}
                    /> */}

                  </form>
                );
              }}
            </Formik>
          </div>
          <div className={styles.siteButtonsContainer}>
            <Button
              className={styles.siteButtonsStyle}
              onClick={() => history.push(`/site/${siteId}/devices`)}
            >

              {t`Site Devices` + `(` + devicesCount + `)`}
            </Button>
          </div>
        </Grid>

      </Grid>
    </ServiceNavigationBar>
  );
};

export default SiteDetails;
