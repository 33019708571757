import { Button, Card, Grid, Icon, recomposeColor, Typography } from "@material-ui/core/";
import {
  addDays,
  sub
} from "date-fns";
import _ from "lodash";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { t } from "ttag";
import Header from "../../components/Header/Header";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import { checkDateInRange } from "../../services/timeService";
import ExportUtils from "../../utils/ExportUtils";
import ServiceNavigationBar from "../../widgets/Menu/ServiceNavigationBar";
import AlertInfo from "./AlertInfo";
import useStyles from "./Alerts.style";
import AlertsTable from "./AlertsTable";

const Alerts: React.FC<any> = (props: any) => {
  const history = useHistory();

  const selectedAlertObj = useStoreState((state) => state.selectedAlert);
  const [selectedAlert, setSelectedAlert] = useState<any>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [] = useState<boolean>(true);
  const classes = useStyles();
  const eventClearTypesMirror = useStoreState((state) => state.eventClearTypesMirror);
  const types = useStoreState((state) => state.types);
  const eventTypesMirror = useStoreState((state) => state.eventTypesMirror);
  const eventStatusTypesMirror = useStoreState((state) => state.eventStatusTypesMirror);
  const getAlertFeildsNamesAndIds = useStoreState((state) => state.alerts.getAlertFeildsNamesAndIds);
  const allUnits = useStoreState((state) => state.units.allUnits);
  const getAlertsByFilters = useStoreActions((action) => action.alerts.getAlertsByFilters);
  const updateSelections = useStoreActions((a) => a.selections.updateSelections);
  const allCustomers = useStoreState((state) => state.customers.customerList);
  const allOpenAlerts = useStoreState((state) => state.alerts.allOpenAlerts);
  const allSites = useStoreState((state) => state.sites.allSites);
  const displayFlags = useStoreState((state) => state.users.displayFlags);
  const setFlags = useStoreActions((a) => a.users.setFlags);

  const [rows, setRows] = useState<any>([]);
  const [page, setPage] = useState<any>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [isDisabled, setIsDisabled] = useState(true);
  const [selectedFilters, setSelectedFilters] = useState<any>({statuses: [], description: [], siteName: [], customerName: [], systemName: [], unitName: []});
  const [deleteFlag, setDeleteFlag] = useState<boolean>(false);
  const setUnitUpdateStatus = useStoreActions((action) => action.setUnitUpdateStatus);
  const selections = useStoreState((s) => s.selections.selections);
  const [resetFilters, setResetFilters] = useState<boolean>(false);
  const [filteredAlerts, setFilteredAlerts] = useState<any>([]);
  const [siteNamesArray, setSiteNamesArray] = useState<any>([]);
  const [customerNamesArray, setCustomerNamesArray] = useState<any>([]);
  const [systemNamesArray, setSystemNamesArray] = useState<any>([]);
  const [unitNamesArray, setUnitNamesArray] = useState<any>([]);
  const [descriptionsArray, setDescriptionsArray] = useState<any>([]);
  const [statusesArray, setStatusesArray] = useState<any>([]);
  const [preSelectedAlert, setPreSelectedAlert] = useState<any>(true);
  const { eventClearTypes, eventTypes } = types;
  setUnitUpdateStatus({ status: "" });

  useEffect(() => {
    const {enableSysDiag, enableUnitDiag, isSysDiagAllowedOnFirstLoad, isUnitDiagAllowedOnFirstLoad} = displayFlags;

    if (!isSysDiagAllowedOnFirstLoad || !isUnitDiagAllowedOnFirstLoad){
      const flags: any = {...displayFlags, isSysDiagAllowedOnFirstLoad: enableSysDiag, isUnitDiagAllowedOnFirstLoad: enableUnitDiag };
      setFlags({ displayFlags: flags});
    }

  }, []);

  useEffect(() => {
    if (!selections.dateRange) {
      updateSelections({
        type: "time",
        data: {
          startDate: new Date(new Date().setHours(0, 0, 0) - 7 * 24 * 60 * 60 * 1000),
          endDate: new Date()
        }
      });
    }
  }, [resetFilters]);

  useEffect(() => {
    if (selectedAlertObj && selectedAlertObj.id) {

      const {
        timestamp,
        unitIds,
        systemIds,
        siteId
      } = selectedAlertObj;
      const evDate = new Date(timestamp);
      const endDate = addDays(evDate, 1);
      const startDate = sub(evDate, { hours: 24 });

      updateSelections({ type: "time", data: { startDate, endDate } });
      if (unitIds && unitIds.length === 1) {
        updateSelections({ type: "unit", data: unitIds[0] });
      }
      else if (systemIds && systemIds.length === 1) {
        updateSelections({ type: "system", data: systemIds[0] });
      }
      else if (siteId) {
        updateSelections({ type: "site", data: siteId });
      }

      return;
    }
  }, [allOpenAlerts, selectedAlertObj]);

  useEffect(() => {
    if (!selections.dateRange) {
      return;
    }

    const startTime = Date.UTC(selections.dateRange?.startDate.getFullYear(), selections.dateRange?.startDate.getMonth(), selections.dateRange?.startDate.getDate());
    const endTime = Date.UTC(selections.dateRange?.endDate.getFullYear(), selections.dateRange?.endDate.getMonth(), selections.dateRange?.endDate.getDate(), 23, 59, 59);

    (async function() {
      setIsDisabled(true);
      setRows([]);
      const allAlertsByTime = await getAlertsByFilters({
        // startTime,
        // endTime,
        type: types.applications.console
      });

      const sortedAlerts = _.orderBy({ ...allAlertsByTime, ...allOpenAlerts }, ["eventTime"], ["asc"]);

      const rowsToRender: any = [];
      const errorCodesArray: any[] = [];
      const alertTypeArray: any[] = [];
      const statusesArray: any[] = [];

      sortedAlerts.forEach((alert: any) => {
        if (eventTypes.adminTelemetry != alert.type) {
          return;
        }
        const namesAndIds = getAlertFeildsNamesAndIds({ type: alert.type, resources: alert.resources, status: alert.status });
        const { eventNames, eventIds } = namesAndIds;

        const resourcesNames = alert?.resources?.map((res: any) => res.name);
        const unitIds = alert?.resources?.map((res: any) => res.id);

        if (eventIds.unitId && !allUnits[eventIds.unitId]?.isVisible) {
          return;
        }

        const status = (eventStatusTypesMirror[alert.status].split(/(?=[A-Z])/)).map((word: string) => {
          word = word[0].toUpperCase() + word.substr(1);
          return word;
        }).join(" ") || "";

        rowsToRender.push({
          type: alert.type,
          acknowledgedTime: alert.acknowledgedTimestamp ? moment(alert.acknowledgedTimestamp).format("DD/MM/YY   HH:mm") : false,
          time: moment(alert.eventTime).format("DD/MM/YY   HH:mm"),
          clearTime: moment(alert.clearTime).format("DD/MM/YY   HH:mm"),
          status,
          alertType: (eventTypesMirror[alert.type].split(/(?=[A-Z])/)).map((word: string) => {
            word = word[0].toUpperCase() + word.substr(1);
            return word;
          }).join(" "),
          siteName: alert.siteName,
          unitName: resourcesNames?.join(","), //eventNames.unitName,
          deviceName: alert.deviceName,
          systemName: alert.systemName,
          unitIds,
          customerName:  alert.customerName,
          clearReason: alert.clearReason ? (eventClearTypesMirror[alert.clearReason] || "").replace(/trap/g, "Anomaly").split(/(?=[A-Z])/).map((word: string) => {
            word = word[0]?.toUpperCase() + word.substr(1);
            return word;
          }).join(" ") : "",
          userText: alert.userText,
          shortId: alert.shortId,
          alertItemContainerIds: { ...eventIds, customerId: alert.customer },
          description: alert.trapDescription,
          errorCode: alert.data,
          timestamp: alert.eventTime,
          id: alert.id
        });
      });

      setCustomerNamesArray([]);
      setSiteNamesArray([]);
      setSystemNamesArray([]);
      setUnitNamesArray([]);
      setStatusesArray(statusesArray);
      setDescriptionsArray([]);
      setRows(rowsToRender);
      setOpen(true);
      setIsDisabled(false);
    })();
  }, [selections.dateRange, deleteFlag]);
  useEffect(() => {
    setPage(0);
    if (!rows.length) {
      setFilteredAlerts([]);
      return;
    }

    const filteredAlerts = getFilteredAlerts(rows);
    setFilteredAlerts(filteredAlerts);
  }, [selections, selectedFilters, rows]);

  useEffect(() => {
    if (preSelectedAlert && !_.isEmpty(selectedAlertObj) && !_.isEmpty(filteredAlerts)) {

      const alertIndex = _.findIndex(filteredAlerts, { id: selectedAlertObj.id });
      const pageNum = Math.ceil((alertIndex + 1) / rowsPerPage);
      setSelectedAlert(filteredAlerts[alertIndex]);
      setPage(pageNum - 1);
    }
  }, [filteredAlerts, rowsPerPage]);

  const clearAllFilters = () => {
    setSelectedFilters({statuses: [], description: [], siteName: [], customerName: [], systemName: [], unitName: []});
  };
  const selectUnit = (unitId: string) => {
    updateSelections({ type: "unit", data: unitId });
  },
    onRowSelect = (alert: any) => {
      setSelectedAlert(alert);
      setOpen(true);
    },
    setPageNum = (num: number) => {
      setPage(num);
    },
    setNumPerPage = (num: number) => {
      setRowsPerPage(num);
    },
    updateFilters = (newFilters: any) => {
      setSelectedFilters({ ...selectedFilters, ...newFilters });
      setPage(0);
    },
    itemGotDeleted = () => {
      setSelectedAlert(null);
      setDeleteFlag(!deleteFlag);
    },
    addResolvedDescription = (resolvedDescription: string, id: string) => {
      const foundIndex = rows.findIndex((x: any) => x.id === id);
      rows[foundIndex] = { ...rows[foundIndex], userText: resolvedDescription };
      setRows([...rows]);
      setSelectedAlert(rows[foundIndex]);
    },
    setAcknowledged = (ackAlert: any) => {
      const changedAckAlerts = filteredAlerts.map((alert: any) => {
        let selectedAlert = null;
        if (alert.id === ackAlert.id) {
          alert.status = ackAlert.data ? "Acknowledged" : "Open";
          selectedAlert = alert;
        }
        return alert;
      });
      setFilteredAlerts(changedAckAlerts);
      setSelectedAlert(selectedAlert);
    },
    convertDataToCSV = () => {
      let csvRows: any = [];
      const headers =
        "DATE & TIME,CUSTOMER NAME,SITE NAME,SYSTEM NAME,UNIT NAME,ALERT STATUS,DESCRIPTION";

      for (const row of filteredAlerts) {
        const customeRow: any = [
          row.time,
          row.customerName,
          row.siteName,
          row.systemName,
          row.unitName,
          row.status,
          row.description
        ];

        csvRows.push(customeRow.join(","));
      }

      const csvContent = "data:text/csv;charset=utf-8," + headers + "\n" + csvRows.join("\n");
      ExportUtils.downloadFile(csvContent, `Alerts Log`);
    };

  // Apply filters and selections to alerts
  const getFilteredAlerts = (alerts: any[]) => {
    function applyFilters(alerts: any[]) {
      return _(alerts)
        .filter((alert) => {
          return selectedFilters.description.length
            ? selectedFilters.description.includes(alert.description)
            : true;
        })
        .filter((alert) => {
          return selectedFilters.customerName.length
            ? selectedFilters.customerName.includes(alert.customerName)
            : true;
        })
        .filter((alert) => {
          return selectedFilters.statuses.length
            ? selectedFilters.statuses.includes(alert.status)
            : true;
        })
        .filter((alert) => {
          return selectedFilters.siteName.length
            ? selectedFilters.siteName.includes(alert.siteName)
            : true;
        })
        .filter((alert) => {
          return selectedFilters.systemName.length
            ? selectedFilters.systemName.includes(alert.systemName)
            : true;
        })
        .filter((alert) => {
          return selectedFilters.unitName.length
            ? selectedFilters.unitName.includes(alert.unitName)
            : true;
        })

        .value();
    }
    function applySelections(alerts: any[]) {
      return _(alerts)
        // .filter((alert: any) =>
        //   selections.unitId ? alert.alertItemContainerIds.unitId ? alert.alertItemContainerIds.unitId === selections.unitId : (alert.alertItemContainerIds.unitIds.length > 1 && alert.alertItemContainerIds.unitIds.includes(selections.unitId)) : true
        // )
        // .filter((alert: any) =>
        //   selections.siteId ? alert.alertItemContainerIds.siteId === selections.siteId : true
        // )
        // .filter((alert: any) =>
        //   selections.systemId ? alert.alertItemContainerIds.systemId ? alert.alertItemContainerIds.systemId === selections.systemId : (alert.alertItemContainerIds.systemIds.length > 1 && alert.alertItemContainerIds.systemIds.includes(selections.systemId)) : true
        // )
        // .filter((alert: any) =>
        //   selections.customerId
        //     ? alert.alertItemContainerIds.customerId === selections.customerId
        //     : true
        // )
        .sortBy(alerts, (alert: any) => {
          return moment(alert.time);
        })
        .value();
    }

    function onlyUnique(value: any, index: any, self: any) {
      return self.indexOf(value) === index;
    }

    const updateFiltersVals = (rows: any) => {
      const selectedFiltersNames = Object.keys(selectedFilters);
      const descriptionArr: any = [];
      const statusArr: any = [];
      const siteNamesArr: any = [];
      const customerNamesArr: any = [];
      const systemNamesArr: any = [];
      const unitNamesArr: any = [];

      for (let rowIndex in rows) {
        const { status, description, siteName, customerName, systemName, unitName } = rows[rowIndex];
        if (!!status) { statusArr.push(status); }
        if (!!description) { descriptionArr.push(description); }
        if (!!siteName) { siteNamesArr.push(siteName); }
        if (!!customerName) { customerNamesArr.push(customerName); }
        if (!!systemName) { systemNamesArr.push(systemName); }
        if (!!unitName) { unitNamesArr.push(unitName); }
      }
      setDescriptionsArray(descriptionArr.filter(onlyUnique));
      setStatusesArray(statusArr.filter(onlyUnique));
      setSiteNamesArray(siteNamesArr.filter(onlyUnique));
      setCustomerNamesArray(customerNamesArr.filter(onlyUnique));
      setSystemNamesArray(systemNamesArr.filter(onlyUnique));
      setUnitNamesArray(unitNamesArr.filter(onlyUnique));
    };

    const filteredRows = applyFilters(applySelections(alerts));
    updateFiltersVals(filteredRows);
    return filteredRows;
  };

  return (
    <ServiceNavigationBar title={t`Alerts`} {...props} >
      <div className={classes.contentArea}>
        <Grid className={classes.contentHeaderContainer}>
          <Button
            variant="contained"
            className={classes.shareButton}
            startIcon={
              <Icon style={{ transform: "rotateY(180deg)", marginTop: "-3px" }}>reply</Icon>
            }
            onMouseUp={convertDataToCSV}
          >
            {t`Share Report`}
          </Button>
        </Grid>
        <Grid
          container
          style={{ height: "calc(100% - 140px)", margin: "unset", padding: "1rem", paddingTop: 0 }}
        >
          <Grid item xs={9} style={{ height: "100%", paddingRight: "20px" }}>
            <Card className={classes.content}>
              <AlertsTable
                rows={filteredAlerts}
                onRowSelect={onRowSelect}
                setPage={setPageNum}
                page={page}
                setRowsPerPage={setNumPerPage}
                rowsPerPage={rowsPerPage}
                isDisabled={isDisabled}
                selectedAlert={selectedAlert && selectedAlert.id}
                setSelectedAlert={setSelectedAlert}
                filterValues={{
                  description: descriptionsArray,
                  statuses: statusesArray,
                  customerName: customerNamesArray,
                  siteName: siteNamesArray,
                  systemName: systemNamesArray,
                  unitName: unitNamesArray
                }}
                appliedFilters={selectedFilters}
                getSelectedFilters={updateFilters}
                style={{ height: "100%" }}
                clearAllFilters={clearAllFilters}
                preSelectedAlert={preSelectedAlert}
                setPreSelectedAlert={setPreSelectedAlert}
              />
            </Card>
          </Grid>

          <Grid item xs={3} className={classes.alertInfoContainer}>
            <Card className={classes.content}>
              <AlertInfo
                open={!!(open && rows.length)}
                alert={selectedAlert || {}}
                selectUnit={selectUnit}
                itemGotDeleted={itemGotDeleted}
                addResolvedDescription={addResolvedDescription}
                setAcknowledged={setAcknowledged}
              />
            </Card>
          </Grid>
        </Grid>
      </div>
    </ServiceNavigationBar>
  );
};
export default Alerts;
