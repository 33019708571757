import _ from "lodash";
import React, { useState, useEffect } from "react";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import NavigationBar from "../NavigationBar/NavigationBar";
import {
  Paper,
  Typography,
  Grid,
  TextField,
  Button,
  MenuItem,
  Select,
  CircularProgress,
  IconButton
} from "@material-ui/core";
import { t } from "ttag";
import SimpleModal from "../../widgets/SimpleModal/SimpleModal";
import styles from "./DeviceManagement.module.css";
import { Formik } from "formik";
import AdvancedInputField from "../../widgets/AdvancedInputField/AdvancedInputField";
import Terminal from "terminal-in-react";
import { Delete } from "@material-ui/icons";

function InputFiled(props: any) {
  const [value, setValue] = useState("");
  const updateDevice = useStoreActions(actions => actions.devices.updateDevice);
  const [waiting, setWaiting] = useState(false);
  const [wasChanged, setWasChanged] = useState(true);

  useEffect(() => {
    setValue(props.value);
  }, []);

  return (
    <Grid container className={styles.fieldGridContainer}>
      <Grid item xs={4} className={styles.fieldTitleGrid}>
        <Typography className={styles.filedTitle}>{props.title}</Typography>
      </Grid>
      <Grid item xs={4} className={styles.fieldInputGrid}>
        <TextField
          value={value}
          onChange={(event: any) => {
            setValue(event.target.value);
            if (event.target.value !== props.value) {
              setWasChanged(false);
            } else {
              setWasChanged(true);
            }
          }}
          className={styles.textField}
        />
      </Grid>
      <Grid item xs={4} className={styles.fieldBtnGrid}>
        <Button
          variant="outlined"
          disabled={wasChanged}
          onClick={async () => {
            try {
              setWaiting(true);
              await updateDevice({
                [props.title]: value,
                deviceId: props.serial
              });
              setWaiting(false);
              setWasChanged(true);
              props.onBoot(true);
            } catch (e) {
              props.setError(e.message);
            }
          }}
        >
          {t`update`}
        </Button>
        {waiting && <CircularProgress />}
      </Grid>
    </Grid>
  );
}

function DropDownFiled(props: any) {
  const [value, setValue] = useState<any>("");
  const [waiting, setWaiting] = useState<boolean>(false);
  const [wasChanged, setWasChanged] = useState<boolean>(true);

  const updateDevice = useStoreActions(actions => actions.devices.updateDevice);
  const selectValues =
    props.value === "enabled" || props.value === "disabled"
      ? ["enabled", "disabled"]
      : ["on", "off"];

  useEffect(() => setValue(props.value), []);

  return (
    <Grid container className={styles.fieldGridContainer}>
      <Grid item xs={4} className={styles.fieldTitleGrid}>
        <Typography className={styles.filedTitle}>{props.title}</Typography>

      </Grid>
      <Grid item xs={4}>
        <Select
          // disabled={false}
          value={value}
          onChange={(event: any) => {
            setValue(event.target.value);
            if (event.target.value !== props.value) {
              setWasChanged(false);
            } else {
              setWasChanged(true);
            }
          }}
          className={styles.selector}
        >
          <MenuItem value={selectValues[0]}>{selectValues[0]}</MenuItem>
          <MenuItem value={selectValues[1]}>{selectValues[1]}</MenuItem>
        </Select>
      </Grid>
      <Grid item xs={4} className={styles.fieldBtnGrid}>
        <Button
          variant="outlined"
          disabled={wasChanged}
          onClick={async () => {
            try {
              setWaiting(true);
              await updateDevice({
                [props.title]: value,
                deviceId: props.serial
              });
              setWaiting(false);
              setWasChanged(true);
              props.onBoot(true);
            } catch (e) {
              props.setError(e.message);
            }
          }}
        >
          {t`update`}
        </Button>
        {waiting && <CircularProgress />}
      </Grid>
    </Grid>
  );
}

interface IDevice {
  serial: any;
  pin: any;
  systems: any;
  units: any;
  isConnected: boolean;
  id: string;
  protocolVersion: any;
  firmwareVersion: any;
  buildDate: any;
  site?: any;
}
export default function DeviceManager(props: any) {
  const upgradeFirmware = useStoreActions(actions => actions.devices.upgradeFirmware);
  const firmwareStatus = useStoreActions(actions => actions.devices.firmwareStatus);
  const [error, setError] = useState<any>(null);
  const [shouldBoot, setShouldBoot] = useState<boolean>(false);
  const [showBootAlert, setShowBootAlert] = useState<boolean>(false);
  const [showBootSpinner, setShowBootSpinner] = useState<boolean>(false);
  const [editable, setEditable] = useState<boolean>(false);
  const [device, setDevice] = useState<IDevice>({
    serial: "",
    pin: "",
    systems: "",
    units: "",
    isConnected: false,
    protocolVersion: "protocolVersion",
    firmwareVersion: "firmwareVersion",
    buildDate: "buildDate",
    id: ""
  });
  const [allUnits, setAllUnits] = useState<any>({});
  const [indoorUnits, setIndoorUnits] = useState<any>([]);
  const [outdoorUnits, setOutdoorUnits] = useState<any>([]);
  const [promptColor, setPromptColor] = useState<string>("#f05347");
  const [fmStatus, setFmStatus] = useState<string>("");
  const [fmPercent, setFmPercent] = useState<string>("");
  const [selectedDeviceToDelete, setDeviceToDelete] = useState<any>(null);
  const deleteDevice = useStoreActions(actions => actions.devices.deleteDevice);
  const deleteSelectedDevice = () => {
    deleteDevice(selectedDeviceToDelete);
    setDeviceToDelete(null);
    props.history.push(`/devices`);
  };
  let checkStatus = true;
  const getSiteName = useStoreState(state => state.sites.getSiteName);


  // const getDeviceMetaDate = useStoreActions(
  //   actions => actions.devices.getDeviceMetaDate
  // );
  // const cleanDeviceMetaDate = useStoreActions(
  //   actions => actions.devices.setChosenDeviceMetaData
  // );
  // const deviceMetaData = useStoreState(
  //   state => state.devices.chosenDeviceMetaData
  // );
  // const bootDevice = useStoreActions(actions => actions.devices.bootDevice);

  const getDevice = useStoreActions(actions => actions.devices.getDeviceById);
  const controlDevice = useStoreActions(
    actions => actions.devices.controlDevice
  );

  const getAllUnits = useStoreActions(actions => actions.units.getAllUnits);

  const {
    match: {
      params: { deviceId }
    }
  } = props;

  // Called when entering the component.
  const checkStatusFMFc = async (device: any) => {
    const status = await firmwareStatus(device.id);

    // do nothing when no status
    if (!status.data || (status.data && status.data.status === '')) {
      setFmStatus('');
      return;
    }

    const statusToShow = status.data.status !== 'UPDATING' ? status.data.status : `${status.data.status}...`
    setFmStatus(statusToShow);
    setFmPercent(`${status.data.percent}% complete`);
    if (status.data.status !== "UPDATED") {
      checkStatusFM(device);
    }
  }


  // Using the state to clean the last timeout that is running
  const [checkStatusFM, setCheckStatusFM] = useState<any>(() => (deviceData: any) => setTimeout(async () => {
    // If already in the timeout, but exited the screen - don't do anything
    if (!checkStatus) {
      return;
    }
    const status = await firmwareStatus(deviceData.id);
    const statusToShow = status.data.status !== 'UPDATING' ? status.data.status : `${status.data.status}...`
    setFmStatus(statusToShow);
    setFmPercent(`${status.data.percent}% complete`);

    // As long as not UPDATED - will continue on checking status
    if (status.data.status !== "UPDATED") {
      checkStatusFM(deviceData);
    } else {
      setTimeout(() => {
        setFmStatus("");
      }, 23 * 1000)
    }
  }, 3000));


  useEffect(() => {
    if (!deviceId) {
      return;
    }

    getAllUnits().then((allunits: any) => {
      setAllUnits(allunits);
    });
    getDevice(deviceId).then((res: any) => {
      setDevice(res);
      //Check if UPDATING when entering component
      checkStatusFMFc(res);
    });

    // Stop the query of FM update status when leaving component
    return () => {
      checkStatus = false;
      setCheckStatusFM(null);
    }

  }, [deviceId]);

  useEffect(() => {
    if (_.isEmpty(device) || _.isEmpty(allUnits)) {
      return;
    }
    const { units } = device;
    let indoorUnits: any = [];
    let outdoorUnits: any = [];

    const filterUnits =
      units &&
      units.filter((unitId: any) => {
        const unit = allUnits[unitId];
        if (unit) {
          if (unit.type === 1) {
            return indoorUnits.push(unit);
          } else {
            return outdoorUnits.push(unit);
          }
        }
      });
    setIndoorUnits(indoorUnits);
    setOutdoorUnits(outdoorUnits);
  }, [device, allUnits]);

  // Will close edit screen when finished booting
  // const onBoot = async () => {
  //   if (showBootSpinner) {
  //     setTimeout(async () => {
  //       try {
  //         await getDeviceMetaDate(device.serial);

  //         if (!_.isEmpty(deviceMetaData)) {
  //           onBoot();
  //         } else {
  //           setShowBootSpinner(false);
  //         }
  //       } catch (e) {
  //         onBoot();
  //       }
  //     }, 5 * 1000);
  //   } else {
  //     props.onClose();
  //   }
  // };

  // useEffect(() => {
  //   if (showBootSpinner) {
  //     onBoot();
  //   }
  // }, [showBootSpinner]);


  const onFileLoad = (event: any) => {
    if (event.target) {
      const file = event.target.files[0];
      const data = new FormData();
      data.append('file', file);

      upgradeFirmware({ deviceId: device.id, command: data });
      setFmStatus("Starting...");
      checkStatusFM(device);

    } else {
      console.log("something whent wrong while loading the file", event);
    }
  }

  const content = () => {
    const {
      serial,
      pin,
      systems,
      units = [],
      isConnected,
      protocolVersion,
      firmwareVersion,
      buildDate
    } = device;

    // const rows = _.map(deviceMetaData, (rowValue, rowTitle) => {
    //   if (rowTitle === "remote-access" || _.includes(rowTitle, "ssl")) {
    //     return (
    //       <div key={rowTitle}>
    //         <DropDownFiled
    //           value={rowValue}
    //           title={rowTitle}
    //           setError={setError}
    //           serial={device && serial}
    //           onBoot={setShouldBoot}
    //         />
    //       </div>
    //     );
    //   }

    //   return (
    //     <div key={rowTitle}>
    //       <InputFiled
    //         value={rowValue}
    //         title={rowTitle}
    //         setError={setError}
    //         serial={device && serial}
    //         onBoot={setShouldBoot}
    //       />
    //     </div>
    //   );
    // });

    const canLeave = () => {
      if (shouldBoot) {
        setShowBootAlert(true);
      } else {
        props.history.push("/devices");
      }
    };
    return (
      <Paper className={styles.section}>
        {shouldBoot && (
          <Typography
            className={styles.bootTitle}
          >{t`Boot device required`}</Typography>
        )}

        <Formik
          initialValues={{
            serialNumber: serial,
            connectionStatus: isConnected.toString(),
            protocolVersion: protocolVersion,
            firmwareVersion: firmwareVersion,
            indoorUnits: indoorUnits.length.toString(),
            outdoorUnits: outdoorUnits.length.toString(),
            numOfSystems: systems.length,
            buildDate: buildDate
          }}
          enableReinitialize={true}
          onSubmit={() => { }}
        >
          {props => {
            const {
              handleSubmit,
              handleReset,

              isSubmitting
            } = props;
            return (
              <form className={styles.siteDetailsForm}>
                <Typography
                  className={styles.deviceInfoTitle}
                >{t`Device Information`}
                  <IconButton
                    onClick={() => {
                      setDeviceToDelete(device.id);
                    }}
                  >
                    <Delete />
                  </IconButton></Typography>

                <AdvancedInputField
                  name="serialNumber"
                  label={t`serial number`}
                  editable={editable}
                  {...props}
                />
                <AdvancedInputField
                  name="connectionStatus"
                  label={t`connection status`}
                  editable={editable}
                  {...props}
                />
                <AdvancedInputField
                  name="protocolVersion"
                  label={t`protocol version`}
                  editable={editable}
                  {...props}
                />
                {firmwareVersion && (
                  <AdvancedInputField
                    name="firmwareVersion"
                    label={t`firmware version`}
                    editable={editable}
                    {...props}
                  />
                )}

                <AdvancedInputField
                  name="indoorUnits"
                  label={t`indoor units`}
                  editable={editable}
                  {...props}
                />
                <AdvancedInputField
                  name="outdoorUnits"
                  label={t`outdoor units`}
                  editable={editable}
                  {...props}
                />
                <AdvancedInputField
                  name="numOfSystems"
                  label={t`number of systems`}
                  editable={editable}
                  {...props}
                />
                {buildDate && (
                  <AdvancedInputField
                    name="buildDate"
                    label={t`build date`}
                    editable={editable}
                    {...props}
                  />
                )}
                <div className={styles.addButtonContainer}>
                  <Button
                    className={styles.buttonStyle}
                    variant="contained"
                    component="label"
                  >
                    {t`Upload firmware update`}
                    <input
                      onChange={onFileLoad}
                      type="file"
                      style={{ display: "none" }}
                    />
                  </Button>
                </div>
                {fmStatus &&
                  <div>
                    <Typography
                      className={styles.deviceInfoTitle}
                    >{fmStatus}
                    </Typography>
                    <Typography>{fmPercent}</Typography>

                  </div>}
              </form>
            );
          }}
        </Formik>

        {/* <Grid container className={styles.fieldGridContainer}> */}
        {/* <Button
            variant="outlined"
            onClick={() => {
              canLeave();
            }}
          >
            {t`Go back`}
          </Button> */}
        {/* <Button
            variant="outlined"
            className={styles.saveButtonStyle} 
            onClick={
              async () => {
              try {
                await bootDevice(serial);
                cleanDeviceMetaDate({});
                setShowBootSpinner(true);
              } catch (e) {
                console.log("error:", e);
                setError(e.message);
              }
            }}
          >
            {t`Boot device`}
          </Button>*/}
        {/* </Grid> */}

        {/* {!_.isEmpty(deviceMetaData) && rows} */}

        <Grid className={styles.consoleContainer}>
          {protocolVersion >= 1 && (
            <Terminal
              color="#f05347"
              outputColor="white"
              prompt={promptColor}
              backgroundColor="black"
              barColor="black"
              style={{
                maxHeight: "100%",
                fontSize: "1.125em",
                width: "100%"
              }}
              commands={{
                help: (cmd: any, print: any) => {
                  setPromptColor("black");
                  const command = { command: cmd.slice(0).join(" ") };
                  controlDevice({ deviceId, command })
                    .then((res: any) => {
                      print(res);
                      setPromptColor("#f05347");
                    })
                    .catch((err: any) => {

                      if (!_.isString(err)) {
                        err = JSON.stringify(err);
                      }

                      print(err);
                      setPromptColor("#f05347");
                    });
                }
              }}
              commandPassThrough={(cmd: any, print: any) => {
                setPromptColor("black");
                const command = { command: cmd.slice(0).join(" ") };
                controlDevice({ deviceId, command })
                  .then((res: any) => {
                    print(res);
                    setPromptColor("#f05347");
                  })
                  .catch((err: any) => {

                    if (!_.isString(err)) {
                      err = JSON.stringify(err);
                    }

                    print(err);
                    setPromptColor("#f05347");
                  });
              }}
              msg="DEVICE CONTROL"
              allowTabs={false}
              showActions={false}
            />
          )}
        </Grid>
      </Paper>
    );
  };

  return (
    <NavigationBar
      navTitle={t`Device Management`}
      pageTitle={t`Device Management`}
      {...props}
    >

      <div className={styles.editDeviceWrapper}>
        <SimpleModal
          showModal={!!error}
          title="Error had happened"
          contentText={error ? error : ""}
          actionName="OK"
          onAction={() => {
            setError(null);
          }}
          closeModal={() => {
            setError(null);
          }}
        />

        <SimpleModal
          showModal={showBootAlert}
          title="Missing Boot alert"
          contentText="Changes will not be set without booting the device. Are you sure you want to leave?"
          actionName="Leave"
          onAction={() => {
            props.onClose();
          }}
          closeModal={() => {
            setShowBootAlert(false);
          }}
        />
        <SimpleModal
          showModal={showBootSpinner}
          title="Booting, please wait..."
          actionName="OK"
          onAction={_.noop}
          closeModal={_.noop}
          hideBtns={true}
        >
          <div className={styles.bootWrapper}>
            <CircularProgress />
          </div>
        </SimpleModal>

        {!_.isEmpty(device) && content()}
      </div>
      <SimpleModal
        showModal={!!selectedDeviceToDelete}
        title="Are you sure?"
        contentText={device.site ? `Are you sure you want to delete device ${device.serial} in site ${getSiteName(device.site)} ?` :
          `Are you sure you want to delete device ${device.serial} ?`}
        actionName="OK"
        onAction={deleteSelectedDevice}
        closeModal={() => setDeviceToDelete(null)}
      />
    </NavigationBar>
  );
}
