import { MenuItem, Popover, Select, Typography } from "@material-ui/core";
import React from "react";
import SvgArrow from "../../icons/Arrow";
import useStyles from "./MenuDropDown.style";

const MenuDropDown = (props: any) => {
  const classes = useStyles();
  const { value, options, onChange, emptyOption, isEmptyOptionDisabled = false, placeholder = "no options" } = props;

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    onChange(event.target.value as string);
  };

  return (
    <Select
      variant="filled"
      value={value}
      onChange={handleChange}
      className={classes.selectContainer}
      classes={{
        filled: classes.selectStyle,
        icon: classes.arrowIcon,
        select: classes.focusStyle,
        iconFilled: classes.iconStyle
      }}
      displayEmpty
      disableUnderline
      MenuProps={{
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left"
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "left"
        },
        getContentAnchorEl: null
      }}
      IconComponent={SvgArrow}
      // classes={{ icon: classes.arrowIcon }}
      disabled={options.length < 2}
    >{
      options.length < 2 ?
          <MenuItem  value="" className={classes.optionStyle}>
            <Typography className={classes.optionText}>{placeholder}</Typography>
          </MenuItem> :
        options.map((option: any) => {
          return (
            <MenuItem key={option.id} value={option.id} className={classes.optionStyle}>
              <Typography className={classes.optionText}>{option.name}</Typography>
            </MenuItem>
          );
        })}
    </Select>
  );
};

export default MenuDropDown;
