import { Customer, Site } from "coolremote-sdk";
import {
  Action,
  action,
  ActionOn,
  actionOn,
  Computed,
  computed,
  thunk,
  Thunk,
} from "easy-peasy";
import _ from "lodash";
import { IRootStoreModel } from "./RootStore";

export interface ISite {
  id: string;
  name: string;
  description?: string;
  country?: string;
  city?: string;
  state?: string;
  address?: string;
  postalCode?: string;
  timezone?: string;
  devices: string[];
  zones: string[];
  triggers: string[];
  alerts: string[];
  customer: string;
  unitSupportedOperationModes: number[];
  unitSupportedFanModes: number[];
  unitSupportedSwingModes: number[];
  unitTemperatureLimits?: any;
  shouldAutoLimit?: boolean;
  role: any;
  ignoreStatus?: any;
  managementStatus?: number;
  serviceStatus?: number;
}

export interface ISitesModel {
  allSites: ISiteMap;

  getSitesByCustomer: Thunk<ISitesModel, string>;
  deleteSite: Thunk<ISitesModel, string>;
  createSite: Thunk<ISitesModel, { customerId: any; data: any }>;
  getSite: Thunk<ISitesModel, string>;
  updateSite: Thunk<ISitesModel, { id: string; data: any }>;
  getSiteName: Computed<ISitesModel, (id?: string) => string>;
  getSiteCustomer: Computed<ISitesModel, (id?: string) => string>;
  initialize: Action<ISitesModel, any>;
  onInitialized: ActionOn<ISitesModel, IRootStoreModel>;
}
export interface ISiteMap {
  [key: string]: ISite;
}

export const SitesModel: ISitesModel = {
  allSites: {},
  initialize: action((state, payload) => {
    const newSites: ISiteMap = _(Object.values(payload))
      .map((site: any) => {
        const devices = site.devices ? Object.keys(site.devices) : [];
        const zones = site.zones ? Object.keys(site.zones) : [];
        const triggers = site.triggers ? Object.keys(site.triggers) : [];
        const alerts = site.alerts ? Object.keys(site.alerts) : [];

        const newSite: ISite = { ...site, devices, zones, triggers, alerts };

        return newSite;
      })
      .keyBy("id")
      .value();
    state.allSites = newSites;
  }),

  onInitialized: actionOn((actions) => [actions.initialize], () => { }),
  getSitesByCustomer: thunk((actions, payload) => {
    return Customer.getSites(payload);
  }),
  deleteSite: thunk((actions, payload) => {
    return Site.delete(payload);
  }),
  createSite: thunk(async (actions, payload) => {
    const { customerId, data } = payload;

    return Customer.createSite(customerId, data);
  }),
  getSite: thunk((actions, payload) => {
    return Site.getSiteById(payload);
  }),
  updateSite: thunk((actions, payload) => {
    return Site.update(payload.id, payload.data);
  }),
  getSiteName: computed([(state) => state.allSites], (allSites) => (id) => {
    if (id && allSites[id]) {
      return allSites[id].name;
    } else {
      return "-";
    }
  }),
  getSiteCustomer: computed([(state) => state.allSites], (allSites) => (id) => {
    if (id && allSites[id]) {
      return allSites[id].customer;
    } else {
      return "-";
    }
  })
};
