import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useStoreState } from "../../models/RootStore";

const Authenticated = ({ component: Component, ...props }: any) => {
  const user = useStoreState(state => state.users.me);
  const { isLoggedIn } = user;
  return (
    <Route
      exact={true}
      {...props}
      render={innerProps => {
        return isLoggedIn ? <Component {...innerProps} disconnected={props.disconnected} /> : <Redirect to="/" />;
      }}
    />
  );
};
export default Authenticated;
