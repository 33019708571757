import _ from 'lodash';
import {
    FormControlLabel,
    Checkbox,
    TextField
} from '@material-ui/core';
import React, { useState, useEffect } from "react";
import SimpleModal from "../../widgets/SimpleModal/SimpleModal";
import { ITriggerModel } from '../../models/TriggerModel'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    dropdown: {
        minWidth: "90%"
    }
}));

interface IProps {
    triggerInfo: ITriggerModel | null,
    onClose: () => void,
    title: string,
    contentText: string,
    btnName: string,
    onSave: (
        triggerName: string,
        template: string,
        id: string,
        isEnabled: boolean,
        sendOnce: boolean,
        resources: string[],
        resourceIds: string[]
    ) => void,
}

export default function OneClient(props: IProps) {
    const [triggerName, setTriggerName] = useState("");
    const [isEnabled, setIsEnabled] = useState(false);
    const [sendOnce, setSendOnce] = useState(false);
    const [resources, setResources] = useState([""]);
    const [resourceIds, setResourceIds] = useState([""]);
    // const [template, setTemplate] = useState("");
    const UiClasses = useStyles();


    const initialize = async () => {
        if (props.triggerInfo) {
            setTriggerName(props.triggerInfo.name || "");
            setIsEnabled(props.triggerInfo.isEnabled || "");
            setSendOnce(props.triggerInfo.sendOnce);
            setResources(props.triggerInfo.resources || []);
            setResourceIds(props.triggerInfo.resourceIds);
        }
    }

    useEffect(() => { initialize() }, []);


    const getEditFileds = () => {
        return (
            <div>
                <div>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={isEnabled}
                                onChange={() => setIsEnabled(!isEnabled)}
                                value={isEnabled}
                                color="primary"
                            />
                        }
                        label="IS enabled"
                    />
                </div>
                <div>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={sendOnce}
                                onChange={() => setSendOnce(!sendOnce)}
                                value={sendOnce}
                                color="primary"
                            />
                        }
                        label="send Alert only once"
                    />
                </div>
            </div>
        )
    };


    const getCreateFields = () => {

    }

    const onSaveEdit = () => {
        const template = props.triggerInfo?.template || "";
        const id = props.triggerInfo ? props.triggerInfo.id : "";

        props.onSave(
            triggerName,
            template,
            id,
            isEnabled,
            sendOnce,
            resources,
            resourceIds
        )
    };

    const onSaveCreate = () => {
        return <div>

        </div>
    };

    return <SimpleModal
        showModal={true}
        title={props.title}
        contentText={props.contentText}
        actionName={props.btnName}
        onAction={() => { props.triggerInfo ? onSaveEdit() : onSaveCreate() }}
        closeModal={() => props.onClose()}
    >
        <div>
            <TextField
                id="standard-helperText"
                label="Trigger name"
                disabled
                className={UiClasses.dropdown}
                margin="normal"
                value={triggerName}
                onChange={_.noop}
            />
        </div>
        {props.triggerInfo && getEditFileds()}
        {!props.triggerInfo && getCreateFields()}

    </SimpleModal>


}
