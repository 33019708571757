import { TextField, MenuItem } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import SimpleModal from "../../widgets/SimpleModal/SimpleModal";
import styles from "./CustomerManagment.module.css";
import { useStoreState } from "../../models/RootStore";
import * as Yup from "yup";
import _ from "lodash";


let emailSchema = Yup.object().shape({
  email: Yup.string().email("invalid email").required("required")
});



interface IProps {
  username: string;
  description: string;
  onSave: (data: any) => void;
  onClose: () => void;
  title: string;
  contentText: string;
  btnName: string;
}

export default function OneCustomer(props: IProps) {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [type, setType] = useState("");
  const [smsUsage, setSmsUsage] = useState("");
  const [billingModel, setBillingModel] = useState("");
  const [prePaidSessionLengthMinutes, setPrePaidSessionLengthMinutes] = useState('');
  const [prePaidAllocatedSessions, setPrePaidAllocatedSessions] = useState('');
  const [contactPerson, setContactPerson] = useState("");
  const [email, setEmail] = useState("");
  const [licensedUnits, setLicensedUnits] = useState("");
  const [status, setStatus] = useState("");
  const types = useStoreState(s => s.types);

  const [errorText, setErrorText] = useState<any>({
    name: '',
    prePaidSessionLengthMinutes: '', prePaidAllocatedSessions: ''
  });
  useEffect(() => {
    setName(props.username);
    setDescription(props.description);
  }, [props.description, props.username]);

  const checkValidation = async () => {
    const error = { ...errorText };
    if (!name) {
      error.name = "required field";
      setErrorText(error);
      return;
    }
    if (prePaidSessionLengthMinutes && _.isNaN(parseInt(prePaidSessionLengthMinutes))) {
      error.prePaidSessionLengthMinutes = "please enter a number";
      setErrorText(error);
      return;
    }
    if (prePaidAllocatedSessions && _.isNaN(parseInt(prePaidAllocatedSessions))) {
      error.prePaidAllocatedSessions = "please enter a number";
      setErrorText(error);
      return;
    }

    if (!licensedUnits) {
      error.licensedUnits = "please enter a number of licensed units";
      setErrorText(error);
      return;
    }

    if (licensedUnits && _.isNaN(parseInt(licensedUnits))) {
      error.licensedUnits = "please enter a number";
      setErrorText(error);
      return;
    }

    if (!contactPerson) {
      error.contactPerson = "please enter a CA sales contact phone number";
      setErrorText(error);
      return;
    }

    const emailError = await emailSchema.isValid({ email })
    if (!emailError) {
      error.emailError = "please enter a valid Email";
      setErrorText(error);
      return;
    }

    const data = {
      name,
      prePaidSessionLengthMinutes: prePaidSessionLengthMinutes ?
        parseInt(prePaidSessionLengthMinutes) : 0,
      prePaidAllocatedSessions: prePaidAllocatedSessions ?
        parseInt(prePaidAllocatedSessions) : 0,
      description,
      status: status ? parseInt(status) : 1,
      email,
      contactPerson,
      billingModel: billingModel ? parseInt(billingModel) : 0,
      licensedUnits: licensedUnits ? parseInt(licensedUnits) : 0,
      createdAt: Date.now()
    };
    props.onSave(data);
  };
  const statusesOptions = _.map(types.customerStatuses, status => {
    return (<MenuItem key={status.value} value={`${status.value}`}>
      {status.text}
    </MenuItem>)
  });
  const billingModelOptions = _.map(types.billingModels, model => {
    return (<MenuItem key={model.value} value={model.value}>
      {model.text}
    </MenuItem>)
  });
  return (
    <SimpleModal
      showModal={true}
      title={props.title}
      contentText={props.contentText}
      actionName={props.btnName}
      onAction={checkValidation}
      closeModal={() => props.onClose()}
    >
      <div className={styles.createClinetModalContainer}>

        <TextField
          id="standard-helperText"
          label="Customer name"
          className={styles.textFieldDialogStyle}
          margin="normal"
          value={name}
          onChange={e => setName(e.target.value)}
          inputProps={{
            maxLength: 20
          }}
          error={!!errorText.name && !name}
          helperText={errorText.name && !name && errorText.name}
        />
      </div>
      <div>
        <TextField
          id="standard-helperText"
          label="Description"
          className={styles.textFieldDialogStyle}
          margin="normal"
          value={description}
          onChange={e => setDescription(e.target.value)}
          InputProps={{
            multiline: true,
            rows: 2
          }}
        />
      </div>
      {/* <div>
        <TextField
          id="standard-helperText"
          label="Type"
          className={styles.textFieldDialogStyle}
          margin="normal"
          value={type}
          onChange={e => setType(e.target.value)}
          InputProps={{
            multiline: true,
            rows: 2
          }}
        />
      </div> */}
      {/* <div>
        <TextField
          id="standard-helperText"
          label="SMS usage"
          className={styles.textFieldDialogStyle}
          margin="normal"
          value={smsUsage}
          onChange={e => setSmsUsage(e.target.value)}
          InputProps={{
            multiline: true,
            rows: 2
          }}
        />
      </div> */}
      <div>
        <TextField
          id="standard-helperText"
          label="Contact Person"
          className={styles.textFieldDialogStyle}
          margin="normal"
          value={contactPerson}
          onChange={e => setContactPerson(e.target.value)}
          error={!!errorText.contactPerson && !contactPerson}
          helperText={errorText.contactPerson && !contactPerson && errorText.contactPerson}
          InputProps={{
            multiline: true,
            rows: 2
          }}
        />
      </div>
      <div>
        <TextField
          id="standard-helperText"
          label="Email"
          className={styles.textFieldDialogStyle}
          margin="normal"
          value={email}
          onChange={e => setEmail(e.target.value)}
          error={!!errorText.emailError}
          helperText={errorText.emailError && errorText.emailError}
          InputProps={{
            multiline: true,
            rows: 2
          }}
        />
      </div>
      <div>
        <TextField
          select
          name={status}
          label={"Status"}
          value={status}
          onChange={e => setStatus(e.target.value)}
          className={styles.selectField}
          // className={styles.textFieldDialogStyle}
          InputProps={{
            disableUnderline: true,
            classes: {
              formControl: styles.inputRoot,
              root: styles.inputRoot
            }
          }}
          InputLabelProps={{ shrink: false }}
          margin="none"
        >
          {statusesOptions}
        </TextField>

      </div>
      <div>
        <TextField
          select
          name={billingModel}
          label={"Billing Model"}
          value={billingModel}
          onChange={e => setBillingModel(e.target.value)}
          className={styles.selectField}
          // className={styles.textFieldDialogStyle}
          InputProps={{
            disableUnderline: true,
            classes: {
              formControl: styles.inputRoot,
              root: styles.inputRoot
            }
          }}
          InputLabelProps={{ shrink: false }}
          margin="none"
        >
          {billingModelOptions}
        </TextField>
        <div>
          <TextField
            id="standard-helperText"
            label="Subscriptions Units"
            className={styles.textFieldDialogStyle}
            margin="normal"
            value={licensedUnits}
            error={!!errorText.licensedUnits}
            helperText={errorText.licensedUnits && errorText.licensedUnits}
            onChange={e => setLicensedUnits(e.target.value)}
            InputProps={{
              multiline: true,
              rows: 2
            }}
          />
        </div>

      </div>
      {billingModel == '1' &&
        (
          <>
            <div>
              <TextField
                type="number"
                id="standard-helperText"
                label="prePaid Session Length Minutes"
                className={styles.textFieldDialogStyle}
                margin="normal"
                value={prePaidSessionLengthMinutes}
                onChange={e => setPrePaidSessionLengthMinutes(e.target.value)}
                InputProps={{
                  multiline: true,
                  rows: 2
                }}
                error={!!errorText.prePaidSessionLengthMinutes}
                helperText={errorText.prePaidSessionLengthMinutes}
              />
            </div>
            <div>
              <TextField
                type="number"
                id="standard-helperText"
                label="prePaid Allocated Sessions"
                className={styles.textFieldDialogStyle}
                margin="normal"
                value={prePaidAllocatedSessions}
                onChange={e => setPrePaidAllocatedSessions(e.target.value)}
                InputProps={{
                  multiline: true,
                  rows: 2
                }}
                error={!!errorText.prePaidAllocatedSessions}
                helperText={errorText.prePaidAllocatedSessions}
              />
            </div>
          </>
        )
      }

      {/* <div>
        <TextField
          disabled
          id="standard-helperText"
          label="prePaidUsedSessions"
          className={styles.textFieldDialogStyle}
          margin="normal"
          value={prePaidSessionLengthMinutes}
          InputProps={{
            multiline: true,
            rows: 2
          }}
        />
      </div> */}
    </SimpleModal>
  );
}
