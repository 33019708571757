import {
    Button,

    List,
    makeStyles,
    Typography,
} from "@material-ui/core";
import {
    Dashboard,
    Devices,
    PeopleAlt,
    Room,
    Settings,
    SupervisedUserCircle,
    VerifiedUser
} from "@material-ui/icons";
import React from "react";
import packageJSON from "../../../package.json";
import {FullLogo} from "../../iconImages";
import {Diagnostics, SvgAnomalies, SvgNotifications, Unit} from "../../icons";
import {useStoreState} from "../../models/RootStore";
import styles from "./menu.style";
import MenuItem from "./MenuItem";

const Menu: React.FC<any> = ({history}) => {
    const classes = styles();
    const displayFlags = useStoreState((state) => state.users.displayFlags);

    const menuOptions = [
        {
            src: <Dashboard className={classes.iconSize}/>,
            text: "Dashboard",
            path: "/dashboard",
            disabled: !displayFlags.enableDashboard
        },

        {
            src: <PeopleAlt className={classes.iconSize}/>,
            text: "Customers",
            path: "/customers",
            disabled: !displayFlags.enableCustomers
        },
        {
            src: <Room className={classes.iconSize}/>,
            text: "Sites",
            path: "/sites",
            disabled: !displayFlags.enableSites
        },
        {
            src: <Devices className={classes.iconSize}/>,
            text: "Devices",
            path: "/devices",
            disabled: !displayFlags.enableDevices
        },

        {
            src: <SupervisedUserCircle className={classes.iconSize}/>,
            text: "Users",
            path: "/users",
            disabled: !displayFlags.enableUsers
        },
        {
            src: <SvgAnomalies className={classes.iconSize}/>,
            text: "Anomaly Rules",
            path: "/anomalies-settings",
            disabled: !displayFlags.enableAnomalies
        },
        {
            src: <SvgNotifications className={classes.iconSize}/>,
            text: "Alert Log",
            path: "/alerts",
            disabled: !displayFlags.enableAlerts
        },
        // {
        //   src: <VerifiedUser className={classes.iconSize} />,
        //   text: "Audit",
        //   path: "/audit",
        //   disabled: false
        // },
        {
            src: <Settings className={classes.iconSize}/>,
            text: "Support",
            path: "/SupportView",
            disabled: !displayFlags.enableSupport
        },
        {
            src: <Diagnostics/>,
            text: "Unit Diag",
            path: "/unit-diag/",
            disabled: (!displayFlags.enableUnitDiag || !displayFlags.enableUnitDiagTopbar)
        },
        {
            src: <Diagnostics/>,
            text: "System Diagnostics",
            path: "/system-diagnostics/",
            disabled: (!displayFlags.enableSysDiag || !displayFlags.enableSysDiagTopbar)
        }, {
            src: <PeopleAlt className={classes.iconSize}/>,
            text: "Subscriptions",
            path: "/subscriptions",
            disabled: !displayFlags.enableSubscriptions
        }
    ];

    return (
        <List className={classes.nav} component="nav">
            <div>
                <Button
                    className={classes.logoButton}
                    onClick={() => history.push("/dashboard")}
                >
                    <img src={FullLogo} className={classes.logoStyle}/>
                </Button>
                {menuOptions.map((menuOption, index) => {
                    const {src, text, path, disabled} = menuOption;
                    return disabled ?
                        null
                        :
                        (
                            <MenuItem
                                key={`nav-option-${index}`}
                                Icon={src}
                                name={text}
                                path={path}
                                disabled={disabled}
                                history={history}
                            />
                        );
                })}
            </div>
            <Typography variant="caption" className={classes.versionText}>
                Version {packageJSON.version}
            </Typography>
        </List>
    );
};

export default Menu;
