import { Button, Theme, withStyles } from "@material-ui/core";
import React from "react";

const StyledButton = (props: any) => {
  const {white, red, width = 169, marginRight, uppercase, bold, onClick, ...otherprops} = props;
  const purple = "linear-gradient(to left, #421a40 100%, #29132e 0%)";
  const disabledPurple = "linear-gradient(to left, #776a7b 99%, #876e86 0%)";
  const pressedWhite = "rgba(75, 28, 70, 0.1)";
  const textTransform = uppercase ?  "uppercase" : "capitalize";

  const StyledButton = withStyles({
  root: {
    height: 40,
    minWidth: width || 169,
    fontSize: 16,
    fontWeight: white || bold ? 500 : "normal",
    lineHeight: "normal",
    color: white ? "#4b1c46" : "#fff",
    textTransform,
    borderRadius: 4,
    background: white  || red ? white ? "#fff" :  "#f05347" : purple ,
    border: white ? "solid 1px #413044" : undefined,
    marginRight: marginRight ? 30 : undefined,
    "&:hover": {
      background: white || red ? white ? pressedWhite : "rgba(240, 83, 71, 0.9)" : purple
    },
    "&:focus": {
        background: white || red ? white ? pressedWhite : "rgba(240, 83, 71, 0.9)" : purple
        },
    "&:disabled": {
            background: white || red ? white ?  "#fff" :  "#f4867e" : "linear-gradient(to left, #776a7b 99%, #876e86 0%)",
            border: white ? "solid 1px #aaa2aa" : undefined,
            color: white ? "#4b1c46" : "#fff"
          }
  }
})(Button);
  return <StyledButton onMouseUp={onClick} {...otherprops}>{props.children}</StyledButton>;
};

export default StyledButton;
