import "./index.css";

import React from "react";
import ReactDOM from "react-dom";
import ReactModal from "react-modal";
import { StoreProvider, createStore } from "easy-peasy";
import Routes from "./screens/Routes/Routes";
import locale from "./services/localeService";
import storeModel from "./models/RootStore";
import { localXhr } from "./services/localXhr";
import { xhr } from "coolremote-sdk";
import { StylesProvider, MuiThemeProvider } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import theme from "./themes/defaultTheme";

locale.init();

ReactModal.setAppElement("#root");

const store = createStore(storeModel);

localXhr.loadToken();
xhr.setToken(localXhr.getToken());

ReactDOM.render(
  <StoreProvider store={store}>
    <MuiThemeProvider theme={theme}>
      <StylesProvider injectFirst>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Routes />
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </MuiThemeProvider>
  </StoreProvider>,
  document.getElementById("root")
);
