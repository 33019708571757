
import _ from 'lodash';
import { addLocale, useLocale } from 'ttag';

const LOCALE_COOKIE = '__locale';

class Locale {

  public init() {
    const locale = this.getLocale();

    if (locale !== 'en') {
      const translationsObj = require(`../locale/${locale}.po.json`);
      addLocale(locale, translationsObj);
      useLocale(locale);
    }
  }

  public getLocale() {
    const items = document.cookie.split(';');
    const cookies = _(items)
      .mapKeys((item) => _.trim(item.split('=')[0]))
      .mapValues((item) => _.trim(item.split('=')[1]))
      .value();

    return cookies[LOCALE_COOKIE] || 'en';
  }

  public setLocale(locale: string) {
    const translationsObj = require(`../locale/${locale}.po.json`);
    addLocale(locale, translationsObj);
    useLocale(locale);
    document.getElementsByTagName('html')[0].dir = 'ltr';
    if (['he'].includes(locale)) {
      document.getElementsByTagName('html')[0].dir = 'rtl';
    }
  }

  public saveLocale(locale: string) {
    document.cookie = `${LOCALE_COOKIE}=${locale}`;
  }

}

export default new Locale();
