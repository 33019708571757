import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  drpContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    padding: '0.7rem'
  },
  drpButtons: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridGap: '0.5rem'
  }
}));

export default useStyles;
