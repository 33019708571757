import {
  Grid,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from "@material-ui/core/";
import { Close, Search } from "@material-ui/icons";
import { FilterList } from "@material-ui/icons";
import clsx from "clsx";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { t } from "ttag";
import SelectionsMenu from "../../components/Header/SelectionsMenu";
import { Lookup } from "../../components/Lookup";
import { DeleteIcon, EditIcon } from "../../iconImages";
import ArrowIcon from "../../icons/ArrowLong";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import ServiceNavigationBar from "../../widgets/Menu/ServiceNavigationBar";
import SimpleModal from "../../widgets/SimpleModal/SimpleModal";
import UserMenu from "../../widgets/UserMenu/UserMenu";
import styles from "./SiteManagement.module.css";

const SiteManagement = (props: any) => {
  const [sites, setSites] = useState<any>({});
  const [filteredSites, setFilteredSites] = useState<any>([]);
  const [filterTerm, setFilterTerm] = useState<String>("");
  const [selectedSite, setSelectedSite] = useState<any>(null);
  const [updateFilter, setUpdateFilter] = useState<any>(0);
  const [showFilterPopup, setFilterPopup] = useState<string>("");
  const [lookupAnchor, setAnchor] = useState(null);
  const [appliedFilters, setAppliedFilters] = useState<any>({
    sitesNames: [],
    customersNames: []
  });
  const [filters, setFilters] = useState<any>({
    sitesNames: [],
    customersNames: []
  });

  const history = useHistory();
  const match = useRouteMatch();
  const getSites = useStoreActions((actions) => actions.sites.getSitesByCustomer);
  const deleteSite = useStoreActions((actions) => actions.sites.deleteSite);
  const selections = useStoreState((state) => state.selections.selections);
  const customers = useStoreState((states) => states.customers.customerList);

  useEffect(() => {
    const { customerId } = props.match.params;
    if (!customerId && !selections.customerId) {
      return;
    }

    if (!customerId && !!selections.customerId) {
      history.push(`/customers/${selections.customerId}/sites`);
      return;
    }
    getSites(customerId).then((data: any) => {
      setSites(data);
      setFilteredSites(data);
    });
  }, [getSites, props.match.params]);

  useEffect(() => {
    if (updateFilter === -1) {
      return;
    }
    const filtSites = _.filter(Object.values(sites), (site: any) => {
      return site.name.toLowerCase().indexOf(filterTerm.toLowerCase()) > -1;
    });
    setFilteredSites(filtSites);
  }, [filterTerm, sites, updateFilter]);

  useEffect(() => {
    if (!!filters.customersNames.length || _.isEmpty(customers)) {
      return;
    }
    const customersNames = Object.values(customers).map(
      (customer: any) => customer.name
    );
    setFilters({ sitesNames: filters.sitesNames, customersNames });
  }, [customers, appliedFilters, filters]);

  useEffect(() => {
    const sitesNames: any = [];
    Object.values(sites).forEach(
      (site: any) =>
        !sitesNames.includes(site.name) && sitesNames.push(site.name)
    );
    setFilters({ ...filters, sitesNames });
  }, [sites]);

  const clearSearchValue = () => {
    setFilterTerm("");
  };

  const deleteCurrentSite = () => {
    deleteSite(selectedSite).then(() => {
      delete sites[selectedSite];
      setSelectedSite(null);
      setUpdateFilter(updateFilter + 1);
    });
  };
  const handleFilterPopup = (event: any, column: string) => {
    setFilterPopup(column);
    setAnchor(event.currentTarget);
  };

  useEffect(() => {

    const { sitesNames: appliedSitesNames, customersNames: appliedCustomersNames } = appliedFilters;
    const hasNameFilter = !!appliedSitesNames.length;
    const hasCustomerFilter = !!appliedCustomersNames.length;

    if (!hasNameFilter && !hasCustomerFilter) {
      setFilteredSites(Object.values(sites));
      return;
    }

    const filterCode = (site: any) => {
      const { name: siteName, customer } = site;
      const customerName = customers[site.customer].name;

      if ((hasNameFilter && appliedSitesNames.indexOf(siteName) === -1) || (hasCustomerFilter && appliedCustomersNames.indexOf(customerName) === -1)) {
        return false;
      }
      return true;

    };

    setFilteredSites(Object.values(sites).filter(filterCode));
  }, [appliedFilters, customers, sites]);

  useEffect(() => {

    if (!filteredSites.length) {
      return;
    }

    const sitesTemp: any = [];
    const customersTemp: any = [];

    for (let siteIndex in filteredSites) {
      const site = filteredSites[siteIndex];
      const { name: siteName, customer } = site;
      const customerName = customers[customer]?.name;

      if (sitesTemp.indexOf(siteName) === -1) {
        sitesTemp.push(siteName);
      }
      if (customersTemp.indexOf(customerName) === -1) {
        customersTemp.push(customerName);
      }
    }

    setFilters({ sitesNames: sitesTemp, customersNames: customersTemp });

  }, [filteredSites]);

  const onApply = (selectedFilters: any) => {
    showFilterPopup === "sites"
      ? setAppliedFilters({ ...appliedFilters, sitesNames: selectedFilters })
      : setAppliedFilters({ ...appliedFilters, customersNames: selectedFilters });
    setFilterPopup("");
  };

  const hasFilters = !!Object.values(appliedFilters).flat().length;
  const { sitesNames: appliedSitesNames, customersNames: appliedCustomersNames } = appliedFilters;
  const sortedSites = _.orderBy(filteredSites, [(site) => site.name.toLowerCase()], ["asc"]);

  return (
    <div className={styles.view}>
      <ServiceNavigationBar
        title={t`All Sites`}
        filters={<SelectionsMenu hideSiteSelection hideUnitSelection hideSystemSelection onCustomerSelect={(id: any) => id ? history.push(`/customers/${id}/sites`) : history.push(`/sites`)} />}
        {...props}
      >
        {!selections.customerId ?
          (<Grid container direction={"column"} className={styles.noContentContainer}>
            <div className={styles.grayOval}>
              <ArrowIcon className={styles.arrowIcon} />
            </div>
            <Typography >
              {t`Please select a customer using the above filters.`}
            </Typography>
          </Grid>) :
          <div className={styles.contentArea}>
            <Grid container className={styles.content}>
              <Grid item xs={12}>
                <TableContainer >
                  <Table stickyHeader className="" aria-label="customized table">
                    <TableHead className={styles.tableHead}>
                      <TableRow>
                        <TableCell
                          className={styles.headCells}
                          onClick={(e: any) => handleFilterPopup(e, "sites")}
                        >
                          <div className={styles.headContainer}>
                            {t`NAME`}
                            <FilterList
                              className={clsx(styles.filterStyle, {
                                [styles.blueFilter]: !_.isEmpty(appliedSitesNames)
                              })}
                            />
                          </div>
                        </TableCell>
                        <TableCell
                          className={styles.headCells}
                          onClick={(e: any) => handleFilterPopup(e, "customers")}
                        >
                          <div className={styles.headContainer}>
                            {t`CUSTOMER`}
                            <FilterList
                              className={clsx(styles.filterStyle, {
                                [styles.blueFilter]: appliedCustomersNames.length
                              })}
                            />
                          </div>
                        </TableCell>
                        <TableCell align="center" className={styles.headCells}>
                          {t`COUNTRY`}
                        </TableCell>
                        <TableCell
                          align="left"
                          className={styles.headCells}
                        >{t`CITY`}</TableCell>
                        <TableCell
                          align="center"
                          className={styles.headCells}
                        >{t`# OF DEVICES`}</TableCell>
                        {/* <TableCell
                        align="center"
                        className={styles.headCells}
                      >{t`DETAILS`}</TableCell> */}
                        <TableCell
                          align="center"
                          className={styles.headCells}
                        >{t`DELETE`}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {sortedSites.map((site: any) => {
                        return (
                          <TableRow hover tabIndex={-1} key={site.id} onDoubleClick={() => props.history.push(`/site/${site.id}`)}>
                            <TableCell
                              component="th"
                              scope="row"
                              align="left"
                              className={styles.rowCell}
                            >
                              {site.name}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              align="left"
                              className={styles.rowCell}
                            >
                              {(site.customer &&
                                customers[site.customer] &&
                                customers[site.customer].name) ||
                                ""}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              align="center"
                              className={styles.rowCell}
                            >
                              {site.country || ""}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              align="left"
                              className={styles.rowCell}
                            >
                              {site.city || ""}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              align="center"
                              className={styles.rowCell}
                            >
                              {site.devices && site.devices.length}
                            </TableCell>
                            {/* <TableCell
                            component="th"
                            scope="row"
                            align="center"
                            className={styles.rowCell}
                          >
                            <IconButton
                              onClick={() =>
                                props.history.push(`/site/${site.id}`)
                              }
                            >
                              <img src={EditIcon} alt="edit user" />
                            </IconButton>
                          </TableCell> */}
                            <TableCell
                              component="th"
                              scope="row"
                              align="center"
                              className={styles.rowCell}
                            >
                              <IconButton
                                onClick={() => {
                                  setSelectedSite(site.id);
                                }}
                              >
                                <img src={DeleteIcon} alt="delete user" />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </div>
        }

        <SimpleModal
          showModal={!!selectedSite}
          title="Delete Site"
          contentText={"Are you sure you would like to delete this site?"}
          actionName="Delete"
          onAction={deleteCurrentSite}
          closeModal={() => setSelectedSite(null)}
        ></SimpleModal>
      </ServiceNavigationBar>

      {showFilterPopup && (
        <Lookup
          filtersList={
            showFilterPopup === "sites" ? filters.sitesNames : filters.customersNames
          }
          appliedFilters={
            showFilterPopup === "sites" ? appliedFilters.sitesNames : appliedFilters.customersNames
          }
          onApply={onApply}
          lookupAnchor={lookupAnchor}
          onClose={() => setFilterPopup("")}
          clearAllFilters={() => setAppliedFilters({ sitesNames: [], customersNames: [] })}
          hasFilters={hasFilters}
        />
      )}
    </div>
  );
};

export default SiteManagement;
