import {
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField
} from "@material-ui/core";
import { Close, Delete, Edit, Search } from "@material-ui/icons";
import { FilterList } from "@material-ui/icons";
import clsx from "clsx";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { t } from "ttag";
import { Lookup } from "../../components/Lookup";
// @ts-ignore
import { IDeviceModel } from "../../models/DeviceModel";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import ServiceNavigationBar from "../../widgets/Menu/ServiceNavigationBar";
import SimpleModal from "../../widgets/SimpleModal/SimpleModal";
import styles from "./DeviceManagement.module.css";
import EditDevice from "./EditDevice";
// import { EditIcon } from "../../iconImages";

// The main screen
export default function DeviceManager(props: any) {
  const [selectedDevice, setSelectedDevice] = useState<IDeviceModel | null>(
    null
  );
  const [selectedDeviceToDelete, setDeviceToDelete] = useState<any>(null);
  const [searchValue, setSearchValue] = useState<any>("");
  const [filteredDevices, setFilteredDevices] = useState<any>([]);
  const [sitesDevices, setSitesDevices] = useState<any>([]);
  const [lookupAnchor, setAnchor] = useState(null);

  const [filters, setFilters] = useState<any>({
    versionList: [],
    deviceSerialList: [],
    siteNames: [],
    customers: [],
    isConnected: [],
    isRegistered: []
  });
  const [appliedFilters, setAppliedFilters] = useState<any>({
    versionList: [],
    deviceSerialList: [],
    siteNames: [],
    customers: [],
    isConnected: [],
    isRegistered: []
  });
  const [clickedColumnName, setClickedColumnName] = useState<any>("");
  const getDevices = useStoreActions((actions) => actions.devices.getDevices);
  const getSiteName = useStoreState((state) => state.sites.getSiteName);
  const devices = useStoreState((state) => state.devices.allDevices);
  const getSiteCustomer = useStoreState((state) => state.sites.getSiteCustomer);
  const getCustomerName = useStoreState(
    (state) => state.customers.getCustomerName
  );
  const getCustomerDevices = useStoreState(
    (state) => state.customers.getCustomerDevices
  );
  const connectedDevices = useStoreState(
    (state) => state.devices.getDeviceWithState
  );
  const deleteDevice = useStoreActions((actions) => actions.devices.deleteDevice);
  const [, setError] = useState<any>(null);

  const initialize = useCallback(async () => {
    try {
      await getDevices();
    } catch (e) {
      setError(e.message);
    }
  }, [getDevices]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    if (_.isEmpty(appliedFilters)) {
      setFilteredDevices(sitesDevices);
      return;
    }
    const filteredSystems = _(sitesDevices)
      .filter((device) => {
        return appliedFilters.versionList.length
          ? appliedFilters.versionList.includes(device.firmwareVersion)
          : true;
      })
      .filter((device) => {
        return appliedFilters.deviceSerialList.length
          ? appliedFilters.deviceSerialList.includes(device.serial)
          : true;
      })
      .filter((device) => {
        return appliedFilters.siteNames.length
          ? appliedFilters.siteNames.includes(getSiteName(device.site))
          : true;
      })
      .filter((device) => {
        return appliedFilters.customers.length
          ? appliedFilters.customers.includes(getCustomerName(getSiteCustomer(device.site)))
          : true;
      })
      .filter((device) => {
        const isConnected = device.isConnected ? "connected" : "not connected";

        return appliedFilters.isConnected.length
          ? appliedFilters.isConnected.includes(isConnected)
          : true;
      })
      .filter((device) => {
        const isRegistered = device.isRegistered ? "registered" : "not registered";

        return appliedFilters.isRegistered.length
          ? appliedFilters.isRegistered.includes(isRegistered)
          : true;
      })
      .value();
    setFilteredDevices(filteredSystems);
  }, [appliedFilters, sitesDevices]);

  useEffect(() => {
    const { siteId } = props.match.params;
    const { customerId } = props.match.params;
    const siteDevices = siteId
      ? Object.values(connectedDevices).filter(
        (device: any) => (device.site === siteId)
      )
      : Object.values(connectedDevices);
    setSitesDevices(siteDevices);
    let deviceSerials;
    if (customerId) {
      const customerDevices = getCustomerDevices(customerId);
      deviceSerials = _.map(customerDevices, (device) => device.serial);
      setSitesDevices(customerDevices);
    }
    setAppliedFilters({
      versionList: [],
      deviceSerialList: deviceSerials ?? [],
      siteNames: [],
      customers: [],
      isConnected: props.disconnected ? ["not connected"] : (props.connected ? ["connected"] : []),
      isRegistered: []
    });
  }, [connectedDevices, props.match.params, props.disconnected]);
  useEffect(() => {

    if (_.isEmpty(connectedDevices)) {
      setFilters({
        versionList: [],
        deviceSerialList: [],
        siteNames: [],
        customers: [],
        isConnected: [],
        isRegistered: []
      });
      return;
    }

    const versionList: any[] = [];
    const deviceSerialList: any = [];
    const siteNames: any = [];
    const customers: any = [];
    const isConnectedList: any = [];
    const isRegisteredList: any = [];
    Object.values(devices).map((device: any) => {
      const { firmwareVersion, serial, customer, site } = device;
      const isConnected = device.isConnected ? "connected" : "not connected";
      const isRegistered = device.isRegistered ? "registered" : "not registered";
      if (serial && serial !== "-" && deviceSerialList.indexOf(serial) === -1) {
        deviceSerialList.push(serial);
      }

      if (firmwareVersion && firmwareVersion !== "-" && versionList.indexOf(firmwareVersion) === -1) {
        versionList.push(firmwareVersion);
      }

      if (site && site !== "-") {
        siteNames.push(site);
      }

      if (customer && customer !== "-") {
        customers.push(customer);
      }
      if (isConnectedList.indexOf(isConnected) === -1) {
        isConnectedList.push(isConnected);
      }
      if (isRegisteredList.indexOf(isRegistered) === -1) {
        isRegisteredList.push(isRegistered);
      }
      return true;

    });
    setFilters({ versionList, deviceSerialList, siteNames, customers, isRegistered: isRegisteredList, isConnected: isConnectedList });

  }, [filteredDevices, appliedFilters]);

  const clearSearchValue = () => {
    setSearchValue("");
    setFilteredDevices(sitesDevices);
  };

  const handleSearchValueChange = (event: any) => {
    const {
      target: { value }
    } = event;
    setSearchValue(value);
  };

  const deleteSelectedDevice = () => {
    deleteDevice(selectedDeviceToDelete);
    setDeviceToDelete(null);
  };

  if (selectedDevice) {
    return (
      <EditDevice
        onClose={() => setSelectedDevice(null)}
        selectedDevice={selectedDevice}
        {...props}
      />
    );
  }
  const onApply = (selectedFilters: any) => {
    setAppliedFilters({
      ...appliedFilters,
      [clickedColumnName]: selectedFilters
    });
    setClickedColumnName("");
  };
  const handleFilterPopup = (event: any, clickedColumn: string) => {
    setClickedColumnName(clickedColumn);
    setAnchor(event.currentTarget);
  };
  const hasFilters = !!Object.values(appliedFilters).flat().length;

  return (
    <ServiceNavigationBar title={t`Device Management`} {...props}>
      <Paper elevation={0} className={styles.paperPadding}>
        <TableContainer>
          <Table stickyHeader className="" aria-label="customized table">
            <TableHead className={styles.tableHead}>
              <TableRow>
                <TableCell
                  className={styles.headCells}
                  align="left"
                  onClick={(e: any) => handleFilterPopup(e, "deviceSerialList")}

                >
                  <div className={styles.headContainer}>
                    {t` DEVICE SERIAL`}
                    <FilterList
                      className={clsx(styles.filterStyle, {
                        [styles.blueFilter]: appliedFilters.deviceSerialList.length
                      })}
                    />
                  </div>
                </TableCell>
                <TableCell
                  className={styles.headCells}
                  align="left"
                  onClick={(e: any) => handleFilterPopup(e, "siteNames")}
                > <div className={styles.headContainer}>
                    {t`SITE`}
                    <FilterList
                      className={clsx(styles.filterStyle, {
                        [styles.blueFilter]: appliedFilters.siteNames.length
                      })}
                    />
                  </div></TableCell>
                <TableCell
                  className={styles.headCells}
                  align="left"
                  onClick={(e: any) => handleFilterPopup(e, "customers")}

                ><div className={styles.headContainer}>
                    {t`CUSTOMER`}
                    <FilterList
                      className={clsx(styles.filterStyle, {
                        [styles.blueFilter]: appliedFilters.customers.length
                      })}
                    />
                  </div></TableCell>
                <TableCell
                  className={styles.headCells}
                  align="center"
                  onClick={(e: any) => handleFilterPopup(e, "versionList")}

                ><div className={styles.headContainer}>
                    {t`Firmware Version`}
                    <FilterList
                      className={clsx(styles.filterStyle, {
                        [styles.blueFilter]: appliedFilters.versionList.length
                      })}
                    />
                  </div></TableCell>
                <TableCell
                  className={styles.headCells}
                  align="center"
                  onClick={(e: any) => handleFilterPopup(e, "isConnected")}

                >
                  <div className={styles.headContainer}>
                    {t`CONNECTED`}
                    <FilterList
                      className={clsx(styles.filterStyle, {
                        [styles.blueFilter]: appliedFilters.isConnected.length
                      })}
                    />
                  </div></TableCell>
                <TableCell
                  className={styles.headCells}
                  align="center"
                  onClick={(e: any) => handleFilterPopup(e, "isRegistered")}

                >
                  <div className={styles.headContainer}>
                    {t`REGISTERED`}
                    <FilterList
                      className={clsx(styles.filterStyle, {
                        [styles.blueFilter]: appliedFilters.isRegistered.length
                      })}
                    />
                  </div></TableCell>
                {/*
                <TableCell
                  className={styles.headCells}
                  align="center"
                >{t`DETAILS`}</TableCell> */}
                {/* <TableCell
                  className={styles.headCells}
                  align="center"
                >{t`DELETE`}</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredDevices.map((device: IDeviceModel) => {
                return (
                  <TableRow hover tabIndex={-1} key={device.id} onDoubleClick={() => {
                    setSelectedDevice(device);
                    props.history.push(`/devices/${device.id}`);
                  }}>
                    <TableCell
                      className={styles.rowCell}
                      component="th"
                      scope="row"
                      align="left"
                    >
                      {device.serial}
                    </TableCell>
                    <TableCell
                      className={styles.rowCell}
                      component="th"
                      scope="row"
                      align="left"
                    >
                      {device.site}
                    </TableCell>
                    <TableCell
                      className={styles.rowCell}
                      component="th"
                      scope="row"
                      align="left"
                    >
                      {device.customer}
                    </TableCell>
                    <TableCell
                      className={styles.rowCell}
                      component="th"
                      scope="row"
                      align="center"
                    >
                      {device.firmwareVersion}
                    </TableCell>
                    <TableCell
                      className={styles.rowCell}
                      component="th"
                      scope="row"
                      align="center"
                    >
                      {device.isConnected ? (
                        <i className="fas fa-check"></i>
                      ) : (
                          <i className="fas fa-times"></i>
                        )}
                    </TableCell>
                    <TableCell
                      className={styles.rowCell}
                      component="th"
                      scope="row"
                      align="center"
                    >
                      {device.isRegistered ? (
                        <i className="fas fa-check"></i>
                      ) : (
                          <i className="fas fa-times"></i>
                        )}
                    </TableCell>
                    {/* <TableCell
                      className={styles.rowCell}
                      component="th"
                      scope="row"
                      align="center"
                    >
                      <IconButton
                        onClick={() => {
                          setSelectedDevice(device);
                          props.history.push(`/devices/${device.id}`);
                        }}
                      // disabled={!device.isConnected}
                      >
                        <Edit />
                      </IconButton>
                    </TableCell> */}

                    {/* <TableCell
                      className={styles.rowCell}
                      component="th"
                      scope="row"
                      align="center"
                    >
                      <IconButton
                        onClick={() => {
                          setDeviceToDelete(device.id);
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </TableCell> */}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      {clickedColumnName && (
        <Lookup
          filtersList={filters[clickedColumnName]}
          appliedFilters={appliedFilters[clickedColumnName]}
          onApply={onApply}
          lookupAnchor={lookupAnchor}
          onClose={() => setClickedColumnName("")}
          clearAllFilters={() => setAppliedFilters({
            versionList: [],
            deviceSerialList: [],
            siteNames: [],
            customers: [],
            isConnected: [],
            isRegistered: []
          })}
          hasFilters={hasFilters}
        />
      )}
      <SimpleModal
        showModal={!!selectedDeviceToDelete}
        title="Are you sure?"
        contentText={"Are you sure you would like to delete this device?"}
        actionName="OK"
        onAction={deleteSelectedDevice}
        closeModal={() => setDeviceToDelete(null)}
      />
    </ServiceNavigationBar>
  );
}
