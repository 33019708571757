import React from "react";
import { IAuditRow } from "./AuditsList";
import { t } from "ttag";
import useStyles from "./AuditsList.style";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Clear } from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import { Typography, Card, Button } from "@material-ui/core";
import { useStoreState } from "../../models/RootStore";
import { showAuditData } from "./AuditsActionMap";

export default function AuditsDataPopUp(props: { audit: IAuditRow }) {
  const { audit } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const types = useStoreState(s => s.types);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <Button
        size="small"
        className={classes.button}
        variant="contained"
        onClick={() => handleClickOpen()}
      >
        {t`More Info`}
      </Button>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title" className={classes.dialogTitle}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              color: "#29132e"
            }}
          >
            <Typography variant="h5">{t`Audit Data`}</Typography>
            <IconButton onClick={() => setOpen(false)}>
              <Clear />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          <Card className={classes.container}>
            <Card className={classes.auditContent}>
              {showAuditData(audit, types) ? (
                showAuditData(audit, types)
              ) : (
                <pre style={{ fontSize: 10 }}>
                  {false ? "-" : JSON.stringify(audit, null, 3)}
                </pre>
              )}
            </Card>
          </Card>
        </DialogContent>
      </Dialog>
    </div>
  );
}
