import { types } from "@babel/core";
import { InputLabel, Switch, TextField, Typography } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import { Clear } from "@material-ui/icons";
import { KeyboardDatePicker } from "@material-ui/pickers";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import * as Yup from "yup";
import { CustomSelect } from "../../components/AddRule/CustomedComponents";
import Button from "../../components/cool_widgets/Button";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import useStyles from "./subscriptions.style";

export default function AddEditSubscription(props: any) {
    const [allErrors, setAllError] = useState<any>({});
    const { subscription, close, open, handleSave, currentDay, aYearFromNow, setRefresh, refresh, allSites, title } = props;
    const classes = useStyles();
    const subscriptionTemplatesTypes = useStoreState((s) => s.types.subscriptionTemplatesTypes);
    const [site, setSite] = useState<any>(subscription.site || null);
    const [purchaseOrder, setPurchaseOrder] = useState(subscription ? subscription.purchaseOrder : "");
    const [startDate, setStartDate] = useState(!_.isEmpty(subscription) ? new Date(subscription.startDate) : currentDay);
    const [endDate, setEndDate] = useState(!_.isEmpty(subscription) && subscription.endDate ? subscription.endDate : aYearFromNow);
    const [description, setDescription] = useState(!_.isEmpty(subscription) ? subscription.description : "");
    const [isPerpetual, setIsPerpetual] = useState(!_.isEmpty(subscription) ? subscription.isPerpetual : false);
    const [contactEmail, setContactEmail] = useState(!_.isEmpty(subscription) ? subscription.contactEmail : "");
    const [units, setUnits] = useState(!_.isEmpty(subscription) ? subscription.units : 0);
    const [type, setType] = useState(!_.isEmpty(subscription) ? subscription.type : 0);
    const [enableFallback, setEnableFallback] = useState(!_.isEmpty(subscription) ? subscription.enableFallback : false);
    const [subscriptionTemplate, setSubscriptionTemplate] = useState<any>(subscription ? subscription.subscriptionTemplate : "");
    const [subscriptionTemplates, setSubscriptionTemplates] = useState<any>([]);
    const getSubscriptionTemplates =  useStoreActions((actions) => actions.subscriptions.getSubscriptionTemplates);

    useEffect(() => {
       if(!isPerpetual){
        setEndDate(!_.isEmpty(subscription) && subscription.endDate ? subscription.endDate : aYearFromNow)
       }
    }, [isPerpetual]);

    useEffect(() => {
        (async () => {
            const templates: any = await getSubscriptionTemplates();
            setSubscriptionTemplates(templates);
        })();
    }, []);

    useEffect(() => {
        if (enableFallback){
            const temp: any = Object.values(subscriptionTemplates).filter((template: any) => template.type === type)[0];
            setSubscriptionTemplate(subscription.subscriptionTemplate || subscriptionTemplates && temp ? temp.id : "");
        }
       else{
        setSubscriptionTemplate("");
       }

    }, [type, enableFallback]);
    
    useEffect(() => {
       if (allErrors.type){
           const temp = {...allErrors};
           delete temp.type;
           setAllError(temp);
       }
    }, [type]);

    useEffect(() => {
            setPurchaseOrder(subscription.purchaseOrder || "");
            setStartDate(subscription.startDate ? new Date(subscription.startDate) : currentDay);
            setEndDate(_.isEmpty(subscription) ? aYearFromNow : subscription.endDate ? new Date(subscription.endDate) : null);
            setDescription(subscription.description || "");
            setIsPerpetual(subscription.isPerpetual ||  false);
            setContactEmail(subscription.contactEmail || "");
            setUnits(subscription.units || 0);
            setSite(subscription.site || null);
            setEnableFallback(subscription.enableFallback || false);
            const temp: any = Object.values(subscriptionTemplates).filter((template: any) => template.type === type)[0];
            setSubscriptionTemplate(subscription.subscriptionTemplate || subscriptionTemplates && temp ? temp.id : "");
            setType(subscription.type || 0);
            setAllError({});
        }, [subscription]);

    const siteOptions = () => {

         const options = Object.values(allSites).map((site: any) => ({
            name: site.name,
            value: site.id,
            key: site.id
          }));
         return [
            {
              name: `No site`,
              value: "",
              key: 0
            },
            ...options
          ];
    };

    const SubscriptionSchema = Yup.object().shape({
        purchaseOrder: Yup.string().required("Required"),
        startDate: Yup.number().required("Required"),
        endDate: Yup.number().nullable(),
        description: Yup.string(),
        isPerpetual: Yup.boolean().required("Required"),
        contactEmail: Yup.string().email().required("Required"),
        units: Yup.number(),
        site: Yup.string(),
        enableFallback: Yup.boolean().required("Required"),
        subscriptionTemplate: Yup.string(),
        type: Yup.number()
    });
console.log(site)
    const handleAgree = async () => {

        const subscriptionInfo = {
            purchaseOrder,
            startDate: startDate ? moment(startDate).unix() * 1000 : moment(currentDay).unix() * 1000,
            endDate: !isPerpetual && endDate ? moment(endDate).unix() * 1000 : null,
            description,
            isPerpetual,
            contactEmail,
            enableFallback: enableFallback || false,
            units: units ? parseInt(units) : 0,
            site: site || "",
            subscriptionTemplate: enableFallback ? subscriptionTemplate : "",
            type
        };

        if (!subscriptionInfo.subscriptionTemplate){
            delete subscriptionInfo.subscriptionTemplate;
        }

        let errors: any = {};
        let isValid;
        try {
            isValid = SubscriptionSchema.validateSync(subscriptionInfo, { abortEarly: false });
        } catch (e) {
            console.log(e);
            const purchaseOrderError = e.inner.find((ve: any) => ve.path === "purchaseOrder")
                ? { purchaseOrder: e.inner.find((ve: any) => ve.path === "purchaseOrder").message }
                : null;
            const contactEmailError = e.inner.find((ve: any) => ve.path === "contactEmail")
                ? { contactEmail: e.inner.find((ve: any) => ve.path === "contactEmail").message }
                : null;
            const startDateError = e.inner.find((ve: any) => ve.path === "startDate")
                ? { startDate: e.inner.find((ve: any) => ve.path === "startDate").message }
                : null;

            errors = {
                contactEmail: contactEmailError?.contactEmail,
                startDate: startDateError?.startDate,
                purchaseOrder: purchaseOrderError?.purchaseOrder
            };

        }

        if (!_.isNil(site) && site && (((allSites[site].serviceSubscription && allSites[site].serviceSubscription !== subscription.id) && type === subscriptionTemplatesTypes.service) ||
        ((allSites[site].managementSubscription && allSites[site].managementSubscription !== subscription.id) && type === subscriptionTemplatesTypes.management))){
            errors.type = "this type of subscription already exist in site";
        }
        if (!_.isEmpty(errors)) {
            setAllError(errors);
            return;
        }

        handleSave(subscriptionInfo).then(() => {
            // setRefresh(true);

        });
    };

    return (
        <Dialog
            open={open}
            onClose={close}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            classes={{ paper: classes.dialog }}
        >
            <DialogTitle
                id="alert-dialog-title"
                className={classes.titleContent}
                disableTypography={true}
            >
                <Typography variant="h6">{title}</Typography>
                <IconButton onClick={() => close()}>
                    <Clear />
                </IconButton>
            </DialogTitle>
            <DialogContent
                className={classes.dialogContent}
            >
                <TextField
                    style={{ width: "100%" }}
                    id="standard-helperText"
                    label="Purchase Order"
                    margin="normal"
                    value={purchaseOrder}
                    onChange={(e: any) => {
                        e.preventDefault();
                        setPurchaseOrder(e.target.value);
                    }}
                    inputProps={{
                        maxLength: 20
                    }}

                    error={!_.isNil(allErrors?.purchaseOrder) ? true : false}
                    helperText={allErrors?.purchaseOrder}
                />
                <div className={classes.datesBox}>
                    <KeyboardDatePicker
                        disableToolbar
                        variant="dialog"
                        format="dd/MM/yyyy"
                        margin="normal"
                        id="date-picker-startDate"
                        label="Start Date"
                        value={startDate}
                        onChange={setStartDate}
                        KeyboardButtonProps={{
                            "aria-label": "change date"
                        }}
                    />
                    {
                        isPerpetual ? <></> : <KeyboardDatePicker
                            disableToolbar
                            variant="dialog"
                            format="dd/MM/yyyy"
                            margin="normal"
                            id="date-picker-endDate"
                            label="End Date"
                            value={endDate}
                            onChange={setEndDate}
                            KeyboardButtonProps={{
                                "aria-label": "change date"
                            }}
                        />
                    }

                </div>
                <InputLabel
                    style={{ width: "100%" }}
                >
                    {t`is perpetual`}
                    <div>
                        disable
                         <Switch
                            color="default"
                            onChange={() => {
                                setIsPerpetual(!isPerpetual);
                            }}
                            checked={isPerpetual}
                            name="isPerpetual"
                        />
                        enable
                    </div>
                </InputLabel>
                <TextField
                    style={{ width: "100%" }}
                    id="standard-helperText"
                    label="Description"
                    margin="normal"
                    value={description}
                    onChange={(e: any) => {
                        e.preventDefault();
                        setDescription(e.target.value);
                    }}
                    inputProps={{
                        maxLength: 20
                    }}
                />

                <TextField
                    style={{ width: "100%" }}
                    id="standard-helperText"
                    label="Contact Email"
                    margin="normal"
                    value={contactEmail}
                    onChange={(e: any) => {
                        e.preventDefault();
                        setContactEmail(e.target.value);
                    }}
                    error={!_.isNil(allErrors?.contactEmail) ? true : false}
                    helperText={allErrors?.contactEmail}

                />
                 <CustomSelect
                    helperText={allErrors?.type}
                    error={!_.isNil(allErrors?.type) ? true : false}
                    placeholder="Type"
                    name={`type`}
                    onChange={(event: any) => {
                        setType(event.target.value);
                    }}
                    value={type}
                    options={_.map(Object.keys(subscriptionTemplatesTypes), (type) => (
                        {
                            key: subscriptionTemplatesTypes[type],
                            name: type,
                            value: subscriptionTemplatesTypes[type]
                        }
                    ))}
                />
                {allErrors?.type ? <Typography style={{color: "red"}}>{allErrors?.type}</Typography> : <></>}

                <TextField
                    style={{ width: "100%" }}
                    id="standard-helperText"
                    label="Units"
                    margin="normal"
                    value={units}
                    onChange={(e: any) => {
                        e.preventDefault();
                        setUnits(e.target.value);
                    }}
                    inputProps={{
                        maxLength: 20
                    }}
                />

                <CustomSelect
                    placeholder=""
                    name={`site`}
                    onChange={(event: any) => {
                        setSite(event.target.value);
                    }}
                    value={site}
                    options={siteOptions()}
                />
                {!isPerpetual ?
                <div>
                 <InputLabel
                 style={{ width: "100%" }}
                 className={classes.subscriptionSwitch}
                >
                 {t`fallback`}
                 <div>
                     disable
                      <Switch
                         color="default"
                         onChange={() => {
                             setEnableFallback(!enableFallback);
                         }}
                         checked={enableFallback}
                         name="enableFallback"
                     />
                     enable
                 </div>
             </InputLabel>
             {
                 enableFallback ? <>
                 <CustomSelect
                    className={classes.subscriptionSelect}
                    placeholder="Subscription Template"
                    name={`subscriptionTemplate`}
                    onChange={(event: any) => {
                        setSubscriptionTemplate(event.target.value);
                    }}
                    value={subscriptionTemplate}
                    options={Object.values(subscriptionTemplates).filter((template: any) => template.type === type).map((template: any) => (
                        {
                            key: template.id,
                            name: template.name,
                            value: template.id
                        }
                    ))}
                />
                {subscriptionTemplate && !_.isNil(subscriptionTemplates[subscriptionTemplate]) ?
                    _.map(subscriptionTemplates[subscriptionTemplate].template, (template) => {
                        return(
                        _.map(template, (value: any, key: any) => {
                            return(<Typography className={classes.subscriptionTemplateLine}>
                            <p className={classes.subscriptionTemplateTitle}>{t`${key}:`}</p>
                            <p>{t`${value}`}</p> </Typography>);
                        })
                        );
                    })
                    : <></>}
                 </> : <></>
             }
             </div>
                : <></>}

            </DialogContent>
            <DialogActions>
                <Button
                    white
                    onClick={close}
                    width={130} uppercase
                    onMouseDown={(event: any) => event.preventDefault()}
                >
                    {t`Cancel`}
                </Button>
                <Button onClick={handleAgree}
                    width={130} uppercase
                    onMouseDown={(event: any) => event.preventDefault()}
                    type="submit"
                >
                    {t`Save`}
                </Button>

            </DialogActions>
        </Dialog >
    );
}
