import React from "react";
import { localXhr } from "../../services/localXhr";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { t } from "ttag";
import { useStoreActions } from "../../models/RootStore";

export default function LogOut(props: any) {
  const [open, setOpen] = React.useState(false);

  const logout = useStoreActions(actions => actions.users.logout);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleAgree = () => {
    logout();
    props.history.push("/");
  };

  return (
    <div>
      <Button onClick={handleClickOpen}>{t`Log out`}</Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t`Log out`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t`Are you sure you want to log-out?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t`No`}</Button>
          <Button onClick={handleAgree} autoFocus={true}>
            {t`Yes`}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
