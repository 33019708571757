import { Admin, ConsoleApplication, Customer, User, xhr } from "coolremote-sdk";
import { Action, action, Computed, computed, Thunk, thunk } from "easy-peasy";
import _ from "lodash";
import { AmdDependency } from "typescript";
import * as Yup from "yup";
import { localXhr } from "../services/localXhr";

export const UserSchema = Yup.object().shape({
  firstName: Yup.string().max(20, "too long!"),
  lastName: Yup.string().max(20, "too long!"),
  email: Yup.string().email("invalid email").required("required"),
  username: Yup.string()
    .min(6, "too short!")
    .max(50, "too long!")
    .required("required"),
  phone: Yup.string().matches(
    /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{3,14})(?: *x(\d+))?\s*$/,
    "invalid phone number"
  ),
  customer: Yup.string().nullable(true).required("required"),
  timeFormat: Yup.number(),
  dateFormat: Yup.number()
});

export const UserSchemaNew = Yup.object().shape({
  firstName: Yup.string().max(20, "too long!"),
  lastName: Yup.string().max(20, "too long!"),
  email: Yup.string().email("invalid email").required("required"),
  username: Yup.string()
    .min(6, "too short!")
    .max(50, "too long!")
    .required("required"),
  phone: Yup.string().matches(
    /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{3,14})(?: *x(\d+))?\s*$/,
    "invalid phone number"
  ),
  password: Yup.string()
    .matches(
      /(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
      "must be at least 1 capital letter and 1 digit"
    )
    .required("required"),
  customer: Yup.string().nullable(true).required("required"),
  timeFormat: Yup.number(),
  dateFormat: Yup.number()
});

export interface IUserModel {
  id: string;
  username?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  role?: string;
  customer?: string;
  site?: string;
  temperatureScale?: number; // 0 - undefined, 1 - celcius, 2 - fahrenheit
  permissions?: any;
  language?: string;
  isLoggedIn: boolean;
  measurementUnits?: number;
  displayFlags?: any;
  timeFormat?: number;
  dateFormat?: number;
}

export interface IUserMap {
  [key: string]: IUserModel;
}
export interface IDisplayFlags {
  enableCustomers: boolean;
  enableSites: boolean;
  enableDevices: boolean;
  enableUsers: boolean;
  enableAnomalies: boolean;
  enableAlerts: boolean;
  enableSupport: boolean;
  enableUnitDiag: boolean;
  enableSubscriptions: boolean;
  enableDashboard: boolean;
  enableSysDiag: boolean;
  enableUnitDiagTopbar: boolean;
  enableSysDiagTopbar: boolean;
  isSysDiagAllowedOnFirstLoad?: boolean;
  isUnitDiagAllowedOnFirstLoad?: boolean;
}

interface ILoginPayload {
  username: string;
  password: string;
}
export interface IUsersModel {
  me: IUserModel;
  displayFlags: IDisplayFlags;
  otherUsers: IUserMap;
  setMe: Action<IUsersModel, { me: IUserModel }>;
  getCustomerUsers: Thunk<IUsersModel, String>;
  setUsers: Action<IUsersModel, { users: any }>;
  createUser: Thunk<IUsersModel, unknown>;
  addUser: Action<IUsersModel, IUserModel>;
  updateUser: Thunk<IUsersModel, unknown>;
  _updateUser: Action<IUsersModel, IUserModel>;
  deleteUser: Thunk<IUsersModel, string>;
  removeUser: Action<IUsersModel, string>;
  login: Thunk<IUsersModel, ILoginPayload>;
  checkLoggedin: Thunk<IUsersModel, void>;
  logout: Thunk<IUsersModel, void>;
  myFullName: Computed<IUsersModel, string>;

  setWasLoaded: Action<IUsersModel, string>;
  dataState: string;
  canLoggedInUserViewTriggerTemplates: Computed<IUsersModel, boolean>;
  canLoggedInUserViewTriggers: Computed<IUsersModel, boolean>;
  getFullName: Computed<IUsersModel, (userId: string) => string>;
  getUsername: Computed<IUsersModel, (userId: string) => string>;
  isLoggedInUserCustomerUser: Computed<IUsersModel, () => boolean>;
  timeFormat?: any;
  dateFormat?: any;
  setFlags: Action<IUsersModel, { displayFlags: IDisplayFlags }>;
}

export const usersModel: IUsersModel = {
  me: { id: "", isLoggedIn: false },
  displayFlags: {
    enableCustomers: true,
    enableSites: true,
    enableDevices: true,
    enableUsers: true,
    enableAnomalies: true,
    enableAlerts: true,
    enableSupport: true,
    enableUnitDiag: true,
    enableSubscriptions: true,
    enableDashboard: true,
    enableSysDiag: true,
    enableUnitDiagTopbar: true,
    enableSysDiagTopbar: true,
    isSysDiagAllowedOnFirstLoad: true,
    isUnitDiagAllowedOnFirstLoad: true
  },
  otherUsers: {},
  dataState: "loading",
  setWasLoaded: action((state, payload) => {
    state.dataState = payload;
  }),

  setMe: action((state, payload: any) => {
    state.me = payload;
  }),

  getCustomerUsers: thunk((actions, payload) => {
    return Customer.getUsers(payload);
  }),
  setUsers: action((state, payload) => {
    const fetchedUsers: IUserMap = _(Object.values(payload.users))
      .map((user: any) => {
        const addedUser: IUserModel = { ...user };

        return addedUser;
      })
      .keyBy("id")
      .value();

    state.otherUsers = fetchedUsers;
  }),
  isLoggedInUserCustomerUser: computed([(state) => state.me], (me) => () => {
    return !_.isUndefined(me.customer);
  }),
  createUser: thunk(async (actions, payload: any) => {
    return Customer.createUser(payload.id, payload.data)
      .then((data) => {
        actions.addUser(data);
        return true;
      })
      .catch((error) => {
        return { err: error.message };
      });
  }),

  addUser: action((state, payload) => {
    state.otherUsers[payload.id] = payload;
  }),

  updateUser: thunk(async (actions, payload: any) => {
    return User.update(payload.id, payload.data)
      .then((data) => {
        actions._updateUser(data);
        return true;
      })
      .catch((error) => {
        return { err: error.message };
      });
  }),

  _updateUser: action((state, payload) => {
    state.otherUsers[payload.id] = payload;
  }),

  deleteUser: thunk(async (actions, payload) => {
    return User.delete(payload)
      .then(() => {
        actions.removeUser(payload);
        return true;
      })
      .catch(() => false);
  }),

  removeUser: action((state, payload) => {
    delete state.otherUsers[payload];
  }),

  login: thunk(async (actions, payload, { getStoreActions }) => {
    const { username, password } = payload;
    const storeActions: any = getStoreActions();
    const { setLoggedIn } = storeActions;

    await actions.logout();
    const { data } = await User.connect(username, password);

    if (!data) {
      return;
    }

    const token = data.token;

    xhr.setToken(token);

    // fetch user
    const user = await User.getMe();

    if (!user) {
      setLoggedIn(false);
      return;
    }

    const displayFlags = await ConsoleApplication.getFlags();
    const {enableSysDiag, enableUnitDiag, enableUnitDiagTopbar, enableSysDiagTopbar} = displayFlags;
    displayFlags.isSysDiagAllowedOnFirstLoad = enableSysDiag && enableSysDiagTopbar;
    displayFlags.isUnitDiagAllowedOnFirstLoad = enableUnitDiag && enableUnitDiagTopbar;

    const updatedUser = { ...user, isLoggedIn: true };
    const isGlobalAdmin = updatedUser.permissions === "globalAdmin";
    if (!isGlobalAdmin) {
      alert("only global admins can login");
      return;
    }
    localStorage.setItem("token", token);

    const liteTree = await Admin.getLiteTree();

    storeActions.selections.setFiltersTree(liteTree || {});
    actions.setMe(updatedUser);
    actions.setFlags({ displayFlags });
    setLoggedIn(true);
    return updatedUser;
  }),
  checkLoggedin: thunk(async (actions, payload, { getStoreActions }) => {
    const token = localStorage.getItem("token");
    const storeActions: any = getStoreActions();
    const { setLoggedIn } = storeActions;

    if (!token) {
      actions.logout();
      actions.setWasLoaded("done");
      return;
    }

    xhr.setToken(token);
    const user = await User.getMe();
    if (!user) {
      actions.logout();
      actions.setWasLoaded("done");
      return;
    }

    const displayFlags = await ConsoleApplication.getFlags();
    const {enableSysDiag, enableUnitDiag, enableUnitDiagTopbar, enableSysDiagTopbar} = displayFlags;
    displayFlags.isSysDiagAllowedOnFirstLoad = enableSysDiag && enableSysDiagTopbar;
    displayFlags.isUnitDiagAllowedOnFirstLoad = enableUnitDiag && enableUnitDiagTopbar;
    actions.setFlags({ displayFlags });

    const liteTree = await Admin.getLiteTree();
    storeActions.selections.setFiltersTree(liteTree || {});

    const updatedUser = { ...user, isLoggedIn: true };
    actions.setMe(updatedUser);
    setLoggedIn(true);
    actions.setWasLoaded("done");

    return updatedUser;
  }),
  logout: thunk(async (actions, payload, { injections, getStoreActions }) => {
    const updatedUser: any = { id: "", isLoggedIn: false };
    actions.setMe(updatedUser);

    localStorage.removeItem("token");

    const storeActions: any = getStoreActions();
    // const { reset } = storeActions;
    // reset();
    storeActions.setIsLoaded(true);

    User.logout();
  }),
  canLoggedInUserViewTriggerTemplates: computed([(state) => state.me], (me) => {
    if (me.permissions === "globalAdmin") {
      return true;
    }

    return false;
  }),
  canLoggedInUserViewTriggers: computed([(state) => state.me], (me) => {
    // Currently everyone can view triggers.
    return true;
  }),
  getFullName: computed(
    [(state) => state.me, (state) => state.otherUsers],
    (me, users) => (userId) => {
      if (_.isNil(users[userId])) return "-";
      const user = users[userId];
      return _createUserNamePresentation(
        user.username,
        user.firstName,
        user.lastName
      );
    }
  ),
  myFullName: computed(
    [(state) => state.me, (state) => state.otherUsers],
    (me, users) => {
      return _createUserNamePresentation(
        me.username,
        me.firstName,
        me.lastName
      );
    }
  ),
  getUsername: computed(
    [(state) => state.me, (state) => state.otherUsers],
    (me, users) => (userId) => {
      console.log(userId);
      console.log(me);

      if (_.isNil(users[userId]))
        return !_.isNil(me.username) && me.id === userId ? me.username : "-";
      return users[userId].username || "-";
    }
  ),
  timeFormat: computed(
    [(state: any) => state.me, (state, storeState: any) => storeState.types],
    (me, types) => {
      if (!types || !types.timeFormat) {
        return "HH:mm";
      }
      const { timeFormat: selectedTime = "0" } = me;
      const timeFormatObject = types.timeFormat[selectedTime];
      if (timeFormatObject.text === "24 hours") {
        return "HH:mm";
      }
      if (timeFormatObject.text === "12 hours") {
        return "hh:mma";
      }
    }
  ),
  dateFormat: computed(
    [(state: any) => state.me, (state, storeState: any) => storeState.types],
    (me, types) => {
      if (!types || !types.dateFormat) {
        return "DD/MM/YY";
      }
      const { dateFormat: selectedDate = "0" } = me;
      return types.dateFormat[selectedDate].text;
    }
  ),
  setFlags: action((state, payload) => {
    state.displayFlags = payload.displayFlags;
  })
};

function _createUserNamePresentation(
  username?: string,
  firstName?: string,
  lastName?: string
) {
  if (firstName) {
    if (lastName) {
      return firstName + " " + lastName;
    } else {
      return firstName;
    }
  } else {
    return username || "no name";
  }
}
