
export const modeIcons: {[key: string]: string} = {
  [-1]: 'power-off',
  0: 'snowflake',
  1: 'sun',
  2: 'sync',
  3: 'tint',
  5: 'fan',
};

interface IRangeMap { [s: string]: string | number[][]; }

export const COLOR_PALETTE: IRangeMap = {
  // min max of color range according to hsl
  0: [[200, 250]],
  1: [[50, 0]],
  // multiple range support
  2: [[200, 250], [50, 0]],
  // support for single temp
  [-1]: 'rgb(126, 132, 135)',
  3: 'green',
  5: 'purple',
};
