import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import _ from "lodash";
import React from "react";
import { t } from "ttag";
import styles from "./ErrorLogs.module.css";

export default function ErrorLogs({alerts}: any) {

  return (
    <Paper className={styles.root}>
      <div className={styles.tableWrapper}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
                <TableCell
                  className={styles.tableCell}
                  align={"left"}
                  /*style={{ minWidth: column.minWidth, fontSize: '1.5rem' }}*/
                >
                  {t`Time`}
                </TableCell>
                <TableCell
                  className={styles.tableCell}
                  align={"left"}
                  /*style={{ minWidth: column.minWidth, fontSize: '1.5rem' }}*/
                >
                  {t`Description`}
                </TableCell>
                <TableCell
                  className={styles.tableCell}
                  align={"left"}
                  /*style={{ minWidth: column.minWidth, fontSize: '1.5rem' }}*/
                >
                  {t`System`}
                </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {alerts.map((alert: any) => {
              return (
                <TableRow  key={alert.id}>
                      <TableCell
                        className={styles.tableCell}
                        align={"left"}
                      >
                      {alert.time}
                      </TableCell>
                      <TableCell
                        className={styles.tableCell}
                        align={"left"}
                      >
                      {alert.description || ""}
                      </TableCell>
                      <TableCell
                        className={styles.tableCell}
                        align={"left"}
                      >
                      {alert.systemName || "-"}
                      </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    </Paper>
  );
}
