import _ from 'lodash';
import {
    Paper,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Button
} from '@material-ui/core';
import LocalButton from '../../widgets/Button/Button';
import React, { useState, useEffect } from 'react';
import { t } from 'ttag';
import NavigationBar from '../NavigationBar/NavigationBar';
import SimpleModal from "../../widgets/SimpleModal/SimpleModal";
import { useStoreActions, useStoreState } from '../../models/RootStore'
import OneTrigger from './OneTrigger'
import { ITriggerModel } from "../../models/TriggerModel";
import ErrorBox from '../../widgets/ErrorBox/ErrorBox'




export default function TriggerManagement(props: any) {
    const getTriggers = useStoreActions(actions => actions.triggers.getTriggers);
    const triggers = useStoreState(state => state.triggers.triggers);
    const [chosenTrigger, setChosenTrigger] = useState<ITriggerModel | null>(null);
    const [delModal, setDelModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [showErr, setShowErr] = useState<string>("");

    const delTrigger = useStoreActions(actions => actions.triggers.delTrigger);
    const updateTrigger = useStoreActions(actions => actions.triggers.updateTrigger);


    const initialize = async () => {
        try {
            await getTriggers();
        } catch (e) {
            setShowErr("Failed to get Triggers");
        }
    }

    useEffect(() => { initialize() }, []);


    const onUpdateApp = async (id: string, isEnabled: boolean, sendOnce: boolean) => {
        await updateTrigger({ id, data: { isEnabled, sendOnce } });
        setChosenTrigger(null);
        setEditModal(false);

    };

    const onUpdateCommand = (triggerName: string,
        template: string,
        id: string,
        isEnabled: boolean,
        sendOnce: boolean,
        resources: string[],
        resourceIds: string[]) => {

        onUpdateApp(id, isEnabled, sendOnce);
    }
    const onDelete = async () => {

        if (!chosenTrigger) {
            return onCloseDelModal();
        }

        const success = await delTrigger(chosenTrigger.id);
        if (!success) {
            setShowErr("something went wrong while deleting Trigger")
        }

        onCloseDelModal();
    }

    const onCloseDelModal = () => {
        setDelModal(false);
        setChosenTrigger(null)
    }

    const onDelModal = () => {
        const name = chosenTrigger ? chosenTrigger.name : "";
        const bodyText = `are you sure you want to delete this Trigger: ${name}`;
        return <SimpleModal
            showModal={delModal}
            title="Delete Trigger"
            contentText={bodyText}
            actionName="Delete"
            onAction={onDelete}
            closeModal={onCloseDelModal}
        >
        </SimpleModal>
    }

    const onErrorModal = () => {
        if (!showErr.length) {
            return <div />
        }

        return <ErrorBox
            error={showErr}
            onClose={() => {
                onCloseDelModal();
                setShowErr("");
            }}
        />
    }


    const populateList = () => {
        return (
            _.map(triggers, trigger => {
                const resourcesId =
                    trigger.resourceIds.length && trigger.resourceIds[0] === "*"
                        ? "All units/Devices"
                        : trigger.resourceIds.join(", ");

                return <TableRow
                    hover
                    tabIndex={-1}
                    key={trigger.id}
                >
                    <TableCell
                        component='th'
                        scope='row'
                        align='left'
                    >
                        {trigger.name}
                    </TableCell>
                    <TableCell
                        component='th'
                        scope='row'
                        align='left'
                    >
                        {trigger.isEnabled ? "true" : "false"}
                    </TableCell>
                    <TableCell
                        component='th'
                        scope='row'
                        align='left'
                    >
                        {trigger.sendOnce ? "true" : "false"}
                    </TableCell>
                    <TableCell
                        component='th'
                        scope='row'
                        align='left'
                    >
                        {trigger.resourceIds[0] === "*" ? "V" : ""}
                    </TableCell>
                    <TableCell
                        component='th'
                        scope='row'
                        align='left'
                    >
                        {trigger.resources?.join(", ")}
                    </TableCell>
                    <TableCell
                        component='th'
                        scope='row'
                        align='left'
                    >
                        {resourcesId}
                    </TableCell>
                    <TableCell
                        component='th'
                        scope='row'
                        align='center'
                    >
                        <Button variant="outlined"
                            onClick={() => {
                                setEditModal(true);
                                setChosenTrigger(trigger)

                            }}>
                            {t`update`}
                        </Button>
                        <Button variant="outlined"
                            color="secondary"
                            onClick={() => {
                                setDelModal(true);
                                setChosenTrigger(trigger)

                            }}>
                            {t`delete`}
                        </Button>
                    </TableCell>


                </TableRow>
            })
        )
    };


    return (
        <NavigationBar
            navTitle={t`Trigger Management`}
            pageTitle={t`Trigger Management`}
            navMenu={TriggerNavMenu}
            {...props}
        >
            {onErrorModal()}
            {onDelModal()}
            {editModal && chosenTrigger &&
                <OneTrigger
                    triggerInfo={chosenTrigger}
                    onSave={onUpdateCommand}
                    onClose={() => setEditModal(false)}
                    title="Update Trigger info"
                    contentText=""
                    btnName="Update"
                />}
            <Paper className="" elevation={0}>
                <Table stickyHeader className="" aria-label='customized table'>
                    <TableHead className="">
                        <TableRow>
                            <TableCell
                                align='left'
                            >
                                {t` NAME`}
                            </TableCell>
                            <TableCell
                                align='left'
                            >
                                {t` ENABLED`}
                            </TableCell>
                            <TableCell
                                align='left'
                            >
                                {t` SEND ONCE`}
                            </TableCell>
                            <TableCell
                                align='left'
                            >{t`ADMIN LEVEL`}
                            </TableCell>
                            <TableCell
                                align='left'
                            >
                                {t`RESOURCES`}
                            </TableCell>
                            <TableCell
                                align='left'
                            >
                                {t`RESOURCES ID'S`}
                            </TableCell>
                            <TableCell
                                align='center'
                            >
                                {t`ACTIONS`}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {populateList()}
                    </TableBody>
                </Table>

            </Paper>
            {t`
   `}{' '}
        </NavigationBar>
    );

}




// Side bar
const TriggerNavMenu = () => {
    const [showModal, toggleModal] = useState(false);
    // const createApp = useStoreActions(actions=>actions.apps.createApp);
    //
    //
    // const createClient = (appName: string, appDescription: string, clientId: any)=>{
    //     const createdClient = createApp({appName, appDescription, clientId });
    //
    //     if(createdClient){
    //         onCloseModal()
    //     }
    // }
    //
    const onCloseModal = () => {
        toggleModal(false);
    }

    return (
        <div>
            {showModal &&
                <OneTrigger
                    triggerInfo={null}
                    onSave={_.noop}
                    onClose={() => onCloseModal()}
                    title="Create Trigger"
                    contentText="please create your new trigger"
                    btnName="Create"
                />
            }
            <LocalButton title={t`Add new Trigger`}
                className=""
                onClick={() => toggleModal(true)} />
        </div>
    );
};

